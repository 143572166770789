import * as yup from 'yup';

export const MAX_LENGTH_CREDIT_REQUEST_COMMENT = 255;
export const MAX_LENGTH_CREDIT_REQUEST_COMMENT_ERROR_MESSAGE = 'A comment cannot be more than 255 characters.';
export const MAX_LENGTH_INVOICE_AND_CREDIT_REQUEST_NUMBER = 128;
export const MAX_LENGTH_INVOICE_NUMBER_ERROR_MESSAGE = 'Invoice Number should not be greater than 128 characters.';
export const MAX_LENGTH_CREDIT_REQUEST_NUMBER_ERROR_MESSAGE = 'Credit Request Number should not be greater than 128 characters.';

export enum CreditRequestFields {
  invoiceNumber = 'invoiceNumber',
  institutionName = 'institutionName',
  requesterFullName = 'requesterFullName',
  requesterTitle = 'requesterTitle',
  requesterEmail = 'requesterEmail',
  requesterPhone = 'requesterPhone',
  incorrectBillingAddress = 'incorrectBillingAddress',
  incorrectShippingAddress = 'incorrectShippingAddress',
  incorrectTax = 'incorrectTax',
  incorrectContactInfo = 'incorrectContactInfo',
  incorrectPricing = 'incorrectPricing',
  other = 'other',
  attachments = 'attachments',
  comment = 'comment'
}

export const creditRequestQueueText = 'Credit Request Queue';

export const EmptyCreditRequestQueueMessage = 'There are no search results.';
export const MINIMUM_LENGTH_CREDIT_REQUEST_SEARCH_TEXT = 5;

export const CreditRequestQueueTableColumns = {
  dateSubmitted: { field: 'createDate', header: 'Date Submitted' },
  creditRequestNumber: { field: 'creditRequestNumber', header: 'Credit Request Number' },
  creditedInvoiceNumber: { field: 'creditedInvoice', header: 'Credited Invoice Number' },
  institution: { field: 'institution', header: 'Institution' },
  reason: { field: 'updatedCreditRequest', header: 'Reason' },
  creditRequestStatus: { field: 'creditRequestStatus', header: 'Credit Request Status' }
};

export const CreditRequestStatuses = {
  unprocessed: { id: 27, name: 'Unprocessed', workflow: 'Unprocessed', defaultContact: 'CMS Generated' },
  underReview: { id: 28, name: 'Under Review', workflow: 'Under Review' },
  creditProcessUnderway: { id: 29, name: 'Credit Process Underway', workflow: 'Approved for Crediting' },
  processed: { id: 30, name: 'Processed', workflow: 'Ready For Crediting', defaultContact: 'CMS Generated' },
  denied: { id: 31, name: 'Denied', workflow: 'Denied' }
};

export const CreditRequestFilterOptions =
  [{
    name: 'All',
    value: 'All'
  }, {
    name: CreditRequestStatuses.creditProcessUnderway.name,
    value: '29'
  }, {
    name: CreditRequestStatuses.denied.name,
    value: '31'
  }, {
    name: CreditRequestStatuses.processed.name,
    value: '30'
  }, {
    name: CreditRequestStatuses.underReview.name,
    value: '28'
  }, {
    name: CreditRequestStatuses.unprocessed.name,
    value: '27'
  }];

export const creditRequestStatusModalId = 'creditRequestStatusModal';

export const ATTACHMENT_MAXIMUM_SIZE = 2000000; // 2MB

export const CREDIT_REQUEST_STATUS_VALIDATION_SCHEMA = {
  comment: yup.string().required('A comment is required.')
};

export const WORKFLOW_CREDIT_REQUEST_OPTIONS = [
  {
    value: '',
    name: 'Select a workflow status',
    confirmMsg: '',
    classColor: 'u-els-color-primary',
    action: null
  },
  {
    value: CreditRequestStatuses.underReview.id,
    name: CreditRequestStatuses.underReview.workflow,
    confirmMsg: 'By updating the workflow to Under Review the credit request will remain open. The credit request status will change to Under Review.',
    classColor: 'u-els-color-primary',
    action: 'underReview'
  },
  {
    value: CreditRequestStatuses.creditProcessUnderway.id,
    name: CreditRequestStatuses.creditProcessUnderway.workflow,
    confirmMsg: `By updating the workflow to Approved for Crediting the credit payload will be sent to Oracle for processing and the credit request
    will become locked. The credit request status will change to Credit Process Underway.`,
    classColor: 'u-els-color-confirm-on-dark',
    action: 'approvedForCrediting',
    disabled: true
  },
  {
    value: CreditRequestStatuses.processed.id,
    name: CreditRequestStatuses.processed.workflow,
    confirmMsg: '',
    classColor: 'u-els-color-confirm-on-dark',
    action: 'processed',
    disabled: true,
    hidden: true
  },
  {
    value: CreditRequestStatuses.denied.id,
    name: CreditRequestStatuses.denied.workflow,
    confirmMsg: 'By updating the workflow to Denied the credit request status will change to Denied.',
    classColor: 'u-els-color-warn',
    action: 'denied'
  },
];
export const CREDIT_REQUEST_NUMBER_VALIDATION_SCHEMA = {
  creditRequestNumber: yup.string().required('Credit Request Number is required.')
    .max(MAX_LENGTH_INVOICE_AND_CREDIT_REQUEST_NUMBER, MAX_LENGTH_CREDIT_REQUEST_NUMBER_ERROR_MESSAGE)
    .matches(/^PSCR-(\d+)-(\d+)$/, 'This is not a valid Program Solutions credit request.')
};

export const INVOICE_NUMBER_VALIDATION_SCHEMA = {
  invoiceNumber: yup.string().required('Invoice Number is required.')
    .max(MAX_LENGTH_INVOICE_AND_CREDIT_REQUEST_NUMBER, MAX_LENGTH_INVOICE_NUMBER_ERROR_MESSAGE)
};
