import React from 'react';
import cx from 'classnames';

export interface TextAreaProps {
  className?: string;
  id: string;
  labelText?: string;
  children?: string | JSX.Element;
  rows?: number;
  value?: string;
  placeholder?:string;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}
const TextArea = (props: TextAreaProps) => (
  <div className={cx('c-els-field', { 'c-els-field--disabled': props.disabled })}>
    <label htmlFor={props.id} className="c-els-field__label">
      <span className="c-els-field__label-text">{props.labelText || props.children}</span>
      <textarea rows={props.rows}
        id={props.id}
        className={props.className ? `c-els-field__input ${props.className}` : 'c-els-field__input'}
        name="textarea"
        placeholder={props.placeholder}
        value={props.value}
        maxLength={props.maxLength}
        minLength={props.minLength}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </label>
  </div>
);

export default TextArea;
