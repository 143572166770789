// eslint-disable-next-line max-len
const INVALID_PRODUCT_LIST_PRICES_OR_TERM_ERROR_MESSAGE = 'Unable to process the invoice. At least one cohort in the installment has products added but the total sum of list prices is less than the per student cost and/or has products added without the term selection made. Ensure all cohort products have been added to the cohort and have a term selection made.';

export const ERROR_MESSAGES = [
  {
    type: 'invalid_add_drop_date',
    message: 'Add/Drop Date cannot be equal to or less than the Payment Window Open Date.'
  },
  {
    type: 'invalid_cohort_end_date',
    message: 'Graduation Date cannot be equal to or less than any Installment Invoice Date'
  },
  {
    type: 'invalid_cloning_products_import',
    // eslint-disable-next-line max-len
    message: 'Unable to proceed with cloning products since one or multiple products have a term selected that is not available in the new cohort. You must deselect the “Include Term(s)” checkbox in order to proceed with cloning.'
  },
  {
    type: 'invoice_not_yet_sent_to_customer',
    message: 'This invoice has not been sent to the customer.'
  },
  {
    type: 'invalid_invoice_number',
    message: 'This is not a valid Program Solutions invoice.'
  },
  {
    type: 'invalid_invoice_number_over_date',
    message: 'Unable to submit credit request for invoice as it’s 30 days past the invoice date.'
  },
  {
    type: 'invoice_credit_request_existed',
    message: 'A credit request has already been submitted for this invoice.'
  },
  {
    type: 'invalid_credit_request_number',
    message: 'This is not a valid Program Solutions credit request.'
  },
  {
    type: 'not_most_recent_invoice_number',
    message: 'This invoice is not the most recent invoice for this cohort and can not be credited. Please contact customer support if you require further assistance.'
  },
  {
    type: 'invoice_credit_request_credited',
    message: 'A credit request has already been credited for this invoice.'
  },
  {
    type: 'invalid_final_invoice_number_over_date',
    message: 'Unable to submit credit request for final invoice as it’s 30 days past the invoice date.'
  },
  {
    type: 'invalid_product_suite',
    message: 'Unable to process the invoice. At least one cohort in the invoice has no products added.'
  },
  {
    type: 'invalid_product_list_prices_error',
    message: INVALID_PRODUCT_LIST_PRICES_OR_TERM_ERROR_MESSAGE
  },
  {
    type: 'missing_term_product_error',
    message: INVALID_PRODUCT_LIST_PRICES_OR_TERM_ERROR_MESSAGE
  },
  {
    type: 'previous_invoice_being_credited',
    message: 'This invoice cannot be approved for billing since the previous one is being credited.'
  },
  {
    type: 'invalid_hesi_cohort_name',
    message: 'The HESI cohort name already exists for the program. The HESI cohort name needs to be revised in order to create the HESI cohort.'
  },
  {
    type: 'invalid_cohort_start_graduation_date',
    message: 'Cohort Start Date and Graduation Date must be at least one month apart.'
  },
  {
    type: 'invalid_cohort_track',
    message: 'Cohort Track is invalid.'
  },
  {
    type: 'hesi_cohort_not_found',
    message: 'Hesi Cohort is not found.'
  },
  {
    type: 'exam_group_or_orderitems_exists',
    message: 'NG Order cannot be cancelled as ExamGroup/OrderItems exists.'
  },
  {
    type: 'orderitem_delivery_exists',
    message: 'Ala Carte Order cannot be cancelled as delivery exists on the orderitem.'
  },
  {
    type: 'invalid_hesi_cohort_id',
    message: 'HESI Cohort was not found.'
  },
  {
    type: 'hesi_contract_not_active',
    message: 'The Hesi Contract was not found or not active.'
  },
  {
    type: 'invalid_faculty_profiles',
    message: 'Unable to assign HESI faculty permissions. The following faculty are missing an institution address and/or work phone number in their Evolve profile. ' +
      'The missing information must be added to their Evolve profile in order to select the faculty as HESI ordering, scheduling, or proctoring faculty.'
  },
  {
    type: 'unenrolled_faculty_profiles',
    message: 'Unable to assign HESI faculty permissions. The following faculty are not valid enrolled faculty in opportunity.'
  },
  {
    type: 'duplicated_group_name',
    message: 'Group Name has been already used.'
  },
  {
    type: 'invalid_contract_start_end_date',
    message: 'The Contract Start Date or Contract End Date is not today or future day OR Contract Start Date is not before Contract End Date'
  }
];

export const ERROR_MESSAGE_PATH_MAPPING = [{
  errorType: 'hesiops_service_error',
  path: 'response.data.errors[0].detail.DetailedErrorMessage'
}];
