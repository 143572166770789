import axios from 'axios';
import { ELSTokenHelper } from '@els/els-ui-common-react';
import { clone, omitBy, isNil } from 'lodash';
import { tspServiceApi } from 'helpers/RestClient.helper';
import { blobToJson, convertToBase64 } from 'utilities/app/app.utility';
import { buildEvolvePagingOptions } from 'helpers/api.helpers';
import { PagingOptions } from 'models/api.model';
import { analyticBaseConfig } from 'config/app.config';
import {
  CreditRequestDetailModel, CreditRequestStatusEditorModel,
  CreditRequestReasonsEditorModel, CreditRequestStatusModel,
  CreditRequestSearchModel,
  CreditRequestModel
} from '../../models/credit-request.models';

export const validateInvoiceNumber = (invoiceNumber: string) => tspServiceApi.post('/invoicing/creditRebill/invoiceNumber/validate', { data: { invoiceNumber } });

export const createCreditRequest = async (creditRequest: CreditRequestDetailModel): Promise<{ creditRequestNumber: string }> => {
  const payload = clone(creditRequest);
  const allPromise = (creditRequest.attachments || []).map(async (attachment, index) => {
    const content = await convertToBase64(attachment.content);
    delete payload.attachments[index].path;
    payload.attachments[index].content = content.replace('data:application/pdf;base64,', '');
  });
  await Promise.all(allPromise);

  return tspServiceApi.post('/invoicing/creditRebill/creditRequest', { data: payload }) as Promise<{ creditRequestNumber: string }>;
};

export const fetchCreditRequestQueue = (pagingOptions: PagingOptions) => {
  const data = {
    ...buildEvolvePagingOptions(pagingOptions),
    query: pagingOptions.query,
    filteredStatuses: pagingOptions.filteredStatuses
  };

  return tspServiceApi.post('/invoicing/creditRebill/creditRequests/search', { data }) as Promise<CreditRequestSearchModel>;
};

export const getPayloadAttachment = async (payload, attachment, index) => {
  const result = { ...payload };
  const content = await convertToBase64(attachment.content);
  delete result.attachments[index].path;
  result.attachments[index].content = content.replace('data:application/pdf;base64,', '');
};

export const updateCreditRequestStatus = async (creditRequestStatusEditorModel: CreditRequestStatusEditorModel): Promise<CreditRequestStatusModel> => {
  const payload = clone(omitBy(creditRequestStatusEditorModel, isNil));
  const attachments = creditRequestStatusEditorModel.attachments || [];
  const allPromise = attachments.map((attachment, index) => getPayloadAttachment(payload, attachment, index));
  await Promise.all(allPromise);

  return tspServiceApi.put(`/invoicing/creditRebill/creditRequest/${creditRequestStatusEditorModel.tspInvoiceCreditRequestId}/editCreditRequestStatus`,
    { data: payload }) as Promise<CreditRequestStatusModel>;
};

export const checkInvoiceCreditRequestStatus = (creditRequestNumber: string): Promise<CreditRequestStatusModel> => {
  const payload = { creditRequestNumber };
  return tspServiceApi.post('/invoicing/creditRebill/creditRequest/checkStatus', { data: payload }) as Promise<CreditRequestStatusModel>;
};
export const getCreditRequestDetails = (creditRequestNumber: string) => tspServiceApi.get(`/invoicing/creditRebill/creditRequest/${creditRequestNumber}`) as
  Promise<CreditRequestModel>;

export const getCreditRequestHistory = (tspInvoiceCreditRequestId: number) =>
  tspServiceApi.get(`/invoicing/creditRebill/creditRequest/${tspInvoiceCreditRequestId}/activityHistory`) as
  Promise<{ tspInvoiceCreditRequestId: number, creditRequestActivities: CreditRequestStatusModel[] }>;

export const fetchAttachmentFile = (pathFile: string): Promise<{ data: string }> => {
  let url = pathFile;
  if (!url.includes(analyticBaseConfig.baseUrl)) {
    url = analyticBaseConfig.baseUrl + pathFile;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${ELSTokenHelper.getToken()}`
    },
    responseType: 'blob'
  }).then(response => response)
    .catch(async error => {
      throw blobToJson(error.response.data);
    });
};

export const updateCreditRequest = async (payload) =>
  tspServiceApi.put(`/invoicing/creditRebill/creditRequest/${payload.tspInvoiceCreditRequestId}/editReasons `, {
    data: payload
  }) as Promise<CreditRequestReasonsEditorModel>;
