import { useState, useEffect } from 'react';
import { getBillingCountries } from 'utilities/app/app.utility';

const useCountries = () => {
  const [countries, setCountries] = useState([]);
  const updateCountries = () => {
    setCountries([
      {
        value: '',
        name: 'Country',
      },
      ...getBillingCountries(),
    ]);
  };

  useEffect(() => {
    updateCountries();
  }, []);

  return { countries, setCountries };
};

export default useCountries;
