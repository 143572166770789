import { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { isEqual } from 'lodash';
import HESIApp from 'modules/hesi/components/HESIApp';
import { ALCRoutePath } from 'modules/hesi/constants/hesi.constants';
import CMSApp from './CMSApp';

const AppRoutes = memo(() => (
  <Switch>
    <Route path={ALCRoutePath.rootPath} component={HESIApp} />
    <Route path="/" component={CMSApp} />
  </Switch>
), isEqual);

export default AppRoutes;
