import React from 'react';
import { ELSIcon } from '@els/els-component-form-field-react';
import { NavigationItemType } from '../../../../models/navigation.models';

export interface TopBaseLinkProps {
  item: NavigationItemType;
}

const TopBaseLink = ({ item }: TopBaseLinkProps) => (
  <div className="o-els-flex-layout o-els-flex-layout--column u-els-text-center">
    {
      item.iconName ?
        (
          <div className="o-els-flex-layout__item c-tsp-nav-item-top__icon">
            <ELSIcon size="2x" prefix={item.iconPrefix} name={item.iconName} align="middle" />
          </div>
        ) : <></>
    }
    <div className="o-els-flex-layout__item c-tsp-nav-item-top__text">
      {item.text}
    </div>
  </div>
);

export default TopBaseLink;
