import { UserRole } from '../../constants/app.constant';

import {
  ANONYMOUS_FOOTER_MENU,
  INSTRUCTOR_FOOTER_MENU,
  STUDENT_FOOTER_MENU
} from '../../constants/footer.constants';

export const getFooterMenu = (roleId, isLoggedIn) => {
  if (!isLoggedIn || !roleId) {
    return ANONYMOUS_FOOTER_MENU;
  }
  if (roleId === UserRole.Student) {
    return STUDENT_FOOTER_MENU;
  }
  return INSTRUCTOR_FOOTER_MENU;
};

export const getCurrentUrl = location => `${process.env.PUBLIC_URL}${location.pathname}${location.search}${location.hash}`;

export const getContactRole = roleId => ((roleId === UserRole.Student) ? 'student' : 'educatorfaculty');
