import { tspServiceApi } from '../../helpers/RestClient.helper';
import { FilterPagingOptions } from '../../models/api.model';
import { buildEvolvePagingOptions } from '../../helpers/api.helpers';
import { UserRole, VALIDATE_RESPONSE_ERROR_TYPE } from '../../constants/app.constant';
import { CohortProductModel, ProductApprovalStatusModel, ProductLMSConfigModel } from '../../models/cohort.models';
import { EvolveProductModel } from '../../models/evolve.models';

const DEFAULT_PAGING_OPTIONS = {
  sortColumn: 'createDate',
  sortDirection: 'asc',
  startIndex: 1,
  size: 1
};

const COUNTRY_CODE = 'US';

const prepareProductConfiguration = (config: ProductLMSConfigModel) => {
  const otherFaculties = config.tspConfig.otherFaculties ? config.tspConfig.otherFaculties.map(faculty => faculty.userName) : [];
  const studentGroups = config.tspConfig.studentGroups ? config.tspConfig.studentGroups.map(group => group.tspGroupId) : [];
  const configuration = {
    ...config,
    tspConfig: {
      ...config.tspConfig,
      leadFaculty: config.tspConfig.leadFaculty ? config.tspConfig.leadFaculty.userName : '',
      studentGroups,
      otherFaculties
    }
  };
  delete configuration.tspConfig.showNotifyButton;
  return configuration;
};

const prepareProducts = (products) => products.map(p => ({
  resourceId: p.resourceId,
  compRequest: p.compRequest,
  quantity: (typeof p.quantity === 'undefined') ? 1 : p.quantity,
  configuration: p.configuration && p.configuration.tspConfig ? prepareProductConfiguration(p.configuration) : p.configuration,
  productRole: UserRole.Faculty,
  term: p.term,
  orderItemId: p.orderItemId
}));

type FetchProductSuiteAPIResponse = {
  items: EvolveProductModel[];
  totalCount: number;
  orderId?: number;
  isContainingDefaultItemsOnly?: boolean;
  productApprovalStatus?: ProductApprovalStatusModel;
  disabledTerms: number[]
}
export const fetchProductSuite = async (cohortId: string | number, sessionId: string, pagingOptions: FilterPagingOptions = null, includeProductDetail = false) => {
  try {
    const pagingData = (pagingOptions) ? buildEvolvePagingOptions(pagingOptions) : DEFAULT_PAGING_OPTIONS;
    const res = await tspServiceApi.post(`/productSuite/${cohortId}`, {
      data: {
        ...pagingData,
        includeProductDetail
      }
    }) as FetchProductSuiteAPIResponse;
    return {
      isCreated: !!res.orderId,
      isContainingDefaultItemsOnly: res.isContainingDefaultItemsOnly,
      Products: res.items,
      TotalCount: res.totalCount,
      productApprovalStatus: res.productApprovalStatus,
      DisabledTerms: res.disabledTerms || []
    };
  } catch (err) {
    if (err.response && err.response.status === 404) {
      return {
        isCreated: false,
        Products: [],
        TotalCount: 0,
        productApprovalStatus: null
      };
    }
    throw err;
  }
};

export const createProductSuite = async (opportunityId: string | number, cohortId: string | number, products) => {
  const data = {
    opportunityId,
    cohortId,
    items: prepareProducts(products),
    countryCode: COUNTRY_CODE
  };
  return tspServiceApi.post('/productSuite/cohortProduct', { data });
};

export const addProductsToProductSuite = async (cohortId: string | number, products) => {
  const data = {
    operation: 'add',
    items: prepareProducts(products),
    countryCode: COUNTRY_CODE
  };
  return tspServiceApi.put(`/productSuite/${cohortId}`, { data });
};

type UpdateProductSuiteAPIResponse = Promise<{ isContainingDefaultItemsOnly: boolean }>;
export const updateProductSuite = async (cohortId: string | number, products) => {
  const data = {
    operation: 'update',
    items: prepareProducts(products),
    countryCode: COUNTRY_CODE
  };
  return tspServiceApi.put(`/productSuite/${cohortId}`, { data }) as UpdateProductSuiteAPIResponse;
};

export const cloneProductSuite = async (source, dest) => {
  const data = {
    sourceCohortId: source.cohortId,
    sourceOpportunityId: source.opportunityId,
    destCohortId: dest.cohortId,
    destOpportunityId: dest.opportunityId
  };
  return tspServiceApi.post('/productSuite/cloneCohort', { data });
};

export const removeProduct = async (cohortId, product) => {
  const data = {
    operation: 'update',
    items: prepareProducts([
      {
        ...product,
        quantity: 0
      }
    ]),
    countryCode: COUNTRY_CODE
  };
  return tspServiceApi.put(`/productSuite/${cohortId}`, { data });
};
export const fetchEntitlementStudents = async (cohortId: string | number, resourceId: string | number, term: string | number, orderItemId: number) =>
  tspServiceApi.get(`/entitlement/cohort/${cohortId}/isbn/${resourceId}/term/${term}/orderItemId/${orderItemId}`);
type ExportProductfulfillProductAPIResponse = Promise<{ orderId: number }>;
export const fulfillProduct = async (cohortId: string | number, orderId: string | number, product) => {
  const { resourceId, configuration, term } = product;
  const configurationModified = {
    ...configuration,
    tspConfig: {
      ...configuration.tspConfig,
      courseSetupDate: configuration.tspConfig && configuration.tspConfig.courseSetupDate ? configuration.tspConfig.courseSetupDate : '',
      studentEnrollmentDate: configuration.tspConfig && configuration.tspConfig.studentEnrollmentDate ? configuration.tspConfig.studentEnrollmentDate : '',
      ...(!!term && { term })
    }
  };
  return tspServiceApi.post(`/fulfillment/cohort/${cohortId}/order/${orderId}/isbn/${resourceId}/orderItemId/${product.orderItemId}`, {
    data: configurationModified
  }) as ExportProductfulfillProductAPIResponse;
};

export const entitleProduct = async (cohortId: string | number, orderId: string | number, product: CohortProductModel, courseIds?: string[]) => {
  const { resourceId } = product;
  let data: any = {
    productType: product.productType,
    term: product.term,
    orderItemId: product.orderItemId
  };
  if (courseIds && Array.isArray(courseIds) && courseIds.length > 0) {
    data = { ...data, courseIds };
  }
  return tspServiceApi.post(`/entitlement/cohort/${cohortId}/order/${orderId}/isbn/${resourceId}`, { data });
};

export const notifyProduct = (resourceId: string | number, cohortId: string | number, orderItemId: number) => tspServiceApi.post(`/productSuite/${cohortId}/${resourceId}/notify`, {
  data: {
    cohortId,
    resourceId,
    orderItemId
  }
});

type ExportProductSuiteAPIResponse = Promise<string>;
export const exportProductSuiteForApproval = (cohortId: string | number, activityId = null) => {
  const data = {
    cohortId,
    activityId
  };
  return tspServiceApi.post(`/approval/productSuite/${cohortId}/export`, { data }, VALIDATE_RESPONSE_ERROR_TYPE.REVIEW_APPROVAL) as ExportProductSuiteAPIResponse;
};

export const exportProductSuite = (cohortId: string | number) => tspServiceApi.get(`/productSuite/${cohortId}/export`) as ExportProductSuiteAPIResponse;

type FetchProductSuiteForApprovalAPIResponse = {
  items: EvolveProductModel[];
  totalCount: number;
  productApprovalStatus?: ProductApprovalStatusModel;
  cohort: {
    cohortId: string;
    cohortName: string;
    program: string;
  };
  opportunity: {
    accountName: string;
    opportunityId: string;
    opportunityIdNumber: number;
    opportunityName: string;
  }
}
export const fetchProductSuiteForApproval = async (cohortId: string | number, sessionId: string, pagingOptions: FilterPagingOptions = null, activityId = null,
  includeProductDetail = true) => {
  const pagingData = (pagingOptions) ? buildEvolvePagingOptions(pagingOptions) : DEFAULT_PAGING_OPTIONS;
  const res = await tspServiceApi.post(`/approval/productSuite/${cohortId}`, {
    data: {
      ...pagingData,
      activityId,
      includeProductDetail
    }
  }, VALIDATE_RESPONSE_ERROR_TYPE.REVIEW_APPROVAL) as FetchProductSuiteForApprovalAPIResponse;
  return {
    isCreated: true,
    Products: res.items,
    TotalCount: res.totalCount,
    productApprovalStatus: res.productApprovalStatus,
    cohort: res.cohort,
    opportunity: res.opportunity
  };
};
