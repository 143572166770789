import { AccessControlType, UserRole } from 'constants/app.constant';
import { useSelector } from 'react-redux';
import { cohortSelectors } from 'redux/cohort';
import { opportunitySelectors } from 'redux/opportunity';
import { securitySelectors } from 'redux/security';
import { isResourceCompleted } from 'utilities/app/app.utility';

const useCohortOrOpportunityCompleted = (resourceEndDate?: string | Date) => {
  if (resourceEndDate) {
    return isResourceCompleted(resourceEndDate);
  }

  const isOpportunityCompleted = useSelector(opportunitySelectors.isOpportunityCompleted);
  const isCohortCompleted = useSelector(cohortSelectors.isCohortCompleted);
  return isCohortCompleted || isOpportunityCompleted;
};

const getResult = (value, actionType, isCohortOrOppCompleted) => {
  const result: { isDisabled?: boolean, isHide?: boolean } = { isHide: false, isDisabled: false };
  switch (actionType) {
    case AccessControlType.Disabled:
      if (value || isCohortOrOppCompleted) {
        result.isDisabled = true;
      }
      break;
    case AccessControlType.Hide:
      if (value || isCohortOrOppCompleted) {
        result.isHide = true;
      }
      break;
    case AccessControlType.Show:
      if (!value || isCohortOrOppCompleted) {
        result.isHide = true;
      }
      break;
    case AccessControlType.Enabled:
      if (!value || isCohortOrOppCompleted) {
        result.isDisabled = true;
      }
      break;
    default:
      break;
  }

  return result;
};

/**
 * action is marked to HIDE/DISABLE when current oppportunity/cohort completed
 * isOnlyRoles = false: if the use has 'roles', it will excute the 'actionType' and vice versa.
 * isOnlyRoles = true: if the use has only 'roles', it will excute the 'actionType' and vice versa.
 * @param isOnlyRoles
 * @param actionType
 * @param roles
 * @param ignoreValidateResource
 * @returns
 */
const useAccessControll = (isOnlyRoles: boolean, actionType: AccessControlType, roles = [], ignoreValidateResource = false, resourceEndDate?: string | Date) => {
  const isValidRoles = useSelector(securitySelectors.isAccessControlRoles(roles, isOnlyRoles));
  const isSuperUser = useSelector(securitySelectors.isAuthorized([UserRole.TspSuperUser]));
  const isCohortOrOppCompleted = (ignoreValidateResource || isSuperUser) ? false : useCohortOrOpportunityCompleted(resourceEndDate);

  return getResult(isValidRoles, actionType, isCohortOrOppCompleted);
};

export default useAccessControll;
