import * as yup from 'yup';
import { isBeforeDate, isSameOrAfterDate, isValidedDateByFormat } from '../utilities/app/app.utility';
import { DATE_FORMAT_MDYYYY, DEFAULT_GENERATED_SYSTEM_NAME } from './app.constant';

export const STUDENT_PAYMENT_WINDOW_OPEN_SCHEMA = {
  studentPaymentStartDate: yup
    .date()
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue === '') {
        return null;
      }
      return isValidedDateByFormat(originalValue, DATE_FORMAT_MDYYYY) ? value : false;
    })
    .required('Payment Window Open Date is required.')
    .typeError('Invalid date format. Date must be in MM/DD/YYYY format.')
    .test({
      name: 'isTodayOrFutureDate',
      test(value) {
        const { startDate } = this.options.context;
        if (isSameOrAfterDate(value, startDate)) {
          return true;
        }
        return false;
      },
      message: 'You must select today\'s date or a future date when editing the Payment Window Open Date.',
      exclusive: true
    })
    .test({
      name: 'isLessThanAddDropDate',
      test(value) {
        const { endDate } = this.options.context;
        if (isBeforeDate(value, endDate)) {
          return true;
        }
        return false;
      },
      message: 'Payment Window Open Date cannot be equal to or greater than the Add/Drop Date.',
      exclusive: true
    })
};

export enum StudentPaymentStatusType {
  AWAITING_PAYMENT = 21,
  PAYMENT_OVERDUE = 22,
  PAID = 23,
  NO_REQUIRED = 24,
  REFUNDED = 44,
  REQUEST_SENT = 19,
  REMINDER_REQUEST_SENT = 20,
  INVOICE_PAYLOAD_SENT = 6
}

export const StudentPaymentStatuses = {
  REQUEST_SENT: {
    id: StudentPaymentStatusType.REQUEST_SENT,
    name: 'Student Payment Request Sent',
    defaultContact: DEFAULT_GENERATED_SYSTEM_NAME
  },
  REMINDER_REQUEST_SENT: {
    id: StudentPaymentStatusType.REMINDER_REQUEST_SENT,
    name: 'Reminder: Student Payment Reminder Request Sent',
    defaultContact: DEFAULT_GENERATED_SYSTEM_NAME
  },
  AWAITING_PAYMENT: {
    id: StudentPaymentStatusType.AWAITING_PAYMENT,
    name: 'Student Awaiting Payment',
    defaultContact: DEFAULT_GENERATED_SYSTEM_NAME
  },
  PAYMENT_OVERDUE: {
    id: StudentPaymentStatusType.PAYMENT_OVERDUE,
    name: 'Student Payment Overdue',
    defaultContact: DEFAULT_GENERATED_SYSTEM_NAME
  },
  PAID: {
    id: StudentPaymentStatusType.PAID,
    name: 'Student Paid'
  },
  NO_REQUIRED: {
    id: StudentPaymentStatusType.NO_REQUIRED,
    name: 'Student Payment Not Required',
    defaultContact: DEFAULT_GENERATED_SYSTEM_NAME
  },
  REFUNDED: {
    id: StudentPaymentStatusType.REFUNDED,
    name: 'Student Payment Refunded'
  },
  INVOICE_PAYLOAD_SENT: {
    id: StudentPaymentStatusType.INVOICE_PAYLOAD_SENT,
    name: 'Invoice Payload Sent',
    defaultContact: DEFAULT_GENERATED_SYSTEM_NAME
  }
};

export const StudentPaymentTableColumns = {
  name: { field: 'name', header: 'Student Name' },
  userName: { field: 'userName', header: 'Evolve Username' },
  email: { field: 'email', header: 'Email' },
  paymentDetail: { field: 'studentPaymentStatus', header: 'Payment Detail' },
  activityDate: { field: 'activityDate', header: 'Last Reminder' }
};

export const EmptyStudentPaymentMessage = 'No Students Found';

export const TSP_STUDENT_PAYMENT_KEY = 'tspOrder';

export enum STUDENT_PAYMENT_HISTORY_TRANSACTION_TYPE {
  PAYMENT = 'Payment',
  CREDITED = 'Credited',
  REFUNDED = 'Refunded'
}
