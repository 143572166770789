import { Loader } from '@els/els-react--loader';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../redux/app';

interface WrapperProps {
  isLoading: boolean;
}

const withLoading = BaseComponent => (props: WrapperProps) => {
  const isAppLoading = useSelector(appSelectors.isLoading);
  const loading = props.isLoading || isAppLoading;
  return (
    <div>
      {loading ? <Loader isBlocking isVisible /> : null}
      <BaseComponent {...props} />
    </div>
  );
};

export default withLoading;
