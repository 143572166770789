import { findIndex } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ALCConstants, defaultExamPagingOptions } from '../../constants/hesi.constants';
import { ProgramModel, PagingOptions, ProgramTypeModel } from '../../models/exam.model';
import { ExamOrderItemModel, ExamOrderModel } from '../../models/order.model';

export const REDUCER_ID = ALCConstants.redux.ORDER_EXAM_STATE;

const initialState = {
  isAuthorized: null,
  programTypes: null,
  examProducts: null,
  pagingOptions: defaultExamPagingOptions,
  orderItems: null,
  orderItemSelected: null,
  programs: [],
  selectedTab: 0,
  examOrder: null
};

export const orderExamSlice = createSlice({
  name: 'orderExams',
  initialState,
  reducers: {
    restoreOrderExamState(state, action) {
      return {
        ...state,
        ...action.payload,
        isAuthorized: null
      };
    },
    setAuthorizeOrderExams(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isAuthorized: action.payload
      };
    },
    fetchProductMetadataSuccess(state, action: PayloadAction<ProgramTypeModel[]>) {
      return {
        ...state,
        programTypes: action.payload
      };
    },
    setOrderItems(state, action: PayloadAction<ExamOrderItemModel[]>) {
      return {
        ...state,
        orderItems: action.payload
      };
    },
    updateOrderItems(state, action: PayloadAction<ExamOrderItemModel>) {
      let orderItems = state.orderItems && state.orderItems.length > 0 ? [...state.orderItems] : [];
      const index = findIndex(orderItems, { name: action.payload.name });
      if (index === -1) {
        orderItems.unshift(action.payload);
      } else {
        orderItems = orderItems.map(order => (order.name === action.payload.name ? { ...action.payload } : order));
      }
      return {
        ...state,
        orderItems
      };
    },
    setOrderItemSelected(state, action: PayloadAction<ExamOrderItemModel>) {
      return {
        ...state,
        orderItemSelected: action.payload
      };
    },
    setPrograms(state, action: PayloadAction<ProgramModel[]>) {
      return {
        ...state,
        programs: action.payload
      };
    },
    setSelectedTab(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload
      };
    },
    fetchExamProductsDataSuccess(state, action) {
      return {
        ...state,
        examProducts: action.payload
      };
    },
    updateExamPagingOptions(state, action: PayloadAction<PagingOptions>) {
      const pagingOptions = {
        ...(state.pagingOptions || {}),
        ...action.payload
      };
      return {
        ...state,
        pagingOptions
      };
    },
    setExamOrder(state, action: PayloadAction<ExamOrderModel>) {
      return {
        ...state,
        examOrder: action.payload
      };
    },
  }
});

export const orderExamActions = orderExamSlice.actions;
export const orderExamReducer = orderExamSlice.reducer;
