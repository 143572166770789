import { lazy } from 'react';
import { ALCAnalytics, ALCRoutePath } from '../constants/hesi.constants';

export const getRoutes = () => [
  {
    path: ALCRoutePath.error.notAuthorized,
    component: lazy(() => import('pages/error/NotAuthorized.page')),
    roles: [],
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.notAuthorized,
        pageName: 'not-authorized'
      }
    }
  },
  {
    path: ALCRoutePath.error.pageNotFound,
    component: lazy(() => import('pages/error/PageNotFound.page')),
    roles: [],
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.pageNotFound,
        pageName: 'page-not-found'
      }
    }
  }
];

export const HESISecutityRoutes = {
  getRoutes
};
