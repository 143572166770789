import { ELSCommonConfig } from '@els/els-ui-common-react';

export const HESIServerConstants = {
  DataSource: ELSCommonConfig.appProfile,

  common: {},
  local: {
    hesiBaseURL: 'https://hesi-dev.apps.els-ols.com'
  },
  dev: {
    hesiBaseURL: 'https://hesi-dev.apps.els-ols.com'
  },
  qa: {},
  test1: {},
  test2: {
    hesiBaseURL: 'https://hesi-test2.apps.els-ols.com'
  },
  test3: {},
  test4: {},
  test5: {},
  test6: {},
  stage: {
    hesiBaseURL: 'https://hesi-stage.apps.els-ols.com'
  },
  prod: {
    hesiBaseURL: 'https://hesi.elsevier.com'
  }
};
