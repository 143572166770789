import React, { ReactNode } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

type FooterMenuItem = {
  id: string,
  text: ReactNode | string,
  href: string,
  target: string
};

export const MENU_ITEMS: FooterMenuItem[] = [
  {
    id: 'footerTermsAndConditions',
    text: (<FormattedMessage id="FOOTER_TERM_AND_CONDITION_LINK" />),
    href: 'https://www.elsevier.com/legal/elsevier-website-terms-and-conditions',
    target: '_blank'
  },
  {
    id: 'footerPrivacyPolicy',
    text: (<FormattedMessage id="FOOTER_PRIVACY_POLICY" />),
    href: 'https://www.elsevier.com/legal/privacy-policy',
    target: '_blank'
  },
  {
    id: 'footerRefundPolicy',
    text: (<FormattedMessage id="FOOTER_RETURN_REFUND_LINK" />),
    href: 'https://www.elsevier.com/legal/elsevier-evolve-terms-and-conditions',
    target: '_blank'
  },
  {
    id: 'footerAccessibility',
    text: (<FormattedMessage id="FOOTER_ACCESSIBILITY_LINK" />),
    href: 'https://service.elsevier.com/app/answers/detail/a_id/11544/supporthub/evolve/kw/accessibility',
    target: '_blank'
  },
  {
    id: 'footerTechnicalRequirements',
    text: (<FormattedMessage id="FOOTER_TECHNICAL_REQUIREMENTS" />),
    href: 'https://service.elsevier.com/app/answers/detail/a_id/8199/supporthub/evolve/kw/system+requirements',
    target: '_blank'
  }
];

const renderFooterMenu = (menu: FooterMenuItem[]) => {
  const menuItems = menu.map((item: FooterMenuItem) => (
    <div className="c-tsp-footer__link" key={item.id}>
      <a className="es-link" href={item.href} id={item.id} target={item.target} rel="noopener noreferrer">{ item.text }</a>
    </div>
  ));
  return (
    <>
      { menuItems }
    </>
  );
};

export interface FooterContentProps {
  children: ReactNode;
}
const FooterContent = (props: FooterContentProps) => (
  <div className="c-tsp-footer__content">
    <div className="c-tsp-footer__link u-els-width-1o1">
      <FormattedHTMLMessage id="FOOTER_COPYRIGHT"
                    values={{ year: new Date().getFullYear() }} />
    </div>
    { renderFooterMenu(MENU_ITEMS) }
    { props.children }
  </div>
);

export default FooterContent;
