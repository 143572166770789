import { Component } from 'react';
import { connect } from 'react-redux';
import { ELSWithModalService } from '@els/els-component-modal-react';
import { Location } from 'history';
import { locationSelectors } from 'redux/location';
import { ELSModalServiceType } from 'models/els.model';

interface LocationChangeHandlerProps {
  location: Location;
  modalService: ELSModalServiceType;
}

class LocationChangeHandler extends Component<LocationChangeHandlerProps> {
  componentDidUpdate({ location }) {
    const pathname = 'pathname';
    const newLocation = this.props.location;
    if (location && newLocation && location[pathname] !== newLocation[pathname]) {
      this.props.modalService.closeAllModal();
    }
    /* eslint-disable no-underscore-dangle */
    window._mfq = window._mfq || [];
    const regexp = /\/#/g;
    const href = window.location.href.replace(regexp, '');
    window._mfq.push(['newPageView', href]);
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  location: locationSelectors.getLocation(state)
});

export default connect(mapStateToProps)(ELSWithModalService(LocationChangeHandler));
