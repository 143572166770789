import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omitBy, isNull } from 'lodash';
import { EvolveUserAddressModel, EvolveUserModel } from 'models/evolve.models';
import { StudentPaymentModel, TspStudentPaymentOrderModel } from 'models/student-payments.models';
import { AppConstant } from '../../constants/app.constant';

export const REDUCER_ID = AppConstant.redux.STUDENT_PAYMENT_STATE;

interface StudentPaymentStateModel {
  user: EvolveUserModel;
  studentPaymentInfo: StudentPaymentModel
}

const initialState: StudentPaymentStateModel = {
  user: null,
  studentPaymentInfo: null
};

export const invoiceSlice = createSlice({
  name: 'studentPayment',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<EvolveUserModel>) {
      return {
        ...state,
        user: action.payload
      };
    },
    setStudentPaymentInfo(state, action: PayloadAction<any>) {
      return {
        ...state,
        studentPaymentInfo: action.payload
      };
    },
    setTspStudentPaymentOrderAddress(state, action: PayloadAction<EvolveUserAddressModel>) {
      return {
        ...state,
        studentPaymentInfo: {
          ...state.studentPaymentInfo,
          TspStudentPaymentOrder: {
            ...state.studentPaymentInfo.TspStudentPaymentOrder,
            billAddress: action.payload
          }
        }
      };
    },
    setTspStudentPaymentOrder(state, action: PayloadAction<TspStudentPaymentOrderModel>) {
      return {
        ...state,
        studentPaymentInfo: {
          ...state.studentPaymentInfo,
          TspStudentPaymentOrder: {
            ...state.studentPaymentInfo.TspStudentPaymentOrder,
            ...omitBy(action.payload, isNull)
          }
        }
      };
    },
    setStudentPaymentStatus(state, action: PayloadAction<number>) {
      return {
        ...state,
        studentPaymentInfo: {
          ...state.studentPaymentInfo,
          StudentPaymentStatus: {
            ...state.studentPaymentInfo.StudentPaymentStatus,
            id: action.payload
          }
        }
      };
    },
    restoreStudentPaymentState(state, action: PayloadAction<any>) {
      return {
        ...state,
        studentPayment: {
          tspOrderId: action.payload.tspOrderId
        }
      };
    }
  }
});

export const studentPaymentActions = invoiceSlice.actions;
export const studentPaymentReducer = invoiceSlice.reducer;
