import { INVOICE_MODE } from 'constants/invoice.constant';
import { getFeatureFlag } from '../../config/app.config';
import { tspServiceApi } from '../../helpers/RestClient.helper';
import { PagingOptions } from '../../models/api.model';
import { InvoiceEditorModel, InvoiceModel, InstitutionInvoiceModel } from '../../models/invoice.model';
import { buildInvoicePayload } from '../../utilities/app/app.utility';
import { buildEvolvePagingOptions } from '../../helpers/api.helpers';
import {
  getInvoiceDetail,
  getInvoices,
  updateInvoice as updateInvoiceAPI,
  createInvoice as createInvoiceApi
} from '../../utilities/api/invoices-api/invoicesApi.utility';
import { VALIDATE_RESPONSE_ERROR_TYPE } from '../../constants/app.constant';

export const fetchInvoiceStatusHistory = (invoiceId: string | number, isConsolidatedHistory: boolean) => {
  const options = { params: { isCH: isConsolidatedHistory === true } };
  return tspServiceApi.get(`/invoicing/invoice/${invoiceId}/cmsStatusHistory`, options);
};

export const updateBillingApproval = (tspInvoiceId: string | number) =>
  tspServiceApi.post(`/invoicing/invoice/${tspInvoiceId}/billingApprove`, { data: { tspInvoiceId: Number(tspInvoiceId) } });

type SendTspInvoiceToBomServicesAPIResponse = Promise<{ invoiceReferenceNumber?: string }>;
export const sendTspInvoiceToBomServices = (invoiceId: string | number) =>
  tspServiceApi.post(`/invoicePosting/invoice/${invoiceId}`) as SendTspInvoiceToBomServicesAPIResponse;

export const fetchInvoiceDetail = (invoiceId): Promise<InvoiceModel> => {
  const fetchOpportunitiesFromApi = getFeatureFlag('fetchOpportunitiesFromApi');
  if (!fetchOpportunitiesFromApi) {
    getInvoiceDetail(invoiceId);
  }
  return tspServiceApi.get(`/invoicing/invoice/${invoiceId}`, {}, VALIDATE_RESPONSE_ERROR_TYPE.REVIEW_APPROVAL) as Promise<InvoiceModel>;
};

export const updateInvoices = (invoiceEditor: InvoiceEditorModel) => {
  const fetchOpportunitiesFromApi = getFeatureFlag('fetchOpportunitiesFromApi');
  if (!fetchOpportunitiesFromApi) {
    return updateInvoiceAPI(invoiceEditor);
  }
  const data = {
    opportunityId: invoiceEditor.opportunityId,
    installment: { installmentId: invoiceEditor.installment.installmentId, cohortId: invoiceEditor.installment.cohortId },
    invoices: buildInvoicePayload(invoiceEditor.invoices, INVOICE_MODE.EDIT)
  };
  return tspServiceApi.put('/invoicing/invoices', { data });
};

type FetchInvoicesAPIResponse = Promise<{
  institutionInvoices: InstitutionInvoiceModel[];
  totalCount: number;
}>
export const fetchInvoices = (opportunityId, pagingOptions: PagingOptions) => {
  const fetchOpportunitiesFromApi = getFeatureFlag('fetchOpportunitiesFromApi');
  if (!fetchOpportunitiesFromApi) {
    return getInvoices(opportunityId, pagingOptions);
  }
  return tspServiceApi.post(`/invoicing/invoices/opportunity/${opportunityId}`, {
    data: buildEvolvePagingOptions(pagingOptions)
  }) as FetchInvoicesAPIResponse;
};

type ValidateInvoiceAPIResponse = Promise<{ activeTspInvoiceIds: number[] }>;
export const validateInvoice = (OpportunityId, installmentSelected) => {
  const data = {
    opportunityId: OpportunityId,
    installment: {
      installmentId: installmentSelected.installmentId,
      installmentNumber: installmentSelected.installmentNumber,
      cohortId: installmentSelected.cohortId
    }
  };
  return tspServiceApi.post('/invoicing/invoice/validateTspInvoice', { data }) as ValidateInvoiceAPIResponse;
};

export const createInvoice = (invoiceEditor: InvoiceEditorModel) => {
  const fetchOpportunitiesFromApi = getFeatureFlag('fetchOpportunitiesFromApi');
  if (!fetchOpportunitiesFromApi) {
    return createInvoiceApi(invoiceEditor);
  }
  const data = {
    opportunityId: invoiceEditor.opportunityId,
    installment: {
      installmentId: invoiceEditor.installment.installmentId,
      cohortId: invoiceEditor.installment.cohortId
    },
    invoices: buildInvoicePayload(invoiceEditor.invoices, INVOICE_MODE.CREATE)
  };
  return tspServiceApi.post('/invoicing/invoices', { data });
};

export const deleteInvoicesByInstallmentId = (installmentId: string | number) =>
  tspServiceApi.delete(`/invoicing/invoices/installment/${installmentId}`);
