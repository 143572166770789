import React from 'react';
import { MenuConstants } from '../../../constants/MenuConstants';

export interface MenuWindowViewProps {
  verticalAlign: string;
  maxHeight?: string | number;
  className?: string;
  setMenuWindow?: any;
  menuPosition?: object;
  menuItems: any[];
  handleKeyDown: Function;
  handleClickItem: Function;
}
const MenuWindowView = (props: MenuWindowViewProps) => {
  const menuClass = props.verticalAlign === MenuConstants.align.top ? 'c-els-menu__window--top' : '';
  const contentStyle = {
    maxHeight: props.maxHeight || 'auto',
    overflow: 'auto'
  };
  return (
    <div className={props.className}>
      <div className={`c-els-menu__window ${menuClass}`} ref={props.setMenuWindow} style={props.menuPosition}>
        <div className="c-els-menu__window-content" style={contentStyle}>
          <ul className="c-els-menu__list">
            {props.menuItems.map((item, itemIndex) => {
              const itemClass = item.isDisabled ? 'c-els-menu__item--disabled' : '';
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  key={`itemKey_${item.key}`}
                  tabIndex={-1}
                  className={`c-els-menu__item ${itemClass}`}
                  onKeyDown={props.handleKeyDown(itemIndex)}
                  onClick={props.handleClickItem(item)}
                >
                  {item.customContent ? item.customContent : <span className="c-els-link__text c-els-link c-els-menu__pointer">{item.content}</span>}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MenuWindowView;
