import { isNil } from 'lodash';
import { OpportunityRequestOptions, HesiAPIResponseList } from '../../models/api.model';
import { HESIProgramModel, OpportunityModel } from '../../models/opportunity.model';
import { Person, EvolveUser } from '../../models/common.models';
import { RosterModel } from '../../models/roster.models';
import { EvolveAddressModel } from '../../models/invoice.model';
import { buildPagingOptions, buildEvolvePagingOptions } from '../../helpers/api.helpers';

import {
  getOpportunities,
  getCohortById,
  getUnprocessedInstallments,
  updateRequiresPONumber as updateRequiresPONumberAPI,
  updateCohortCountApprover as updateCohortCountApproverAPI
} from '../../utilities/api/opportunities-api/opportunitiesApi.utility';
import { getFeatureFlag } from '../../config/app.config';
import { tspServiceApi } from '../../helpers/RestClient.helper';
import { EvolveInstallmentModel } from '../../models/installment.model';

type UpdateTspInstallmentPayloadType = {
  opportunityId: string | number
  installmentId: string | number
  cohortId: string | number
  term?: number
  addDropDate?: string
  studentPaymentStartDate?: string
  installmentInvoiceDate?: string;
}

type FetchTspOpportunitiesAPIResponse = Promise<HesiAPIResponseList<OpportunityModel[]>>;
export const fetchTspOpportunities = async (options: OpportunityRequestOptions, IncludeCohorts = false, IncludeOpportunityHistory = false, InFlightCohorts?) => {
  const fetchOpportunitiesFromApi = getFeatureFlag('fetchOpportunitiesFromApi');
  if (!fetchOpportunitiesFromApi) {
    return getOpportunities(options);
  }

  const requestBody: any = {
    IncludeCohorts,
    IncludeOpportunityHistory,
    IncludeEmptyOpportunities: true,
    ...buildPagingOptions(options)
  };

  if (!isNil(InFlightCohorts)) {
    if (InFlightCohorts) {
      requestBody.HasInFlightCohorts = true;
    } else {
      requestBody.HasRegularCohorts = true;
    }
  }

  return tspServiceApi.post('/opportunities/searchOpportunities', {
    data: { ...requestBody }
  }) as FetchTspOpportunitiesAPIResponse;
};

type FetchValidEvolveUserResponse = Promise<EvolveUser>;
export const validateEmailAssociationWithEvolve = (email: string) =>
tspServiceApi.post('/evolveAdminUser/validateByEmail', { data: { email } }) as FetchValidEvolveUserResponse;

type FetchTspOpportunityByIdAPIResponse = Promise<{
  opportunityId: string,
  opportunityName: string,
  opportunityIdNumber: number,
  accountName: string,
  cohortProgram?: string,
  cohortCountApprover: RosterModel,
  billingAddress: EvolveAddressModel,
  shippingAddress: EvolveAddressModel,
  poNumber: string,
  taxExemptFlag: boolean;
  taxRegistrationNumber?: string;
  keyDecisionMakers?: string,
  opportunityOwner?: Person;
  poRequired: boolean,
  hasInFlightCohorts: boolean,
  hasRegularCohorts: boolean,
  contract: {
    contractId: string,
    contractStatus: string,
    contractAgreement: string
  },
  contactName?: string,
  contactEmail?: string,
  lastCohortEndDate?: string,
  ecrId?: string
}>;
export const fetchTspOpportunityById = (opportunityId: string) => tspServiceApi.get(`/opportunities/${opportunityId}`) as FetchTspOpportunityByIdAPIResponse;

type UpdateTspOpportunityPayloadType = {
  opportunityId: string | number;
  cohortCountApprover?: string;
  taxRegistrationNumber?: string;
  taxExemptFlag?: boolean;
  opportunityOwner?: string;
}
export const updateTspOpportunity = (payload: UpdateTspOpportunityPayloadType) => {
  const fetchOpportunitiesFromApi = getFeatureFlag('fetchOpportunitiesFromApi');
  if (!fetchOpportunitiesFromApi) {
    return updateCohortCountApproverAPI(payload);
  }
  return tspServiceApi.put(`/opportunities/${payload.opportunityId}`, { data: payload });
};

type UpdateTspInstallmentResponse = Promise<{
  invoiceDate?: string | Date;
  addDropDate?: string | Date;
  studentPaymentStartDate?: string | Date;
  term?: number;
}>
export const updateTspInstallment = (payload: UpdateTspInstallmentPayloadType) => (
  tspServiceApi.put(`/invoicing/installment/${payload.installmentId}`, { data: payload })) as UpdateTspInstallmentResponse;

type FetchUnprocessedInstallmentsResponse = Promise<{
  installments: EvolveInstallmentModel[];
  totalCount: number;
}>;
export const fetchUnprocessedInstallments = (opportunityId, pagingOptions) => {
  const fetchOpportunitiesFromApi = getFeatureFlag('fetchOpportunitiesFromApi');
  if (!fetchOpportunitiesFromApi) {
    return getUnprocessedInstallments(opportunityId, pagingOptions);
  }
  return tspServiceApi.post(`/invoicing/installments/opportunity/${opportunityId}`, {
    data: buildEvolvePagingOptions(pagingOptions)
  }) as FetchUnprocessedInstallmentsResponse;
};

export const fetchCohortById = (cohortId, opportunityId) => getCohortById(cohortId, opportunityId);

export const updateRequiresPONumber = (payload) => updateRequiresPONumberAPI(payload);

type FetchHESIProgramResponse = Promise<HESIProgramModel[]>;

export const fetchHESIProgramList = opportunityId => tspServiceApi.get(`/opportunities/${opportunityId}/hesiPrograms/`) as FetchHESIProgramResponse;

export const archiveOpportunity = (opportunityId: string | number) => tspServiceApi.delete(`/opportunities/${opportunityId}`);
