import React from 'react';
import { NavigationSectionType } from '../../../models/navigation.models';
import NavigationItem from '../navigation-items/navigation-item/NavigationItem.component';

export interface SideNavigationSectionProps {
  section: NavigationSectionType;
}

const SideNavigationSection = ({ section }: SideNavigationSectionProps) => (
  <div className="c-tsp-nav-section--side">
    {section.content}
    <ul className="o-els-flex-layout
                   o-els-flex-layout--column
                   o-els-flex-layout--left
                   o-els-flex-layout--vertical-gutters
                   c-els-nav-group">
      {section.items.map(item => <NavigationItem key={item.key} item={item} />)}
    </ul>
  </div>
);

export default SideNavigationSection;
