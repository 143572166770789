import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppConstant } from '../../constants/app.constant';
import { APIError } from '../../models/api.model';

export const REDUCER_ID = AppConstant.redux.INSTALLMENT_STATE;

const initialState = {
  installmentStatus: null,
  error: null
};

export const installmentSlice = createSlice({
  name: 'installment',
  initialState,
  reducers: {
    resetInstallmentStatus(state) {
      return {
        ...state,
        installmentStatus: null,
        error: null
      };
    },
    fetchInstallmentStatusesSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        installmentStatus: action.payload,
        error: null
      };
    },
    fetchInstallmentStatusesFailed(state, action: PayloadAction<APIError>) {
      return {
        ...state,
        error: action.payload
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const installmentActions = installmentSlice.actions;
export const installmentReducer = installmentSlice.reducer;
