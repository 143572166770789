import Cookies from 'js-cookie';
import { getFedAccessCookieName, getProperty, getSessionIdCookieName } from 'config/app.config';
import { gatewayServiceApi } from '../../helpers/RestClient.helper';

export const get = (name: string): string => Cookies.get(name);
export const remove = (name: string): void => Cookies.remove(name, { domain: getProperty('cookieDomain') });
export const getHttpCookie = (name: string[]) => gatewayServiceApi.post('/login-service/evolve/cookies', {
  data: name
});

export const getSessionId = async () => {
  const sessionIdName = getSessionIdCookieName();
  const response = await getHttpCookie([sessionIdName]);
  return response[sessionIdName];
};

export const getFaLogin = async () => {
  const fedAccessCookieName = getFedAccessCookieName();
  const response = await getHttpCookie([fedAccessCookieName]);
  return response[fedAccessCookieName] && response[fedAccessCookieName].trim().toLowerCase() === 'true';
};

export const isFaLogin = async () => {
  const fedAccessCookieName = getProperty('fedAccessCookieName');
  const response = await getHttpCookie([fedAccessCookieName]);
  const faLogin = response[fedAccessCookieName] && response[fedAccessCookieName].trim().toLowerCase();
  return faLogin === 'true';
};
