import { filter, omit } from 'lodash';
import { UserRole } from '../../constants/app.constant';
import { evolveServiceApi, gatewayServiceApi } from '../../helpers/RestClient.helper';
import { EvolveProductModel, EvolveProductSearchOptions, EvolveUserAddressModel, EvolveUserModel, ProductTypeCategoriesModel } from '../../models/evolve.models';
import { buildFilterFacets, normalizeCategories } from '../../utilities/api/evolve/evolveApi.utility';
import { setEvolveProductPlatfromsCached, getEolveProductPlatformsCached } from '../../utilities/evolve/evolve.utility';

const NotificationTypes = {
  AnonInstructor: ['All', 'Faculty'],
  AuthenticatedInstructor: ['All', 'Faculty', 'Members'],
  AnonStudent: ['All', 'Students'],
  AuthenticatedStudent: ['All', 'Students', 'Members']
};

const ignoreUserFields = ['userId', 'userName', 'roles', 'systemRole'];

const getUserType = (roleId, isAuthenticated) => {
  if (roleId === UserRole.Faculty) {
    return (isAuthenticated) ? 'AuthenticatedInstructor' : 'AnonInstructor';
  }
  return (isAuthenticated) ? 'AuthenticatedStudent' : 'AnonStudent';
};

export const fetchNotifications = async (roleId, isAuthenticated) => {
  const allNotifications = await evolveServiceApi.get('/notification/evolve-notification.json', {
    useWithCredentials: false,
    forceSkipCache: false
  });

  const userType = getUserType(roleId, isAuthenticated);
  const filteredNotifications = allNotifications || [];
  const types = NotificationTypes[userType] || [];
  return filter(filteredNotifications, (notification) => types.indexOf(notification.audience) !== -1);
};

export const fetchUserRoles = async (sessionId) => {
  try {
    const userProfile = await gatewayServiceApi.get('/api/evolveUser', {
      headers: {
        'evl-session-id': sessionId
      },
      params: {
        role: UserRole.Faculty,
        fields: ''
      }
    }) as EvolveUserModel;
    return userProfile.roles;
  } catch (e) {
    return [];
  }
};

export const fetchAppConfig = async () => evolveServiceApi.get('/cv/all', { withCredentials: true, forceSkipCache: false });

type FetchProductTypeCategoriesAPIResponse = Promise<ProductTypeCategoriesModel[]>;
type QueryProductsAPIResponse = Promise<{
  defaultCouponCode: string;
  facets: {
    availableFormat: { nane: string; total: number; type: string; }[];
    productType: { nane: string; total: number; type: string; }[];
  },
  results: EvolveProductModel[];
  total: number;
}>
export const searchProducts = async (searchOptions: EvolveProductSearchOptions, sessionId, productTypeCategories) => {
  const fetchProductTypeCategories = async () => {
    if (productTypeCategories) {
      return productTypeCategories;
    }
    return evolveServiceApi.get('/eca/api/resource/productTypes.json', { forceSkipCache: false }) as FetchProductTypeCategoriesAPIResponse;
  };
  const queryProducts = async () => gatewayServiceApi.get('/api/catalog/search', {
    headers: {
      'evl-session-id': sessionId
    },
    params: {
      role: UserRole.Faculty,
      ...searchOptions
    }
  }) as QueryProductsAPIResponse;
  const [categoriesRes, searchRes] = await Promise.all([
    fetchProductTypeCategories(),
    queryProducts()
  ]);
  const normalizedCategories = productTypeCategories || normalizeCategories(categoriesRes);
  const facets = buildFilterFacets(searchRes.facets, normalizedCategories);
  return {
    ...searchRes,
    productTypeCategories: normalizedCategories,
    facets
  };
};

type FetchProductByIdAPIResponse = Promise<EvolveProductModel>;
export const fetchProductById = async (productId: string | number, sessionId) =>
  gatewayServiceApi.get(`/api/catalog/products/isbn/${productId}`, {
    headers: {
      'evl-session-id': sessionId
    },
    params: {
      role: UserRole.Faculty
    }
  }) as FetchProductByIdAPIResponse;

export const fetchProductsByIds = async (productIds: string[] | number[], sessionId: string) => {
  if (productIds.length === 0) {
    return [];
  }
  const methods = [];
  const { length } = productIds;
  for (let i = 0; i < length; i += 1) {
    methods.push(fetchProductById(productIds[i], sessionId));
  }
  return Promise.all(methods.map(p => p.catch(e => e)));
};

export const fetchEvolveProductPlatforms = async () => {
  let platformsData = getEolveProductPlatformsCached();
  if (!platformsData) {
    platformsData = await evolveServiceApi.get('/eca/api/resource/platforms.json', { forceSkipCache: false });
    setEvolveProductPlatfromsCached(platformsData);
  }
  return platformsData;
};

export const fetchProductVision = async (isbn: string, username: string, sessionId: string) =>
  gatewayServiceApi.get('/api/cart/provision', {
    headers: {
      'evl-session-id': sessionId
    },
    params: {
      isbn,
      evolveUserName: username
    }
  });

export const refreshSessionId = async (sessionId, userRole) => gatewayServiceApi.put(`/api/evolveUser/session/${sessionId}?hours=3&role=${userRole}&rememberMe=TRUE`);

type GetCurrentUserProfileAPIResponse = Promise<EvolveUserModel>;
export const getCurrentUserProfile = (sessionId: string, userRole: string): Promise<EvolveUserModel> =>
  gatewayServiceApi.get('/api/evolveUser', {
    headers: {
      'evl-session-id': sessionId
    },
    params: {
      role: userRole,
      fields: 'addresses'
    }
  }) as GetCurrentUserProfileAPIResponse;

type ValidateUserAddressAPIResponse = Promise<{ isValid: boolean }>;
export const validateUserAddress = (userRole: string, address: EvolveUserAddressModel) =>
  gatewayServiceApi.post(`/api/evolveUser/address?role=${userRole}`, {
    data: address
  }) as ValidateUserAddressAPIResponse;

export const updateUser = (user: EvolveUserModel, sessionId: string) =>
  gatewayServiceApi.put(`/api/evolveUser?sessionId=${sessionId}&&isFullUser=true`, {
    data: omit(user, ignoreUserFields)
  });
