/* eslint jsx-a11y/anchor-is-valid:0 */
import React from 'react';
import { FormattedMessage } from 'react-intl';

const FooterCookieConsent = () => {
  const link = (
    <a className="optanon-toggle-display es-link u-els-font-family-bold">
      <FormattedMessage id="FOOTER_COOKIES_TEXT" />
    </a>
  );
  return (
    <div className="c-tsp-footer__link u-els-width-1o1">
      <FormattedMessage id="FOOTER_COOKIE_CONSENT_TEXT" values={{ link }} />
    </div>
  );
};

export default FooterCookieConsent;
