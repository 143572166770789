import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { NavigationItemType } from '../../../../models/navigation.models';
import TopBaseLink from '../base-link/TopBaseLink.component';
import { REDIRECT_URL, RoutePath } from '../../../../constants/app.constant';
import { StorageHelper } from '../../../../helpers/storage.helper';

export interface TopNavigationLinkProps {
  item: NavigationItemType;
}

const TopNavigationLinkWithIcon = ({ item }: TopNavigationLinkProps) => (
  <NavLink
    to={item.path}
    className="c-els-nav-group__link
               u-els-display-block
               u-els-padding-1o2
               u-els-padding-left-1x
               c-tsp-nav-item-top
               c-tsp-nav-item-top--button
               u-els-padding-right-1x"
    activeClassName="c-tsp-nav-item-top--active">
    <TopBaseLink item={item} />
  </NavLink>
);

const handleClickLink = (path: string) => {
  if (path === RoutePath.security.login) {
    const redirectUrl = `${location.pathname.replace('/tsp', '')}${location.search || ''}`;
    StorageHelper.setItem(REDIRECT_URL, redirectUrl);
  }
};

const TopNavigationLinkWithoutIcon = ({ item }: TopNavigationLinkProps) => (
  <NavLink
    to={item.path}
    onClick={() => handleClickLink(item.path)}
    className={cx(`c-els-nav-group__link
               c-tsp-nav-item-top
               c-tsp-nav-item-top--link`, item.className)}
    activeClassName="c-tsp-nav-item-top--active">
    <TopBaseLink item={item} />
  </NavLink>
);

const TopNavigationLink = ({ item }: TopNavigationLinkProps) => (item.iconName ?
  <TopNavigationLinkWithIcon item={item} /> :
  <TopNavigationLinkWithoutIcon item={item} />);

export default TopNavigationLink;
