import React, { Children } from 'react';
import { isNil, get } from 'lodash';
import { useAccessControll } from 'hooks';
import { AccessControlType } from '../../../constants/app.constant';

interface AccessControlProps {
  roles?: string[];
  isOnlyRoles?: boolean;
  actionType?: AccessControlType;
  children?: any;
  ignoreValidateResource?: boolean;
  resourceEndDate?: string | Date;
}

// only validate for TSP roles
const AccessControl = ({ roles = [], isOnlyRoles, actionType = AccessControlType.Hide, children, ignoreValidateResource, resourceEndDate }: AccessControlProps) => {
  const validateValue = useAccessControll(isOnlyRoles, actionType, roles, ignoreValidateResource, resourceEndDate);

  if (roles.length === 0) {
    return children;
  }

  const childrenWithProps = Children.map(children, child => {
    if (React.isValidElement(child)) {
      const isDisabled = get(child.props, 'isDisabled') || validateValue.isDisabled;
      const disabled = get(child.props, 'disabled') || validateValue.isDisabled;
      const props = { ...validateValue, isDisabled, disabled };
      return !validateValue.isHide && React.cloneElement(child, { ...props });
    }
    return child;
  }).filter(child => !isNil(child));

  return !validateValue.isHide && <>{childrenWithProps}</>;
};

export default AccessControl;
