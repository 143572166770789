import history from 'helpers/history.helper';
import { ELSLoggingService } from '@els/els-ui-common-react';
import { AxiosResponse } from 'axios';
import { REDIRECT_URL, RoutePath, VALIDATE_RESPONSE_ERROR_TYPE, TSP_APP_CONTEXT } from '../../constants/app.constant';
import { isErrorByErrorType } from '../../utilities/app/app.utility';
import { StorageHelper } from '../../helpers/storage.helper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiErrorHandler = (error: Error, defaultIfError: any) => {
  ELSLoggingService.error('', error.message, error);
  return defaultIfError;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withApiErrorHandler = (promiseFunction: () => Promise<any>, defaultIfError: any) =>
  promiseFunction()
    .then(res => res.data)
    .catch(err => apiErrorHandler(err, defaultIfError));

const handleReviewApprovalError = error => {
  if (error.response.status === 410) {
    history.push(RoutePath.error.expiredLink);
  }
  if (error.response.status === 401) {
    history.push(RoutePath.error.notAuthorizedApproved);
  }
};

const handleRosterSubmissionError = error => {
  if (error.response.status === 401) {
    history.push(RoutePath.error.notAuthorizedRosterSubmission);
  }
  if (error.response.status === 400) {
    history.push(RoutePath.error.expiredCohortRoster);
  }
};

const handleStudentPaymentError = error => {
  if (error.response.status === 400 && isErrorByErrorType(error, 'student_payment_not_found')) {
    history.push(RoutePath.error.studentPaymentNotFound);
  }
  if (error.response.status === 400 && isErrorByErrorType(error, 'student_payment_link_expired_invalid')) {
    history.push(RoutePath.error.expiredStudentPayment);
  }
  if (error.response.status === 400 && isErrorByErrorType(error, 'no_payment_due')) {
    history.push(RoutePath.error.noPaymentDue);
  }

  if (error.response.status === 401 || error.response.status === 403) {
    let redirectUrl = `${location.pathname}${location.search || ''}`;
    // This logic is only applied here when we could not retrieve the location object from the ConnectedRouter
    redirectUrl = redirectUrl.startsWith(TSP_APP_CONTEXT) ? redirectUrl.substring(4) : redirectUrl;
    StorageHelper.setItem(REDIRECT_URL, redirectUrl);
    history.push(RoutePath.security.login);
  }
};

const handleNormalError = error => {
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    history.push(RoutePath.security.logout);
  }
};

const handleErrorResponse = (error, validateErrorType) => {
  switch (validateErrorType) {
    case VALIDATE_RESPONSE_ERROR_TYPE.REVIEW_APPROVAL:
      handleReviewApprovalError(error);
      break;
    case VALIDATE_RESPONSE_ERROR_TYPE.ROSTER_SUBMISSION:
      handleRosterSubmissionError(error);
      break;
    case VALIDATE_RESPONSE_ERROR_TYPE.STUDENT_PAYMENT:
      handleStudentPaymentError(error);
      break;
    default:
      handleNormalError(error);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withApiErrorLoggingOnly = <T extends any>(promiseFunction: () => Promise<AxiosResponse<T>>, validateErrorType = VALIDATE_RESPONSE_ERROR_TYPE.NORMAL) =>
  promiseFunction()
    .then(res => res.data)
    .catch((error) => {
      ELSLoggingService.error('', error.message, error);
      handleErrorResponse(error, validateErrorType);
      throw error;
    });

export const withPromiseDelay = <T>(delay, value) => new Promise<T>(resolve => setTimeout(resolve, delay, value));
