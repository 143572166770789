import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppConstant } from '../../constants/app.constant';
import { APIError } from '../../models/api.model';

export const REDUCER_ID = AppConstant.redux.INVOICE_STATE;

const initialState = {
  invoiceStatus: null,
  Error: null
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    resetInvoiceStatus(state) {
      return {
        ...state,
        invoiceStatus: null
      };
    },
    fetchInvoiceStatusesSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        invoiceStatus: action.payload,
        Error: null
      };
    },
    fetchInvoiceStatusesFailed(state, action: PayloadAction<APIError>) {
      return {
        ...state,
        Error: action.payload
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const invoiceActions = invoiceSlice.actions;
export const invoiceReducer = invoiceSlice.reducer;
