import { COHORT_MANAGEMENT_SYSTEM, RoutePath } from '../../constants/app.constant';
import { CohortModel, OpportunityModel } from '../../models/opportunity.model';
import { creditRequestQueueText } from '../../constants/creditRequest.constant';
import { billingQueueText } from '../../constants/billingQueue.constant';

export const cohortManagementDashboardBreadcrumb = () => ({
  path: {
    route: RoutePath.myDashboard.home,
    params: undefined
  },
  text: COHORT_MANAGEMENT_SYSTEM
});

export const inFlightDashboardBreadcrumb = () => ({
  path: {
    route: RoutePath.myDashboard.inFlightCohortDashboard,
    params: { ifc: true }
  },
  text: 'Opportunities with Pilot Cohorts'
});

export const opportunityBreadcrumb = (opportunity: OpportunityModel) => ({
  path: {
    route: RoutePath.myDashboard.opportunityDetail,
    params: { opportunityId: opportunity.OpportunityID }
  },
  text: opportunity.AccountName || opportunity.OpportunityName
});

export const cohortBreadcrumb = (opportunityId: string | number, cohort: CohortModel) => ({
  path: {
    route: RoutePath.myDashboard.cohortDetail,
    params: { opportunityId, cohortId: cohort.CohortID }
  },
  text: cohort.CohortName
});

export const productSelectionBreadcrumbs = (opportunity: OpportunityModel, cohort: CohortModel) => {
  const breadcrumbs = [];
  breadcrumbs.push(opportunityBreadcrumb(opportunity));
  breadcrumbs.push(cohortBreadcrumb(opportunity.OpportunityID, cohort));
  breadcrumbs.push({ text: 'Product Selection' });
  return breadcrumbs;
};

export const creditRequestQueueBreadcrumb = () => ({
  path: {
    route: RoutePath.myDashboard.creditRequestQueue,
    params: {}
  },
  text: creditRequestQueueText
});

export const billingDashboardQueueBreadcrumb = () => ({
  path: {
    route: RoutePath.myDashboard.billingDashboardQueue,
    params: {}
  },
  text: billingQueueText
});
