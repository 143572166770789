import { orderBy, cloneDeep } from 'lodash';
import { EvolveInstallmentModel } from 'models/installment.model';
import { RosterModel } from 'models/roster.models';
import { invoices, invoiceDetail } from '../../../dummy-data';
import { InvoiceEditorModel, InstitutionInvoiceModel, InvoiceStatusModel } from '../../../models/invoice.model';
import { withPromiseDelay } from '../api.utility';

export const getInvoices = (OpportunityID, pagingOptions) => {
  const sortedInvoices = orderBy(invoices, pagingOptions.sortColumn, pagingOptions.sortDirection);
  const start = (pagingOptions.currentPage - 1) * pagingOptions.resultsPerPage;
  const end = Math.min(sortedInvoices.length, start + pagingOptions.resultsPerPage);
  const data = sortedInvoices.slice(start, end);
  return withPromiseDelay<{
    totalCount?: number;
    startIndex: number;
    size: number;
    institutionInvoices: InstitutionInvoiceModel[];
    errors?: any;
  }>(1000, { institutionInvoices: cloneDeep(data), totalCount: data.length });
};

export const getInvoiceDetail = (invoiceId: string) => withPromiseDelay<{
  Success: boolean;
  Response: {
    invoice: InstitutionInvoiceModel;
  };
}>(1000, { Success: true, Response: { invoiceId, invoice: invoiceDetail } });

export const updateInvoice = (invoiceEditor: InvoiceEditorModel) => withPromiseDelay<{
  cohortCountApprover: RosterModel;
  installments: EvolveInstallmentModel[];
  invoiceStatus: InvoiceStatusModel;
}>(1000, {
  cohortCountApprover: { firstName: 'John', lastName: 'John' },
  installment: invoiceEditor.installment,
  invoiceStatus: {
    name: 'Cohort Count Approval Request Sent',
    desc: 'Cohort Count Approval Request Sent',
    id: 2,
    activityDate: '2021-11-02T00:00:00Z'
  }
});

export const updateBillingApproval = (invoiceId: string | number) => withPromiseDelay<{
  invoiceId: string;
  billingAprroval: boolean;
}>(0, {
  invoiceId
});

export const createInvoice = (invoiceEditor: InvoiceEditorModel) => withPromiseDelay<{
  Success: boolean,
  Response: InvoiceEditorModel
}>(1000, { Success: true, Response: invoiceEditor });

export const deleteInvoice = (invoiceId: string) => withPromiseDelay<{ errors?: any }>(0, { invoiceId });
