import React from 'react';
import { NavigationItemType } from '../../../../models/navigation.models';
import TopNavigationLink from '../navigation-link/TopNavigationLink.component';
import TopNavigationButton from '../navigation-button/TopNavigationButton.component';

interface TopNavigationItemProps {
  item: NavigationItemType;
}

const TopNavigationItem = ({ item }: TopNavigationItemProps) => (
  <li key={item.key} className="c-els-nav-group__item o-els-layout--bottom">
    {item.onAction ? <TopNavigationButton item={item} /> : <TopNavigationLink item={item} />}
  </li>
);

export default TopNavigationItem;
