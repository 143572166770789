import React, { Component, createRef, ReactElement } from 'react';
import DataTableViewComponent from './data-table-view/DataTableView.component';
import { DataColumnProps } from './data-column/DataColumn.component';

export const SortDirection = {
  ascending: 'asc',
  descending: 'desc'
};

export interface DataTableProps<T> {
  id: string;
  rowKey?: string;
  children?: ReactElement[];
  displayListFor?: string;
  maxHeight?: number;
  noWrap?: boolean;
  cssModifier?: string;
  className?: string;
  columnHeaderCss?: string[];
  stickyHeader?: string;
  data: T[];
  labelWidthForList?: string;
  sortLabel?: string;
  sortIconName?: string;
  sortIconSize?: string;
  sortColumn?: string;
  sortDirection?: string;
  onChangeSort?: Function;
  emptyRenderer?: () => string | ReactElement;
  expandText?: string;
  positionExpandBtn?: string;
  subRowsFieldName?:string;
  expandableTable?: boolean;
  alwaysExpandedTable?: boolean;
  expandableRenderer?: (row: T) => string | ReactElement;
  getRowKey?: (row: T) => string;
}
class DataTable<T> extends Component<DataTableProps<T>> {
  tableContainerRef: React.RefObject<HTMLDivElement>;
  state = {
    hasScrollRight: false,
    hasScrollLeft: false,
    expandedKeys: []
  };
  constructor(props) {
    super(props);
    this.tableContainerRef = createRef();
  }

  componentDidMount() {
    this.setHasScrollRightState(this.tableContainerRef.current);
  }

  getColumns = (): DataColumnProps[] => this.props.children.filter(column => !!column).map(column => column.props);

  handleSort = (field, direction) => () => {
    const sortField = field;
    let sortDirection = direction;
    if (!sortDirection) {
      if (sortField === this.props.sortColumn) {
        sortDirection =
          this.props.sortDirection === SortDirection.ascending ? SortDirection.descending : SortDirection.ascending;
      } else {
        sortDirection = SortDirection.ascending;
      }
    }
    if (this.props.onChangeSort) {
      this.props.onChangeSort(sortField, sortDirection);
      this.setState({ expandedKeys: [] });
    }
  };

  changeSortField = ({ target }) => {
    this.handleSort(target.value, null)();
  };

  changeSortDirection = () => {
    this.handleSort(this.props.sortColumn, null)();
  };

  getClasses = breakPoint => {
    if (!breakPoint) return '';
    return `c-els-table--list@${breakPoint}`;
  };

  setHasScrollRightState = target => {
    if (!target) {
      return;
    }
    if (target.scrollWidth - target.scrollLeft === target.offsetWidth) {
      if (this.state.hasScrollRight) {
        this.setState({ hasScrollRight: false });
      }
    } else if (!this.state.hasScrollRight) {
      this.setState({ hasScrollRight: true });
    }
  };

  setHasScrollLeftState = target => {
    if (!target) {
      return;
    }
    if (target.scrollLeft > 0) {
      if (!this.state.hasScrollLeft) {
        this.setState({ hasScrollLeft: true });
      }
    } else if (this.state.hasScrollLeft) {
      this.setState({ hasScrollLeft: false });
    }
  };

  handleScroll = evt => {
    this.setHasScrollRightState(evt.target);
    this.setHasScrollLeftState(evt.target);
  };

  handleToggle = key => {
    const { expandedKeys } = this.state;
    const keys = expandedKeys.includes(key) ? expandedKeys.filter(item => item !== key) : [...expandedKeys, key];
    this.setState({ expandedKeys: keys });
  }

  render() {
    const { hasScrollLeft, hasScrollRight, expandedKeys } = this.state;
    const className = this.getClasses(this.props.displayListFor);
    const injectProps = {
      data: this.props.data || [],
      columns: this.getColumns(),
      id: this.props.id,
      rowKey: this.props.rowKey || 'id',
      noWrap: this.props.noWrap || false,
      stickyHeader: this.props.stickyHeader,
      maxHeight: this.props.maxHeight,
      cssModifier: this.props.cssModifier || '',
      labelWidthForList: this.props.labelWidthForList,
      sortLabel: this.props.sortLabel || 'Sort by',
      handleSort: this.handleSort,
      changeSortField: this.changeSortField,
      changeSortDirection: this.changeSortDirection,
      isSortDescending: this.props.sortDirection === SortDirection.descending,
      sortField: this.props.sortColumn,
      className,
      columnHeaderCss: this.props.columnHeaderCss || [],
      sortIconName: this.props.sortIconName,
      sortIconSize: this.props.sortIconSize || '3o4',
      handleScroll: this.handleScroll,
      hasScrollLeft,
      hasScrollRight,
      expandedKeys,
      ref: this.tableContainerRef,
      emptyRenderer: this.props.emptyRenderer,
      expandText: this.props.expandText,
      positionExpandBtn: this.props.positionExpandBtn,
      subRowsFieldName: this.props.subRowsFieldName,
      handleToggle: this.handleToggle,
      expandableTable: this.props.expandableTable,
      alwaysExpandedTable: this.props.alwaysExpandedTable,
      expandableRenderer: this.props.expandableRenderer,
      classTableName: this.props.className,
      getRowKey: this.props.getRowKey
    };
    return <DataTableViewComponent {...injectProps} />;
  }
}

export default DataTable;
