import React from 'react';
import { NavigationSectionType } from '../../../models/navigation.models';
import TopNavigationLink from '../navigation-items/navigation-link/TopNavigationLink.component';

import TopNavigationSection from './TopNavSection.component';

export interface SignInNavSectionProps {
  section: NavigationSectionType;
}

const SignInNavSection = ({ section }: SignInNavSectionProps) => (
  <div className="c-tsp-nav-section--sign-in">
    <TopNavigationSection section={section} />
    <div className="c-tsp-nav-section--sign-in__icons u-els-display-none u-els-display-block@tablet">
      {section.items.map(item => <TopNavigationLink key={item.key} item={{ ...item, iconName: 'user', iconPrefix: 'hmds' }} />)}
    </div>
  </div>
);

export default SignInNavSection;
