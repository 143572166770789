import { AppConstant } from '../constants/app.constant';
import { appActions, appReducer } from './app';
import { securityReducer } from './security';
import { opportunityReducer } from './opportunity';
import { cohortReducer } from './cohort';
import { productSearchReducer } from './product-search';
import { invoiceReducer } from './invoice';
import { installmentReducer } from './installment';
import { opportunitiesReducer } from './opportunities';
import { studentPaymentReducer } from './student-payment';
import { StoreConfig } from './app.store';

const appStoreConfig : StoreConfig = {
  actionTypesToStore: ['app/setInflightCohortView'],
  statesToBeStoredInLocalStorage: {
    [AppConstant.redux.APP_STATE]: appActions.restoreAppState,
  },
  reducers: {
    [AppConstant.redux.SECURITY_STATE]: securityReducer,
    [AppConstant.redux.APP_STATE]: appReducer,
    [AppConstant.redux.OPPORTUNITY_STATE]: opportunityReducer,
    [AppConstant.redux.COHORT_STATE]: cohortReducer,
    [AppConstant.redux.PRODUCT_SEARCH]: productSearchReducer,
    [AppConstant.redux.INVOICE_STATE]: invoiceReducer,
    [AppConstant.redux.INSTALLMENT_STATE]: installmentReducer,
    [AppConstant.redux.OPPORTUNITIES_STATE]: opportunitiesReducer,
    [AppConstant.redux.STUDENT_PAYMENT_STATE]: studentPaymentReducer
  }
};

export default appStoreConfig;
