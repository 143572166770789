import { ELSCommonConfig, ELSCommonUIConstants } from '@els/els-ui-common-react';
import { ELSFormFieldConfig } from '@els/els-component-form-field-react';
import { DATE_FORMAT_MDYYYY, ServicePath } from 'constants/app.constant';
import { ServerConstants } from 'constants/server.constant';
import { isLocalProfile } from 'utilities/app/app.utility';

// version must be increased if redux store schema changed
export const tspStateVersion = '00';

export const getProperty = property => {
  if (!property) {
    return '';
  }
  return ServerConstants[ServerConstants.DataSource][property] ||
    ServerConstants.common[property] ||
    ServerConstants[property] ||
    '';
};

export const getConfig = config => {
  if (!config) {
    return '';
  }
  const configs = getProperty('config') || {};
  return configs[config] || '';
};

export const getFeatureFlag = flag => {
  if (!flag) {
    return '';
  }
  const featureFlags = getProperty('featureFlags') || {};
  return featureFlags[flag] || '';
};

export const getEvolveLoginUrl = () => {
  const evolvePortalBaseUrl = getProperty('evolvePortalBaseUrl');
  return `${evolvePortalBaseUrl}/cs/login?d=${evolvePortalBaseUrl}/tsp/login`;
};

export const getEvolveRosterUrl = (cohortId) => {
  const evolvePortalBaseUrl = getProperty('evolvePortalBaseUrl');
  return `${evolvePortalBaseUrl}/cs/courseRoster/createRoster?cohortId=${cohortId}`;
};

export const getEvolveAccountSettingsUrl = () => {
  const evolvePortalBaseUrl = getProperty('evolvePortalBaseUrl');
  return `${evolvePortalBaseUrl}/cs/account?d=${evolvePortalBaseUrl}/tsp`;
};

export const getFedAccessLogoutUrl = () => {
  const fedAccessLogoutUrl = getProperty('fedAccessLogoutUrl');
  if (fedAccessLogoutUrl) {
    return fedAccessLogoutUrl;
  }
  const evolvePortalBaseUrl = getProperty('evolvePortalBaseUrl');
  return `${evolvePortalBaseUrl}/fa/logout?appRedirectURL=${evolvePortalBaseUrl}/cs/logout`;
};

export const getEvolveLogoutUrl = () => {
  const evolvePortalBaseUrl = getProperty('evolvePortalBaseUrl');
  return `${evolvePortalBaseUrl}/cs/logout`;
};

export const getTspServiceBaseUrl = () => {
  const evolvePortalBaseUrl = getProperty('gatewayBaseURL');
  return `${evolvePortalBaseUrl}/api/tsp`;
};

export const getEvolveUrl = () => {
  const evolvePortalBaseUrl = getProperty('evolvePortalBaseUrl');
  return `${evolvePortalBaseUrl}/cs`;
};

export const getTspUrl = () => {
  const evolvePortalBaseUrl = getProperty('evolvePortalBaseUrl');
  return `${evolvePortalBaseUrl}/tsp`;
};

export const getEvolveSearchProductUrl = (opportunityId: string, cohortId: string) => {
  const evolvePortalBaseUrl = getProperty('evolvePortalBaseUrl');
  return `${evolvePortalBaseUrl}/cs/search?opportunityId=${opportunityId}&cohortId=${cohortId}`;
};

export const getEvolveVideoUrl = () => {
  const evolvePortalBaseUrl = getProperty('evolvePortalBaseUrl');
  return `${evolvePortalBaseUrl}/html/video/`;
};

export const setDefaultAppConfig = () => {
  ELSCommonUIConstants.defaultBufferFactor = 0.7;
  // set environment to invoke facade API
  ELSCommonConfig.setBuildUrl(getProperty('gatewayBaseURL'));
  // set storage for storing EOLS token
  ELSCommonConfig.setStorageDriver(ELSCommonUIConstants.security.Locker.DefaultDriver);
  // set override token service path
  ELSCommonConfig.setTokenServicePath(ServicePath.tokenServicePath);
  // set override i18 message - overide message in warning timeout
  ELSCommonConfig.setI18Messages({
    warningHeading: 'You appear to be idle',
    warningMessage: 'If you do not return soon you will be automatically signed out',
    stay: 'Ok'
  });
  // set override message for i18n or override date format used in Date Component
  ELSFormFieldConfig.setI18nConfig({
    dateFormat: {
      primary: DATE_FORMAT_MDYYYY
    }
  });
};

export const analyticBaseConfig = {
  edApp: 'Cohort Management Application',
  baseUrl: getProperty('gatewayBaseURL'),
  adobeAnalyticServiceUrl: `${getProperty('gatewayBaseURL')}/api/features/app/EVOLVE/key/ADOBE_ANALYTICS_DISABLED?defaultValue=false`
};

export const getResultsPerPageOptions = label =>
  [10, 25, 50, 75, 100].map(option => ({ value: `${option}`, name: `${option} ${label}` }));

export const getSessionIdCookieName = (): string => getProperty('sessionId');

export const getFedAccessCookieName = (): string => getProperty('fedAccessCookieName');

export const idleTimeout = 10740000; // 10740000 = 2hrs 59 min in milliseconds

export const idleWarningTime = 60000; // 60000 = 60 sec in milliseconds

export const externalRedirectDelayInMs = 0;

export const refreshSessionInterval = 19 * 60 * 1000; // 19 minutes

export const getLMSEvolveConfiguration = () => ({
  SelectCourseTools: {
    EvolveContentOnly: 1,
    EvolveContentPlusCourseTools: 2,
    ChooseLMS: 3,
    LoExtEvolveContentPlusCourseTools: 4,
    NoLmsUniqueCourseTools: 5,
    IgnoreFulfillment: -1
  },
  DeliveryMethod: {
    EvolveLink: 'Evolve Link'
  }
});

export const getAffiliatedNursingPrograms = () => ([
  'High School',
  'Diploma',
  'LPN',
  'ADN',
  'BSN',
  'Accelerated BSN',
  'RN-BSN',
  'MSN',
  'FNP',
  'PA',
  'DNP',
  'Pharmacy',
  'Other'
]);

export const getCohortTermOptions = (numberOfInstallmens, disabledTerms: number[] = []): { name: string, value: number, disabled?: boolean }[] => {
  const terms = [];
  for (let i = 1; i <= numberOfInstallmens; i += 1) {
    terms.push({
      name: `Term ${i}`,
      value: i
    });
  }
  return terms.map(term => ({ ...term, disabled: disabledTerms.includes(term.value) }));
};

export const getPlatformCourseTypes = () => [
  {
    name: 'New Course',
    value: 'new_course',
    tooltip: 'By selecting this option, a new course will be created upon fulfillment.'
  },
  {
    name: 'Course Copy',
    value: 'course_copy',
    tooltip: 'By selecting this option, an existing course will be copied to create the course.'
  },
  {
    name: 'Combo Course',
    value: 'combo_course',
    tooltip: 'By selecting this option, the product will be fulfilled via a combo course. Fulfillment will be disabled.'
  },
  {
    name: 'Existing Course',
    value: 'existing_course',
    tooltip: 'By selecting this option, an existing Course ID will be used for fulfillment. The existing Course ID will need to be provided.'
  },
];

export const getRequiredCourseIdFields = () => [
  'existing_course'
];

export const getComboCourseTypes = () => [
  'combo_course'
];

export const getCombinationCourseType = () => [
  {
    name: 'New Course',
    value: 'new_course',
    isDisabled: true,
    tooltip: 'By selecting this option, a new course will be created upon fulfillment.'
  },
  {
    name: 'Existing Course',
    value: 'existing_course',
    isDisabled: false,
    tooltip: 'By selecting this option, an existing Course ID will be used for fulfillment. The existing Course ID will need to be provided.'
  }
];

export const getDirectAccessPlatformTypes = () => [
  'existing_course',
  'new_course'
];

export const getProductSuitePageSize = () => 1000;

export const eFlagDefaultValue = 'Y';

export const excludeNYPDefaultValue = false;

export const isTSPDefaultValue = true;

export const defaultFulfillErrorMessage = 'The following are required in order to fulfill:';

export const addProductModalTitle = 'Add Product';

export const maximumOpportunities = 20;

export const getHostUrl = () => {
  const { protocol, host, port } = location;
  if (isLocalProfile() && port) {
    return `${protocol}//${host}:${port}`;
  }

  return `${protocol}//${host}`;
};

export const getFacultiesPageSize = () => 1000;
