import React, { forwardRef, ReactElement, RefObject, UIEventHandler } from 'react';
import classNames from 'classnames';
import { ELSDropDown } from '@els/els-component-form-field-react';
import { ELSTable, ELSTableHead, ELSTableBody, ELSTableRow, ELSTableCell } from '@els/els-component-data-table-react';
import { DataColumnProps } from '../data-column/DataColumn.component';
import TableHeadCell from '../table-head-cell/TableHeadCell.component';
import ExpandableTableBody from '../expandable-table-body/ExpandableTableBody.component';
import { STICKY_CELL_Z_INDEX } from '../../../constants/app.constant';
import { ELSButtonWithIcon } from '../../common/els';

interface DataTableViewProps<T> {
  handleScroll?: UIEventHandler;
  maxHeight?: number;
  id: string;
  rowKey?: string;
  noWrap?: boolean;
  cssModifier?: string;
  columns: DataColumnProps[];
  columnHeaderCss: string[];
  stickyHeader?: string;
  hasScrollLeft?: boolean;
  sortField?: string;
  isSortDescending?: boolean;
  handleSort?: Function;
  sortIconName?: string;
  sortIconSize?: string;
  changeSortField?: Function;
  changeSortDirection?: Function;
  sortLabel?: string;
  data: T[];
  labelWidthForList?: string
  emptyRenderer?: () => string | ReactElement;
  expandedKeys?: string[];
  handleToggle?: (key: string | number) => void;
  expandText?: string;
  positionExpandBtn?: string;
  subRowsFieldName?: string;
  expandableTable?: boolean;
  alwaysExpandedTable?: boolean;
  expandableRenderer?: (row: T) => string | ReactElement;
  classTableName?: string;
  getRowKey?: (row: T) => string;
}

const renderHeader = <T extends {}>(props: DataTableViewProps<T>) => {
  const { columns, columnHeaderCss, stickyHeader, hasScrollLeft, classTableName } = props;
  return (
    <>
      {columns
        .filter(column => column.header)
        .map((column, index) => {
          const headerCss = classNames(
            // 'c-els-table--shadow-header-bottom',
            classTableName ? `${classTableName}__column--${index}` : '',
            {
              'c-els-table__row--sticky': stickyHeader,
              'c-els-table__cell--sticky': column.sticky,
              'c-els-table--shadow-header-left-bottom': hasScrollLeft && column.sticky,
              'u-els-background-color-n0': column.highlight
            },
            columnHeaderCss[index] || ''
          );
          const inlineStyle = column.sticky ? { zIndex: STICKY_CELL_Z_INDEX.HEADER_CELL } : undefined;
          return (
            <TableHeadCell
              key={column.field}
              isSortable={column.sortable}
              isSortActive={column.sortable && (column.field === props.sortField || column.sortField === props.sortField)}
              isSortDesc={props.isSortDescending}
              onSort={props.handleSort(column.sortField || column.field)}
              align={column.align}
              headerCss={headerCss}
              sortIcon={props.sortIconName}
              iconSize={props.sortIconSize}
              inlineStyle={inlineStyle}
              expandContent={column.expandHeaderContent}
            >
              {column.header}
            </TableHeadCell>
          );
        })}
    </>
  );
};

const renderHeaderTableList = <T extends {}>(props: DataTableViewProps<T>) => {
  const { id, isSortDescending, sortField, changeSortField, changeSortDirection, sortLabel } = props;
  const columns = props.columns.filter(column => column.sortable);
  const dropDownOptions = columns.map(column => ({
    name: column.dropDownOption || column.header,
    value: column.field,
    iconSize: column.iconSize
  }));
  const iconName = !isSortDescending ? 'ArrowUp' : 'ArrowDown';
  return (
    <div className="c-els-table--list__header">
      <div className="c-els-table--list__sort-group o-els-flex-layout--bottom">
        <ELSDropDown id={`${id}-sort-field`} name={`${id}-sort-field`} options={dropDownOptions} value={sortField} changeHandler={changeSortField}>
          <p className="u-els-margin-top">{sortLabel}</p>
        </ELSDropDown>
        <span className="u-els-margin-left" />
        <ELSButtonWithIcon id={`${id}-sort-direction`} sprite={iconName} onClick={changeSortDirection}><span /></ELSButtonWithIcon>
      </div>
    </div>
  );
};

const renderBodyContent = <T extends {}>(props: DataTableViewProps<T>) => {
  const { data, columns, labelWidthForList, hasScrollLeft, rowKey, getRowKey } = props;

  const getKey = (row: T) => {
    if (getRowKey) {
      return getRowKey(row);
    }
    return `row_${row[rowKey]}`;
  };

  return (
    <>
      {data.map((row) => (
        <ELSTableRow key={getKey(row)}>
          {columns.map((column) => (
            <ELSTableCell
              key={column.field}
              align={column.align}
              cssModifier={classNames({
                'c-els-table__cell--sticky': column.sticky,
                'c-els-table--shadow-left': column.sticky && hasScrollLeft,
                'u-els-background-color-n0': column.highlight,
                'c-els-table__cell--nowrap': column.noWrap
              })}
              inlineStyle={column.sticky ? { zIndex: STICKY_CELL_Z_INDEX.DATA_CELL } : {}}
            >
              <div className="c-els-table__cell-header" style={{ width: labelWidthForList }}>
                {column.header}
              </div>
              <div className="c-els-table__cell-content">{column.customRender ? column.customRender(row, column) : row[column.field]}</div>
            </ELSTableCell>
          ))}
        </ELSTableRow>
      ))}
    </>
  );
};

const getTableClassNames = props => {
  const { hasScrollLeft, hasScrollRight } = props;
  const hasStickyColumn = props.columns.some(column => column.sticky);
  const hasShadowBothSides = !hasStickyColumn && hasScrollLeft && hasScrollRight;
  let shadowClassNames = ' ';
  if (hasShadowBothSides) {
    shadowClassNames = 'c-els-table--inset-shadow-left-right ';
  }
  return classNames('u-els-width-4o4@wide', 'u-els-overflow-auto', shadowClassNames, props.className || '');
};

const DataTableViewComponent = <T extends {}>(props: DataTableViewProps<T>, ref: RefObject<HTMLDivElement>) => {
  const tableClassNames = getTableClassNames(props);
  const renderer = props.emptyRenderer ? props.emptyRenderer() : 'No results found.';
  return (
    <div className={tableClassNames} onScroll={props.handleScroll} ref={ref} style={{ maxHeight: props.maxHeight }}>
      {renderHeaderTableList(props)}
      <ELSTable id={props.id} noWrap={props.noWrap} cssModifier={props.cssModifier}>
        <ELSTableHead>{renderHeader(props)}</ELSTableHead>
        <ELSTableBody>
          {props.expandableTable ? <ExpandableTableBody {...props} /> : renderBodyContent({ ...props })}
          {
            (props.data.length === 0) ?
              (
                <ELSTableRow cssModifier="c-els-table__caption">
                  <td className="c-els-table__cell" colSpan={props.columns.length}>
                    {renderer}
                  </td>
                </ELSTableRow>
              ) : null
          }
        </ELSTableBody>
      </ELSTable>
    </div>
  );
};

export default forwardRef(DataTableViewComponent);
