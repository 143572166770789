import withExtendProps from 'hocs/with-extend-props/withExtendProps.hoc';
import { lazy } from 'react';
import { Analytics, RoutePath } from '../../constants/app.constant';

const getRoutes = () => [
  {
    path: RoutePath.security.login,
    component: lazy(() => import('pages/security/Login.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.login,
        pageName: 'login'
      }
    }
  },
  {
    path: RoutePath.security.logout,
    component: lazy(() => import('pages/security/Logout.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.logout,
        pageName: 'logout'
      }
    }
  },
  {
    path: RoutePath.error.pageNotFound,
    component: lazy(() => import('pages/error/PageNotFound.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.pageNotFound,
        pageName: 'page-not-found'
      }
    }
  },
  {
    path: RoutePath.error.notAuthorized,
    component: lazy(() => import('pages/error/NotAuthorized.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.notAuthorized,
        pageName: 'not-authorized'
      }
    }
  },
  {
    path: RoutePath.error.notAuthorizedApproved,
    component: lazy(() => import('pages/error/NotAuthorizedApprover.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.notAuthorizedApproved,
        pageName: 'not-authorized:approval'
      }
    }
  },
  {
    path: RoutePath.error.notAuthorizedRosterSubmission,
    component: lazy(() => import('pages/error/NotAuthorizedRosterSubmission.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.notAuthorizedRosterSubmission,
        pageName: 'not-authorized:roster-submission'
      }
    }
  },
  {
    path: RoutePath.error.productNotFound,
    component: lazy(() => import('pages/error/ProductNotFound.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.productNotFound,
        pageName: 'product:not-found'
      }
    }
  },
  {
    path: RoutePath.error.expiredLink,
    component: lazy(() => import('pages/error/ExpiredLink.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.expiredLink,
        pageName: 'review-approval:expired'
      }
    }
  },
  {
    path: RoutePath.error.expiredCohortRoster,
    component: lazy(() => import('pages/error/ExpiredCohortRoster.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.expiredCohortRoster,
        pageName: 'roster-submission:expired'
      }
    }
  },
  {
    path: RoutePath.error.expiredStudentPayment,
    component: withExtendProps({
      icon: 'alert',
      title: 'EXPIRED_STUDENT_PAYMENT_TITLE',
      message: 'EXPIRED_STUDENT_PAYMENT_MSG'
    })(lazy(() => import('pages/error/ErrorStudentPayment.page'))),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.expiredStudentPayment,
        pageName: 'student-payment:expired'
      }
    }
  },
  {
    path: RoutePath.error.studentPaymentNotFound,
    component: withExtendProps({
      icon: 'alert-triangle',
      title: 'STUDENT_PAYMENT_NOT_FOUND_TITLE',
      message: 'STUDENT_PAYMENT_NOT_FOUND_MSG'
    })(lazy(() => import('pages/error/ErrorStudentPayment.page'))),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.studentPaymentNotFound,
        pageName: 'student-payment:not-found'
      }
    }
  },
  {
    path: RoutePath.error.noPaymentDue,
    component: withExtendProps({
      messageId: 'NO_PAYMENT_DUE'
    })(lazy(() => import('components/common/system-error-message/SystemErrorMessage.component'))),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.noPaymentDue,
        pageName: 'student-payment:no-payment-due'
      }
    }
  }
];

export const SecurityRoutes = {
  getRoutes
};
