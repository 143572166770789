import moment from 'moment';
import { ELSTokenHelper } from '@els/els-ui-common-react';
import { StorageHelper } from './storage.helper';

const EVOLVE_SESSIONID = 'evolve.sessionId';

const isTokenNotExpired = ():boolean => {
  const token = ELSTokenHelper.getToken();
  if (ELSTokenHelper.isTokenRegistered()) {
    const tokenExpireDate = ELSTokenHelper.getExpirationDateFromToken(token);
    const now = moment();
    return !now.isAfter(tokenExpireDate);
  }
  return false;
};

const isTokenExpired = ():boolean => !isTokenNotExpired();

const getAppParamsFromToken = () => {
  const tokenUser = ELSTokenHelper.getUser() || {};
  return tokenUser.appParams || {};
};

const getEvolveUser = () => {
  const tokenUser = getAppParamsFromToken();
  return {
    role: tokenUser['evolve.role'],
    userName: tokenUser['evolve.username'],
    userId: tokenUser['evolve.userId'],
    sessionId: tokenUser[EVOLVE_SESSIONID]
  };
};

const getOauthTokenSessionId = ():string => {
  const appParams = getAppParamsFromToken();
  if (appParams[EVOLVE_SESSIONID]) {
    return appParams[EVOLVE_SESSIONID];
  }
  return null;
};

const validateSessionIdWithTokenSessionId = ():boolean => {
  const sessionId = StorageHelper.getEvolveSessionId() || null;
  const tokenSessionId = getOauthTokenSessionId() || null;
  return tokenSessionId === sessionId;
};

export const TokenHelper = {
  isTokenExpired,
  isTokenNotExpired,
  validateSessionIdWithTokenSessionId,
  getEvolveUser
};
