import { CohortInstallmentModel } from 'models/cohort.models';
import { EvolveInstallmentModel } from 'models/installment.model';

export const INSTALLMENTS: CohortInstallmentModel[] = [
  {
    CohortID: 'CohortIDInt100',
    Status: '',
    InstallmentID: 'PackageInstallementIDInt100f',
    InstallmentName: 'Installment Int 001',
    InvoiceDate: '2022-02-21T14:28:00Z',
    CostPerStudent: 10.0,
    TotalCost: 100.0,
    CohortName: 'CohortTitleInt100',
    Quantity: 10,
    NetAmount: 10 * 10
  },
  {
    CohortID: 'CohortIDInt100',
    Status: '',
    InstallmentID: 'PackageInstallementIDInt100l',
    InstallmentName: 'Installment Int 002',
    InvoiceDate: '2022-07-21T14:28:00Z',
    CostPerStudent: 10.0,
    TotalCost: 100.0,
    CohortName: 'CohortTitleInt100',
    Quantity: 10,
    NetAmount: 10 * 10
  },
  {
    CohortID: 'CohortIDInt100',
    Status: '',
    InstallmentID: 'PackageInstallementIDInt100b',
    InstallmentName: 'Installment Int 003',
    InvoiceDate: '2021-10-21T14:28:00Z',
    CostPerStudent: 10.0,
    TotalCost: 300.0,
    CohortName: 'CohortTitleInt100',
    Quantity: 10,
    NetAmount: 10 * 10
  },
  {
    CohortID: 'CohortIDInt100',
    Status: '',
    InstallmentID: 'PackageInstallementIDInt100k',
    InstallmentName: 'Installment Int 004',
    InvoiceDate: '2022-06-21T14:28:00Z',
    CostPerStudent: 20.0,
    TotalCost: 700.0,
    CohortName: 'CohortTitleInt100',
    Quantity: 10,
    NetAmount: 10 * 20
  },
  {
    CohortID: 'CohortIDInt100',
    Status: '',
    InstallmentID: 'PackageInstallementIDInt100g',
    InstallmentName: 'Installment Int 005',
    InvoiceDate: '2022-03-21T14:28:00Z',
    CostPerStudent: 10.0,
    TotalCost: 100.0,
    CohortName: 'CohortTitleInt100',
    Quantity: 10,
    NetAmount: 10 * 10
  },
  {
    CohortID: 'CohortIDInt100',
    Status: '',
    InstallmentID: 'PackageInstallementIDInt100i',
    InstallmentName: 'Installment Int 006',
    InvoiceDate: '2022-05-21T14:28:00Z',
    CostPerStudent: 20.0,
    TotalCost: 200.0,
    CohortName: 'CohortTitleInt100',
    Quantity: 10,
    NetAmount: 10 * 20
  },
  {
    CohortID: 'CohortIDInt100',
    Status: '',
    InstallmentID: 'PackageInstallementIDInt100e',
    InstallmentName: 'Installment Int 007',
    InvoiceDate: '2022-01-21T14:28:00Z',
    CostPerStudent: 20.0,
    TotalCost: 200.0,
    CohortName: 'CohortTitleInt100',
    Quantity: 10,
    NetAmount: 10 * 20
  },
  {
    CohortID: 'CohortIDInt100',
    Status: '',
    InstallmentID: 'PackageInstallementIDInt100c',
    InstallmentName: 'Installment Int 008',
    InvoiceDate: '2021-11-21T14:28:00Z',
    CostPerStudent: 10.0,
    TotalCost: 500.0,
    CohortName: 'CohortTitleInt100',
    Quantity: 10,
    NetAmount: 10 * 10
  },
  {
    CohortID: 'CohortIDInt100',
    Status: '',
    InstallmentID: 'PackageInstallementIDInt100a',
    InstallmentName: 'Installment Int 009',
    InvoiceDate: '2021-09-21T14:28:00Z',
    CostPerStudent: 10.0,
    TotalCost: 200.0,
    CohortName: 'CohortTitleInt100',
    Quantity: 10,
    NetAmount: 10 * 10
  },
  {
    CohortID: 'CohortIDInt100',
    Status: '',
    InstallmentID: 'PackageInstallementIDInt100h',
    InstallmentName: 'Installment Int 010',
    InvoiceDate: '2022-04-21T14:28:00Z',
    CostPerStudent: 10.0,
    TotalCost: 100.0,
    CohortName: 'CohortTitleInt100',
    Quantity: 10,
    NetAmount: 10 * 10
  },
  {
    CohortID: 'CohortIDInt100',
    Status: '',
    InstallmentID: 'PackageInstallementIDInt100d',
    InstallmentName: 'Installment Int 011',
    InvoiceDate: '2021-12-21T14:28:00Z',
    CostPerStudent: 20.0,
    TotalCost: 200.0,
    CohortName: 'CohortTitleInt100',
    Quantity: 10,
    NetAmount: 10 * 20
  },
  {
    CohortID: '41',
    InstallmentID: 'Spring 2020 Cohort A Installment 01',
    InstallmentName: 'Installment Int 012',
    CostPerStudent: 433.33,
    Status: '',
    InvoiceDate: '08-26-2021',
    TotalCost: 10,
    CohortName: 'Spring 2020 Cohort A (BSN)',
    Quantity: 10,
    NetAmount: 10 * 433.33
  },
  {
    CohortID: '41',
    InstallmentID: 'Spring 2020 Cohort A Installment 02',
    InstallmentName: 'Installment Int 013',
    CostPerStudent: 433.33,
    Status: '',
    InvoiceDate: '08-24-2021',
    TotalCost: 10,
    CohortName: 'Spring 2020 Cohort A (BSN)',
    Quantity: 10,
    NetAmount: 10 * 433.33
  },
  {
    CohortID: '42',
    InstallmentID: 'Spring 2020 Cohort B Installment 01',
    InstallmentName: 'Installment Int 014',
    CostPerStudent: 433.33,
    Status: '',
    InvoiceDate: '08-22-2021',
    TotalCost: 10,
    CohortName: 'Spring 2020 Cohort B (BSN)',
    Quantity: 10,
    NetAmount: 10 * 433.33
  },
  {
    CohortID: '42',
    InstallmentID: 'Spring 2020 Cohort B Installment 02',
    InstallmentName: 'Installment Int 015',
    CostPerStudent: 433.33,
    Status: '',
    InvoiceDate: '08-30-2021',
    TotalCost: 10,
    CohortName: 'Spring 2020 Cohort B (BSN)',
    Quantity: 10,
    NetAmount: 10 * 433.33
  },
  {
    CohortID: '43',
    InstallmentID: 'Spring 2020 Cohort C Installment 01',
    InstallmentName: 'Installment Int 016',
    CostPerStudent: 433.33,
    Status: '',
    InvoiceDate: '08-2-2021',
    TotalCost: 10,
    CohortName: 'Spring 2020 Cohort C (ADN)',
    Quantity: 10,
    NetAmount: 10 * 433.33
  },
  {
    CohortID: '44',
    InstallmentID: 'Spring 2020 Cohort D Installment 01',
    InstallmentName: 'Installment Int 017',
    CostPerStudent: 433.33,
    Status: '',
    InvoiceDate: '08-26-2021',
    TotalCost: 10,
    CohortName: 'Spring 2020 Cohort D (ADN)'
  },
  {
    CohortID: '45',
    InstallmentID: 'Spring 2020 Cohort E Installment 01',
    InstallmentName: 'Installment Int 018',
    CostPerStudent: 433.33,
    Status: '',
    InvoiceDate: '08-25-2021',
    TotalCost: 10,
    CohortName: 'Spring 2020 Cohort E (BSN)'
  },
  {
    CohortID: '46',
    InstallmentID: 'Spring 2020 Cohort F Installment 01',
    InstallmentName: 'Installment Int 019',
    CostPerStudent: 433.33,
    Status: '',
    InvoiceDate: '08-20-2021',
    TotalCost: 10,
    CohortName: 'Spring 2020 Cohort F (ADN)',
    Quantity: 10,
    NetAmount: 10 * 433.33
  }
];

export const EVOLVE_INSTALLMENTS: EvolveInstallmentModel[] = [
  {
    installmentNumber: 1,
    installmentId: 'PackageInstallementIDInt100a',
    installmentName: 'Installment 100a',
    invoiceDate: '2021-10-26T00:00:00Z',
    costPerStudent: 10,
    cohortId: 'CohortIDInt100',
    cohortName: 'CohortTitleInt100',
    cohortProgram: 'ANS'
  },
  {
    installmentNumber: 2,
    installmentId: 'PackageInstallementIDInt100b',
    installmentName: 'Installment 100b',
    invoiceDate: '2021-10-23T00:00:00Z',
    costPerStudent: 10,
    cohortId: 'CohortIDInt100',
    cohortName: 'CohortTitleInt100',
    cohortProgram: 'ANS'
  },
  {
    installmentNumber: 3,
    installmentId: 'PackageInstallementIDInt100c',
    installmentName: 'Installment 100c',
    invoiceDate: '2021-11-21T14:28:00Z',
    costPerStudent: 10,
    cohortId: 'CohortIDInt100',
    cohortName: 'CohortTitleInt100',
    cohortProgram: 'ANS'
  },
  {
    installmentNumber: 4,
    installmentId: 'PackageInstallementIDInt100d',
    installmentName: 'Installment 100d',
    invoiceDate: '2021-12-21T14:28:00Z',
    costPerStudent: 20,
    cohortId: 'CohortIDInt100',
    cohortName: 'CohortTitleInt100',
    cohortProgram: 'ANS'
  },
  {
    installmentNumber: 5,
    installmentId: 'PackageInstallementIDInt100e',
    installmentName: 'Installment 100d',
    invoiceDate: '2022-01-21T14:28:00Z',
    costPerStudent: 20,
    cohortId: 'CohortIDInt100',
    cohortName: 'CohortTitleInt100',
    cohortProgram: 'ANS'
  },
  {
    installmentNumber: 6,
    installmentId: 'PackageInstallementIDInt100f',
    installmentName: 'Installment 100f',
    invoiceDate: '2022-02-21T14:28:00Z',
    costPerStudent: 10,
    cohortId: 'CohortIDInt100',
    cohortName: 'CohortTitleInt100',
    cohortProgram: 'ANS'
  },
  {
    installmentNumber: 7,
    installmentId: 'PackageInstallementIDInt100g',
    installmentName: 'Installment 100g',
    invoiceDate: '2022-03-21T14:28:00Z',
    costPerStudent: 10,
    cohortId: 'CohortIDInt100',
    cohortName: 'CohortTitleInt100',
    cohortProgram: 'ANS'
  },
  {
    installmentNumber: 8,
    installmentId: 'PackageInstallementIDInt100h',
    installmentName: 'Installment 100h',
    invoiceDate: '2022-04-21T14:28:00Z',
    costPerStudent: 10,
    cohortId: 'CohortIDInt100',
    cohortName: 'CohortTitleInt100',
    cohortProgram: 'ANS'
  },
  {
    installmentNumber: 9,
    installmentId: 'PackageInstallementIDInt100i',
    installmentName: 'Installment 100i',
    invoiceDate: '2022-05-21T14:28:00Z',
    costPerStudent: 20,
    cohortId: 'CohortIDInt100',
    cohortName: 'CohortTitleInt100',
    cohortProgram: 'ANS'
  },
  {
    installmentNumber: 10,
    installmentId: 'PackageInstallementIDInt100l',
    installmentName: 'Installment 100l',
    invoiceDate: '2022-07-21T14:28:00Z',
    costPerStudent: 10,
    cohortId: 'CohortIDInt100',
    cohortName: 'CohortTitleInt100',
    cohortProgram: 'ANS'
  }
];
