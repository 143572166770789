import React from 'react';
import { NavigationConfig, NavigationSectionType } from '../../../models/navigation.models';
import TopNavigationSection from '../navigation-sections/TopNavSection.component';

export interface TopNavProps {
  navConfig: NavigationConfig;
}

const TopNav = ({ navConfig }: TopNavProps) => (
  <nav
    role="navigation"
    className="o-els-flex-layout
               o-els-flex-layout--left
               o-els-flex-layout--middle
               c-tsp-top-nav">
    {navConfig.navSections.map((section: NavigationSectionType) => {
      const NavSectionComponent = section.navSectionComponent || TopNavigationSection;
      return (
        <div key={section.key} className="o-els-flex-layout__item">
          <NavSectionComponent section={section} />
        </div>
      );
    })}
  </nav>
);

export default TopNav;
