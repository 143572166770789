import { isResourceCompleted } from 'utilities/app/app.utility';
import { REDUCER_ID } from './opportunity.reducer';

export const getOpportunity = (globalState) => globalState[REDUCER_ID].Opportunity;
export const getOpportunityError = (globalState) => globalState[REDUCER_ID].Error;
export const getInvoiceEditor = globalState => globalState[REDUCER_ID].InvoiceEditor;
export const getInvoiceEditorErrors = globalState => globalState[REDUCER_ID].InvoiceEditorErrors;
export const getOpportunityApprovers = globalState => globalState[REDUCER_ID].OpportunityApprovers;
export const getInvoicePopupTypeSelected = globalState => globalState[REDUCER_ID].InvoicePopupTypeSelected;
export const isOpportunityCompleted = (globalState) => {
  const opportunity = globalState[REDUCER_ID] && globalState[REDUCER_ID].Opportunity;
  if (!opportunity || !opportunity.LastCohortEndDate) {
    return false;
  }

  return isResourceCompleted(opportunity.LastCohortEndDate);
};
export const getInvoiceFormFocusElement = globalState => globalState[REDUCER_ID].InvoiceFormFocusElement;
