import { CreditRequestStatusEditorModel } from 'models/credit-request.models';
import { WORKFLOW_CREDIT_REQUEST_OPTIONS } from '../../constants/creditRequest.constant';

let cacheCreditRequestEditor = null;
export const getCacheCreditRequestEditor = () => cacheCreditRequestEditor;

export const setCacheCreditRequestEditor = (data?: CreditRequestStatusEditorModel) => {
  cacheCreditRequestEditor = data;
};

export const getClassColorForCreditRequestStatus = (value = 0) => {
  const status = WORKFLOW_CREDIT_REQUEST_OPTIONS.find((opt) => Number(opt.value) === value);
  return status && status.classColor;
};
