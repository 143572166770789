import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppConstant } from '../../constants/app.constant';

export const REDUCER_ID = AppConstant.redux.SECURITY_STATE;

export const initialState = {
  token: '',
  sessionId: null,
  role: null,
  loggedIn: false,
  faLogin: false,
  evolveUser: null,
  eolsExternalUserId: null,
  errors: null
};

const securitySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {
    restoreToken(state, action) {
      return {
        ...state,
        ...action.payload
      };
    },
    authSuccess(state, action) {
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
        errors: null
      };
    },
    authFailed(state, action) {
      return {
        ...state,
        errors: action.payload,
        loggedIn: false,
        token: null,
        sessionId: null,
        evolveUser: null
      };
    },
    logoutSuccess(state) {
      return {
        ...state,
        errors: null,
        loggedIn: false,
        faLogin: false,
        token: null,
        sessionId: null,
        evolveUser: null
      };
    },
    logoutFailed(state, action) {
      return {
        ...state,
        loggedIn: false,
        faLogin: false,
        token: null,
        sessionId: null,
        errors: action.payload
      };
    },
    saveSessionId(state, action) {
      return {
        ...state,
        sessionId: action.payload
      };
    },
    setToken(state, action: PayloadAction<string>) {
      return {
        ...state,
        token: action.payload
      };
    }
  }
});

export const securityReducer = securitySlice.reducer;
export const securityActions = securitySlice.actions;
