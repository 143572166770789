import React from 'react';
import { NavigationItemType } from '../../../../models/navigation.models';
import BaseLink from '../base-link/BaseLink.component';

export interface NavigationTextProps {
  item: NavigationItemType;
}

const NavigationText = ({ item }: NavigationTextProps) => (
  <span className="c-tsp-nav-item c-tsp-nav-item--disabled" aria-disabled="true">
    <BaseLink item={item} />
  </span>
);

export default NavigationText;
