import { addLocaleData } from 'react-intl';
import localeEN from 'react-intl/locale-data/en';

import en from '../../localization/en.json';

addLocaleData([...localeEN]);

const LANGUAGES = {
  en
};

const DEFAULT_LOCALE = 'en';

const getLanguageMessages = (locale = DEFAULT_LOCALE) => {
  const fallbackLocale = LANGUAGES[locale] ? locale : DEFAULT_LOCALE;
  return {
    locale: fallbackLocale,
    languageMessages: LANGUAGES[fallbackLocale]
  };
};

export { getLanguageMessages };
