import React from 'react';
import BaseLink from '../base-link/BaseLink.component';
import { NavigationItemType } from '../../../../models/navigation.models';

export interface NavigationButtonProps {
  item: NavigationItemType;
}
const NavigationButton = ({ item }: NavigationButtonProps) => (
  <button
    onClick={() => {
      item.onAction(item);
    }}
    type="button"
    className="u-els-debuttonize c-tsp-nav-item c-tsp-nav-item--button"
  >
    <BaseLink item={item} />
  </button>
);

export default NavigationButton;
