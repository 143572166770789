import { appActions, appSelectors } from '../../redux/app';

export enum AsyncActionStatus {
  LOADING
}
const withUseAsyncLoading = (actionName) => (asyncAction: (dispatch, getState?) => Promise<unknown>) => async (dispatch, getState) => {
  const isAsyncLoading = appSelectors.isAsyncLoading(actionName)(getState());
  if (isAsyncLoading) {
    return AsyncActionStatus.LOADING;
  }
  try {
    dispatch(appActions.startAsyncLoading(actionName));
    return await asyncAction(dispatch, getState);
  } finally {
    dispatch(appActions.endAsyncLoading(actionName));
  }
};

export default withUseAsyncLoading;
