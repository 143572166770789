import React from 'react';
import cx from 'classnames';
import TopBaseLink from '../../navigation/navigation-items/base-link/TopBaseLink.component';

export interface MenuViewProps {
  buttonProps: any;
  className?: string;
  showMenu: boolean;
  setMenuContainer: any;
  menuWindow: any;
}
const MenuView = (props: MenuViewProps) => {
  const { buttonProps } = props;
  return (
    <div className={`c-els-menu ${props.className} ${props.showMenu ? 'c-els-menu--active' : ''}`} ref={props.setMenuContainer}>
      <button
        type="button"
        aria-expanded={props.showMenu}
        aria-label={buttonProps.buttonAriaLabel}
        onClick={buttonProps.toggleMenu}
        className={cx('c-els-nav-group__link',
          'u-els-display-block',
          'u-els-padding-1o2',
          'u-els-padding-left-1x',
          'c-tsp-nav-item-top',
          'c-tsp-nav-item-top--button c-tsp-nav-item-top--toggle-button',
          'u-els-padding-right-1x')}>
        <TopBaseLink item={{
          iconName: buttonProps.icon,
          iconPrefix: buttonProps.iconPrefix,
          text: buttonProps.content
        }} />
      </button>
      {props.showMenu && <props.menuWindow {...props} />}
    </div>
  );
};

export default MenuView;
