import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpportunityModel } from '../../models/opportunity.model';
import { InvoiceEditorModel } from '../../models/invoice.model';
import { EvolveInstallmentModel } from '../../models/installment.model';
import { APIError } from '../../models/api.model';
import { RosterModel } from '../../models/roster.models';
import { AppConstant } from '../../constants/app.constant';

export const REDUCER_ID = AppConstant.redux.OPPORTUNITY_STATE;

const initialState = {
  Opportunity: null,
  Error: null,
  InvoiceEditor: {
    opportunityId: null,
    installment: {},
    invoices: []
  },
  InvoicePopupTypeSelected: null,
  InvoiceEditorErrors: null,
  OpportunityApprovers: [],
  InvoiceFormFocusElement: null
};

export const opportunitySlice = createSlice({
  name: 'opportunity',
  initialState,
  reducers: {
    resetOpportunity(state) {
      return {
        ...state,
        Opportunity: null,
        Error: null
      };
    },
    fetchOpportunitySuccess(state, action: PayloadAction<OpportunityModel>) {
      return {
        ...state,
        Opportunity: action.payload,
        Error: null
      };
    },
    fetchOpportunityFailed(state, action: PayloadAction<APIError>) {
      return {
        ...state,
        Error: action.payload
      };
    },
    updatePoRequired(state, action: PayloadAction<boolean>) {
      if (state.Opportunity) {
        return {
          ...state,
          Opportunity: {
            ...state.Opportunity,
            PORequired: action.payload
          }
        };
      }
      return state;
    },
    updateCohortCountApprover(state, action: PayloadAction<RosterModel>) {
      if (state.Opportunity) {
        return {
          ...state,
          Opportunity: {
            ...state.Opportunity,
            Approver: action.payload
          }
        };
      }
      return state;
    },
    updatePONumber(state, action: PayloadAction<string>) {
      if (state.Opportunity) {
        return {
          ...state,
          Opportunity: {
            ...state.Opportunity,
            PONumber: action.payload
          }
        };
      }
      return state;
    },
    updateOpportunity(state, action: PayloadAction<Partial<OpportunityModel>>) {
      if (state.Opportunity) {
        return {
          ...state,
          Opportunity: {
            ...state.Opportunity,
            ...action.payload
          }
        };
      }
      return state;
    },
    setInvoiceEditor(state, action: PayloadAction<InvoiceEditorModel>) {
      return {
        ...state,
        InvoiceEditor: action.payload
      };
    },
    setInvoiceInstallments(state, action: PayloadAction<EvolveInstallmentModel[]>) {
      return {
        ...state,
        InvoiceEditor: {
          ...state.InvoiceEditor,
          installments: action.payload
        }
      };
    },
    setInvoiceEditorValue(state, action: PayloadAction<{ fieldName: string, value: string | boolean | number }>) {
      return {
        ...state,
        InvoiceEditor: {
          ...state.InvoiceEditor,
          [action.payload.fieldName]: action.payload.value
        }
      };
    },
    setInvoiceEditorErrors(state, action: PayloadAction<APIError[]>) {
      return {
        ...state,
        InvoiceEditorErrors: action.payload
      };
    },
    setOpportunityApprovers(state, action: PayloadAction<{ name: string, value: string }[]>) {
      return {
        ...state,
        OpportunityApprovers: action.payload
      };
    },
    resetInvoiceEditor(state) {
      return {
        ...state,
        InvoiceEditor: null,
        InvoiceEditorErrors: null,
        OpportunityApprovers: []
      };
    },
    setInvoicePopupTypeSelected(state, action: PayloadAction<{ invoicePopupType: number, invoiceIndexSelected: number }>) {
      return {
        ...state,
        InvoicePopupTypeSelected: action.payload
      };
    },
    resetInvoicePopupTypeSelected(state) {
      return {
        ...state,
        InvoicePopupTypeSelected: null
      };
    },
    setInvoiceFormFocusElement(state, action: PayloadAction<{ invoiceFormFocusElement: string }>) {
      return {
        ...state,
        InvoiceFormFocusElement: action.payload.invoiceFormFocusElement
      };
    },
  }
});

// Action creators are generated for each case reducer function
export const opportunityActions = opportunitySlice.actions;
export const opportunityReducer = opportunitySlice.reducer;
