import * as yup from 'yup';
import { SortDirection } from 'components/data-table/DataTable.component';
import { OpportunityRequestOptions } from 'models/api.model';
import { StatusOptionModel } from '../models/opportunity.model';

export const STATUS_MAPPING: StatusOptionModel[] = [
  {
    value: 'Yes',
    name: 'Yes',
    type: 'secondary-on-dark'
  },
  {
    value: 'No',
    name: 'No',
    type: 'n4'
  }
];

export enum OpportunityTabs {
  CohortsTab = 0,
  FacultyStaffTab = 1,
  InstallmentsTab = 2,
  InvoicesTab = 3
}

export const SelectedOpportunityTab = 'selectedTab';
export const InstallmentSelectionNotAvailableMessage = 'This installment is not available for selection since it’s cohort count has not been approved.';
export const InFlightViewMessge = 'Please note that when viewing pilot cohorts, billing and revenue allocation is not supported and has been disabled.';
export const EnterValidEmail = 'Opportunity owner’s email address is required.';
export const PreviousOutStandingCreditMessage = 'This installment cannot be selected while the previous installment has an outstanding credit request.';
export const PreviousPendingInvoiceMessage = 'This installment cannot be selected while the previous installment has the pending invoices or a pending amount.';

export const defaultOpportunitiesPagingOptions: OpportunityRequestOptions = {
  currentPage: 1,
  resultsPerPage: 10,
  sortColumn: 'FirstCohortStartDate',
  sortDirection: SortDirection.descending,
  query: '',
  showCompletedOpportunities: false
};

export const OPPORTUNITY_FIELD_PATH = {
  AccountName: 'AccountName',
  OpportunityName: 'OpportunityName',
  OpportunityID: 'OpportunityID',
  OpportunityIDNumber: 'OpportunityIDNumber',
  OpportunityOwnerEmail: 'OpportunityOwner.Email',
  OpportunityOwnerFullName: 'OpportunityOwner.FullName',
  ContractAgreement: 'Contract.ContractAgreement',
  ContractStatus: 'Contract.ContractStatus',
  FirstCohortStartDate: 'FirstCohortStartDate'
};

export const OPPORTUNITY_OWNER_EMAIL_SCHEMA = {
  ownerEmail: yup.string()
    .transform((v) => v.trim())
    .required(EnterValidEmail)
    .email('Opportunity owner’s email address is not valid.')
};
