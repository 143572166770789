import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { AppConstant } from 'constants/app.constant';
import { configureStore as reduxConfigureStore, Reducer } from '@reduxjs/toolkit';
import { pick, uniq } from 'lodash';
import { SecondaryStorageHelper, StorageHelper } from '../helpers/storage.helper';
import appStoreConfig from './app.store.config';
import hesiStoreConfig from '../modules/hesi/redux/hesi.store.config';

export interface StoreConfig {
  actionTypesToStore: string[];
  statesToBeStoredInLocalStorage?: { [key: string]: Function };
  statesToBeStoredInSessionStorage?: { [key: string]: Function };
  reducers: { [key: string]: Reducer};
  initialState?: object;
}

const storeConfigs: StoreConfig[] = [appStoreConfig, hesiStoreConfig];

const combinedStoreConfigs = storeConfigs.reduce((storeConfig, {
  statesToBeStoredInLocalStorage = {},
  statesToBeStoredInSessionStorage = {},
  actionTypesToStore = [],
  reducers = {},
  initialState = {}
}) => ({
  statesToBeStoredInLocalStorage: {
    ...storeConfig.statesToBeStoredInLocalStorage,
    ...statesToBeStoredInLocalStorage
  },
  statesToBeStoredInSessionStorage: {
    ...storeConfig.statesToBeStoredInSessionStorage,
    ...statesToBeStoredInSessionStorage
  },
  actionTypesToStore: uniq([...storeConfig.actionTypesToStore, ...actionTypesToStore]),
  reducers: {
    ...storeConfig.reducers,
    ...reducers
  },
  initialState: {
    ...storeConfig.initialState,
    ...initialState
  }
}), {
  statesToBeStoredInSessionStorage: {},
  statesToBeStoredInLocalStorage: {},
  actionTypesToStore: [
    '@@router/LOCATION_CHANGE'
  ],
  reducers: {},
  initialState: {}
});

const createAppReducer = history => ({
  [AppConstant.redux.ROUTER_STATE]: connectRouter(history),
  ...combinedStoreConfigs.reducers
});

const restoreStateInLocalStorage = store => {
  const stateFromStorage = StorageHelper.getTSPState();
  if (!stateFromStorage) return;

  Object.entries(combinedStoreConfigs.statesToBeStoredInLocalStorage).forEach(([stateKey, restoreFunc]) => {
    const state = stateFromStorage[stateKey];
    if (state) {
      store.dispatch(restoreFunc(state));
    }
  });
};

const restoreStateInSessionStorage = store => {
  const stateFromStorage = SecondaryStorageHelper.getTSPState();
  if (!stateFromStorage) return;

  Object.entries(combinedStoreConfigs.statesToBeStoredInSessionStorage).forEach(([stateKey, restoreFunc]) => {
    const state = stateFromStorage[stateKey];
    if (state) {
      store.dispatch(restoreFunc(state));
    }
  });
};

const restoreState = store => {
  restoreStateInLocalStorage(store);
  restoreStateInSessionStorage(store);
};

export const getInitialState = () => combinedStoreConfigs.initialState;

const storageMiddleware = ({ getState }) => next => action => {
  const result = next(action);
  if (combinedStoreConfigs.actionTypesToStore.includes(action.type)) {
    const state = pick(getState(), Object.keys(combinedStoreConfigs.statesToBeStoredInLocalStorage));
    StorageHelper.storeTSPState(state);

    const stateInSession = pick(getState(), Object.keys(combinedStoreConfigs.statesToBeStoredInSessionStorage));
    SecondaryStorageHelper.storeTSPState(stateInSession);
  }
  return result;
};

export const configureStore = history => {
  const middlewares = [thunkMiddleware, routerMiddleware(history), storageMiddleware];
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger({ collapsed: true }));
  }
  const store = reduxConfigureStore({
    reducer: createAppReducer(history),
    middleware: middlewares,
    devTools: process.env.NODE_ENV === 'development',
    preloadedState: getInitialState()
  });
  restoreState(store);

  return store;
};
