import React from 'react';
import { ELSIcon } from '@els/els-component-form-field-react';
import { NavigationItemType } from '../../../../models/navigation.models';

export interface BaseLinkProps {
  item: NavigationItemType;
}

const BaseLink = ({ item }: BaseLinkProps) => {
  const icon = item.iconName ? (
    <div className="c-tsp-nav-item__icon">
      <ELSIcon size="2x"
               prefix={item.iconPrefix}
               name={item.iconName}
               align="middle"
               customClass="u-els-margin-right-1o2" />
    </div>
  ) : <></>;

  return (
    <div className="o-els-flex-layout o-els-flex-layout--left o-els-flex-layout--middle">
      <span className="o-els-flex-layout__item">{icon}</span>
      <span className="o-els-flex-layout__item">
        <span className="c-tsp-nav-item__text">{item.text}</span>
      </span>
    </div>
  );
};

export default BaseLink;
