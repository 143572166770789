import moment from 'moment';
import { DATE_FORMAT_MDYYYY } from 'constants/app.constant';

/**
 * this function supports to change a date with local timezone to UTC timezone without datetime conversion
 * @param localDate date is a local date time
 */
export const changeDateToISODate = (localDate: Date): Date => {
  const dateStr = moment(localDate).format(DATE_FORMAT_MDYYYY);
  return moment.utc(dateStr).toDate();
};

/**
 * this function supports to change a date with UTC timezone to local timezone without datetime conversion
 * @param isoDate date is a iso date time
 */
export const changeDateToLocalDate = (isoDate: Date | string): Date => {
  const dateStr = moment.utc(isoDate).format(DATE_FORMAT_MDYYYY);
  return moment(dateStr).toDate();
};

/**
 * this function supports to change a date string to UTC timezone without datetime conversion
 * @param isoDate
 */
export const parseISODate = (isoDate: string): Date => moment.utc(isoDate).toDate();
