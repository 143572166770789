import { orderBy, cloneDeep } from 'lodash';
import { CohortModel } from 'models/opportunity.model';
import { withPromiseDelay } from '../api.utility';
import { CommentModel } from '../../../models/common.models';
import { PagingOptions } from '../../../models/api.model';
import { OpportunityModel } from '../../../models/opportunity.model';
import {
  CohortInstallmentModel,
  CohortWorkflowStatusModel,
  ProductApprovalStatusModel
} from '../../../models/cohort.models';
import { cohorts, COHORT_DETAILS } from '../../../dummy-data';
import { StorageHelper } from '../../../helpers/storage.helper';
import { getInternalOpportunity } from '../opportunities-api/opportunitiesApi.utility';

const getSortedDataByPage = (data, pageOptions: PagingOptions) => {
  let sortedData = data;
  if (pageOptions.sortColumn && pageOptions.sortDirection) {
    sortedData = orderBy(data, pageOptions.sortColumn, pageOptions.sortDirection);
  }
  const startIndex = (pageOptions.currentPage - 1) * pageOptions.resultsPerPage;
  const endIndex = Math.min(data.length, startIndex + pageOptions.resultsPerPage);
  return sortedData.slice(startIndex, endIndex);
};

export const getCohorts = (OpportunityID) => withPromiseDelay<{
  TotalCount: number,
  Cohorts: CohortModel[]
}>(1000, { Cohorts: cloneDeep(cohorts), TotalCount: cohorts.length, OpportunityID });

/* istanbul ignore next */
export const getCohortById = (opportunityID, cohortId) => {
  const Opportunity = getInternalOpportunity(opportunityID);
  const Cohort = cohorts.find(c => c.CohortID === cohortId) || cohorts[0];
  const result = {
    Opportunity,
    Cohort
  };
  return withPromiseDelay<{
    Opportunity: OpportunityModel,
    Cohort: CohortModel
  }>(0, cloneDeep(result));
};

export const getCommentsByCohortId = (cohortId, pageOptions: PagingOptions) => {
  const data = getSortedDataByPage(COHORT_DETAILS.COMMENTS, pageOptions);
  return withPromiseDelay<{
    TotalCount: number,
    Comments: CommentModel[]
  }>(1000, { Comments: data, TotalCount: COHORT_DETAILS.COMMENTS.length });
};

type ResponseUpdateWorkflow = Partial<CohortWorkflowStatusModel> & {
  ErrorMsg?: string;
}

export const updateCohortWorkflowStatus = payload => withPromiseDelay<{
  Success: boolean,
  Response: ResponseUpdateWorkflow
}>(1000, {
  Success: true,
  Response: payload,
});

export const approvedProduct = (cohortId, comment) => withPromiseDelay<ProductApprovalStatusModel>(0, {
  id: Date.now(),
  activityDate: new Date(),
  contact: 'Last Name, First Name',
  name: 'Approved',
  desc: 'Approved',
  notes: comment
});

export const updateCohortInstallment = (installment: any) => withPromiseDelay<{
  Success: boolean,
  Response: CohortInstallmentModel
}>(1000, { Success: true, Response: installment });

export const updateCohortApi = (cohort: CohortModel) =>
  withPromiseDelay<{
    Success: boolean;
    Response: { Cohort?: CohortModel; ErrorMsg?: string };
  }>(1000, { Success: true, Response: { Cohort: cohort } });

export const addComment = (CohortID: string | number, Message: string) => {
  const evolveUser = StorageHelper.getEvolveUser();
  return withPromiseDelay<{
    Success: boolean,
    Response: {
      ErrorMsg?: string;
      Comment?: CommentModel;
    },
  }>(1000, {
    Success: true,
    Response: {
      Comment: {
        CohortID,
        Message,
        CommentID: Date.now().toString(),
        CommentDate: Date.now(),
        FirstName: evolveUser.userName,
        LastName: evolveUser.userName,
        Email: (`${evolveUser.userName}@email.com`)
      }
    }
  });
};

export const createInstallment = (installment: { InstallmentName: string; CohortID: string | number; InvoiceDate: string; CostPerStudent: number }) =>
  withPromiseDelay<{
    Success: boolean;
    Response: {
      Installment?: CohortInstallmentModel;
      ErrorMsg?: string;
    }
  }>(1000, {
    Success: true,
    Response: {
      Installment: {
        CohortID: installment.CohortID,
        InstallmentID: installment.InstallmentName,
        CostPerStudent: installment.CostPerStudent,
        Status: '',
        InvoiceDate: installment.InvoiceDate,
        TotalCost: 100
      }
    }
  });
