import React, { UIEventHandler, ReactElement } from 'react';
import { ELSIcon } from '@els/els-component-form-field-react';
import { ELSTableRow, ELSTableCell } from '@els/els-component-data-table-react';
import classNames from 'classnames';
import { STICKY_CELL_Z_INDEX } from 'constants/app.constant';
import { isEmpty } from 'lodash';
import { DataColumnProps } from '../data-column/DataColumn.component';
import { ELSButton } from '../../common/els';

interface ExpandableTableBodyProps {
  handleScroll?: UIEventHandler;
  maxHeight?: number;
  id: string;
  rowKey?: string;
  noWrap?: boolean;
  cssModifier?: string;
  columns: DataColumnProps[];
  columnHeaderCss: string[];
  stickyHeader?: string;
  hasScrollLeft?: boolean;
  sortField?: string;
  isSortDescending?: boolean;
  handleSort?: Function;
  sortIconName?: string;
  sortIconSize?: string;
  changeSortField?: Function;
  changeSortDirection?: Function;
  sortLabel?: string;
  data: object[];
  labelWidthForList?: string;
  emptyRenderer?: () => string | ReactElement;
  expandedKeys?: string[];
  handleToggle?: (key: string | number) => void;
  expandText?: string;
  positionExpandBtn?: string;
  subRowsFieldName?: string;
  expandableTable?: boolean;
  alwaysExpandedTable?: boolean,
  expandableRenderer?: (row: object) => string | ReactElement;
}

const renderExpandButton = ({ expandedKeys, handleToggle, expandText }: ExpandableTableBodyProps, rowKey) => (
  <ELSButton type="link" className="u-els-nowrap u-els-margin-top-1o2" onClick={() => handleToggle(rowKey)}>
    <span>{expandText}</span>
    <ELSIcon customClass="u-els-margin-left" size="1x" name={expandedKeys.includes(rowKey) ? 'chevron-up' : 'chevron-down'} />
  </ELSButton>
);

const renderRowData = (props: ExpandableTableBodyProps, row, isDisplayChildRow) => {
  const { columns, labelWidthForList, hasScrollLeft, rowKey, positionExpandBtn, expandedKeys, subRowsFieldName, alwaysExpandedTable, expandableRenderer } = props;

  return (
    <ELSTableRow key={`row-${row[rowKey]}`}>
      {columns.map(column => (
        <ELSTableCell
            key={column.field}
            align={column.align}
            cssModifier={classNames({
              'c-els-table__cell--sticky': column.sticky,
              'c-els-table--shadow-left': column.sticky && hasScrollLeft,
              'u-els-background-color-n0': column.highlight || isDisplayChildRow,
              'c-els-table__cell--nowrap': column.noWrap,
              'c-els-table__cell--expanded': (expandedKeys && expandedKeys.includes(row[rowKey])) || alwaysExpandedTable
            })}
            inlineStyle={column.sticky ? { zIndex: STICKY_CELL_Z_INDEX.DATA_CELL } : {}}
          >
          <div className="c-els-table__cell-header" style={{ width: labelWidthForList }}>
            {column.header}
          </div>
          <div className="c-els-table__cell-content">
            {column.customRender ? column.customRender(row, column) : row[column.field]}
            {(!isEmpty(row[subRowsFieldName]) || expandableRenderer) && column.field === positionExpandBtn && !alwaysExpandedTable && renderExpandButton(props, row[rowKey])}
          </div>
        </ELSTableCell>
      ))}
    </ELSTableRow>
  );
};

const renderExpandableRowData = (props: ExpandableTableBodyProps, row) => {
  const { rowKey, columns, positionExpandBtn, alwaysExpandedTable, expandableRenderer } = props;

  return (
    <ELSTableRow key={`row-${row[rowKey]}-expanded`}>
      {columns.map(column => {
        const isColumnFieldExpanded = column.field === positionExpandBtn;
        return (
          <ELSTableCell
            key={column.field}
            cssModifier={classNames({
              'c-els-table__cell--field-expanded': isColumnFieldExpanded || alwaysExpandedTable,
              'u-els-display-none': !isColumnFieldExpanded
            })}
            ariaProps={isColumnFieldExpanded || alwaysExpandedTable ? { colSpan: columns.length } : {}}
          >
            <div className="c-els-table__cell-header u-els-display-block@tablet u-els-display-block@mobile u-tsp-visibility--hidden">{column.header}</div>
            <div className="c-els-table__cell-content">{isColumnFieldExpanded || alwaysExpandedTable ? expandableRenderer(row) : ''}</div>
          </ELSTableCell>
        );
      })}
    </ELSTableRow>
  );
};

const renderSubRows = (props: ExpandableTableBodyProps, subRowsData: object[]) => subRowsData.map(subRow => renderRowData(props, subRow, true));

const ExpandableTableBody = (props: ExpandableTableBodyProps) => {
  const { data, rowKey, expandedKeys, subRowsFieldName, alwaysExpandedTable, expandableRenderer } = props;
  const isDisplaySubRows = row => (expandedKeys && expandedKeys.includes(row[rowKey])) || alwaysExpandedTable;
  return (
    <>
      {data.map(row => (
        <React.Fragment key={`row-${row[rowKey]}`}>
          {renderRowData(props, row, false)}
          {expandableRenderer && isDisplaySubRows(row) && renderExpandableRowData(props, row)}
          {!expandableRenderer && isDisplaySubRows(row) && row[subRowsFieldName] && renderSubRows(props, row[subRowsFieldName])}
        </React.Fragment>
      ))}
    </>
  );
};

export default ExpandableTableBody;
