import { findIndex, cloneDeep, isNil } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CohortModel } from '../../models/opportunity.model';
import { APIError } from '../../models/api.model';
import { CohortProductModel, ProductLMSConfigModel, ProductSuiteModel } from '../../models/cohort.models';
import { AppConstant } from '../../constants/app.constant';
import { prepareProductCollection, prepareMasterProductCollection } from '../../utilities/evolve/evolve.utility';
import { generateOrderItemIdByProduct } from '../../utilities/product-suite/product-suite.utility';

export const REDUCER_ID = AppConstant.redux.COHORT_STATE;

type AddProductModel = {
  products: CohortProductModel | CohortProductModel[];
  removedProductId?: string | number;
  isContainingDefaultItemsOnly?: boolean;
};
const initialState = {
  Cohort: null,
  ProductSuite: null,
  SelectedProducts: [],
  SelectedProductCount: 0,
  LMSConfiguration: null,
  Error: null
};

const fetchAPIError = (state, action: PayloadAction<APIError>) => ({ ...state, Error: action.payload });

export const cohortSlice = createSlice({
  name: 'cohort',
  initialState,
  reducers: {
    resetCohort(state) {
      return {
        ...state,
        Cohort: null,
        ProductSuite: null,
        SelectedProducts: [],
        SelectedProductCount: 0,
        Error: null
      };
    },
    resetProductSuite(state) {
      return {
        ...state,
        ProductSuite: null,
        Error: null
      };
    },
    emptySelectedProducts(state) {
      return {
        ...state,
        SelectedProducts: [],
        SelectedProductCount: 0,
        Error: null
      };
    },
    fetchCohortSuccess(state, action: PayloadAction<CohortModel>) {
      return {
        ...state,
        Cohort: action.payload,
        Error: null
      };
    },
    fetchCohortFailed(state, action: PayloadAction<APIError>) {
      return fetchAPIError(state, action);
    },
    setCohort(state, action: PayloadAction<CohortModel>) {
      return {
        ...state,
        Cohort: action.payload
      };
    },
    setCurrentCohortCount(state, action: PayloadAction<number>) {
      return {
        ...state,
        Cohort: {
          ...state.Cohort,
          CurrentCohortCount: action.payload
        }
      };
    },
    updateCohort(state, action: PayloadAction<object>) {
      return {
        ...state,
        Cohort: {
          ...state.Cohort,
          ...action.payload
        }
      };
    },
    fetchProductSuiteSuccess(state, action: PayloadAction<ProductSuiteModel>) {
      return {
        ...state,
        ProductSuite: action.payload,
        Error: null
      };
    },
    fetchProductSuiteError(state, action: PayloadAction<APIError>) {
      return fetchAPIError(state, action);
    },
    updateProductFromProductSuite(state, action: PayloadAction<AddProductModel>) {
      const products = [...state.ProductSuite.Products];
      const evlOrderId = products.length > 0 ? products[0].evlOrderId : '';
      let totalCount = state.ProductSuite.TotalCount;
      if (action.payload.removedProductId) {
        const index = findIndex(products, { orderItemId: action.payload.removedProductId });
        if (index !== -1) {
          products.splice(index, 1);
          totalCount = totalCount > 0 ? totalCount - 1 : totalCount;
        }
      }
      const newProducts: CohortProductModel[] = Array.isArray(action.payload.products) ? action.payload.products : [action.payload.products];
      const { length } = newProducts;
      for (let i = 0; i < length; i += 1) {
        const p = newProducts[i];
        const index = findIndex(products, { orderItemId: p.orderItemId });
        if (index === -1) {
          products.push({ ...p, evlOrderId: p.evlOrderId || evlOrderId });
          totalCount += 1;
        } else {
          products.splice(index, 1, p);
        }
      }

      const { isContainingDefaultItemsOnly } = action.payload;

      return {
        ...state,
        ProductSuite: {
          ...state.ProductSuite,
          Products: products,
          TotalCount: totalCount,
          isContainingDefaultItemsOnly: !isNil(isContainingDefaultItemsOnly) ? isContainingDefaultItemsOnly : state.ProductSuite.isContainingDefaultItemsOnly
        },
        Error: null
      };
    },
    removeListProductFromProductSuite(state, action: PayloadAction<AddProductModel>) {
      const products = [...state.ProductSuite.Products];
      let totalCount = state.ProductSuite.TotalCount;
      const { isContainingDefaultItemsOnly } = action.payload;
      const removeProducts: CohortProductModel[] = Array.isArray(action.payload.products) ? action.payload.products : [action.payload.products];
      const { length } = removeProducts;
      for (let i = 0; i < length; i += 1) {
        const product = removeProducts[i];
        const index = findIndex(products, { orderItemId: product.orderItemId });
        if (index !== -1) {
          products.splice(index, 1);
          totalCount = totalCount > 0 ? totalCount - 1 : totalCount;
        }
      }
      return {
        ...state,
        ProductSuite: {
          ...state.ProductSuite,
          Products: products,
          TotalCount: totalCount,
          isContainingDefaultItemsOnly: !isNil(isContainingDefaultItemsOnly) ? isContainingDefaultItemsOnly : state.ProductSuite.isContainingDefaultItemsOnly
        }
      };
    },
    removeProductFromProductSuite(state, action: PayloadAction<string | number>) {
      const products = [...state.ProductSuite.Products];
      const index = findIndex(products, { orderItemId: action.payload });
      if (index !== -1) {
        products[index] = {
          ...products[index],
          quantity: 0
        };
      }
      return {
        ...state,
        ProductSuite: {
          ...state.ProductSuite,
          Products: products
        }
      };
    },
    addSelectedProducts(state, action: PayloadAction<AddProductModel>) {
      const products = [...state.SelectedProducts];
      if (action.payload.removedProductId) {
        const index = findIndex(products, { orderItemId: action.payload.removedProductId });
        if (index !== -1) {
          products.splice(index, 1);
        }
      }

      let newProducts: CohortProductModel[] = [];
      if (Array.isArray(action.payload.products)) {
        newProducts = action.payload.products.map(product => generateOrderItemIdByProduct(product));
      } else {
        newProducts = [generateOrderItemIdByProduct(action.payload.products)];
      }

      if (newProducts.length > 1) {
        // sherpath product
        const configuration = cloneDeep(newProducts[0].configuration);
        delete configuration.courseSelectionOrderItemId;
        newProducts[1].configuration = {
          ...configuration,
          ...prepareProductCollection(newProducts[0].resourceId, newProducts[1].resourceId)
        };

        // simNG product
        newProducts[0].configuration = {
          ...configuration,
          ...prepareMasterProductCollection(newProducts[0].resourceId, newProducts[1].resourceId, newProducts[1].orderItemId)
        };
      }
      const { length } = newProducts;
      for (let i = 0; i < length; i += 1) {
        const product = newProducts[i];
        const index = findIndex(products, { orderItemId: product.orderItemId });
        if (index === -1) {
          products.push(product);
        } else {
          products.splice(index, 1, product);
        }
      }

      return {
        ...state,
        SelectedProducts: products,
        SelectedProductCount: products.length,
        Error: null
      };
    },
    removeSelectedProduct(state, action: PayloadAction<string | number>) {
      const products = [...state.SelectedProducts];
      const index = findIndex(state.SelectedProducts, { orderItemId: action.payload });
      if (index !== -1) {
        products.splice(index, 1);
      }
      return {
        ...state,
        SelectedProducts: products,
        SelectedProductCount: products.length,
        Error: null
      };
    },
    setLMSConfiguration(state, action: PayloadAction<ProductLMSConfigModel>) {
      return {
        ...state,
        LMSConfiguration: action.payload
      };
    },
    resetLMSConfiguration(state) {
      return {
        ...state,
        LMSConfiguration: null
      };
    },
  }
});

// Action creators are generated for each case reducer function
export const cohortActions = cohortSlice.actions;
export const cohortReducer = cohortSlice.reducer;
