export const EXAM_TO_CART_POPUP_TYPE = {
  EXAM_PROGRAM_TYPE: 0,
  EXAM_ASSIGN_PERMISSION: 1,
  EXAM_SELECT_QUANTITY: 2
};

export const assessmentTypeFields = {
  assessmentTypeId: 'assessmentTypeId',
  assessmentTypeName: 'assessmentTypeName',
};

export const programTypeFields = {
  programTypeId: 'programTypeId',
  programTypeName: 'programTypeName'
};
export const PAYMENT_METHODS = {
  CREDIT_CARD: 'credit_card',
  BILL_MY_INSTITUTION: 'bill_my_institution',
  BILL_EACH_STUDENT: 'bill_each_student'
};

export const PAYMENT_METHOD_OPTIONS = [
  { name: 'Select a payment method', value: '' },
  { name: 'Credit card', value: PAYMENT_METHODS.CREDIT_CARD },
  { name: 'Bill my institution', value: PAYMENT_METHODS.BILL_MY_INSTITUTION },
  { name: 'Bill each student', value: PAYMENT_METHODS.BILL_EACH_STUDENT }
];

export const INPUT_QUANTITY_PATTERN = /^\d+(\.\d{0,2})?$/;

export const HESI_CART_TYPES = {
  CATALOG: 'CATALOG',
  PAYMENT: 'PAYMENT',
};

export const LIMIT_DISPLAY_FACULTIES = 4;

export const ORDER_CART_TABS = {
  REVIEW: 0,
  PAYMENT: 1,
  CONFIRM: 2
};

export const PHONE_ERROR_MSG = 'The phone number you entered is invalid.';
