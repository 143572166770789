/* eslint-disable */
/*
 * This opportunities api utility will be replace by real one whenever the TSP's backend APIs are available
 */

import { cloneDeep, orderBy } from 'lodash';
import { cohorts, opportunities, statuses } from '../../../dummy-data';
import { EVOLVE_INSTALLMENTS } from '../../../dummy-data/opportunities';
import { withPromiseDelay } from '../api.utility';
import { DropDownItem } from '../../../models/components.model';
import { Person } from '../../../models/common.models';
import { CohortModel, OpportunityModel } from '../../../models/opportunity.model';
import { EvolveInstallmentModel } from '../../../models/installment.model';
import { RosterModel } from '../../../models/roster.models';
import { HesiAPIResponseList, OpportunityRequestOptions } from '../../../models/api.model';
import { formatPersonName } from '../../customers/customers.utility';
import { EvolveAddressModel } from 'models/invoice.model';

// formatting should be done at front-end. However for now it is ok.
/* istanbul ignore next */
const getEducationConsultantOptions = (): DropDownItem[] => {
  const allSalePersons = opportunities.reduce((salePersons, opportunity) => {
    salePersons[opportunity.OpportunityOwner.Email] = opportunity.OpportunityOwner;
    return salePersons;
  }, {});
  const options = Object.values(allSalePersons).map((person: Person) => ({
    name: `${person.FirstName} ${person.LastName}`,
    value: person.Email
  }));
  options.unshift({
    name: 'All Consultants',
    value: ''
  });
  return options;
};
/* istanbul ignore next */
const getStatusOptions = () => statuses;
/* istanbul ignore next */
const getTerritoryCodeOptions = () => {
  const allTerritoryCodes = opportunities.reduce((territoryCodes, opportunity) => {
    territoryCodes[opportunity.SalesTerritoryID] = opportunity.SalesTerritoryID;
    return territoryCodes;
  }, {});
  const options = Object.values(allTerritoryCodes).map(territoryCode => ({ name: territoryCode, value: territoryCode }));
  options.unshift({
    name: 'All Territories',
    value: ''
  });
  return options;
};

/* istanbul ignore next */
export const getOpportunities = (options: OpportunityRequestOptions): Promise<HesiAPIResponseList<OpportunityModel[]>> => {
  let filteredOpportunities = opportunities;
  if (options.sortColumn && options.sortDirection) {
    if (options.sortColumn === 'OpportunityOwner') {
      filteredOpportunities = orderBy(filteredOpportunities, opportunity => formatPersonName(opportunity.OpportunityOwner), options.sortDirection);
    } else {
      filteredOpportunities = orderBy(filteredOpportunities, options.sortColumn, options.sortDirection);
    }
  }
  const startPos = (options.currentPage - 1) * options.resultsPerPage;
  const paginatedOpoortunities = filteredOpportunities.slice(startPos, startPos + options.resultsPerPage);
  const result = {
    Success: true,
    Response: {
      Opportunities: paginatedOpoortunities,
      TotalCount: filteredOpportunities.length
    }
  };
  return withPromiseDelay(0, cloneDeep(result));
};

/* istanbul ignore next */
export const getInternalOpportunity = opportunityID => {
  let opportunity = opportunities.find(c => c.OpportunityID == opportunityID);
  if (!opportunity) {
    // get first one as default
    opportunity = opportunities[0];
  }
  if (!opportunity.Cohorts) {
    opportunity.Cohorts = cohorts;
  }
  return opportunity;
};

/* istanbul ignore next */
export const getOpportunityById = opportunityID => {
  const Opportunity = getInternalOpportunity(opportunityID);
  const result = {
    Opportunity,
    facets: {
      filterByEducationConsultant: {
        name: 'Account Filter',
        options: getEducationConsultantOptions()
      },
      filterByEducationConsultant1: {
        name: 'Account Filter 2',
        options: getEducationConsultantOptions()
      },
      filterByStatus: {
        name: 'Status Filter',
        options: getStatusOptions()
      },
      filterByTerritoryCode: {
        name: 'Territory Filter',
        options: getTerritoryCodeOptions()
      }
    }
  };
  return withPromiseDelay<{
    Opportunity: OpportunityModel;
    facets: object[];
  }>(0, cloneDeep(result));
};

export const getCohortCountApproverByOpportunityId = (opportunityId: string) => withPromiseDelay<{
  opportunityId: string;
  cohortCountApprover: RosterModel;
  shippingAddress: EvolveAddressModel;
  billingAddress: EvolveAddressModel;
  poNumber: string;
  taxExemptFlag: boolean;
  taxRegistrationNumber?: string;
}>(0, {
  opportunityId, cohortCountApprover: {
    firstName: 'Staff',
    lastName: 'A',
    title: 'Dean of Nursing',
    userName: 'FacultyA12',
    email: 'FacultyA@paylor.edu',
    enrollmentRole: 'facuty'
  },
  shippingAddress: {
    address1: '1234 West St',
    city: 'Townville',
    address2: 'Suite 2A',
    state: 'PA',
    postalCode: '12345'
  },
  billingAddress: {
    address1: '1234 West St',
    city: 'Townville',
    address2: 'Suite 2A',
    state: 'PA',
    postalCode: '12345'
  },
  taxExemptFlag: true,
  taxRegistrationNumber: '123'
});

export const updateCohortCountApprover = (payload) => withPromiseDelay<{}>(0, {})

export const getCohorts = () => {
  return withPromiseDelay<{
    TotalCount: number,
    Cohorts: CohortModel[]
  }>(1000, { Cohorts: cloneDeep(cohorts), TotalCount: cohorts.length });
}

/* istanbul ignore next */
export const getCohortById = (opportunityID, cohortId) => {
  const opportunity = getInternalOpportunity(opportunityID);
  let cohort = cohorts.find(c => c.CohortID == cohortId) || cohorts[0];
  const result = {
    opportunity,
    cohort,
    facets: {
      cohortFilter1: {
        name: 'Cohort Filter',
        options: getEducationConsultantOptions()
      },
      cohortFilter2: {
        name: 'Cohort Filter 2',
        options: getEducationConsultantOptions()
      },
      filterByStatus: {
        name: 'Status Filter',
        options: getStatusOptions()
      },
      filterByTerritoryCode: {
        name: 'Territory Filter',
        options: getTerritoryCodeOptions()
      }
    }
  };
  return withPromiseDelay<{
    Opportunity: OpportunityModel;
    Cohort: CohortModel;
    facets: object[];
  }>(0, cloneDeep(result));
};

export const updateOpportunity = payload => {
  return withPromiseDelay<{
    ErrorMsg?: string,
    OpportunityID: string,
    KeyDecisionMakers: string,
    PORequired?: boolean
  }>(1000, {
    ...payload
  });
};

export const getUnprocessedInstallments = async (opportunityId, pagingOptions) => {
  const sortedInstallments = orderBy(EVOLVE_INSTALLMENTS, pagingOptions.sortColumn, pagingOptions.sortDirection);
  const start = (pagingOptions.currentPage - 1) * pagingOptions.resultsPerPage;
  const end = Math.min(sortedInstallments.length, start + pagingOptions.resultsPerPage);
  const data = sortedInstallments.slice(start, end);
  return withPromiseDelay<{
    errors?: any;
    installments: EvolveInstallmentModel[];
    startIndex: number;
    totalCount: number;
    size: number;
  }>(1000, {
    installments: data,
    totalCount: EVOLVE_INSTALLMENTS.length
  });
};

export const updateCohort = payload =>
  withPromiseDelay<{
    Success: boolean;
    Response: {
      CohortID?: string | number;
      Status?: string[];
      LastModified?: Date;
      ErrorMsg?: string;
    };
  }>(1000, {
    Success: true,
    Response: { ...payload, LastModified: Date.now() }
  });

export const updateRequiresPONumber = (payload) => {
  return withPromiseDelay<{
    Success: boolean;
    Response: {
      ErrorMsg?: string;
      OpportunityID: string;
      RequiresPONumber: boolean;
    }
  }>(1000, {
    Success: true,
    Response: {
      ...payload
    }
  });
};
