import { RevenueAllocationStatusModel } from 'models/billing-queue.models';
import { VALIDATE_RESPONSE_ERROR_TYPE } from '../../constants/app.constant';
import { EvolveInstallmentReviewModel, InstallmentStatusModel } from '../../models/installment.model';
import { transformToCohortPackage } from '../../utilities/app/app.utility';
import { tspServiceApi } from '../../helpers/RestClient.helper';

export const fetchInstallmentStatusHistory = (installmentId: string | number) => tspServiceApi.get(`/invoicing/installment/${installmentId}/installmentStatusHistory`);

export const fetchRevenueAllocationStatusHistory = (installmentId: string | number) =>
  tspServiceApi.get(`/invoicing/installment/${installmentId}/getRevenueAllocationStatusHistory`) as
  Promise<{ installmentId: string | number, revenueAllocationStatusHistory: RevenueAllocationStatusModel[] }>;

export const fetchTspInstallmentForApproval = async (installmentId: string, activityId: string): Promise<EvolveInstallmentReviewModel> => {
  const options = { params: { activityId } };
  const response: any = await tspServiceApi.get(`/approval/invoicing/installment/${installmentId}`, options, VALIDATE_RESPONSE_ERROR_TYPE.REVIEW_APPROVAL);
  return Promise.resolve({
    InstallmentId: response.installmentId,
    InstallmentName: response.installmentName,
    InstallmentNumber: response.installmentNumber,
    InstallmentStatus: response.installmentStatus,
    CostPerStudent: response.costPerStudent,
    CohortID: response.cohortId,
    CohortName: response.cohortName,
    CohortCount: response.quantity,
    Program: response.cohortProgram,
    Package: transformToCohortPackage(response.package),
    StartDate: response.startDate,
    EndDate: response.endDate,
    CohortCountApprover: response.cohortCountApprover,
    OpportunityID: response.opportunityId,
    OpportunityName: response.opportunityName,
    AccountName: response.accountName
  });
};

export const approveInstallment = (installmentId: string, notes: string) =>
  tspServiceApi.post(`/approval/invoicing/installment/${installmentId}/approve`, {
    data: {
      installmentId,
      notes
    }
  }, VALIDATE_RESPONSE_ERROR_TYPE.REVIEW_APPROVAL);

export const rejectInstallment = (installmentId: string, notes: string) =>
  tspServiceApi.post(`/approval/invoicing/installment/${installmentId}/reject`, {
    data: {
      installmentId,
      notes
    }
  }, VALIDATE_RESPONSE_ERROR_TYPE.REVIEW_APPROVAL);

export const resendCohortCountApprovalRequest = async (faculties: string[], opportunityId: string | number, cohortId: string | number, installmentId: string) => {
  const data = {
    faculties,
    opportunityId,
    cohortId,
    installmentId
  };

  return tspServiceApi.post(`/invoicing/installment/${installmentId}/resendCohortCntAprvlRequest`, { data }) as Promise<InstallmentStatusModel>;
};

export const undoCohortCountApproval = async (opportunityId: string | number, cohortId: string | number, installmentId: string, comment: string) => {
  const data = {
    opportunityId,
    cohortId,
    installmentId,
    comment
  };

  return tspServiceApi.post(`/invoicing/installment/${installmentId}/undoCohortCntAprvlRequest`, { data }) as Promise<InstallmentStatusModel>;
};

export const markCohortCountAsApproved = (opportunityId: string | number, cohortId: string | number, installmentId: string) => {
  const data = {
    opportunityId,
    cohortId,
    installmentId
  };

  return tspServiceApi.post(`/invoicing/installment/${installmentId}/markAsApproved`, { data }) as Promise<InstallmentStatusModel>;
};
