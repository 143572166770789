import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ELSPendoService } from '@els/els-ui-common-react';
import { getProperty } from 'config/app.config';
import { securitySelectors } from 'redux/security';
import { adobeAnalyticsServices } from 'services';
import { appSelectors } from 'redux/app';

const AdobeAnalytics = () => {
  const user = useSelector(securitySelectors.getEvolveUser);
  const isLoading = useSelector(appSelectors.isLoading);
  const [isRegisterAdobe, setIsRegisterAdobe] = useState(false);

  const { pendo } = (window as any);

  const registerAA = () => {
    adobeAnalyticsServices.registerAdobeAnalytics(user);
    const pendoInstalled = pendo && pendo.isReady ? pendo.isReady() : false;
    if (!pendoInstalled) {
      const pendoApiKey = getProperty('pendoApiKey');
      ELSPendoService.start(pendoApiKey);
    }
    setIsRegisterAdobe(true);
  };

  useEffect(() => {
    if (!isLoading && !isRegisterAdobe) {
      registerAA();
    }
  }, [isLoading]);

  return <></>;
};

export default AdobeAnalytics;
