import { ELSTokenHelper } from '@els/els-ui-common-react';
import { EvolveUserModel } from 'models/evolve.models';
import { gatewayServiceApi } from '../../helpers/RestClient.helper';

export const evolveLoginService = userInfo =>
  gatewayServiceApi.post('/login-service/evolvePortalLogin', {
    data: userInfo
  });

export const evolveLogoutService = (token, sessionId) =>
  gatewayServiceApi.delete(`/api/evolveUser/session/${sessionId}`, {
    headers: {
      Authorization: `Bearer ${ELSTokenHelper.getToken() || token}`
    }
  });

export const getUser = (token, sessionId) =>
  gatewayServiceApi.get('/api/evolveUser?role=student&fields=addresses', {
    headers: {
      'evl-session-id': sessionId
    }
  });

type GeneratingJwtTokenBasedOnEvolveSessionIdAPIResponse = Promise<{ token: string }>;
export const generatingJwtTokenBasedOnEvolveSessionId = (skipAuthorization = false) =>
  gatewayServiceApi.post('/login-service/evolve/token', { skipAuthorization }) as GeneratingJwtTokenBasedOnEvolveSessionIdAPIResponse;

type GetAnonymousTokenAPIResponse = Promise<{ token: { token: string } }>;
export const getAnonymousToken = () => gatewayServiceApi.get('/login-service/anonymousUserLogin') as GetAnonymousTokenAPIResponse;

type SingleSignOnAPIResponse = Promise<{
  acceptedLatestAgreement: boolean;
  existingUser: EvolveUserModel;
  sessionId: string;
  token: {
    token: string;
    expireDate: number;
  }
}>;
export const singleSignOn = () => gatewayServiceApi.post('/api/evolveUser/sso') as SingleSignOnAPIResponse;
