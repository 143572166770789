import { gatewayServiceApi } from '../../helpers/RestClient.helper';
import { getFeatureFlag } from '../../config/app.config';
import { SUPPORT_EMAIL_URL } from '../../constants/footer.constants';

export const getContactUrl = (data) => {
  if (getFeatureFlag('authenticationDisabled') || !data) {
    return Promise.resolve(SUPPORT_EMAIL_URL);
  }
  return gatewayServiceApi.post('/api/v1/supportTickets', { data }) as Promise<string>;
};
