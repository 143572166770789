import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';
import Logo from '../common/logo/Logo.component';
import { NavigationConfig } from '../../models/navigation.models';
import { securitySelectors } from '../../redux/security';
import SideDrawer from '../navigation/side-drawer/SideDrawer.component';
import TopNav from '../navigation/top-nav/TopNav.component';
import AccessControl from '../common/access-control/AccessControl.component';
import { AccessControlType, ReadOnlyViewRoles } from '../../constants/app.constant';

export interface HeaderProps {
  navConfig: NavigationConfig;
}
const Header = (props: HeaderProps) => {
  const isLoggedIn = useSelector(securitySelectors.isLoggedIn);

  return (
    <header
      className="o-els-flex-layout
                 o-els-flex-layout--space-between
                 o-els-flex-layout--middle
                 c-els-header c-els-header__container
                 u-els-margin-top-1o2@mobile
                 u-els-margin-bottom-1o2@mobile
                 c-tsp-header">
      <ol className="c-els-skip-link-group">
        <li>
          <HashLink to="#main" className="c-els-skip-link-group__link">Skip to the main content</HashLink>
        </li>
      </ol>
      <div className="o-els-flex-layout o-els-flex-layout--middle">
        <Logo />
        <AccessControl isOnlyRoles roles={ReadOnlyViewRoles} actionType={AccessControlType.Show} ignoreValidateResource>
          <div className="u-els-margin-left-2x u-els-font-family-bold">Read-only Access</div>
        </AccessControl>
      </div>
      <div className="o-els-flex-layout o-els-flex-layout--middle">
        <TopNav navConfig={props.navConfig} />
        {isLoggedIn && <SideDrawer navConfig={props.navConfig} />}
      </div>
    </header>
  );
};

export default Header;
