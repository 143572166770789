import { isResourceCompleted } from 'utilities/app/app.utility';
import { REDUCER_ID } from './cohort.reducer';

export const getCohort = (globalState) => globalState[REDUCER_ID].Cohort;
export const getProductSuite = (globalState) => globalState[REDUCER_ID].ProductSuite;
export const getSelectedProducts = (globalState) => globalState[REDUCER_ID].SelectedProducts;
export const getSelectedProductCount = (globalState) => globalState[REDUCER_ID].SelectedProductCount;
export const getCohortError = (globalState) => globalState[REDUCER_ID].Error;
export const getLMSConfiguration = (globalState) => globalState[REDUCER_ID].LMSConfiguration;
export const isCohortCompleted = (globalState) => {
  const cohort = globalState[REDUCER_ID] && globalState[REDUCER_ID].Cohort;
  if (!cohort || !cohort.EndDate) {
    return false;
  }
  return isResourceCompleted(cohort.EndDate);
};
