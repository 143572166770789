import { cloneDeep, findIndex, isEmpty, get } from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import { getComboCourseTypes, getLMSEvolveConfiguration } from 'config/app.config';
import { DATE_FORMAT_MMDDYYYY, LMS_PLAFTFORMS, LMS_VALIDATION_SCHEMA, SHADOW_HEALTH_VALIDATION_SCHEMA, UNDER_PLATFORM_COMBO_COURSE } from '../../constants/app.constant';
import {
  getEolveProductPlatformsCached,
  getPlatformDataFromSelectedPlatform,
  getProductLMSPlatforms,
  isExistingCourseType,
  isSimNGCourseBasedProduct,
  isShadowHealthOrCPRAPProductDirectAccessWithoutExistingCourse,
  isCourseHostedOnLOLMS,
  isShadowHealthOrCPRAProduct
} from '../evolve/evolve.utility';
import { formatDateWithoutTime } from '../../utilities/app/app.utility';
import { CohortProductModel, ProductLMSConfigModel } from '../../models/cohort.models';
import { StudentGroupModel } from '../../models/roster.models';
import { FulfillmentStatusType, PLATFORM_COURSE_TYPE, PRODUCT_GROUPS } from '../../constants/product.constant';

const resetCourseConfiguration = (productLMSConfig, LMSEvolveConfiguration) => {
  const lmsConfiguration = {
    ...productLMSConfig
  };
  const isResetCoursesConfig = productLMSConfig.selectedCourseTools !== LMSEvolveConfiguration.SelectCourseTools.NoLmsUniqueCourseTools ||
    isExistingCourseType(productLMSConfig.platformCourseType);
  if (!isResetCoursesConfig) {
    return lmsConfiguration;
  }
  if (!isEmpty(productLMSConfig.courses) && productLMSConfig.instanceCount) {
    lmsConfiguration.instanceCount = 1;
  }
  lmsConfiguration.courses = null;

  return lmsConfiguration;
};

export const coreceLMSConfigData = (LMSEvolveConfiguration, productLMSPlatforms, productLMSConfig): ProductLMSConfigModel => {
  const obj = resetCourseConfiguration(productLMSConfig, LMSEvolveConfiguration);
  if (productLMSConfig.selectedCourseTools === LMSEvolveConfiguration.SelectCourseTools.ChooseLMS) {
    obj.platformId = productLMSConfig.selectedLMSPlatform;
    obj.platformName = productLMSConfig.selectedLMSPlatformName;
  } else if (productLMSConfig.tspConfig && productLMSConfig.tspConfig.ignoreFulfillment) {
    obj.platformId = '';
    obj.platformName = '';
  } else {
    const platformData = getPlatformDataFromSelectedPlatform(productLMSConfig.selectedCourseTools, productLMSPlatforms);
    obj.platformId = platformData && platformData.resourceId;
    obj.platformName = platformData && platformData.name;
  }
  obj.courseFormat = obj.platformId;
  obj.courseFormatName = obj.platformName;
  if (obj.platformCourseType === 'combo_course' && obj.platformComboCourseIsbn) {
    obj.platformCourseType = 'sub_combo_course';
  }
  delete obj.platformComboCourseType;
  obj.courseStartDate = (obj.courseStartDate) ? formatDateWithoutTime(obj.courseStartDate, DATE_FORMAT_MMDDYYYY) : obj.courseStartDate;
  obj.courseEndDate = (obj.courseEndDate) ? formatDateWithoutTime(obj.courseEndDate, DATE_FORMAT_MMDDYYYY) : obj.courseEndDate;
  obj.enrollmentStartDate = (obj.enrollmentStartDate) ? formatDateWithoutTime(obj.enrollmentStartDate, DATE_FORMAT_MMDDYYYY) : obj.enrollmentStartDate;
  return obj;
};

const parseYupErrorObject = (yupError) => {
  const errors = {};
  if (yupError.errors.length === 0 && yupError.inner.length === 0) {
    return errors;
  }
  if (yupError.inner.length === 0) {
    errors[yupError.params.path] = yupError.message;
    return errors;
  }
  const fieldNames = [];
  const { length } = yupError.inner;
  for (let i = 0; i < length; i += 1) {
    const errorItem = yupError.inner[i];
    if (!fieldNames.includes(errorItem.path)) {
      errors[errorItem.path] = errorItem.message;
      fieldNames.push(errorItem.path);
    }
  }
  return errors;
};

export const validateLMSConfiguration = (data, fields, abortEarly = true) => {
  const reversedFields = fields.reverse();
  const validatedFields = {};
  const { length } = reversedFields;
  for (let i = 0; i < length; i += 1) {
    if (LMS_VALIDATION_SCHEMA[reversedFields[i]]) {
      validatedFields[reversedFields[i]] = LMS_VALIDATION_SCHEMA[reversedFields[i]];
    }
  }
  if (Object.keys(validatedFields).length === 0) {
    return null;
  }
  const minDate = moment().startOf('day');
  const context: any = {
    courseStartDate: minDate,
    enrollmentStartDate: (data.courseStartDate && data.courseStartDate > minDate) ? data.courseStartDate : minDate
  };
  let minEnrollmentStartDate = minDate;
  if (data.courseStartDate || data.enrollmentStartDate) {
    minEnrollmentStartDate = (data.enrollmentStartDate) ? data.enrollmentStartDate : data.courseStartDate;
  }
  context.courseEndDate = moment(minEnrollmentStartDate).add(1, 'days').toDate();
  const schema = yup.object().shape(validatedFields);
  try {
    schema.validateSync(data, { context, abortEarly });
  } catch (yupErr) {
    return parseYupErrorObject(yupErr);
  }
  return null;
};

export const validateShadowHealthForm = (data, fields) => {
  const reversedFields = fields.reverse();
  const validatedFields = {};
  const { length } = reversedFields;
  for (let i = 0; i < length; i += 1) {
    if (SHADOW_HEALTH_VALIDATION_SCHEMA[reversedFields[i]]) {
      validatedFields[reversedFields[i]] = SHADOW_HEALTH_VALIDATION_SCHEMA[reversedFields[i]];
    }
  }
  const minDate = moment().startOf('day');
  const context = { enrollmentStartDate: minDate };
  const schema = yup.object().shape(validatedFields);
  try {
    schema.validateSync(data, { context, abortEarly: false });
  } catch (yupErr) {
    return parseYupErrorObject(yupErr);
  }
  return null;
};

export const mergeErrorMessages = (currentValidationErrors, newErrors, validationFields) => {
  const currentErrors = {
    ...currentValidationErrors
  };
  const { length } = validationFields;
  const newErrorFields = (newErrors) ? Object.keys(newErrors) : [];
  for (let i = 0; i < length; i += 1) {
    if (!newErrorFields.includes(validationFields[i])) {
      delete currentErrors[validationFields[i]];
    }
  }
  const errorObj = {
    ...currentErrors,
    ...newErrors
  };
  return (Object.keys(errorObj).length === 0) ? null : errorObj;
};

export const isEntitled = (product: CohortProductModel) => product.isEntitled;

export const isProductStartedFulFillment = (product: CohortProductModel) =>
  !!product.adoptionId ||
  (!product.adoptionId && !!product.status && product.status.toLocaleUpperCase() !== FulfillmentStatusType.PENDING) ||
  product.isFulfilled ||
  isEntitled(product);

export const isIgnoreFulfillment = product =>
  product.configuration &&
  product.configuration.tspConfig &&
  product.configuration.tspConfig.ignoreFulfillment;

export const isSubComboCourse = (product: CohortProductModel) =>
  product && product.configuration && product.configuration.platformCourseType === PLATFORM_COURSE_TYPE.SUB_COMBO_COURSE;

export const isSherpathNSSComponent = (product: CohortProductModel) =>
  product && product.configuration && product.configuration.isSherpathNSSComponent;

export const isSherpathHasNSSComponents = (product: CohortProductModel) =>
  product && product.configuration && product.configuration.isSherpathHasNSSComponents;

export const isValidComboProduct = (product, item) => {
  const { configuration: { platformComboOrderItemId, platformComboCourseIsbn }, term } = item;
  if (platformComboOrderItemId) {
    return product.orderItemId === platformComboOrderItemId;
  }

  return product.resourceId === platformComboCourseIsbn && product.term === term;
};

export const getComboProduct = (products, item) => {
  if (item.configuration && item.configuration.platformComboCourseIsbn && !isSubComboCourse(item)) {
    return null;
  }
  return products.find(pro => pro.configuration && item.configuration && isValidComboProduct(pro, item));
};

export const isRenderNumberOfCourses = (product: CohortProductModel) => {
  const configuration = (product && product.configuration) || {};
  const { productType, hostedLMS } = product;
  const { platformId, platformCourseType } = configuration;
  const shadowHealthOrCPRAProductDirectAccessWithoutExistingCourses =
    isShadowHealthOrCPRAPProductDirectAccessWithoutExistingCourse(productType, platformId, platformCourseType);
  const isLOorLOX2OptionSelected = (platformId === LMS_PLAFTFORMS.EVOLVE_UNIQUE && isCourseHostedOnLOLMS(hostedLMS)) || platformId === LMS_PLAFTFORMS.LOX2;
  return (shadowHealthOrCPRAProductDirectAccessWithoutExistingCourses || (!isShadowHealthOrCPRAProduct(productType) && isLOorLOX2OptionSelected));
};

export const isSimNgProductWithAssociation = (product: CohortProductModel) => !!product.isSimNgProductWithAssociation;

export const isComponentProduct = (product: CohortProductModel) => product.isSubComboCourse || isSimNgProductWithAssociation(product) ||
  isSherpathNSSComponent(product);

export const clearExpandFields = (products) => {
  const productList = [];
  products.forEach(pro => {
    const product = cloneDeep(pro);
    delete product.hasChildProduct;
    delete product.isSubComboCourse;
    delete product.isSimNgProduct;
    delete product.isSimNgProductWithAssociation;
    delete product.parentOrderItemId;
    productList.push(product);
  });
  return productList;
};

const getProductStudentGroups = (product: CohortProductModel): StudentGroupModel[] => {
  const studentGroups = (product.configuration && product.configuration.tspConfig && product.configuration.tspConfig.studentGroups) || [];
  if ((isEntitled(product) || product.hasChildProductEntitled) && studentGroups.length === 0) {
    return [{ ...PRODUCT_GROUPS.ALL_STUDENTS, groupName: PRODUCT_GROUPS.ALL_STUDENTS.name }];
  }
  return studentGroups;
};

export const isProductContainsGroup = (product: CohortProductModel, tspGroupId: number): boolean => {
  const studentGroups = getProductStudentGroups(product);
  return !!studentGroups.find(group => group.tspGroupId === tspGroupId);
};

const isContainStudentGroupFilter = (product, studentGroupsFilter) => {
  const isContainStudentGroupFiltersInStudentGroups = studentGroupsFilter.some(groupId => isProductContainsGroup(product, groupId));

  if (isContainStudentGroupFiltersInStudentGroups || !studentGroupsFilter.includes(PRODUCT_GROUPS.NO_STUDENTS.tspGroupId)) {
    return isContainStudentGroupFiltersInStudentGroups;
  }

  const studentGroups = getProductStudentGroups(product);
  return studentGroups && studentGroups.length === 0;
};

/**
 * check list products is valid by filter condition
 *  - For filter by studentGroups: should check parent instead of check all products because the config's studentGroups of component products get from parent product
 * @param products include sherpath + NSS component or sherpath + simchart or combo + subcombo
 * @param filterCondition productType or term or studentGroups
 * @returns boolean
 */
export const validateProducts = (products, filterCondition) => {
  const { term, productType, studentGroups } = filterCondition;
  const termFilter = term.map(item => Number(item));
  const studentGroupsFilter = studentGroups.map(item => Number(item));
  return !!products.find(p => ((termFilter && termFilter.length === 0) || (termFilter && termFilter.includes(p.term))) &&
    ((productType && productType.length === 0) || (productType && productType.includes(p.productType))) &&
    ((studentGroupsFilter && studentGroupsFilter.length === 0) ||
      (studentGroupsFilter && !isComponentProduct(p) && isContainStudentGroupFilter(p, studentGroupsFilter))));
};

export const isValidSherpathCollection = (product, item) => {
  const courseSelectionOrderItemId = get(item, 'configuration.courseSelectionOrderItemId');
  if (courseSelectionOrderItemId) {
    return product.orderItemId === courseSelectionOrderItemId;
  }

  const courseSelection = get(item, 'configuration.courseSelection');
  const courses = (!isEmpty(courseSelection) && courseSelection[0].course1.split(',')) || [];
  const sherpathCollectionIsbn = courses[courses.length - 1];

  return product.resourceId === sherpathCollectionIsbn && product.term === item.term;
};

export const getSherpathParentNSSComponent = (products, currentProduct) =>
  products.find(pro => currentProduct.configuration && currentProduct.configuration.courseSelectionOrderItemId === pro.orderItemId);

const findCompCoursesAndNonAssociateSimNgProducts = (products) => {
  const componentCourseMap = {};
  const nonAssociatedSimNgProduct = [];
  products.forEach((product: CohortProductModel) => {
    const productClone = cloneDeep(product);
    if (isSubComboCourse(product)) {
      const comboCourse = getComboProduct(products, product);
      const comboCourseOrderItemId = comboCourse ? comboCourse.orderItemId : '';
      productClone.isSubComboCourse = true;
      productClone.parentOrderItemId = comboCourseOrderItemId;
      productClone.hasChildProduct = false;
      const subComboCourseMap = componentCourseMap[comboCourseOrderItemId] || [];
      subComboCourseMap.push(productClone);
      componentCourseMap[comboCourseOrderItemId] = subComboCourseMap;
    } else if (isSimNGCourseBasedProduct(product.productType)) {
      const sherpathCollection = products.find(pro => isValidSherpathCollection(pro, product));
      if (sherpathCollection) {
        const sherpathCollectionOrderItemId = sherpathCollection.orderItemId || '';
        productClone.isSimNgProduct = true;
        productClone.isSimNgProductWithAssociation = true;
        productClone.parentOrderItemId = sherpathCollectionOrderItemId;
        productClone.hasChildProduct = false;
        const sherpathCourseMap = componentCourseMap[sherpathCollectionOrderItemId] || [];
        sherpathCourseMap.push(productClone);
        componentCourseMap[sherpathCollectionOrderItemId] = sherpathCourseMap;
      } else {
        nonAssociatedSimNgProduct.push(product.orderItemId);
      }
    } else if (isSherpathNSSComponent(product)) {
      const sherpathCollection = getSherpathParentNSSComponent(products, product);
      if (sherpathCollection) {
        const sherpathCollectionOrderItemId = sherpathCollection.orderItemId || '';
        productClone.isSherpathNSSComponent = true;
        productClone.parentOrderItemId = sherpathCollectionOrderItemId;
        productClone.hasChildProduct = false;
        const sherpathCourseMap = componentCourseMap[sherpathCollectionOrderItemId] || [];
        sherpathCourseMap.push(productClone);
        componentCourseMap[sherpathCollectionOrderItemId] = sherpathCourseMap;
      }
    }
  });

  return { componentCourseMap, nonAssociatedSimNgProduct };
};

export const isArrNotEmpty = (arr) => Array.isArray(arr) && arr.length > 0;

const buildListProductInOrderToDislay = (products, filtersCondition) => {
  const modifiedProducts = [];
  const { componentCourseMap, nonAssociatedSimNgProduct } = findCompCoursesAndNonAssociateSimNgProducts(products);

  products.forEach((product: CohortProductModel) => {
    const productClone = cloneDeep(product);
    productClone.isSubComboCourse = isSubComboCourse(productClone);
    productClone.isSimNgProduct = isSimNGCourseBasedProduct(productClone.productType);
    productClone.isSimNgProductWithAssociation = productClone.isSimNgProduct && nonAssociatedSimNgProduct.indexOf(productClone.orderItemId) === -1;

    if (!isComponentProduct(productClone)) {
      const childProducts = componentCourseMap[product.orderItemId] || [];
      productClone.hasChildProductEntitled = !!childProducts.find(p => isEntitled(p));
      const hasFilters = filtersCondition && (isArrNotEmpty(filtersCondition.term) || isArrNotEmpty(filtersCondition.productType) || isArrNotEmpty(filtersCondition.studentGroups));
      if (!hasFilters || validateProducts([productClone, ...childProducts], filtersCondition)) {
        modifiedProducts.push(productClone);
        if (childProducts.length > 0) {
          productClone.hasChildProduct = true;
          modifiedProducts.push(...childProducts);
        }
      }
    }
  });

  return modifiedProducts;
};

export const getProductInOrderToDisplay = (productSuite, filtersCondition) => {
  let products = [];
  if (!productSuite) {
    return products;
  }

  products = clearExpandFields(productSuite.Products) || [];
  if (!products || products.length === 0) {
    return products;
  }

  products = buildListProductInOrderToDislay(products, filtersCondition);

  return products;
};

export const findProductByIsbn = (products, isbn) => {
  const index = findIndex(products, { resourceId: isbn });
  if (index === -1) {
    return null;
  }
  return products[index];
};

export const getDefaultValueFollowType = (field: string) => {
  const booleanFields = ['ignoreFulfillment', 'showNotifyButton'];
  if (booleanFields.includes(field)) {
    return false;
  }
  return '';
};

export const updateTspConfig = (currentTspConfig, newTspConfig) => {
  if (!currentTspConfig) {
    return newTspConfig;
  }
  const tspConfig = { ...currentTspConfig, ...newTspConfig };
  Object.keys(currentTspConfig).forEach(key => {
    tspConfig[key] = newTspConfig[key] !== undefined ? newTspConfig[key] : getDefaultValueFollowType(key);
  });
  return tspConfig;
};

export const initConfigurationSubComboCourse = (orderItemId, comboCourseId) => ({
  platformComboCourseIsbn: comboCourseId,
  platformComboCourseType: UNDER_PLATFORM_COMBO_COURSE,
  platformCourseType: 'combo_course',
  platformComboOrderItemId: orderItemId,
  tspConfig: {
    ignoreFulfillment: false
  }
});

export const isComboCourseType = (type: string) => getComboCourseTypes().includes(type);

export const isIgnoreEntitlement = product =>
  product.configuration &&
  product.configuration.tspConfig &&
  product.configuration.tspConfig.ignoreEntitlement;

let ORDER_ITEM_ID_CACHE = 0;
export const resetOrderOrderItemId = () => {
  ORDER_ITEM_ID_CACHE = 0;
};

export const generateOrderItemIdByProduct = (product) => {
  const result = { ...product };
  if (!product.orderItemId) {
    ORDER_ITEM_ID_CACHE -= 1;
    result.orderItemId = ORDER_ITEM_ID_CACHE;
  }
  return result;
};

export const buildSubComboCourseProduct = (productItem, comboProduct) => {
  const lmsEvolveConfiguration = getLMSEvolveConfiguration();
  let lmsConfig: ProductLMSConfigModel = initConfigurationSubComboCourse(comboProduct.orderItemId, comboProduct.resourceId);
  lmsConfig = { ...lmsConfig, tspConfig: { ...lmsConfig.tspConfig, ...(comboProduct.configuration.tspConfig || {}) } };
  const productLMSPlatforms = getProductLMSPlatforms(getEolveProductPlatformsCached(), productItem.platformList);
  lmsConfig.selectedCourseTools = productItem.platformList && !!productItem.platformList.find(p => p.platformId === 'learningobject_x2') ?
    lmsEvolveConfiguration.SelectCourseTools.LoExtEvolveContentPlusCourseTools : lmsEvolveConfiguration.SelectCourseTools.EvolveContentPlusCourseTools;
  const configuration = coreceLMSConfigData(lmsEvolveConfiguration, productLMSPlatforms, lmsConfig);
  return {
    ...generateOrderItemIdByProduct(productItem),
    configuration,
    term: comboProduct.term
  };
};

export const isProductFinalTerm = (numberOfInstallments: number, productTerm?: number) =>
  productTerm && productTerm === numberOfInstallments;

export const hasGroupBelongsToProduct = (product: CohortProductModel) => {
  const tspConfig = (product && product.configuration && product.configuration.tspConfig) || {};
  return isArrNotEmpty(tspConfig.studentGroups);
};

export const hasChildProductEntitled = (products, parentProduct) => {
  if (parentProduct.hasChildProduct) {
    return !!products.find((p: CohortProductModel) => p.parentOrderItemId === parentProduct.orderItemId && p.isEntitled);
  }
  return false;
};
