import { cohorts } from './cohorts.data';
import { opportunities } from './opportunities.data';
import { statuses } from './statuses.data';
import * as COHORT_DETAILS from './cohort.data';
import { invoices, invoiceDetail } from './opportunities/invoices.data';
import { invoiceStatusHistories } from './invoice-status-history.data';
import { HESI_PROGRAMS, HESI_COHORT } from './hesi-cohort.data';
import { installmentAmounts } from './installment-amounts-history.data';

export { opportunities, cohorts, statuses, COHORT_DETAILS, invoices, invoiceDetail, invoiceStatusHistories, HESI_PROGRAMS, HESI_COHORT, installmentAmounts };
