import { Redirect, Switch } from 'react-router';
import { createTSPRoutes } from 'utilities/route/route.utility';
import { RoutePath } from 'constants/app.constant';
import { getRoutes } from 'routes';
import ErrorBoundary from './error-boundary/ErrorBoundary.component';
import Layout from './layout/Layout.component';

const appRoutes = createTSPRoutes(getRoutes());
const CMSApp = () => (
  <Layout>
    <ErrorBoundary>
      <Switch>
        {appRoutes}
        <Redirect from="/" exact to={RoutePath.home} />
        <Redirect to={RoutePath.error.pageNotFound} />
      </Switch>
    </ErrorBoundary>
  </Layout>
);

export default CMSApp;
