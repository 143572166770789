import { get } from 'lodash';
import { REDUCER_ID } from './app.reducer';
import { BreadcrumbType } from '../../models/navigation.models';

export const isLoading = (globalState) => !!globalState[REDUCER_ID].loadingActions;
export const isAsyncLoading = (actionName) => (globalState) => {
  const { asyncLoadingActions } = globalState[REDUCER_ID];
  return asyncLoadingActions && asyncLoadingActions[actionName];
};
export const getBreadcrumbs = (globalState): BreadcrumbType[] => globalState[REDUCER_ID].breadcrumbs;
export const getNotifications = (globalState) => globalState[REDUCER_ID].notifications;
export const getAllowAccessRoles = (globalState) => globalState[REDUCER_ID].accessRoles;
export const isTspEnabled = (globalState) => globalState[REDUCER_ID].isTspEnabled;
export const getMessage = globalState => globalState[REDUCER_ID].message;
export const getInflightCohortView = (globalState) => globalState[REDUCER_ID].inflightCohortView;
export const enableHesiIntegrationPhase1 = (globalState) => globalState[REDUCER_ID].enableHesiIntegrationPhase1;
export const maxInvoicesNumber = (globalState) => globalState[REDUCER_ID].maxInvoicesNumber;
export const enableFeature = (path) => (globalState) => get(globalState, path);
