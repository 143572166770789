import { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose';
import cx from 'classnames';
import { isEmpty, get, difference, omitBy, isNil, forEach } from 'lodash';
import { ELSWithModalService } from '@els/els-component-modal-react';
import { ELSCheckBox, ELSIcon, ELSTextBox } from '@els/els-component-form-field-react';
import { useDispatch } from 'react-redux';
import withUseLoading from 'utilities/with-loading/withUseLoading';
import { AcceptFileType } from '../../../../constants/app.constant';
import TextArea from '../../../common/textarea/TextArea.component';
import { Attachment, CreditRequestDetailModel, InternalCreditRequestModel } from '../../../../models/credit-request.models';
import { EvolveUserAddressModel } from '../../../../models/evolve.models';
import { validateCreditRequest } from '../../../../utilities/validators/validators.utility';
import CreditRequestAddress from '../credit-request-address/CreditRequestAddress.component';
import { EvolveAddressModel } from '../../../../models/invoice.model';
import { ELSModalServiceType } from '../../../../models/els.models';
import { ATTACHMENT_MAXIMUM_SIZE, CreditRequestFields, MAX_LENGTH_CREDIT_REQUEST_COMMENT } from '../../../../constants/creditRequest.constant';
import CreditRequestConfirmation from '../credit-request-confirmation/CreditRequestConfirmation.component';
import { appAsyncActions } from '../../../../redux/app';
import { invoiceCreditRebillServices } from '../../../../services';
import { getMessageBasedOnErrorType } from '../../../../utilities/error/error.utility';
import CreditRequestAttachmentComponent from '../../../common/credit-request-attachment/CreditRequestAttachment.component';
import { ELSButton, ELSButtonWithIcon } from '../../../common/els';
import CreditRequestDescription from '../../CreditRequestDescription.component';

interface CreditRequestProps extends InternalCreditRequestModel {
  invoiceNumber: string;
  modalService: ELSModalServiceType;
  handleBack: () => void;
  handleSubmit: (creditRequestNumber: string) => void;
}

const defaultValues: CreditRequestDetailModel = {
  invoiceNumber: '',
  institutionName: '',
  requesterFullName: '',
  requesterTitle: '',
  requesterEmail: '',
  requesterPhone: '',
  incorrectTax: false,
  incorrectContactInfo: false,
  incorrectPricing: false,
  other: false,
  comment: ''
};

const defaultAddress: EvolveAddressModel = {
  address1: '',
  address2: '',
  city: '',
  country: '',
  postalCode: '',
  state: ''
};

const requireFields = [
  CreditRequestFields.institutionName,
  CreditRequestFields.requesterFullName,
  CreditRequestFields.requesterTitle,
  CreditRequestFields.requesterEmail,
  CreditRequestFields.requesterPhone
];

const verifyRequireFields = (creditRequest: CreditRequestDetailModel, reqFields: string[]) => reqFields.filter(fieldName => {
  if (fieldName === CreditRequestFields.incorrectBillingAddress && !creditRequest.incorrectBillingAddress) {
    return false;
  }

  if (fieldName === CreditRequestFields.incorrectShippingAddress && !creditRequest.incorrectShippingAddress) {
    return false;
  }

  return true;
});

const updateRequiredFields = (source: string[], target: string[], setRequireFields: (fields: string[]) => void) => {
  if (difference(source, target).length !== 0) {
    setRequireFields(source);
  }
};
const isCreditRequestReasonsSelected = (creditRequest) => (creditRequest.incorrectBillingAddress ||
    creditRequest.incorrectShippingAddress ||
    creditRequest.incorrectTax ||
    creditRequest.incorrectPricing ||
    creditRequest.incorrectContactInfo ||
    creditRequest.other);

const handleValidateCreditRequest = (creditRequest: CreditRequestDetailModel, setRequireFields: (fields: string[]) => void, validateCreditRequestReasons: boolean,
  fields?: string[]) => {
  let reqFields = [];
  if (!isEmpty(fields)) {
    reqFields = verifyRequireFields(creditRequest, fields);
  } else {
    reqFields = [...requireFields];
    if (creditRequest.incorrectBillingAddress) {
      reqFields.push(CreditRequestFields.incorrectBillingAddress);
    }
    if (creditRequest.incorrectShippingAddress) {
      reqFields.push(CreditRequestFields.incorrectShippingAddress);
    }
    if (creditRequest.incorrectTax || creditRequest.incorrectPricing || creditRequest.incorrectContactInfo || creditRequest.other) {
      reqFields.push(CreditRequestFields.comment);
    }
  }
  updateRequiredFields(reqFields, fields, setRequireFields);
  const error = validateCreditRequest(reqFields, creditRequest);

  if (validateCreditRequestReasons && !isCreditRequestReasonsSelected(creditRequest)) {
    const errorReason = { reasons: 'Reason(s) For Credit Request is required.' };
    return error ? { ...error, ...errorReason } : errorReason;
  }
  return error;
};

const CreditRequestForm = (props: CreditRequestProps) => {
  const { invoiceNumber } = props;
  const [creditRequest, setCreditRequest] = useState<CreditRequestDetailModel>({ ...defaultValues, invoiceNumber });
  const [editedFields, setEditedField] = useState([]);
  const [validationErrors, setValidationErrors] = useState<{ [x: string]: string }>({});
  const uploadFile = useRef<HTMLInputElement>();
  const [submitedCreditRequest, setsubmitedCreditRequest] = useState(false);

  const dispatch = useDispatch();

  const handleValidate = (isSubmitCreditRequest: boolean, fields: string[] = []) => {
    const validateCreditRequestReasons = isSubmitCreditRequest || submitedCreditRequest;
    const error = handleValidateCreditRequest(creditRequest, setEditedField, validateCreditRequestReasons, fields);
    const newError = omitBy({ ...error, [CreditRequestFields.attachments]: validationErrors[CreditRequestFields.attachments] }, isNil);
    setValidationErrors(newError);
    return newError;
  };

  useEffect(() => {
    if (editedFields.length > 0) {
      handleValidate(false, editedFields);
    }
  }, [creditRequest, editedFields]);

  const getDataValue = (field: string, object?: CreditRequestDetailModel) => {
    if (!object || !object[field]) {
      return '';
    }
    return object[field];
  };

  const findErrorMessage = (field: string) => validationErrors && validationErrors[field] && `${validationErrors[field]}`;

  const renderErrorMessage = (field: string) => {
    const error = findErrorMessage(field);
    return error && (
      <div className="c-els-field__message-list">
        <span className="c-els-field__message c-els-field__message--error">{error}</span>
      </div>
    );
  };

  const updateEditedFields = (field: CreditRequestFields, newCreditRequest: CreditRequestDetailModel) => {
    setEditedField((preData) => {
      let newData = [...preData];
      if (!newCreditRequest.incorrectTax && !newCreditRequest.incorrectPricing && !newCreditRequest.incorrectContactInfo && !newCreditRequest.other) {
        newData = newData.filter(fieldName => fieldName !== CreditRequestFields.comment);
      }
      if (!preData.includes(field)) {
        newData.push(field);
      }
      return newData;
    });
  };

  const handleCreditRequest = (event: never, value: string, { name }: { name: string }): void => {
    setCreditRequest((prevData) => {
      const updateData = { [name]: value };
      const newData = { ...prevData, ...updateData };
      if (!newData.incorrectTax && !newData.incorrectPricing && !newData.incorrectContactInfo && !newData.other) {
        delete newData.comment;
      }
      updateEditedFields(name as CreditRequestFields, newData);
      return newData;
    });
  };

  const closeCreditRequestConfirmation = () => {
    props.modalService.closeModal('creditRequestConfirmation');
  };

  const submitCreditRequest = withUseLoading(async () => {
    try {
      const response = await invoiceCreditRebillServices.createCreditRequest(creditRequest);
      closeCreditRequestConfirmation();
      props.handleSubmit(response.creditRequestNumber);
    } catch (error) {
      closeCreditRequestConfirmation();
      dispatch(appAsyncActions.setMessage(false, getMessageBasedOnErrorType(error)));
    }
  });

  const showCreditRequestConfirmation = () => {
    props.modalService.openModal({
      modalId: 'creditRequestConfirmation',
      color: 'primary',
      content: (
        <CreditRequestConfirmation
          creditRequest={creditRequest}
          onSubmit={() => dispatch(submitCreditRequest)}
          onCancel={closeCreditRequestConfirmation} />
      )
    });
  };

  const handleSubmitCreditRequest = () => {
    setsubmitedCreditRequest(true);
    const error = handleValidate(true);
    if (!isEmpty(error)) {
      setValidationErrors(error);
    } else {
      showCreditRequestConfirmation();
    }
  };

  const handleSelectedCreditRequestAddress = (fieldName: CreditRequestFields, defaultValue?: EvolveAddressModel) => {
    setCreditRequest(
      {
        ...creditRequest,
        [fieldName]: creditRequest[fieldName] ? undefined : defaultValue
      }
    );
  };

  const updateValueAddressField = (fieldName: CreditRequestFields, address: EvolveAddressModel) => {
    setCreditRequest(
      {
        ...creditRequest,
        [fieldName]: address
      }
    );
  };

  const handleCommentChange = (event) => {
    const newCreditRequest = {
      ...creditRequest,
      comment: event.target.value
    };
    setCreditRequest(newCreditRequest);
    updateEditedFields(CreditRequestFields.comment, newCreditRequest);
  };

  const handleAttachmentFile = async (e) => {
    if (!e.target || !e.target.files || !e.target.files.length) {
      return;
    }
    let isErrorAttachment = false;
    const attachments = [];
    const { files } = e.target;
    forEach(files, file => {
      isErrorAttachment = isErrorAttachment || file.type !== AcceptFileType.PDF || file.size > ATTACHMENT_MAXIMUM_SIZE;
      attachments.push({
        fileName: file.name,
        content: file,
        path: URL.createObjectURL(file)
      });
    });
    setValidationErrors(prevData => ({
      ...prevData,
      [CreditRequestFields.attachments]: isErrorAttachment ? 'The attachment file must be PDF, maximum size: 2Mb' : undefined
    }));
    setCreditRequest(
      {
        ...creditRequest,
        [CreditRequestFields.attachments]: attachments
      }
    );
  };

  const removeAttachment = (attachment: Attachment) => {
    const newAttachments = creditRequest.attachments.filter(att => att.path !== attachment.path);
    setCreditRequest(prevData => ({
      ...prevData,
      attachments: newAttachments.length === 0 ? undefined : newAttachments
    }));
    setValidationErrors(prevData => {
      const newData = { ...prevData };
      if (newAttachments.length === 0) {
        delete newData.attachments;
      }
      return newData;
    });
  };

  const renderIncorrectTax = () => (
    <div className="c-tsp-credit-request-form__tax">
      {(!creditRequest.attachments || creditRequest.attachments.length === 0) && (
        <>
          <span>For tax exemption issues, please </span>
          <input className="c-tsp-credit-request-form__tax--input" type="file" accept={AcceptFileType.PDF} ref={uploadFile} onChange={handleAttachmentFile} />
          <ELSButton type="link" onClick={() => uploadFile.current && uploadFile.current.click()}>attach your PDF tax exempt document here.</ELSButton>
        </>
      )}

      {creditRequest.attachments && creditRequest.attachments.length > 0 && (
        <div className="u-els-margin-top-1o2">
          <p>Attached Document:</p>
          <div>
            {
              creditRequest.attachments.map(attachment => (
                <div key={attachment.fileName} className="o-els-flex-layout o-els-flex-layout--space-between o-els-flex-layout--wrap@mobile">
                  <div className="o-els-flex-layout__item u-els-color-secondary u-els-margin-right">
                    <CreditRequestAttachmentComponent attachment={attachment} />
                  </div>
                  <div className="o-els-flex-layout__item u-els-display-flex">
                    <ELSButtonWithIcon
                      type="link"
                      size="x-small"
                      sprite="Caution"
                      linkIconSize="xs"
                      className="c-els-link__text--warn"
                      onClick={() => removeAttachment(attachment)}>
                      Remove Attached Document
                    </ELSButtonWithIcon>
                  </div>
                </div>
              ))
            }
          </div>
          {renderErrorMessage(CreditRequestFields.attachments)}
        </div>
      )}
    </div>
  );

  const renderItemClasses = (errorField?: string, defaultClass?: string) =>
    cx(`u-els-margin-top ${defaultClass || ''}`, { 'c-els-field--error': errorField && validationErrors && validationErrors[errorField] });

  return (
    <div className="u-els-width c-tsp-credit-request-form u-els-margin-left-9x">
      <h2 className="c-tsp-credit-request-form__title u-els-margin-bottom-1o2">Submit Credit Request</h2>
      <div className="c-tsp-credit-request-form__description">
        <CreditRequestDescription isInternalCreditRequest={props.isInternalCreditRequest} />
      </div>
      <div className="c-tsp-credit-request-form__content u-els-padding-right u-els-margin-top-2x">
        <div>
          <ELSTextBox
            name={CreditRequestFields.invoiceNumber}
            id="invoiceNumber"
            value={creditRequest.invoiceNumber}
            iconRight="confirmation"
            isDisabled
            changeHandler={handleCreditRequest}
          >
            <div className="invoice-number-field-text">
              <span> Invoice Number*</span>
            </div>
            <ELSIcon name="confirmation-solid-circle" size="1x" color="confirm" customClass="c-tsp-credit-request__icon-confirmation" />
          </ELSTextBox>
        </div>
        <div className={renderItemClasses(CreditRequestFields.institutionName)}>
          <ELSTextBox
            name={CreditRequestFields.institutionName}
            id="txtInstitution"
            autoComplete="off"
            value={getDataValue(CreditRequestFields.institutionName, creditRequest)}
            changeHandler={handleCreditRequest}
          >
            Institution*
          </ELSTextBox>
          {renderErrorMessage(CreditRequestFields.institutionName)}
        </div>

        <div className={renderItemClasses(CreditRequestFields.requesterFullName)}>
          <ELSTextBox
            name={CreditRequestFields.requesterFullName}
            id="txtFullname"
            autoComplete="off"
            value={getDataValue(CreditRequestFields.requesterFullName, creditRequest)}
            changeHandler={handleCreditRequest}
          >
            Requester&apos;s Full Name*
          </ELSTextBox>
          {renderErrorMessage(CreditRequestFields.requesterFullName)}
        </div>

        <div className={renderItemClasses(CreditRequestFields.requesterTitle)}>
          <ELSTextBox
            name={CreditRequestFields.requesterTitle}
            id="txtTitle"
            autoComplete="off"
            value={getDataValue(CreditRequestFields.requesterTitle, creditRequest)}
            changeHandler={handleCreditRequest}
          >
            Requester&apos;s Title*
          </ELSTextBox>
          {renderErrorMessage(CreditRequestFields.requesterTitle)}
        </div>

        <div className={renderItemClasses(CreditRequestFields.requesterEmail)}>
          <ELSTextBox
            name={CreditRequestFields.requesterEmail}
            id="txtEmail"
            autoComplete="off"
            value={getDataValue(CreditRequestFields.requesterEmail, creditRequest)}
            changeHandler={handleCreditRequest}
          >
            Requester&apos;s Email Address*
          </ELSTextBox>
          {renderErrorMessage(CreditRequestFields.requesterEmail)}
        </div>

        <div className={renderItemClasses(CreditRequestFields.requesterPhone)}>
          <ELSTextBox
            name={CreditRequestFields.requesterPhone}
            id="txtPhonenumber"
            autoComplete="off"
            value={getDataValue(CreditRequestFields.requesterPhone, creditRequest)}
            changeHandler={handleCreditRequest}
          >
            Requester&apos;s Phone Number*
          </ELSTextBox>
          {renderErrorMessage(CreditRequestFields.requesterPhone)}
        </div>

        <div className="u-els-margin-top">
          Select Reason(s) For Credit Request*
          {renderErrorMessage('reasons')}
        </div>
        <div>
          <div className="u-els-margin-top">
            <ELSCheckBox
              name={CreditRequestFields.incorrectShippingAddress}
              id="cbxShipAddress"
              className="u-els-font-family-base"
              checked={!!creditRequest.incorrectShippingAddress}
              labelText="Incorrect “Ship To” Address"
              changeHandler={() => handleSelectedCreditRequestAddress(CreditRequestFields.incorrectShippingAddress, defaultAddress)}
            />
            {!!creditRequest.incorrectShippingAddress && (
              <CreditRequestAddress
                address={creditRequest.incorrectShippingAddress as EvolveAddressModel}
                fieldName={CreditRequestFields.incorrectShippingAddress}
                errorMessage={validationErrors}
                updateAddress={(address: EvolveUserAddressModel) => updateValueAddressField(CreditRequestFields.incorrectShippingAddress, address)}
              />
            )}
          </div>
          <div className="u-els-margin-top">
            <ELSCheckBox
              name={CreditRequestFields.incorrectBillingAddress}
              id="cbxShipAddress"
              className="u-els-font-family-base"
              checked={creditRequest.incorrectBillingAddress}
              labelText="Incorrect “Bill To” Address"
              changeHandler={() => handleSelectedCreditRequestAddress(CreditRequestFields.incorrectBillingAddress, defaultAddress)}
            />
            {!!creditRequest.incorrectBillingAddress && (
              <CreditRequestAddress
                address={creditRequest.incorrectBillingAddress as EvolveAddressModel}
                fieldName={CreditRequestFields.incorrectBillingAddress}
                errorMessage={validationErrors}
                updateAddress={(address: EvolveUserAddressModel) => updateValueAddressField(CreditRequestFields.incorrectBillingAddress, address)}
              />
            )}
          </div>
          <div className="u-els-margin-top">
            <ELSCheckBox
              name={CreditRequestFields.incorrectTax}
              id="cbxIncorrectTax"
              className="u-els-font-family-base"
              checked={creditRequest.incorrectTax}
              labelText="Incorrect Tax"
              changeHandler={handleCreditRequest}
            />
            {creditRequest.incorrectTax && renderIncorrectTax()}
          </div>
          <div className="u-els-margin-top">
            <ELSCheckBox
              name={CreditRequestFields.incorrectPricing}
              id="cbxIncorrectPricing"
              className="u-els-font-family-base"
              checked={creditRequest.incorrectPricing}
              labelText="Incorrect Pricing"
              changeHandler={handleCreditRequest}
            />
          </div>
          <div className="u-els-margin-top">
            <ELSCheckBox
              name={CreditRequestFields.incorrectContactInfo}
              id="cbxContactInformation"
              className="u-els-font-family-base"
              checked={creditRequest.incorrectContactInfo}
              labelText="Incorrect Contact Information"
              changeHandler={handleCreditRequest}
            />
          </div>
          <div className="u-els-margin-top">
            <ELSCheckBox
              name={CreditRequestFields.other}
              id="cbxOther"
              className="u-els-font-family-base"
              checked={creditRequest.other}
              labelText="Other"
              changeHandler={handleCreditRequest}
            />
          </div>
          {(creditRequest.incorrectContactInfo || creditRequest.incorrectPricing || creditRequest.incorrectTax || creditRequest.other) && (
            <div className={cx('u-els-margin-top', { 'c-els-field--error': validationErrors && get(validationErrors, CreditRequestFields.comment) })}>
              <label htmlFor="txtProvideDetails" className="c-els-field__label">
                <span className="c-els-field__label-text u-els-display-inline-block">Please provide details*</span>
              </label>
              <TextArea
                id="txtProvideDetails"
                className="u-els-height-auto"
                placeholder="Add details here"
                value={creditRequest.comment}
                rows={3}
                maxLength={MAX_LENGTH_CREDIT_REQUEST_COMMENT}
                onChange={handleCommentChange}
              />
              {renderErrorMessage(CreditRequestFields.comment)}
            </div>
          )}
        </div>
        <div className="u-els-margin-top-2x u-els-display-inline-block ">
          <div>
            <ELSButton type="primary" className="c-tsp-credit-request-form__button u-els-margin-right els-button--primary els-button--x-large" onClick={handleSubmitCreditRequest}>
              Submit
            </ELSButton>
            <ELSButton type="primary" className="c-tsp-credit-request-form__button1 els-button--primary els-button--x-large" onClick={props.handleBack}>
              Back
            </ELSButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const enhancers = [
  ELSWithModalService
];

export default compose(...enhancers)(CreditRequestForm);
