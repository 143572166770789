import { opportunityActions, opportunityReducer } from './opportunity.reducer';
import * as opportunitySelectors from './opportunity.selector';
import * as opportunityAsyncActions from './opportunity.actions';

export {
  opportunityReducer,
  opportunitySelectors,
  opportunityActions,
  opportunityAsyncActions
};
