export const headerFacetData = {
  id: 'filters',
  groupName: 'filters',
  title: 'Filters',
  type: 'header',
  marginBottom: '1o2'
};

export const facet = [
  headerFacetData,
  {
    id: 'programType',
    title: 'Program Type',
    accordion: true,
    isExpandTogglePanelByDefault: true,
    options: [
      {
        name: 'APRN',
        value: 3
      },
      {
        name: 'Canadian RN',
        value: 18
      }
    ]
  },
  {
    id: 'examType',
    title: 'Exam Type',
    accordion: true,
    isExpandTogglePanelByDefault: true,
    options: [
      {
        name: 'A&P',
        value: 1
      },
      {
        name: 'Acute Care',
        value: 50
      }
    ]
  }
];

export const exams = [
  {
    id: 1011,
    title: 'RN Maternity',
    name: 'HESI',
    type: 'HESI Case Studies',
    ISBN: '973637935936',
    cright: 2024,
    amount: 172.0,
    programTypes: [
      {
        programTypeId: 1,
        programTypeName: 'RN',
        examTypes: [
          {
            examTypeId: 1,
            examTypeName: 'Specialty'
          }
        ]
      }
    ]
  },
  {
    id: 1012,
    title: 'RN Exit',
    name: 'HESI',
    type: 'HESI Case Study',
    ISBN: '9736379936',
    cright: 2024,
    amount: 223.0,
    programTypes: [
      {
        programTypeId: 1,
        programTypeName: 'RN',
        examTypes: [
          {
            examTypeId: 4,
            examTypeName: 'Exit'
          }
        ]
      }
    ]
  },
  {
    id: 1013,
    title: 'RN Admission Assesment',
    name: 'HESI',
    type: 'Non-HESI Case Study',
    ISBN: '9736379936',
    cright: 2024,
    amount: 223.0,
    programTypes: [
      {
        programTypeId: 1,
        programTypeName: 'RN',
        examTypes: [
          {
            examTypeId: 9,
            examTypeName: 'State-Based'
          }
        ]
      }
    ]
  }
];

export const CartList = [
  {
    examName: 'Alabama CBC N 113',
    pricePerStudent: '$30.00',
    quantity: '10',
    price: '$300.00'
  },
  {
    examName: 'Cloud RN CAT 03/26',
    pricePerStudent: '$33.00',
    quantity: '10',
    price: '$330.00'
  }
];

export const hesiOrderExamToCartPrograms = [
  { name: 'Elsevier College - NYC Campus - RN', value: '23456' },
  { name: 'Elsevier College - Houston Campus - RN', value: '34567' },
  { name: 'Elsevier College - St.Louis Campus - RN', value: '45678' }
];

export const hesiOrderExamFacultyOptions = [
  { value: '12', name: 'Sen, Arnab' },
  { value: '45', name: 'Kshirsagar, Priyanka' },
  { value: '67', name: 'Shukla, Akash' }
];

export const programTypes = [
  {
    programTypeId: 3,
    programTypeName: 'APRN',
    examTypes: [
      {
        assessmentType: {
          assessmentTypeId: 1,
          assessmentTypeName: 'Specialty'
        },
        examTypeId: 11,
        examTypeName: 'Adult'
      },
      {
        assessmentType: {
          assessmentTypeId: 2,
          assessmentTypeName: 'Custom'
        },
        examTypeId: 16,
        examTypeName: 'Custom'
      },
      {
        assessmentType: {
          assessmentTypeId: 3,
          assessmentTypeName: 'Friend'
        },
        examTypeId: 21,
        examTypeName: 'Family'
      },
      {
        assessmentType: {
          assessmentTypeId: 4,
          assessmentTypeName: 'Brother'
        },
        examTypeId: 35,
        examTypeName: 'Nursing Executive'
      },
      {
        assessmentType: {
          assessmentTypeId: 5,
          assessmentTypeName: 'Hood'
        },
        examTypeId: 50,
        examTypeName: 'Acute Care'
      },
      {
        assessmentType: {
          assessmentTypeId: 6,
          assessmentTypeName: 'Fan'
        },
        examTypeId: 52,
        examTypeName: 'Patho Pharm Phys Dx'
      }
    ]
  },
  {
    programTypeId: 21,
    programTypeName: 'RN Mobility',
    examTypes: [
      {
        assessmentType: {
          assessmentTypeId: 5,
          assessmentTypeName: 'Hood'
        },
        examTypeId: 2,
        examTypeName: 'Admission'
      },
      {
        assessmentType: {
          assessmentTypeId: 3,
          assessmentTypeName: 'Friend'
        },
        examTypeId: 1,
        examTypeName: 'Specialty'
      }
    ]
  }
];

export const programs = [
  {
    programName: 'KMSProgram',
    programId: 200725,
    ecrId: null,
    distanceTestingFlag: false,
    poRequiredFlag: true,
    prePaymentRequiredFlag: true,
    creditHoldFlag: true,
    contractedPricingFlag: true
  },
  {
    programName: 'PN program',
    programId: 200752,
    ecrId: null,
    distanceTestingFlag: false,
    poRequiredFlag: false,
    prePaymentRequiredFlag: false,
    creditHoldFlag: false,
    contractedPricingFlag: false
  }
];

const facultyUser = {
  firstName: 'Smith',
  lastName: 'John',
  email: 'johnsmith@yopmail.com',
  userName: 'johnS'
};

export const HESIOrderItems = [
  {
    name: 'Alabama CBC N 113',
    price: 10,
    programTypes,
    programsSelected: [
      {
        programId: '200751',
        programName: 'Program Name',
        facultyUserNames: [facultyUser],
        quantity: 2
      },
      {
        programId: '200752',
        programName: 'Program Name1',
        facultyUserNames: [facultyUser],
        quantity: 2
      }
    ]
  },
  {
    name: 'RN Admission Assesment',
    price: 10,
    programTypes,
    programsSelected: [
      {
        programId: '200751',
        programName: 'Program Name2',
        facultyUserNames: [facultyUser],
        quantity: 2
      },
      {
        programId: '200752',
        programName: 'Program Name3',
        facultyUserNames: [facultyUser],
        quantity: 2
      }
    ]
  }
];

export const HESIExamOrders = [
  {
    orderId: 12345,
    firstName: 'Jack',
    lastName: 'Test',
    institution: 'Elsevier University',
    email: 'name@elsevier.com',
    phone: '555-555-5555',
    poNumber: '1234567-89',
    amountDue: 289,
    paymentInfo: {
      paymentMethod: 'Credit Card',
      details: 'Visa ending in 2023',
      amountPaid: 1234
    },
    billingAddress: {
      address1: '1234 West St',
      city: 'Townville',
      address2: 'Suite 2A',
      state: 'PA',
      postalCode: '12345',
      country: 'USA'
    },
    orderItems: HESIOrderItems
  }
];
