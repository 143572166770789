import React from 'react';
import { NavigationConfig } from '../../../models/navigation.models';
import SideNavigationSection from '../navigation-sections/SideNavSection.component';

export interface SideNavProps {
  navConfig: NavigationConfig
}

const SideNav = ({ navConfig }: SideNavProps) => (
  <div className="o-els-flex-layout o-els-flex-layout--column o-els-flex-layout--left c-tsp-side-nav">
    {
      navConfig.navSections.filter(section => section.items.length > 0).map((section) => (
        <div key={section.key} className="o-els-flex-layout__item">
          <SideNavigationSection section={section} />
        </div>
      ))
    }
  </div>
);
export default SideNav;
