import { find, isEmpty, filter, cloneDeep } from 'lodash';
import { InvoiceModel } from 'models/invoice.model';
import { EvolveInstallmentModel } from 'models/installment.model';
import { getInstallmentInstitutionAmount } from 'utilities/installment/installment.utility';
import { getComboProduct, isSubComboCourse, isSherpathNSSComponent } from '../product-suite/product-suite.utility';
import { UserRole, PRODUCT_TYPES, EVOLVE_PRODUCT_TYPES, CODE_REDEMPTION, LMS_PLAFTFORMS, DEFAULT_TERM_VALUE } from '../../constants/app.constant';
import { getProperty, getLMSEvolveConfiguration, getComboCourseTypes, getPlatformCourseTypes, getRequiredCourseIdFields } from '../../config/app.config';
import { CohortProductModel } from '../../models/cohort.models';
import { EvolvePlatformModel, EvolveLMSConfigModel, PublishedStatus } from '../../models/evolve.models';

let EVOLVE_PRODUCT_PLATFORMS_CACHED = null;
const REQUIRED_COURSE_ID_FIELDS = getRequiredCourseIdFields();

export const setEvolveProductPlatfromsCached = (data) => {
  EVOLVE_PRODUCT_PLATFORMS_CACHED = data;
};

export const getEolveProductPlatformsCached = () => EVOLVE_PRODUCT_PLATFORMS_CACHED;

export const formatProductEdition = (editionText, appendText = false) => `${editionText}${(appendText) ? ' Edition' : ''}`;

export const getProductTitle = (product, suffix = '') => {
  const editionText = product.editionText ? `, ${product.editionText}${suffix}` : '';
  return `${product.name}${editionText}`;
};

export const getProductLogo = (productType) => {
  if (PRODUCT_TYPES.VST.indexOf(productType) !== -1) {
    return {
      // eslint-disable-next-line global-require, import/no-dynamic-require, import/no-webpack-loader-syntax
      imageSrc: require('assets/icons/vitalsource-brand-icon.png'),
      className: 'c-tsp-product-selection__product-vst-logo',
      altText: 'VitalSource'
    };
  }
  if (PRODUCT_TYPES.INTEL.indexOf(productType) !== -1) {
    return {
      // eslint-disable-next-line global-require, import/no-dynamic-require, import/no-webpack-loader-syntax
      imageSrc: require('assets/icons/inteleducation-brand-icon.png'),
      className: 'c-tsp-product-selection__product-intel-logo',
      altText: 'Intel Education'
    };
  }
  return null;
};

export const isShowProductAd = (userRoles = []) => userRoles.includes(UserRole.Faculty) || userRoles.includes(UserRole.FacultyRegistered);

export const getProductAdLeftImage = (campaignCode) => {
  const productAdImages = getProperty('productAdImages');
  return {
    imageURL: `${productAdImages.dynamicLeftAdImage}${(campaignCode || 'default')}.jpg`,
    websiteURL: `${productAdImages.dynamicAdImageUrl}${(campaignCode || 'default')}&type=pp_left`
  };
};

export const getProductAdRightImage = (campaignCode) => {
  const productAdImages = getProperty('productAdImages');
  return {
    imageURL: `${productAdImages.dynamicRightAdImage}${(campaignCode || 'default')}.jpg`,
    websiteURL: `${productAdImages.dynamicAdImageUr}${(campaignCode || 'default')}&type=pp_right`
  };
};

export const isHesiNgProductType = (productType: string) => ((productType || '') === EVOLVE_PRODUCT_TYPES.HESI_ASSESSMENT_NG);
export const isCompassProductType = (productType: string) => ((productType || '') === EVOLVE_PRODUCT_TYPES.HESI_COMPASS_EXAMPREP);
export const isHesiLSProductType = (productType: string) => (PRODUCT_TYPES.HESI_LS.indexOf(productType) !== -1);
export const isSherpathProductType = (productType: string) => (CODE_REDEMPTION.PRODUCT_TYPES.SHERPATH.indexOf(productType) !== -1);
export const isOriginalSherpathProduct = (productType: string) => {
  const type = productType || '';
  return (type === EVOLVE_PRODUCT_TYPES.SHERPATH_IA || type === EVOLVE_PRODUCT_TYPES.SHERPATH_BOOK_ORGANIZED);
};
export const isSherpathIntegratedProduct = (productType: string) => ((productType || '') === EVOLVE_PRODUCT_TYPES.SHERPATH_INTEGRATED_IA);
export const isNCOProduct = (productType: string) => (PRODUCT_TYPES.NCO.indexOf(productType) !== -1);
export const isSimchartProduct = (productType: string) => (PRODUCT_TYPES.SIMCHART.indexOf(productType) !== -1);
export const isSimSLSProduct = (productType: string) => (PRODUCT_TYPES.SIM_SLS.indexOf(productType) !== -1);
export const isSimNGCourseBasedProduct = (productType: string) => (PRODUCT_TYPES.SIM_NG_COURSE_BASED.indexOf(productType) !== -1);
export const isShadowHealthProduct = (productType: string) => ((productType || '') === EVOLVE_PRODUCT_TYPES.SHADOW_HEALTH);
export const isEAQProduct = (productType: string) => (PRODUCT_TYPES.EAQ.indexOf(productType) !== -1);
export const isHESILiveReviewProduct = (productType: string) => (PRODUCT_TYPES.HESI_LIVE_REVIEW.indexOf(productType) !== -1);
export const isOnlineCourseProduct = (productType: string) => (PRODUCT_TYPES.ONLINE_COURSE.indexOf(productType) !== -1);
export const isResourceProduct = (productType: string) => (PRODUCT_TYPES.EVOLVE_RESOURCE.indexOf(productType) !== -1);
export const isBillOnlyProduct = (productType: string) => (PRODUCT_TYPES.BILL_ONLY.indexOf(productType) !== -1);
export const isEbookProduct = (productType: string) => PRODUCT_TYPES.VST.indexOf(productType) !== -1 || PRODUCT_TYPES.INTEL.indexOf(productType) !== -1;
export const isNYPProduct = (pubStatus: string) => pubStatus === PublishedStatus.NotYetPublished;
export const isNotNYPProduct = (pubStatus: string) => pubStatus !== PublishedStatus.NotYetPublished;
export const showNYPProductAvailibilityMessage = (product) => !isSherpathNSSComponent(product) && product.pubStatus === PublishedStatus.NotYetPublished;
export const isCourseHostedOnLOLMS = (productHostedLMS) => productHostedLMS === 'lo';
export const isClinicalPracticeReadinessAssessmentProduct = (productType: string) => (productType === EVOLVE_PRODUCT_TYPES.CLINICAL_PRACTICE_READINESS_ASSESSMENT);
export const isCombinationCourse = (productType: string) => (productType === EVOLVE_PRODUCT_TYPES.COMBINATION_COURSE);

export const isCanvasCourse = (course) => {
  if (!course || !course.resourceId) {
    return false;
  }
  return `${course.resourceId}` === EVOLVE_PRODUCT_TYPES.CANVAS_COURSE;
};

export const hasCanvasCourse = (contentList) => {
  const foundItem = find(contentList || [], isCanvasCourse);
  return !!foundItem;
};

export const isRequireHostingLocation = (productLMSPlatforms: EvolveLMSConfigModel) => (productLMSPlatforms.evolveContent.length > 0 ||
  productLMSPlatforms.globalEvolveContent.length > 0 ||
  productLMSPlatforms.lmsDropDownPlatformList.length > 0 ||
  productLMSPlatforms.loExtEvolveContent.length > 0 ||
  productLMSPlatforms.noLmsUniqueContent.length > 0);

export const getDefaultSelectedCourseTools = (evolveAndLmsListObject) => {
  if (!isEmpty(evolveAndLmsListObject.noLmsUniqueContent)) {
    return 5;
  }
  if (!isEmpty(evolveAndLmsListObject.lmsDropDownPlatformList)) {
    return 3;
  }
  if (!isEmpty(evolveAndLmsListObject.globalEvolveContent)) {
    return 1;
  }
  if (!isEmpty(evolveAndLmsListObject.loExtEvolveContent)) {
    return 4;
  }
  return 2;
};

export const getSelectedCourseToolsFromPlatformId = (platformId) => {
  switch (platformId) {
    case 'global':
      return 1;
    case 'unique':
      return 2;
    case 'learningobject_x2':
      return 4;
    case LMS_PLAFTFORMS.NO_LMS_UNIQUE:
      return 5;
    default:
      return 3;
  }
};

export const getPlatformIdFromSelectedCourseTools = (selectedCourseTools, lmsConfig) => {
  switch (selectedCourseTools) {
    case 1:
      return 'global';
    case 2:
      return 'unique';
    case 4:
      return 'learningobject_x2';
    case 5:
      return LMS_PLAFTFORMS.NO_LMS_UNIQUE;
    default:
      return lmsConfig;
  }
};

export const getPlatformDataFromConfiguration = (configuration, lmsDropDownPlatformList) => {
  const selectedPlatform = find(lmsDropDownPlatformList, { resourceId: configuration && configuration.platformId });
  if (!selectedPlatform) {
    return {
      selectedLMSPlatform: '',
      selectedLMSPlatformName: ''
    };
  }
  return {
    selectedLMSPlatform: selectedPlatform.resourceId,
    selectedLMSPlatformName: (selectedPlatform.resourceId !== LMS_PLAFTFORMS.OTHER_LMS) ?
      (configuration && configuration.platformName) || '' : selectedPlatform.name
  };
};

const getValidPlatforms = (evolveProductPlatforms, platformList: Partial<EvolvePlatformModel>[]) => {
  const facultyPlatforms = filter(evolveProductPlatforms || [], (p) => (p.courseFormatFlag === 'Y' && p.resourceId));
  const { length } = facultyPlatforms;
  const platformsObj = {};
  for (let i = 0; i < length; i += 1) {
    const p = facultyPlatforms[i];
    platformsObj[p.resourceId] = p;
  }
  const validPlatforms = [];
  for (let i = 0, len = platformList.length; i < len; i += 1) {
    const { platformId } = platformList[i];
    if (platformsObj[platformId]) {
      validPlatforms.push({
        ...platformsObj[platformId],
        ...platformList[i],
        value: platformId
      });
    }
  }
  return validPlatforms;
};

export const getProductLMSPlatforms = (evolveProductPlatforms, platformList: Partial<EvolvePlatformModel>[]) => {
  const evolveAndLmsListObject = {
    lmsDropDownPlatformList: [],
    evolveContent: [],
    loExtEvolveContent: [],
    globalEvolveContent: [],
    noLmsUniqueContent: []
  };
  const validPlatforms = getValidPlatforms(evolveProductPlatforms, platformList || []);
  validPlatforms.forEach(item => {
    const data = {
      ...item
    };
    switch (item.resourceId) {
      case 'unique':
        evolveAndLmsListObject.evolveContent.push(data);
        break;
      case 'global':
        evolveAndLmsListObject.globalEvolveContent.push(data);
        break;
      case 'learningobject_x2':
        evolveAndLmsListObject.loExtEvolveContent.push(data);
        break;
      case LMS_PLAFTFORMS.NO_LMS_UNIQUE:
        evolveAndLmsListObject.noLmsUniqueContent.push(data);
        break;
      default:
        evolveAndLmsListObject.lmsDropDownPlatformList.push(data);
    }
  });
  return evolveAndLmsListObject;
};

export const getPlatformDataFromSelectedPlatform = (selectedCourseTools, productLMSConfig: EvolveLMSConfigModel) => {
  const LMSEvolveConfiguration = getLMSEvolveConfiguration();
  if (selectedCourseTools === LMSEvolveConfiguration.SelectCourseTools.EvolveContentOnly) {
    return !isEmpty(productLMSConfig.globalEvolveContent) ? productLMSConfig.globalEvolveContent[0] : null;
  }
  if (selectedCourseTools === LMSEvolveConfiguration.SelectCourseTools.EvolveContentPlusCourseTools) {
    return !isEmpty(productLMSConfig.evolveContent) ? productLMSConfig.evolveContent[0] : null;
  }
  if (selectedCourseTools === LMSEvolveConfiguration.SelectCourseTools.LoExtEvolveContentPlusCourseTools) {
    return !isEmpty(productLMSConfig.loExtEvolveContent) ? productLMSConfig.loExtEvolveContent[0] : null;
  }
  if (selectedCourseTools === LMSEvolveConfiguration.SelectCourseTools.NoLmsUniqueCourseTools) {
    return !isEmpty(productLMSConfig.noLmsUniqueContent) ? productLMSConfig.noLmsUniqueContent[0] : null;
  }
  return null;
};

export const getDefaultLMSConfig = (product) => {
  const buttonType = (Array.isArray(product.buttonList) && product.buttonList.length > 0) ? product.buttonList[0].type : null;
  const noLMSConfigButtonTypes = [
    'redeem_ebook_compcopy',
    'hardgoods_compcopy',
    'ebook_compcopy',
    'preorder_ebook_compcopy',
    'preorder_hardgoods_compcopy'
  ];
  if (noLMSConfigButtonTypes.includes(buttonType)) {
    return {
      compRequest: buttonType !== 'redeem_ebook_compcopy'
    };
  }
  if ((buttonType === 'eprod_add_to_cart' || buttonType === 'preorder_eprod') && !isSherpathProductType(product.productType)) {
    if (!isSimNGCourseBasedProduct(product.productType) && product.adoptAsStudent) {
      return {
        adoptionAsStudent: product.adoptAsStudent,
        compRequest: false
      };
    }
    return null;
  }
  if (isSherpathProductType(product.productType)) {
    return null;
  }
  if (isCombinationCourse(product.productType)) {
    return null;
  }
  return {
    compRequest: false
  };
};

export const isRequireLMSConfigData = (productType) => PRODUCT_TYPES.REQUIRE_LMS_CONFIG.indexOf(productType || '') !== -1;

export const getDeliveryMethods = (lmsConfig, lmsDropDownPlatformList) => {
  const foundLMS = find(lmsDropDownPlatformList, { resourceId: lmsConfig.selectedLMSPlatform });
  if (!foundLMS || !Array.isArray(foundLMS.formatConfig) || foundLMS.formatConfig.length === 0) {
    return null;
  }
  return foundLMS.formatConfig;
};

export const getPlatformHostingName = (product) => {
  if (!product.configuration) {
    return '';
  }
  switch (product.configuration.platformId) {
    case 'unique':
      return isCourseHostedOnLOLMS(product.hostedLMS) ? 'Evolve, Content Plus Course Tools' : 'Canvas provided by Elsevier';
    case 'global':
      return 'Evolve, Content Only';
    case 'learningobject_x2':
      return 'Evolve, Content Plus Course Tools';
    case LMS_PLAFTFORMS.NO_LMS_UNIQUE:
      return 'Evolve Portal - Direct Access';
    default:
      return product.configuration.platformName || product.configuration.platformId;
  }
};

export const checkForShowingDeliveryConfig = (isLMSPlatformSelected, selectedLMSPlatform, deliveryMethods, deliveryMethod, productType) => {
  if (!isLMSPlatformSelected) {
    return false;
  }
  if (deliveryMethods) {
    const LMSEvolveConfiguration = getLMSEvolveConfiguration();
    return deliveryMethod === LMSEvolveConfiguration.DeliveryMethod.EvolveLink;
  }
  if (isLMSPlatformSelected && selectedLMSPlatform.startsWith(LMS_PLAFTFORMS.NO_LMS)) {
    return false;
  }
  return isRequireLMSConfigData(productType);
};

export const isComboProduct = product => {
  const COMBO_COURSE_TYPES = getComboCourseTypes();
  return product.configuration && COMBO_COURSE_TYPES.includes(product.configuration.platformCourseType);
};

export const getComboCourseProducts = (currentProduct, products) =>
  products.filter(product => currentProduct.resourceId !== product.resourceId && isComboProduct(product));

export const isCurrentSubComboProduct = (product, comboProduct) => isSubComboCourse(product) &&
  product.configuration.platformComboCourseIsbn === comboProduct.resourceId &&
  ((!product.configuration.platformComboOrderItemId && product.term === (comboProduct.term || DEFAULT_TERM_VALUE)) ||
    product.configuration.platformComboOrderItemId === comboProduct.orderItemId);

export const isComboProductIncludedOthers = (products, productSelected) => {
  if (productSelected.configuration && !isComboProduct(productSelected)) {
    return false;
  }
  return !!products.find(pro => isCurrentSubComboProduct(pro, productSelected));
};

export const getPlatformCourseTypeName = (platformCourseType?: string) => {
  const comboCourseType = getComboCourseTypes();
  if (!platformCourseType || comboCourseType.includes(platformCourseType)) {
    return '';
  }
  const platform = getPlatformCourseTypes().find(p => p.value === platformCourseType);
  return platform ? platform.name : '';
};

export const resetPlatformIgnoreFulfillment = (configuration) => {
  const lmsEvolveConfiguration = getLMSEvolveConfiguration();
  const obj = {
    ...configuration
  };
  if (configuration && configuration.tspConfig && configuration.tspConfig.ignoreFulfillment) {
    obj.platformId = '';
    obj.platformName = '';
    obj.courseFormat = '';
    obj.courseFormatName = '';
    obj.selectedCourseTools = lmsEvolveConfiguration.SelectCourseTools.IgnoreFulfillment;
  }
  return obj;
};

export const mergeProducts = (productSuite, productsStore) => ([...productSuite, ...productsStore]);

// find sherpath which associate to simNG
export const findCurrentAssociatedProduct = (simchartProduct, products) =>
  products.find(p => (p.configuration &&
    p.configuration.skillsCollections === simchartProduct.resourceId &&
    p.configuration.collections !== p.configuration.skillsCollections &&
    p.resourceId !== simchartProduct.resourceId &&
    ((simchartProduct.configuration && !simchartProduct.configuration.courseSelectionOrderItemId && p.term === simchartProduct.term) ||
      simchartProduct.configuration.courseSelectionOrderItemId === p.orderItemId)));

export const findMasterProduct = (associatedProduct, products) => ((associatedProduct.configuration &&
  associatedProduct.configuration.skillsCollections &&
  associatedProduct.configuration.skillsCollections !== associatedProduct.resourceId) ?
  products.find((p: CohortProductModel) => p.configuration && p.configuration.courseSelectionOrderItemId === associatedProduct.orderItemId) : null);

export const findProductByOrderItemId = (orderItemId, products) =>
  products.find((p: CohortProductModel) => p.orderItemId === orderItemId);

export const prepareProductCollection = (masterProductId, productId) => (
  {
    collections: productId,
    courseSelection: [
      {
        course1: `${productId},${masterProductId}`
      }
    ],
    skillsCollections: masterProductId
  });

export const prepareMasterProductCollection = (masterProductId, associateProductId, associateProductOrderItemId) => (
  {
    collections: masterProductId,
    courseSelection: [
      {
        course1: `${masterProductId},${associateProductId}`
      }
    ],
    courseSelectionOrderItemId: associateProductOrderItemId,
    skillsCollections: masterProductId
  });

export const prepareSherpathProductCollection = (resourceId: string) => ({
  collections: resourceId,
  courseSelection: [
    {
      course1: resourceId
    }
  ],
  courseSelectionOrderItemId: undefined,
  skillsCollections: ''
});

export const hasAutoPopulatedChildProducts = (product: CohortProductModel) => (product.componentParentProductIds && product.componentParentProductIds.length > 0);

export const isShadowHealthOrCPRAProduct = (productType) =>
  (isShadowHealthProduct(productType) || isClinicalPracticeReadinessAssessmentProduct(productType));

export const isShadowHealthOrCPRAProductDirectAccess = (productType, platformId) =>
  (isShadowHealthOrCPRAProduct(productType) && (platformId === LMS_PLAFTFORMS.NO_LMS_UNIQUE));

export const isExistingCourseType = (platformCourseType) =>
  REQUIRED_COURSE_ID_FIELDS.includes(`${platformCourseType}`);

export const isShadowHealthOrCPRAPProductDirectAccessWithoutExistingCourse = (productType, platformId, platformCourseType) =>
  isShadowHealthOrCPRAProductDirectAccess(productType, platformId) && !isExistingCourseType(platformCourseType);

const getSherpathIsbnFromSimchartProduct = (simcharProduct: CohortProductModel) => {
  const courseSelection = simcharProduct.configuration && simcharProduct.configuration.courseSelection;
  const courses = (!isEmpty(courseSelection) && courseSelection[0].course1.split(',')) || [];
  const isbn = courses[courses.length - 1];
  return isbn !== simcharProduct.resourceId ? isbn : undefined;
};

const findComboProductForMigration = (subComboProduct, productsSuite, parentProductMap) => {
  if (!subComboProduct.configuration || !subComboProduct.configuration.platformComboCourseIsbn) {
    return undefined;
  }

  let comboProduct = parentProductMap[`${subComboProduct.configuration.platformComboCourseIsbn}_${subComboProduct.term}`];
  if (!comboProduct) {
    comboProduct = getComboProduct(productsSuite, subComboProduct);
  }
  return comboProduct;
};

const findAssociateProductForMigration = (simchartProduct: CohortProductModel, productsSuite: CohortProductModel[], parentProductMap: { [x: string]: CohortProductModel }) => {
  const associateIsbn = getSherpathIsbnFromSimchartProduct(simchartProduct);
  if (!associateIsbn) {
    return undefined;
  }
  let associateProduct = parentProductMap[`${associateIsbn}_${simchartProduct.term}`];
  if (!associateProduct) {
    associateProduct = findCurrentAssociatedProduct(simchartProduct, productsSuite);
  }
  return associateProduct;
};

const findParentProductForSubComboCourse = (product, productsSuite, orderItemIdsSelected, parentProduct) => {
  const newParentObj = { ...parentProduct };
  const comboProduct = findComboProductForMigration(product, productsSuite, parentProduct);
  const isSubComboSelectedNotUpdateParent = orderItemIdsSelected.includes(product.orderItemId) && !orderItemIdsSelected.includes(product.configuration.platformComboOrderItemId);
  const isOldSubComboSameIsbn = !orderItemIdsSelected.includes(product.orderItemId) && !product.configuration.platformComboOrderItemId;
  if ((isSubComboSelectedNotUpdateParent || isOldSubComboSameIsbn) && comboProduct) {
    newParentObj[`${comboProduct.resourceId}_${comboProduct.term}`] = comboProduct;
  }

  return newParentObj;
};

const findParentProductForSimNGCourseBased = (product, productsSuite, orderItemIdsSelected, parentProduct) => {
  const newParentObj = { ...parentProduct };
  const associatedProduct = findAssociateProductForMigration(product, productsSuite, parentProduct);
  const isSimchartSelectedProductNotUpdateParent = orderItemIdsSelected.includes(product.orderItemId) &&
    !orderItemIdsSelected.includes(product.configuration.courseSelectionOrderItemId);
  const isOldSimchartSameIsbn = !orderItemIdsSelected.includes(product.orderItemId) &&
    product.configuration && !product.configuration.courseSelectionOrderItemId;
  if ((isSimchartSelectedProductNotUpdateParent || isOldSimchartSameIsbn) && associatedProduct) {
    newParentObj[`${associatedProduct.resourceId}_${associatedProduct.term}`] = associatedProduct;
  }

  return newParentObj;
};

const findParentProductsFromSelectedProducts = (selectedProducts: CohortProductModel[], productsSuite: CohortProductModel[]): { [x: string]: CohortProductModel } => {
  const isbnsSelected: Set<string> = selectedProducts.reduce((isbnSet: Set<string>, product: CohortProductModel) => {
    isbnSet.add(product.resourceId);
    if (isComboProduct(product) && hasAutoPopulatedChildProducts(product)) {
      product.componentParentProductIds.forEach(childIsbn => isbnSet.add(childIsbn));
    }
    return isbnSet;
  }, new Set());

  const orderItemIdsSelected = selectedProducts.map(product => product.orderItemId);
  return productsSuite.reduce((parentObj, pro) => {
    let newParentObj = { ...parentObj };

    if (!isbnsSelected.has(pro.resourceId)) {
      return newParentObj;
    }

    // if parent's selected sub-combo is NOT included selected product
    // if sub-combo has same isbn with products selected and not included in selected product
    if (isSubComboCourse(pro)) {
      newParentObj = findParentProductForSubComboCourse(pro, productsSuite, orderItemIdsSelected, newParentObj);
    }

    // if parent's selected simchart is NOT included selected product
    // if simchart has same isbn with products selected and not included in selected product
    if (isSimNGCourseBasedProduct(pro.productType)) {
      newParentObj = findParentProductForSimNGCourseBased(pro, productsSuite, orderItemIdsSelected, newParentObj);
    }

    if (orderItemIdsSelected.includes(pro.orderItemId)) {
      return newParentObj;
    }

    // parent has same isbn with products selected
    if (isComboProduct(pro) ||
      (isSherpathProductType(pro.productType) && pro.configuration && pro.configuration.skillsCollections)
    ) {
      newParentObj[`${pro.resourceId}_${pro.term}`] = pro;
    }

    return newParentObj;
  }, {});
};

const isExistingChildProductToUpdate = (product: CohortProductModel, parentProductMap: { [x: string]: CohortProductModel }, orderItemIdsSelected: number[]) => {
  if (orderItemIdsSelected.includes(product.orderItemId)) {
    return false;
  }

  if (isSubComboCourse(product) && parentProductMap[`${product.configuration.platformComboCourseIsbn}_${product.term}`] && !product.configuration.platformComboOrderItemId) {
    return true;
  }

  if (isSimNGCourseBasedProduct(product.productType)) {
    const associateIsbn = getSherpathIsbnFromSimchartProduct(product);
    if (associateIsbn && parentProductMap[`${associateIsbn}_${product.term}`] &&
      product.configuration && product.configuration.skillsCollections && !product.configuration.courseSelectionOrderItemId) {
      return true;
    }
  }

  return false;
};

// find old data which need to update configuration
export const findExistingChildProductsToUpdate = (selectedProducts = [], productsSuite = []) => {
  if (selectedProducts.length === 0 || productsSuite.length === 0) {
    return [];
  }

  const orderItemIdsSelected = selectedProducts.map(product => product.orderItemId);
  const parentProductMap = findParentProductsFromSelectedProducts(selectedProducts, productsSuite);
  const updatingProducts = productsSuite
    .filter(pro => isExistingChildProductToUpdate(pro, parentProductMap, orderItemIdsSelected))
    .map(pro => {
      const updatingProduct = cloneDeep(pro);
      if (isSubComboCourse(pro) && parentProductMap[`${pro.configuration.platformComboCourseIsbn}_${pro.term}`]) {
        const comboProduct = parentProductMap[`${pro.configuration.platformComboCourseIsbn}_${pro.term}`];
        updatingProduct.configuration.platformComboOrderItemId = comboProduct.orderItemId;
      }

      if (isSimNGCourseBasedProduct(pro.productType)) {
        const associateIsbn = getSherpathIsbnFromSimchartProduct(pro);
        const associateProduct = parentProductMap[`${associateIsbn}_${pro.term}`];
        if (associateProduct) {
          updatingProduct.configuration.courseSelectionOrderItemId = associateProduct.orderItemId;
        }
      }

      return updatingProduct;
    });

  return [...updatingProducts];
};

export const validateCostPerStudent = (invoices: InvoiceModel[], installment: EvolveInstallmentModel, errors: { [key: string]: string }) => {
  let validateErrors = { ...errors };
  const totalCostPerStudent = invoices.reduce((currentTotal, invoice) => +currentTotal + +invoice.costPerStudent, 0);
  if (+totalCostPerStudent.toFixed(2) !== +getInstallmentInstitutionAmount(installment)) {
    validateErrors = { ...validateErrors, costPerStudent: 'The sum of all invoices cost per student must equal the installment’s cost per student.' };
    for (let index = 0; index < invoices.length; index += 1) {
      validateErrors = { ...validateErrors, [`invoices[${index}].costPerStudent`]: ' ' };
    }
  }
  return validateErrors;
};
