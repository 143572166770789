import React, { Component } from 'react';
import cx from 'classnames';
import { MenuConstants } from '../../../constants/MenuConstants';
import MenuWindowView from './MenuWindowView';
import MenuView from './MenuView';

export interface MenuComponentProps {
  maxHeight?: number;
  onMenuClose?: Function;
  menuItems?: any[];
  onItemClick?: Function;
  isFixedPosition?: boolean;
  position?: string;
  allowWindowOverflow?: boolean;
  isAnchorized?: boolean;
  icon?: string;
  iconPosition?: string;
  buttonClassName?: string;
  buttonType?: string;
  iconPrefix?: string;
  buttonAriaLabel?: string;
  className?: string;
}

class MenuComponent extends Component<MenuComponentProps> {
  menuContainer: any;
  menuElement: any;
  state = {
    showMenu: false,
    menuPosition: {
      display: 'block',
      top: 'auto',
      right: 0,
      bottom: 'auto',
      left: 'auto'
    },
    maxHeight: this.props.maxHeight,
    verticalAlign: '',
    defaultMenuWidth: 0
  };

  registerEvent = () => {
    document.addEventListener('click', this.handleCloseMenu);
    window.addEventListener('resize', this.setPositionMenuWindow);
  };

  unregisterEvent = () => {
    document.removeEventListener('click', this.handleCloseMenu);
    window.removeEventListener('resize', this.setPositionMenuWindow);
  };

  handleCloseMenu = event => {
    const { target } = event;
    /* istanbul ignore else */
    if (this.state.showMenu && !this.isDescendant(this.menuContainer, target) && !this.isDescendant(this.menuElement, target)) {
      this.closeMenu(event);
    }
  };

  handleToggleMenu = event => {
    /* istanbul ignore else */
    if (!this.state.showMenu) {
      this.openMenu(event);
    } else {
      this.closeMenu(event);
    }
  };

  openMenu = ({ clientX, clientY }) => {
    this.setState({ showMenu: true }, () => {
      this.autoFocusFirstItem({ clientX, clientY });
    });
    this.registerEvent();
  };

  autoFocusFirstItem = ({ clientX, clientY }) => {
    // check open by event enter
    /* istanbul ignore else */
    if (clientX === 0 && clientY === 0) {
      this.setFocusItem(0);
    }
  };

  closeMenu = event => {
    this.unregisterEvent();
    /* istanbul ignore else */
    if (event && typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    }
    /* istanbul ignore else */
    if (this.props.onMenuClose) this.props.onMenuClose();
    this.setState({
      showMenu: false
    });
  };

  setMenuContainer = element => {
    this.menuContainer = element;
  };

  setMenuWindow = element => {
    this.menuElement = element;
    this.setPositionMenuWindow();
  };

  setPositionMenuWindow = () => {
    if (!this.menuElement || !this.menuContainer) return;

    const { defaultMenuWidth } = this.state;
    const defaultWidth = defaultMenuWidth < this.menuElement.clientWidth ? this.menuElement.clientWidth : defaultMenuWidth;
    let menuPosition: any = {
      display: 'block',
      top: 'auto',
      bottom: 'auto'
    };

    const screenWidth = document.documentElement.clientWidth;
    const positionContainer = this.menuContainer.getBoundingClientRect();
    if (positionContainer.right >= defaultWidth) {
      menuPosition = { ...menuPosition, right: 0, left: 'auto' };
    } else if (screenWidth - positionContainer.left > defaultWidth) {
      menuPosition = { ...menuPosition, left: 0, right: 'auto' };
    } else {
      const width = screenWidth > defaultWidth ? defaultWidth : (screenWidth - 20);
      menuPosition = { ...menuPosition, width, right: positionContainer.right - width, left: 'auto' };
    }
    this.setState({ menuPosition, defaultMenuWidth: defaultWidth });
  }

  handleKeyDown = itemIndex => ({ keyCode }) => {
    /* istanbul ignore else */
    if (keyCode === MenuConstants.keyCodes.up) {
      // focus last menu item
      if (itemIndex === 0) {
        this.setFocusItem(this.props.menuItems.length - 1);
      } else {
        this.setFocusItem(itemIndex - 1);
      }
    }
    /* istanbul ignore else */
    if (keyCode === MenuConstants.keyCodes.down) {
      // focus first menu item
      if (itemIndex === this.props.menuItems.length - 1) {
        this.setFocusItem(0);
      } else {
        this.setFocusItem(itemIndex + 1);
      }
    }
    /* istanbul ignore else */
    if (keyCode === MenuConstants.keyCodes.enter) {
      this.handleClickItem(this.props.menuItems[itemIndex])();
    }
    /* istanbul ignore else */
    if (keyCode === MenuConstants.keyCodes.esc) {
      this.closeMenu(null);
    }
    /* istanbul ignore else */
    if (keyCode === MenuConstants.keyCodes.home) {
      this.setFocusItem(0);
    }
    /* istanbul ignore else */
    if (keyCode === MenuConstants.keyCodes.end) {
      this.setFocusItem(this.props.menuItems.length - 1);
    }
  };

  setFocusItem = itemIndex => {
    const menuItem = this.menuElement.querySelectorAll('.c-els-menu__item .c-tsp-nav-item')[itemIndex];
    /* istanbul ignore else */
    if (menuItem) {
      menuItem.focus();
    }
  };

  handleClickItem = item => () => {
    if (item.isDisabled) return;
    if (this.props.onItemClick) {
      this.props.onItemClick(item);
    }
    this.closeMenu(null);
  };

  isDescendant = (parent, child) => {
    let node = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  };

  getButtonClassName = () => {
    let buttonClassName = '';
    if (this.props.isAnchorized) {
      buttonClassName = cx(
        'u-els-anchorize c-els-link',
        {
          'c-els-link--with-icon': this.props.icon,
          [`c-els-link--with-icon-${this.props.iconPosition}`]: this.props.iconPosition
        },
        this.props.buttonClassName
      );
    } else {
      buttonClassName = cx(
        'c-els-menu__button c-els-button',
        {
          'c-els-button--icon': this.props.icon,
          'c-els-button--icon-only': !this.props.children,
          [`c-els-button--icon-${this.props.iconPosition}`]: this.props.iconPosition,
          [`c-els-button--${this.props.buttonType}`]: this.props.buttonType
        },
        this.props.buttonClassName
      );
    }
    return buttonClassName;
  };

  render() {
    const buttonProps = {
      buttonClassName: this.getButtonClassName(),
      icon: this.props.icon,
      iconPrefix: this.props.iconPrefix,
      iconPosition: this.props.iconPosition,
      content: this.props.children,
      toggleMenu: this.handleToggleMenu,
      isAnchorized: this.props.isAnchorized,
      buttonAriaLabel: this.props.buttonAriaLabel
    };
    const menuWindow = MenuWindowView;
    const injectProps = {
      className: this.props.className,
      buttonProps,
      showMenu: this.state.showMenu,
      menuPosition: this.state.menuPosition,
      verticalAlign: this.state.verticalAlign,
      maxHeight: this.state.maxHeight,
      menuItems: this.props.menuItems,
      handleKeyDown: this.handleKeyDown,
      handleClickItem: this.handleClickItem,
      setMenuWindow: this.setMenuWindow,
      setMenuContainer: this.setMenuContainer,
      menuWindow
    };
    return <MenuView {...injectProps} />;
  }
}

export default MenuComponent;
