import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { head, omit } from 'lodash';
import { CohortModel } from '../../models/opportunity.model';
import { ListingResponse, OpportunityRequestOptions, PagingOptions } from '../../models/api.model';
import { maximumOpportunities } from '../../config/app.config';
import { AppConstant } from '../../constants/app.constant';

export const REDUCER_ID = AppConstant.redux.OPPORTUNITIES_STATE;

const initialState = {
  opportunities: {}, // store list cohorts by opportunityID
  opportunitiesList: null, // store list opportunities
  opportunitiesPagingOptions: null,
  inflightCohortView: null
};

export const opportunitiesSlice = createSlice({
  name: 'opportunities',
  initialState,
  reducers: {
    setCohorts(state, action: PayloadAction<{opportunityId: string | number, response: any}>) {
      const newState = { ...state };
      const opportunity = newState.opportunities[action.payload.opportunityId] || {};
      const removedIds = [action.payload.opportunityId];
      const opportunityIds = Object.keys(newState.opportunities);
      if (opportunityIds.length === maximumOpportunities) {
        removedIds.push(head(opportunityIds));
      }
      newState.opportunities = omit(newState.opportunities, removedIds);
      return {
        ...newState,
        opportunities: {
          ...newState.opportunities,
          [action.payload.opportunityId]: { ...opportunity, ...action.payload.response }
        }
      };
    },
    updateCohort(state, action: PayloadAction<{opportunityId: string | number, cohort: CohortModel}>) {
      if (state.opportunities[action.payload.opportunityId] &&
        state.opportunities[action.payload.opportunityId].Cohorts && state.opportunities[action.payload.opportunityId].Cohorts.length > 0) {
        const newState = { ...state };
        const cohorts =
          newState.opportunities[action.payload.opportunityId].Cohorts.map(c => (c.CohortID === action.payload.cohort.CohortID ? action.payload.cohort : c));
        return {
          ...newState,
          opportunities: {
            ...newState.opportunities,
            [action.payload.opportunityId]: {
              ...newState.opportunities[action.payload.opportunityId],
              Cohorts: cohorts
            }
          }
        };
      }
      return state;
    },
    updateCohortPagingOptions(state, action: PayloadAction<{opportunityId: string | number, pagingOptions: PagingOptions}>) {
      const opportunity = state.opportunities[action.payload.opportunityId];
      if (!opportunity) {
        return state;
      }
      return {
        ...state,
        opportunities: {
          ...state.opportunities,
          [action.payload.opportunityId]: {
            ...opportunity,
            pagingOptions: {
              ...opportunity.pagingOptions,
              ...action.payload.pagingOptions
            }
          }
        }
      };
    },
    setOpportunitiesList(state, action: PayloadAction<{response: ListingResponse, pagingOptions: OpportunityRequestOptions, inflightCohortView: boolean }>) {
      return {
        ...state,
        opportunitiesList: action.payload.response,
        opportunitiesPagingOptions: state.opportunitiesPagingOptions || action.payload.pagingOptions,
        inflightCohortView: action.payload.inflightCohortView
      };
    },
    updateOpportunitiesPagingOptions(state, action: PayloadAction<OpportunityRequestOptions>) {
      const opportunitiesPagingOptions = {
        ...(state.opportunitiesPagingOptions || {}),
        ...action.payload
      };
      return {
        ...state,
        opportunitiesPagingOptions
      };
    },
    resetOpportunitiesList(state) {
      return {
        ...state,
        opportunitiesList: null,
        opportunitiesPagingOptions: null,
        inflightCohortView: null,
        query: null,
      };
    }
  }
});

export const opportunitiesActions = opportunitiesSlice.actions;
export const opportunitiesReducer = opportunitiesSlice.reducer;
