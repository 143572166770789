import { gatewayServiceApi } from 'helpers/RestClient.helper';
import { clone, omitBy, isNil } from 'lodash';
import { convertToBase64 } from 'utilities/app/app.utility';
import { InstallmentCommentModel } from '../../models/billing-queue.models';

export const fetchCommentsByInstallmentId = (installmentId: string | number) =>
gatewayServiceApi.get(`/api/tsp/billingDashboard/installments/${installmentId}/comments`) as Promise<{ totalCount: number, comments: InstallmentCommentModel[] }>;

const getPayloadAttachment = async (payload, attachment, index) => {
  const result = { ...payload };
  const content = await convertToBase64(attachment.content);
  delete result.attachments[index].path;
  result.attachments[index].content = content.replace('data:application/pdf;base64,', '');
};

export const addComment = async (installmentId: string | number, comment: string, attachmentsList): Promise<InstallmentCommentModel> => {
  const data = {
    installmentSysId: installmentId,
    comment,
    attachments: attachmentsList
  };
  const payload = clone(omitBy(data, isNil));
  const attachments = data.attachments || [];
  const allPromise = attachments.map((attachment, index) => getPayloadAttachment(payload, attachment, index));
  await Promise.all(allPromise);

  return gatewayServiceApi.post(`/api/tsp/billingDashboard/installments/${installmentId}/comments`,
    { data: payload }) as Promise<InstallmentCommentModel>;
};
