import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sanitize } from 'dompurify';
import { isEmpty } from 'lodash';
import ELSMenu from '../../common/menu/MenuComponent';
import { NavigationSectionType } from '../../../models/navigation.models';
import { appAsyncActions, appSelectors } from '../../../redux/app';
import { securitySelectors } from '../../../redux/security';
import { EVOLVE_NOTIFICATION_TIMEOUT } from '../../../constants/app.constant';

export interface AlertsNavSectionProps {
  section: NavigationSectionType;
}

const NotificationItem = ({ item }: { item: { name: string, message: string } }) => (
  <>
    <h3>{item.name}</h3>
    {/* eslint-disable-next-line react/no-danger */}
    <div dangerouslySetInnerHTML={{ __html: sanitize(item.message) }} />
  </>
);

const AlertsNavSection = ({ section }: AlertsNavSectionProps) => {
  const roleId = useSelector(securitySelectors.getRoleId);
  const isAuthenticated = useSelector(securitySelectors.isLoggedIn);
  const [evolveNotificationFlag, setEvolveNotificationFlag] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appAsyncActions.fetchEvolveNotifications(roleId, isAuthenticated));

    const timer = setTimeout(() => {
      setEvolveNotificationFlag(flag => !flag);
    }, EVOLVE_NOTIFICATION_TIMEOUT);
    return () => clearTimeout(timer);
  }, [evolveNotificationFlag]);

  const notifications = useSelector(appSelectors.getNotifications);
  const notificationItems = notifications.map(item => ({
    key: item.evolveNotificationId,
    content: <NotificationItem item={item} />
  }));

  let dropdownMenuItems;
  const noAlerts = isEmpty(notifications);
  if (noAlerts) {
    dropdownMenuItems = [{
      key: 'noAlerts',
      content: 'No Alerts'
    }];
  } else {
    dropdownMenuItems = [
      ...notificationItems
    ];
  }
  const counterFlag = !noAlerts ?
    <div className="c-tsp-nav-section--alerts__counter-flag">{dropdownMenuItems.length}</div> :
    <></>;
  return (
    <div className="c-tsp-nav-section--dropdown c-tsp-nav-section--alerts">
      <ul className="c-els-nav-group">
        <li className="c-els-nav-group__item o-els-layout--bottom u-els-margin-none">
          <ELSMenu
            menuItems={dropdownMenuItems}
            buttonType="tertiary"
            buttonClassName="u-els-padding-1o2
                             u-els-padding-left-1x
                             u-els-padding-right-1x
                             c-tsp-nav-section--dropdown__toggle-button"
            icon={section.iconName}
            iconPrefix={section.iconPrefix}>
            <div className="c-tsp-nav-section--dropdown__toggle-button__text">{section.iconLabel}</div>
            {counterFlag}
          </ELSMenu>
        </li>
      </ul>
    </div>
  );
};

export default AlertsNavSection;
