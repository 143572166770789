import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isBoolean } from 'lodash';
import { Loader } from '@els/els-react--loader';
import { locationAsyncActions } from '../../../../redux/location';
import { ALCRoutePath } from '../../constants/hesi.constants';
import { isAuthorizeOrderExams } from '../../redux/order-exams/order-exams.selectors';
import { checkAuthorizeOrderExams } from '../../redux/order-exams/order-exams.actions';
import { securitySelectors } from '../../../../redux/security';
import { StorageHelper } from '../../../../helpers/storage.helper';
import { REDIRECT_URL, RoutePath } from '../../../../constants/app.constant';
import { appSelectors } from '../../../../redux/app';

const withHESIAuthorize = WrappedComponent => props => {
  const isLoading = useSelector(appSelectors.isLoading);
  const isLoggedIn = useSelector(securitySelectors.isLoggedIn);
  const isAuthorized = useSelector(isAuthorizeOrderExams);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      const redirectUrl = `${props.location.pathname}${props.location.search || ''}`;
      StorageHelper.setItem(REDIRECT_URL, redirectUrl);
      dispatch(locationAsyncActions.redirect(RoutePath.security.login));
    }
  }, [isLoading, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && !isBoolean(isAuthorized)) {
      dispatch(checkAuthorizeOrderExams());
    }
  }, [isLoggedIn, isAuthorized]);

  useEffect(() => {
    if (isBoolean(isAuthorized) && !isAuthorized) {
      dispatch(locationAsyncActions.redirect(ALCRoutePath.error.notAuthorized));
    }
  }, [isAuthorized]);

  return (isLoggedIn && isAuthorized) ? <WrappedComponent {...props} /> : <><Loader isBlocking isVisible /></>;
};

export default withHESIAuthorize;
