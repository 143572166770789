import { ELSAdobeAnalyticService } from '@els/els-ui-common-react';
import { Analytics } from 'constants/app.constant';
import { analyticBaseConfig } from '../../config/app.config';

export const registerAdobeAnalytics = (user?) => {
  const { pageData } = (window as any);
  let config = {
    ...Analytics.defaultAnalyticsConfig,
    environment: pageData && pageData.page.environment
  };

  if (user) {
    config = {
      ...config,
      productName: pageData && pageData.page.productName,
      businessUnit: pageData && pageData.page.businessUnit,
      userId: `eols:${user.userId}`,
      accountName: pageData ? pageData.visitor.accountName : '',
      accountId: `eols:${user.userId}`
    };
  }
  ELSAdobeAnalyticService.registerAdobeAnalytic(analyticBaseConfig.adobeAnalyticServiceUrl, config);
};
