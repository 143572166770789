/* eslint sonarjs/no-duplicate-string:0 */
import { CommentModel } from '../models/common.models';
import {
  CohortProductModel,
  ProductApprovalStatusModel
} from '../models/cohort.models';
import { EvolveInstallmentModel } from '../models/installment.model';

export const INSTALLMENTS: EvolveInstallmentModel[] = [
  {
    installmentNumber: 1,
    installmentId: 'installment_001',
    installmentName: 'Installment 001',
    costPerStudent: 433.33,
    cmsStatus: 'Invoice Pending',
    cohortId: '41',
    invoiceDate: '5-1-2021',
    invoiceBomRefNumber: '123'
  },
  {
    installmentNumber: 2,
    installmentId: 'installment_002',
    installmentName: 'Installment 002',
    costPerStudent: 433.33,
    cmsStatus: 'Invoice Approved',
    cohortId: '42',
    invoiceDate: '12-31-2021',
    invoiceBomRefNumber: '123'
  },
  {
    installmentNumber: 3,
    installmentId: 'installment_003',
    installmentName: 'Installment 003',
    costPerStudent: 433.33,
    cmsStatus: 'Cohort Count Approval Request Sent',
    cohortId: '44',
    invoiceDate: '8-15-2022',
    invoiceBomRefNumber: '123'
  },
  {
    installmentNumber: 4,
    installmentId: 'installment_004',
    installmentName: 'Installment 004',
    costPerStudent: 433.33,
    cmsStatus: 'Cohort Counts Approved',
    cohortId: '44',
    invoiceDate: '8-15-2022',
    invoiceBomRefNumber: '123'
  },
  {
    installmentNumber: 5,
    installmentId: 'installment_005',
    installmentName: 'Installment 005',
    costPerStudent: 433.33,
    cmsStatus: 'Invoice Past Due',
    cohortId: '44',
    invoiceDate: '8-15-2022',
    invoiceBomRefNumber: '123'
  },
  {
    installmentNumber: 6,
    installmentId: 'installment_006',
    installmentName: 'Installment 006',
    costPerStudent: 433.33,
    cohortId: '44',
    invoiceDate: '8-15-2022'
  }
];

export const PRODUCTS: CohortProductModel[] = [
  {
    resourceId: '978-0-323-39627-1',
    name: 'Lewis’ Medical-Surgical Nursing Elsevier eBook on Vitalsource, 11th edition',
    productTypeName: 'eBook',
    shortAuthorsHtml: 'Elsevier',
    productThumbnailImgUrl: 'https://covers.elsevier.com/thumbnail_FW/9780323594431.jpg',
    listPrice: 0,
    copyYear: '2010',
    platformList: [
      {
        platformId: 'unique',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'global',
        hideIcon: true,
        formatConfig: []
      }, {
        platformId: 'bb_enterprise_6x',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'angel_6x',
        hideIcon: false,
        formatConfig: []
      }
    ],
    configuration: {
      courseStartDate: '2021-05-14T00:00:00Z',
      enrollmentStartDate: '2021-05-08T00:00:00Z',
      schoolCourseName: 'NURSE 3370 Intro Nursing Care Clinical',
      term: 2,
      leadFaculty: {
        ID: 1,
        FullName: 'Faculty A',
        FirstName: '',
        LastName: '',
        Email: ''
      },
      otherFaculties: [
        {
          ID: 2,
          FullName: 'Faculty B',
          FirstName: 'Faculty',
          LastName: 'B',
          Email: ''
        },
        {
          ID: 3,
          FullName: 'Faculty C',
          FirstName: 'Faculty',
          LastName: 'C',
          Email: ''
        }
      ]
    },
    archived: false
  },
  {
    resourceId: '978-0-323-39627-2',
    name: 'Medical Terminology Online with Elsevier Adaptive Learning for Medical Terminology: A Short Course, 8th Edition',
    productTypeName: 'HESI Practice Test',
    shortAuthorsHtml: 'Perry',
    courseIds: ['155081_jkitsis_1004'],
    productThumbnailImgUrl: 'https://covers.elsevier.com/thumbnail_FW/9780323370899.jpg',
    listPrice: 0,
    copyYear: '2016',
    platformList: [
      {
        platformId: 'unique',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'global',
        hideIcon: true,
        formatConfig: []
      }, {
        platformId: 'bb_enterprise_6x',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'angel_6x',
        hideIcon: false,
        formatConfig: []
      }
    ],
    configuration: {
      courseStartDate: '2021-05-14T00:00:00Z',
      enrollmentStartDate: '2021-05-08T00:00:00Z',
      schoolCourseName: 'NURSE 3370 Intro Nursing Care Clinical',
      term: '1',
      leadFaculty: {
        ID: 1,
        FullName: 'Faculty C',
        FirstName: 'Faculty',
        LastName: 'C',
        Email: ''
      },
      otherFaculties: [
        {
          ID: 2,
          FullName: 'Faculty F',
          FirstName: 'Faculty',
          LastName: 'F',
          Email: ''
        }
      ]
    },
    archived: false
  },
  {
    resourceId: '978-0-323-39627-3',
    name: 'Clinical Skills: Skills for Nursing Collection',
    productTypeName: 'eBook',
    shortAuthorsHtml: 'Potter',
    courseIds: ['155081_jkitsis_1004'],
    productThumbnailImgUrl: 'https://covers.elsevier.com/thumbnail_FW/9780323758765.jpg',
    listPrice: 0,
    copyYear: '2021',
    platformList: [
      {
        platformId: 'unique',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'global',
        hideIcon: true,
        formatConfig: []
      }, {
        platformId: 'bb_enterprise_6x',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'angel_6x',
        hideIcon: false,
        formatConfig: []
      }
    ],
    configuration: {
      courseStartDate: '2021-05-14T00:00:00Z',
      enrollmentStartDate: '2021-05-08T00:00:00Z',
      schoolCourseName: 'NURSE 3370 Intro Nursing Care Clinical',
      term: 1,
      leadFaculty: {
        ID: 1,
        FullName: 'Faculty A',
        FirstName: 'Faculty',
        LastName: 'A',
        Email: ''
      },
      otherFaculties: [
        {
          ID: 2,
          FullName: 'Faculty C',
          FirstName: 'Faculty',
          LastName: 'C',
          Email: ''
        }
      ]
    },
    archived: false
  },
  {
    resourceId: '978-0-323-39627-4',
    name: 'Elsevier Adaptive Quizzing for Basic Pharmacology for Nurses, 17th Edition',
    productTypeName: 'Online Course',
    shortAuthorsHtml: 'Potter',
    courseIds: ['155081_jkitsis_1004'],
    productThumbnailImgUrl: 'https://covers.elsevier.com/thumbnail_FW/9780323311151.jpg',
    listPrice: 0,
    copyYear: '2016',
    platformList: [
      {
        platformId: 'unique',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'global',
        hideIcon: true,
        formatConfig: []
      }, {
        platformId: 'bb_enterprise_6x',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'angel_6x',
        hideIcon: false,
        formatConfig: []
      }
    ],
    configuration: {
      schoolCourseName: 'NURSE 3315 Behavioral Health',
      term: 4,
      courseStartDate: '2021-05-14T00:00:00Z',
      enrollmentStartDate: '2021-05-08T00:00:00Z',
      leadFaculty: {
        ID: 1,
        FullName: 'Faculty B',
        FirstName: 'Faculty',
        LastName: 'B',
        Email: ''
      },
      otherFaculties: [
        {
          ID: 2,
          FullName: 'Faculty F',
          FirstName: 'Faculty',
          LastName: 'F',
          Email: ''
        }
      ]
    },
    archived: false
  },
  {
    resourceId: '978-0-323-39627-5',
    name: 'SimChart Psychiatric Collection – 6 month',
    productTypeName: 'eBook',
    shortAuthorsHtml: 'Elsevier',
    courseIds: ['155081_jkitsis_1004'],
    productThumbnailImgUrl: 'https://covers.elsevier.com/thumbnail_FW/9780323883610.jpg',
    listPrice: 0,
    copyYear: '2022',
    platformList: [
      {
        platformId: 'unique',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'global',
        hideIcon: true,
        formatConfig: []
      }, {
        platformId: 'bb_enterprise_6x',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'angel_6x',
        hideIcon: false,
        formatConfig: []
      }
    ],
    configuration: {
      schoolCourseName: 'NURSE 3370 Intro Nursing Care Clinical',
      term: 3,
      courseStartDate: '2021-05-14T00:00:00Z',
      enrollmentStartDate: '2021-05-08T00:00:00Z',
      leadFaculty: {
        ID: 1,
        FullName: 'Faculty B',
        FirstName: 'Faculty',
        LastName: 'B',
        Email: ''
      },
      otherFaculties: [
        {
          ID: 2,
          FullName: 'Faculty C',
          FirstName: 'Faculty',
          LastName: 'C',
          Email: ''
        }
      ]
    },
    archived: false
  },
  {
    resourceId: '978-0-323-39627-6',
    name: 'Shadow Health',
    productTypeName: 'eBook',
    shortAuthorsHtml: 'Elsevier',
    courseIds: ['155081_jkitsis_1004'],
    productThumbnailImgUrl: 'https://covers.elsevier.com/thumbnail_FW/9780323753685.jpg',
    listPrice: 0,
    copyYear: '2021',
    platformList: [
      {
        platformId: 'unique',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'global',
        hideIcon: true,
        formatConfig: []
      }, {
        platformId: 'bb_enterprise_6x',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'angel_6x',
        hideIcon: false,
        formatConfig: []
      }
    ],
    configuration: {
      schoolCourseName: 'NURSE 3315 Behavioral Health',
      term: 2,
      courseStartDate: '2021-05-14T00:00:00Z',
      enrollmentStartDate: '2021-05-08T00:00:00Z',
      leadFaculty: {
        ID: 1,
        FullName: 'Faculty D',
        FirstName: 'Faculty',
        LastName: 'D',
        Email: ''
      },
      otherFaculties: [
        {
          ID: 2,
          FullName: 'Faculty F',
          FirstName: 'Faculty',
          LastName: 'F',
          Email: ''
        }
      ]
    },
    archived: false
  },
  {
    resourceId: '978-0-323-39627-7',
    name: 'Simulation Learning Suite for Maternity and Women’s Health Nursing',
    productTypeName: 'eBook',
    shortAuthorsHtml: 'Elsevier',
    courseIds: ['155081_jkitsis_1004'],
    productThumbnailImgUrl: 'https://covers.elsevier.com/thumbnail_FW/9781455750061.jpg',
    listPrice: 0,
    copyYear: '2013',
    platformList: [
      {
        platformId: 'unique',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'global',
        hideIcon: true,
        formatConfig: []
      }, {
        platformId: 'bb_enterprise_6x',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'angel_6x',
        hideIcon: false,
        formatConfig: []
      }
    ],
    configuration: {
      schoolCourseName: 'NURSE 3370 Intro Nursing Care Clinical',
      term: 3,
      courseStartDate: '2021-05-14T00:00:00Z',
      enrollmentStartDate: '2021-05-08T00:00:00Z',
      leadFaculty: {
        ID: 1,
        FullName: 'Faculty D',
        FirstName: 'Faculty',
        LastName: 'D',
        Email: ''
      },
      otherFaculties: [
        {
          ID: 2,
          FullName: 'Faculty F',
          FirstName: 'Faculty',
          LastName: 'F',
          Email: ''
        }
      ]
    },
    archived: false
  },
  {
    resourceId: '978-0-323-39627-8',
    name: 'HESI Specialty Exams NG (unlimited)',
    productTypeName: 'HESI Practice Test',
    shortAuthorsHtml: 'Elsevier',
    productThumbnailImgUrl: 'https://covers.elsevier.com/thumbnail_FW/9780323311151.jpg',
    listPrice: 0,
    copyYear: '2020',
    platformList: [
      {
        platformId: 'unique',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'global',
        hideIcon: true,
        formatConfig: []
      }, {
        platformId: 'bb_enterprise_6x',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'angel_6x',
        hideIcon: false,
        formatConfig: []
      }
    ],
    configuration: {
      schoolCourseName: 'NURSE 3315 Behavioral Health',
      term: 4,
      courseStartDate: '2021-05-14T00:00:00Z',
      enrollmentStartDate: '2021-05-08T00:00:00Z',
      leadFaculty: {
        ID: 1,
        FullName: 'Faculty D',
        FirstName: 'Faculty',
        LastName: 'D',
        Email: ''
      },
      otherFaculties: [
        {
          ID: 2,
          FullName: 'Faculty F',
          FirstName: 'Faculty',
          LastName: 'F',
          Email: ''
        }
      ]
    },
    archived: false
  },
  {
    resourceId: '978-0-323-39627-9',
    name: 'HESI Custom Exams (2)',
    productTypeName: 'eBook',
    shortAuthorsHtml: 'Elsevier',
    productThumbnailImgUrl: 'https://covers.elsevier.com/thumbnail_FW/9780323582476.jpg',
    listPrice: 0,
    copyYear: '2019',
    platformList: [
      {
        platformId: 'unique',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'global',
        hideIcon: true,
        formatConfig: []
      }, {
        platformId: 'bb_enterprise_6x',
        hideIcon: false,
        formatConfig: []
      }, {
        platformId: 'angel_6x',
        hideIcon: false,
        formatConfig: []
      }
    ],
    configuration: {
      schoolCourseName: 'NURSE 3370 Intro Nursing Care Clinical',
      term: 5,
      courseStartDate: '2021-05-14T00:00:00Z',
      enrollmentStartDate: '2021-05-08T00:00:00Z',
      leadFaculty: {
        ID: 1,
        FullName: 'Faculty D',
        FirstName: 'Faculty',
        LastName: 'D',
        Email: ''
      },
      otherFaculties: [
        {
          ID: 2,
          FullName: 'Faculty F',
          FirstName: 'Faculty',
          LastName: 'F',
          Email: ''
        }
      ]
    },
    archived: false
  }
];

export const COMMENTS: CommentModel[] = [
  {
    CommentID: '1',
    CohortID: 'cohort#1',
    CommentDate: '2021-12-14T09:32:00Z',
    FirstName: 'Kristin',
    LastName: 'Zurliene',
    Email: 'kzurliene@email.com',
    Comment: 'Reached out to institution to begin planning for term 2 product selections'
  },
  {
    CommentID: '2',
    CohortID: 'cohort#2',
    CommentDate: '2021-12-10T13:02:00Z',
    FirstName: 'Kristin',
    LastName: 'Zurliene',
    Email: 'kzurliene@email.com',
    Comment: 'Setup complete'
  },
  {
    CommentID: '3',
    CohortID: 'cohort#3',
    CommentDate: '2021-05-09T10:13:00Z',
    FirstName: 'Kristin',
    LastName: 'Zurliene',
    Email: 'kzurliene@email.com',
    Comment: 'Cohort Created'
  }
];

export const APPROVALS: ProductApprovalStatusModel[] = [
  {
    id: 2,
    activityId: 2,
    activityDate: '12/15/20 09:32 am',
    name: 'Rejected',
    desc: 'Rejected',
    notes: 'These products don\'t seem right for my course'
  },
  {
    id: 1,
    activityId: 2,
    activityDate: '11/15/20 09:32 am',
    name: 'Approval Request Sent',
    desc: 'Approval Request Sent',
    activityBy: {
      firstName: 'A',
      lastName: 'Test',
      email: 'testa@yopmail.com'
    }
  }
];
