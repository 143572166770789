import { compose } from 'recompose';
import classNames from 'classnames';
import { ELSButton } from 'components/common/els';
import { useDispatch, useSelector } from 'react-redux';
import { locationAsyncActions } from 'redux/location';
import withLoading from '../../hocs/with-loading/withLoading.hoc';
import CreditRequestSubmission from '../../components/submit-credit-request/tabs/submit-credit-request-number/CreditRequestSubmission.component';
import CreditRequestTrack from '../../components/submit-credit-request/tabs/track-credit-request-number/CreditRequestTrack.component';
import { RoutePath, UserRole } from '../../constants/app.constant';
import { securitySelectors } from '../../redux/security';
import { InternalCreditRequestModel } from '../../models/credit-request.models';

export enum SubmitCreditRequestTabs {
  SubmitCreditRequest = 'submitCreditLink',
  TrackCreditRequest = 'trackCreditLink'
}

interface SubmitCreditRequestPageProps {
  activeTab: SubmitCreditRequestTabs;
}

interface LayoutProps extends InternalCreditRequestModel {
  activeLink: SubmitCreditRequestTabs;
}

const Layout = ({ activeLink, isInternalCreditRequest }: LayoutProps) => {
  const dispatch = useDispatch();

  const handleSelectTab = (link: string) => {
    dispatch(locationAsyncActions.redirect(link));
  };

  const activeView =
    activeLink === SubmitCreditRequestTabs.SubmitCreditRequest ? (
      <CreditRequestSubmission isInternalCreditRequest={isInternalCreditRequest} />
    ) : (
      <CreditRequestTrack isInternalCreditRequest={isInternalCreditRequest} />
    );

  return (
    <>
      {!isInternalCreditRequest && (
        <div className="c-els-credit-layout">
          <div className="c-els-credit-layout__left-menu">
            <div className={classNames('c-els-credit-layout__item', { 'c-els-credit-layout__item--active': activeLink === SubmitCreditRequestTabs.SubmitCreditRequest })}>
              <ELSButton type="debuttonize" attrType="button" className="c-els-credit-layout__item__anchor" onClick={() => handleSelectTab(RoutePath.submitCreditRequest)}>
                <span className="c-els-anchor__text u-tsp-font-size-1x1o4">Submit Program Solutions Credit Request</span>
              </ELSButton>
            </div>
            <div className={classNames('c-els-credit-layout__item', { 'c-els-credit-layout__item--active': activeLink === SubmitCreditRequestTabs.TrackCreditRequest })}>
              <ELSButton type="debuttonize" attrType="button" className="c-els-credit-layout__item__anchor" onClick={() => handleSelectTab(RoutePath.trackCreditRequest)}>
                <span className="c-els-anchor__text u-tsp-font-size-1x1o4">Track Program Solutions Credit Request</span>
              </ELSButton>
            </div>
          </div>
        </div>
      )}
      <div className="o-els-container c-els-margin-align">{activeView}</div>
    </>
  );
};

const SubmitAndTrackCreditRequestPage = (props: SubmitCreditRequestPageProps) => {
  const isInternalCreditRequest = useSelector(securitySelectors.isAuthorized([UserRole.TspSuperUser, UserRole.TspBillingSpecialist]));

  return (
    <div className={`c-tsp-credit-request ${isInternalCreditRequest ? 'c-tsp-credit-request-custom' : ''}`}>
      <Layout activeLink={props.activeTab || SubmitCreditRequestTabs.SubmitCreditRequest} isInternalCreditRequest={isInternalCreditRequest} />
    </div>
  );
};

const enhancers = [withLoading];

export default compose(...enhancers)(SubmitAndTrackCreditRequestPage);
