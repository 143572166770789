import { Redirect, Route, Switch } from 'react-router';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary.component';
import HESILayout from './layout/HESILayout.component';
import { ALCRoutePath } from '../constants/hesi.constants';
import 'modules/hesi/assets/main.scss';
import OrderExamHomePage from '../pages/order-exam-home-page/OrderExamHomePage.page';
import { HESISecutityRoutes } from '../routes/secutity.routes';
import { createHESIRoutes } from '../utilities/route/route.utility';

const hesiSecurityRoutes = createHESIRoutes(HESISecutityRoutes.getRoutes());
const HESIApp = () => (
  <HESILayout>
    <ErrorBoundary>
      <Switch>
        {hesiSecurityRoutes}
        <Route path={ALCRoutePath.orderExams.home} component={OrderExamHomePage} />
        <Redirect exact from={ALCRoutePath.rootPath} to={ALCRoutePath.orderExams.home} />
        <Redirect to={ALCRoutePath.error.pageNotFound} />
      </Switch>
    </ErrorBoundary>
  </HESILayout>
);

export default HESIApp;
