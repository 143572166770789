import { lazy } from 'react';
import withExtendProps from 'hocs/with-extend-props/withExtendProps.hoc';
import { ALCAnalytics, ALCRoutePath } from '../constants/hesi.constants';
import { ORDER_CART_TABS } from '../constants/order-exams.constant';

export const getRoutes = () => [
  {
    path: ALCRoutePath.orderExams.orderCartReview,
    component: withExtendProps({
      activeTab: ORDER_CART_TABS.REVIEW
    })(lazy(() => import('modules/hesi/pages/order-management/OrderManagement.page'))),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.orderCartReview,
        pageName: 'alc-order-cart:review'
      }
    }
  },
  {
    path: ALCRoutePath.orderExams.orderCartPayment,
    component: withExtendProps({
      activeTab: ORDER_CART_TABS.PAYMENT
    })(lazy(() => import('modules/hesi/pages/order-management/OrderManagement.page'))),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.orderCartPayment,
        pageName: 'alc-order-cart:payment'
      }
    }
  },
  {
    path: ALCRoutePath.orderExams.orderCartConfirm,
    component: withExtendProps({
      activeTab: ORDER_CART_TABS.CONFIRM
    })(lazy(() => import('../pages/order-management/OrderManagement.page'))),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.orderCartConfirm,
        pageName: 'alc-order-cart:confirm'
      }
    }
  },
  {
    path: ALCRoutePath.orderExams.examTypes,
    component: lazy(() => import('../pages/exam-selection/ExamTypeSelection.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.examTypes,
        pageName: 'alc-exam-types'
      }
    }
  },
  {
    path: ALCRoutePath.orderExams.examCatalog,
    component: lazy(() => import('../pages/exam-selection/ExamSelection.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.examCatalog,
        pageName: 'alc-exam-catalog'
      }
    }
  },
  {
    path: ALCRoutePath.orderExams.programTypes,
    component: lazy(() => import('../pages/exam-selection/ProgramTypeSelection.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.orderHESIExams,
        pageName: 'alc-program-types'
      }
    }
  }
];

export const ALCRoutes = {
  getRoutes
};
