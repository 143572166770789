import { pick } from 'lodash';
import { PagingOptions } from '../../models/api.model';
import { RosterModel, RosterRequestModel, StudentGroupModel } from '../../models/roster.models';
import { tspServiceApi } from '../../helpers/RestClient.helper';
import { buildEvolvePagingOptions } from '../../helpers/api.helpers';
import { VALIDATE_RESPONSE_ERROR_TYPE } from '../../constants/app.constant';
import { StudentPaymentHistory } from '../../models/student-payments.models';

const FACULTY_ROSTER_FIELDS = ['firstName', 'lastName', 'email', 'enrollmentRole', 'title'];
export const STUDENT_ROSTER_FIELDS = ['firstName', 'lastName', 'email', 'enrollmentRole', 'isGroupEntitlement'];

type FetchFacultyRostersAPIReponse = Promise<{
  enrollments: RosterModel[];
  totalCount: number;
  tspRosterId?: number;
}>
export const fetchFacultyRosters = async (resourceId: string | number, status: boolean, pagingOptions: PagingOptions, includeTspCohortPermissions = '') => {
  const data = {
    enrolled: status,
    includeTspCohortPermissions,
    ...buildEvolvePagingOptions(pagingOptions)
  };
  try {
    return await tspServiceApi.post(`/roster/byOpportunity/${resourceId}`, { data }) as FetchFacultyRostersAPIReponse;
  } catch (err) {
    if (err.response.status === 404) {
      return {
        enrollments: [],
        totalCount: 0
      };
    }
    throw err;
  }
};

type RosterRequestsAPIResponse = Promise<{ rosterRequests: RosterRequestModel[] }>;
export const fetchRosterHistory = async (cohortId: string | number) =>
  tspServiceApi.get(`/roster/byCohort/${cohortId}/rosterRequestHistory`) as RosterRequestsAPIResponse;

type GetPaymentHistoryAPIResponse = Promise<{ studentPaymentHistory: StudentPaymentHistory[] }>;
export const getPaymentHistory = async (insId: string, userName: string) =>
  tspServiceApi.get(`/roster/installment/${insId}/student/${userName}/studentPaymentHistory`) as GetPaymentHistoryAPIResponse;

export const sendRosterRequest = async (faculties: string[], opportunityId: string | number, cohortId: string | number) => {
  const data = {
    faculties,
    opportunityId,
    cohortId
  };

  return tspServiceApi.post(`/roster/byCohort/${cohortId}/sendRosterRequest`, { data });
};

export const sendPaymentReminderEmail = async (opportunityId: string, cohortId: string, installmentId: string, userName: string) => {
  const data = {
    opportunityId,
    cohortId,
    installmentId,
    userName
  };
  return tspServiceApi.post(`/roster/installment/${installmentId}/student/${userName}/sendStudentPaymentNotificationEmail`, { data });
};

export const fetchAllFacultyRosters = async (resourceId: string | number, activePagingOptions: PagingOptions, removedPagingOptions: PagingOptions) =>
  Promise.all([
    fetchFacultyRosters(resourceId, true, activePagingOptions),
    fetchFacultyRosters(resourceId, false, removedPagingOptions)
  ]);

type FetchStudentRostersAPIResponse = Promise<{
  enrollments: RosterModel[];
  totalCount: number;
  tspRosterId?: number;
  locked?: boolean;
  cohortCount?: number;
}>
export const fetchStudentRosters = async (resourceId: string | number, status: boolean, pagingOptions: PagingOptions, includedInCohortCount = false) => {
  const data = {
    enrolled: status,
    includedInCohortCount,
    filteredGroups: pagingOptions.filteredGroups,
    ...buildEvolvePagingOptions(pagingOptions)
  };
  try {
    return await tspServiceApi.post(`/roster/byCohort/${resourceId}`, { data }) as FetchStudentRostersAPIResponse;
  } catch (err) {
    if (err.response.status === 404) {
      return {
        enrollments: [],
        totalCount: 0
      };
    }
    throw err;
  }
};

export const fetchAllStudentRosters = async (resourceId: string | number, enrolledPagingOptions: PagingOptions, droppedPagingOptions: PagingOptions) =>
  Promise.all([
    fetchStudentRosters(resourceId, true, enrolledPagingOptions),
    fetchStudentRosters(resourceId, false, droppedPagingOptions)
  ]);

export const createOrUpdateFacultyRosters = async (rosterId: string, opportunityId: string | number, rosters: RosterModel[]) => {
  let data: any = {
    enrollments: rosters.map(r => ({
      ...pick(r, FACULTY_ROSTER_FIELDS)
    }))
  };
  let url = '/roster';
  let method = 'post';
  if (rosterId) {
    url = `/roster/${rosterId}`;
    method = 'put';
  } else {
    data = {
      ...data,
      opportunityId
    };
  }
  try {
    return await tspServiceApi[method](url, { data });
  } catch (err) {
    const { errors } = err.response.data;
    if (method === 'post' && errors && errors[0].type === 'item_exist' && errors[0].detail.tspRosterId) {
      return tspServiceApi.put(`/roster/${errors[0].detail.tspRosterId}`, { data });
    }
    throw err;
  }
};

export const createOrUpdateStudentRosters = async (rosterId: string, opportunityId: string | number, cohortId: string | number, rosters: RosterModel[]) => {
  let data: any = {
    enrollments: rosters.map(r => ({
      ...pick(r, STUDENT_ROSTER_FIELDS)
    }))
  };
  let url = '/roster';
  let method = 'post';
  if (rosterId) {
    url = `/roster/${rosterId}`;
    method = 'put';
  } else {
    data = {
      ...data,
      opportunityId,
      cohortId,
    };
  }
  try {
    return await tspServiceApi[method](url, { data });
  } catch (err) {
    const { errors } = err.response.data;
    if (method === 'post' && errors && errors[0].type === 'item_exist' && errors[0].detail.tspRosterId) {
      return tspServiceApi.put(`/roster/${errors[0].detail.tspRosterId}`, { data });
    }
    throw err;
  }
};

export const updateFacultyTitle = async (rosterId: string, userName: string, title: string) => {
  const data = {
    enrollments: [
      {
        userName,
        title
      }
    ]
  };
  return tspServiceApi.put(`/roster/${rosterId}`, { data });
};

type UpdateIncludedInCohortAPIResponse = Promise<{ cohortCount: number }>;
export const updateIncludedInCohort = async (rosterId: string, userName: string, status: boolean) => {
  const data = {
    enrollments: [
      {
        userName,
        includedInCohortCount: status
      }
    ]
  };
  return tspServiceApi.put(`/roster/${rosterId}`, { data }) as UpdateIncludedInCohortAPIResponse;
};

export const removeRoster = async (rosterId: string, userName: string, ignoreOtherFacultyChecking = false) => {
  const data = ignoreOtherFacultyChecking ?
    {
      'un-enrollments': [{ userName }],
      ignoreOtherFacultyChecking
    } :
    { 'un-enrollments': [{ userName }] };

  return tspServiceApi.put(`/roster/${rosterId}`, { data });
};

export const reactiveRoster = async (rosterId: string, userName: string, isGroupEntitlement = false, groups = []) => {
  const data = {
    enrollments: [
      {
        userName,
        isGroupEntitlement,
        groups
      }
    ]
  };
  return tspServiceApi.put(`/roster/${rosterId}`, { data });
};

type FetchStudentRostersApprovalAPIResponse = Promise<{
  enrollments: RosterModel[];
  totalCount: number;
}>
export const fetchStudentRostersApproval =
  async (cohortId: string | number, status: boolean, pagingOptions: PagingOptions, invoiceId: string, installmentId: string, activityId: number, includedInCohortCount = false) => {
    const data = {
      invoiceId,
      installmentId,
      activityId,
      enrolled: status,
      includedInCohortCount,
      ...buildEvolvePagingOptions(pagingOptions)
    };
    try {
      return await tspServiceApi.post(`/approval/roster/byCohort/${cohortId}`, { data }, VALIDATE_RESPONSE_ERROR_TYPE.REVIEW_APPROVAL) as FetchStudentRostersApprovalAPIResponse;
    } catch (err) {
      if (err.response.status === 404) {
        return {
          enrollments: [],
          totalCount: 0
        };
      }
      throw err;
    }
  };

export const fetchRostersByInstallmentId = async (installmentId: string | number, pagingOptions: PagingOptions) => {
  const data = { ...buildEvolvePagingOptions(pagingOptions) };
  try {
    return await tspServiceApi.post(`/roster/byInstallment/${installmentId}`, { data }) as Promise<{ enrollments: RosterModel[], totalCount: number }>;
  } catch (err) {
    if (err.response.status === 404) {
      return {
        enrollments: [],
        totalCount: 0
      };
    }
    throw err;
  }
};

type StoreStudentGroupAPIResponse = Promise<{ tspGroupId: number, groupName: string }>

export const createGroupStudent = data => tspServiceApi.post(`/roster/byCohort/${data.cohortId}/groups`, { data }) as StoreStudentGroupAPIResponse;

export const updateGroupStudent = data => tspServiceApi.put(`/roster/byCohort/${data.cohortId}/groups/${data.tspGroupId}`, { data }) as StoreStudentGroupAPIResponse;

type FetchStudentGroupsAPIResponse = Promise<{
  tspCohortId: string | number;
  groups: StudentGroupModel[];
}>

export const fetchStudentGroups = (cohortId: string | number) => tspServiceApi.get(`/roster/byCohort/${cohortId}/groups`) as FetchStudentGroupsAPIResponse;

export const fetchStudentRosterInfo = (rosterId: string, tspRosterEnrollmentsId: number) =>
  tspServiceApi.get(`/roster/${rosterId}/${tspRosterEnrollmentsId}`) as Promise<RosterModel>;

type GetStudentUniqueGuidAPIResponse = Promise<{
  studentUniqueUrlGuid: string;
}>
export const getStudentUniqueUrlGuid = (cohortId: string | number) => tspServiceApi.get(`/roster/byCohort/${cohortId}/studentUniqueGuid`) as GetStudentUniqueGuidAPIResponse;
