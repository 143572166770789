import { pick } from 'lodash';
import { STUDENT_ROSTER_FIELDS } from 'services/roster/roster.service';
import { PagingOptions } from 'models/api.model';
import { buildEvolvePagingOptions } from 'helpers/api.helpers';
import { VALIDATE_RESPONSE_ERROR_TYPE } from 'constants/app.constant';
import { StudentRosterSubmissionModel } from 'models/roster-submission.model';
import { User } from 'models/components.model';
import { RosterSubmissionDetail } from '../../models/roster-submission.model';
import { tspServiceApi } from '../../helpers/RestClient.helper';
import { transformToCohortPackage } from '../../utilities/app/app.utility';
import { RosterModel } from '../../models/roster.models';

const buildUser = (user: any): User => (user && {
  firstName: user.firstName,
  lastName: user.lastName
});

export const fetchRosterSubmission = async (guid: string): Promise<RosterSubmissionDetail> => {
  const response: any = await tspServiceApi.get(`/rosterSubmission/${guid}`, {}, VALIDATE_RESPONSE_ERROR_TYPE.ROSTER_SUBMISSION);
  return Promise.resolve({
    AccountName: response.accountName,
    OpportunityID: response.opportunityId,
    OpportunityName: response.opportunityName,
    CohortID: response.cohortId,
    CohortName: response.cohortName,
    CohortCount: response.cohortCount,
    Program: response.programName,
    StartDate: response.startDate,
    EndDate: response.endDate,
    Package: transformToCohortPackage(response.package),
    StudentUniqueUrlGuid: response.studentUniqueUrlGuid
  });
};

export const fetchStudentRosterSubmission = async (guid: string): Promise<StudentRosterSubmissionModel> => {
  const response: any = await tspServiceApi.get(`/rosterSubmission/student/${guid}`, {}, VALIDATE_RESPONSE_ERROR_TYPE.ROSTER_SUBMISSION);
  return Promise.resolve({
    AccountName: response.accountName,
    OpportunityID: response.opportunityId,
    OpportunityName: response.opportunityName,
    CohortID: response.cohortId,
    CohortName: response.cohortName,
    Program: response.programName,
    StartDate: response.startDate,
    EndDate: response.endDate,
    Locked: response.locked,
    Faculty: buildUser(response.faculty),
    Enrolled: response.enrolled,
    IsInternalRequest: response.isInternalRequest
  });
};

type FetchRostersSubmissionByCohortAPIResponse = Promise<{
  enrollments: RosterModel[];
  totalCount: number;
  tspRosterId?: number;
  locked?: boolean;
  cohortCount?: number;
}>;
export const fetchRostersSubmissionByCohort = async (cohortId: string | number, status: boolean, pagingOptions: PagingOptions) => {
  const data = {
    enrolled: status,
    ...buildEvolvePagingOptions(pagingOptions)
  };
  try {
    return await tspServiceApi
      .post(`/rosterSubmission/roster/byCohort/${cohortId}`, { data }, VALIDATE_RESPONSE_ERROR_TYPE.ROSTER_SUBMISSION) as FetchRostersSubmissionByCohortAPIResponse;
  } catch (err) {
    if (err.response.status === 404) {
      return {
        enrollments: [],
        totalCount: 0
      };
    }
    throw err;
  }
};

export const updateRoster = (rosterId: string, payload: any) => {
  const data = {
    ...payload
  };
  return tspServiceApi.put(`/rosterSubmission/roster/${rosterId}`, { data }, VALIDATE_RESPONSE_ERROR_TYPE.ROSTER_SUBMISSION);
};

export const createRoster = (payload) => {
  const data = {
    ...payload
  };
  return tspServiceApi.post('/rosterSubmission/roster', { data }, VALIDATE_RESPONSE_ERROR_TYPE.ROSTER_SUBMISSION);
};

export const createOrUpdateRosterSubmission = async (rosterId: string, opportunityId: string | number, cohortId: string | number, data: any) => {
  const payload = {
    enrollments: data.map(r => ({
      ...pick(r, STUDENT_ROSTER_FIELDS)
    })),
    opportunityId,
    cohortId,
  };
  try {
    if (rosterId) {
      await updateRoster(rosterId, payload);
    } else {
      await createRoster(payload);
    }
  } catch (err) {
    const { errors } = err.response.data;
    if (!rosterId && errors && errors[0].type === 'item_exist' && errors[0].detail.tspRosterId) {
      updateRoster(errors[0].detail.tspRosterId, payload);
    }
    throw err;
  }
};

export const updateStudentRosterSubmission = async (studentGuid: string) => {
  const data = {
    studentGuid
  };
  await tspServiceApi.post('/rosterSubmission/roster/student', { data }, VALIDATE_RESPONSE_ERROR_TYPE.ROSTER_SUBMISSION);
};
