import React from 'react';
import CreditRequestContact from './CreditRequestContact.component';
import { InternalCreditRequestModel } from '../../models/credit-request.models';

const CreditRequestDescription = ({ isInternalCreditRequest }: InternalCreditRequestModel) => (
  <>
    <h4 className="u-els-margin-top">
      {!isInternalCreditRequest && <strong>Credit requests must be submitted within 30 days of the invoice date.&nbsp;</strong>}
        To submit a program solutions credit request you will first need to enter a valid program solutions invoice number.
    </h4>
    {!isInternalCreditRequest && <CreditRequestContact />}
  </>
);

export default CreditRequestDescription;
