/* eslint sonarjs/no-duplicate-string: 0 */
import { RoutePath } from './app.constant';
import { getProperty } from '../config/app.config';

const evolvePortalBaseUrl = getProperty('evolvePortalBaseUrl');

export const SUPPORT_EMAIL_URL = 'https://service.elsevier.com/app/contact/supporthub/evolve/';

export enum FooterMenuItemType {
  SUPPORT_EMAIL = 1
}

type FooterMenuItem = {
  id: string,
  name: string,
  href?: string,
  target?: string,
  type?: FooterMenuItemType,
  internal?: boolean
};

type FooterMenuGroup = {
  id: string,
  name: string,
  href?: string,
  target?: string,
  type?: FooterMenuItemType,
  internal?: boolean,
  items: FooterMenuItem[]
};

const GET_SUPPORT_MENU: FooterMenuGroup = {
  id: 'mnuGetSupport',
  name: 'Get Support',
  href: 'https://service.elsevier.com/app/overview/evolve/',
  target: '_blank',
  items: [
    {
      id: 'mnuForgotPassword',
      name: 'Forgot Password',
      href: 'https://service.elsevier.com/app/answers/detail/a_id/8356/supporthub/evolve/',
      internal: false,
      target: '_blank',
    },
    {
      id: 'mnuAccessCode',
      name: 'Access code not working',
      href: 'https://service.elsevier.com/app/answers/detail/a_id/8313/supporthub/evolve/kw/access+code/',
      target: '-blank',
    },
    {
      id: 'mnuRequestingResources',
      name: 'Requesting resources',
      href: 'https://service.elsevier.com/app/answers/detail/a_id/3715/supporthub/evolve/',
      target: '_blank',
    },
    {
      id: 'mnuSubmitSupportEmail',
      name: 'Submit support email',
      type: FooterMenuItemType.SUPPORT_EMAIL,
      target: '_blank',
    }
  ]
};

const LEARN_MORE_MENU: FooterMenuGroup = {
  id: 'mnuLearnMore',
  name: 'Learn More',
  href: `${evolvePortalBaseUrl}/education/about/`,
  target: '_blank',
  items: [
    {
      id: 'mnuElsevierEducation',
      name: 'Elsevier Education',
      href: `${evolvePortalBaseUrl}/education`,
      internal: false,
      target: '_blank',
    },
    {
      id: 'mnuProductTraining',
      name: 'Product Training',
      href: `${evolvePortalBaseUrl}/education/training/`,
      target: '_blank',
    },
    {
      id: 'mnuStudentTraining',
      name: 'Student Training',
      href: `${evolvePortalBaseUrl}/studentlife/index.html`,
      target: '_blank',
    },
    {
      id: 'mnuFindYourESC',
      name: 'Find your Education Solutions Consultant',
      href: `${evolvePortalBaseUrl}/education/replocator-home/`,
      target: '-blank'
    }
  ]
};

const MY_ACCOUNT_MENU: FooterMenuItem[] = [
  {
    id: 'mnuMyAccount',
    name: 'My Account',
    href: `${evolvePortalBaseUrl}/cs/account`,
    internal: false,
    target: null,
  },
  {
    id: 'mnuOrderHistory',
    name: 'Order history',
    href: `${evolvePortalBaseUrl}/cs/orderHistory/`,
    internal: false,
    target: null,
  },
  {
    id: 'mnuLogout',
    name: 'Logout',
    href: RoutePath.security.logout,
    target: null,
    internal: true
  }
];

export const ANONYMOUS_FOOTER_MENU: FooterMenuGroup[] = [
  {
    id: 'mnuMyEvolve',
    name: 'My Evolve',
    href: RoutePath.security.login,
    internal: true,
    target: null,
    items: [
      {
        id: 'mnuMyAccount',
        name: 'My Account',
        href: RoutePath.security.login,
        target: null,
        internal: true
      },
      {
        id: 'mnuOrderHistory',
        name: 'Order history',
        href: RoutePath.security.login,
        target: null,
        internal: true
      },
      {
        id: 'mnuNotAEducator',
        name: 'Not an educator?',
        href: '/cs/store?role=faculty',
        target: null
      },
      {
        id: 'mnuSignIn',
        name: 'Sign in',
        href: RoutePath.security.login,
        target: null,
        internal: true
      }
    ]
  },
  {
    id: 'mnuCatalog',
    name: 'Catalog',
    href: `${evolvePortalBaseUrl}/cs/store?role=student`,
    target: null,
    items: [
      {
        id: 'mnuRedeemAccessCode',
        name: 'Redeem access code',
        href: `${evolvePortalBaseUrl}/cs/store?role=student&redeem=1#redeem`,
        internal: false,
        target: null,
      },
      {
        id: 'mnuBrowseByDiscipline',
        name: 'Discipline list',
        href: `${evolvePortalBaseUrl}/cs/products`,
        target: null,
      },
      {
        id: 'mnuSubmitClassRoster',
        name: 'Submit class roster',
        href: `${evolvePortalBaseUrl}/cs/courseRoster/createRoster`,
        target: '_blank',
      }
    ]
  },
  {
    ...GET_SUPPORT_MENU
  },
  {
    ...LEARN_MORE_MENU
  }
];

export const STUDENT_FOOTER_MENU: FooterMenuGroup[] = [
  {
    id: 'mnuMyEvolve',
    name: 'My Evolve',
    href: `${evolvePortalBaseUrl}/cs/myEvolve`,
    target: null,
    internal: false,
    items: [...MY_ACCOUNT_MENU]
  },
  {
    id: 'mnuCatalog',
    name: 'Catalog',
    href: `${evolvePortalBaseUrl}/cs/store?role=student`,
    target: null,
    items: [
      {
        id: 'mnuRedeemAccessCode',
        name: 'Redeem access code',
        href: `${evolvePortalBaseUrl}/cs/store?role=student&redeem=1`,
        internal: false,
        target: null,
      },
      {
        id: 'mnuBrowseByDiscipline',
        name: 'Discipline list',
        href: `${evolvePortalBaseUrl}/cs/products`,
        target: null,
      }
    ]
  },
  {
    ...GET_SUPPORT_MENU
  },
  {
    id: 'mnuTraining',
    name: 'Training',
    href: `${evolvePortalBaseUrl}/studentlife/index.html`,
    target: '_blank',
    items: [
      {
        id: 'mnuStudentLife',
        name: 'Student Life',
        href: `${evolvePortalBaseUrl}/studentlife/index.html`,
        target: '_blank',
      },
      {
        id: 'mnuGetReady',
        name: 'Get ready for NCLEX',
        href: `${evolvePortalBaseUrl}/nclexpromo2013/?dmnum=04411&utm_source=Evolve&utm_medium=Footer&utm_campaign=NCLEX-Review-Products&utm_content=NCLEX-Promo`,
        target: '_blank',
      }
    ]
  }
];

export const INSTRUCTOR_FOOTER_MENU: FooterMenuGroup[] = [
  {
    id: 'mnuMyEvolve',
    name: 'My Evolve',
    href: `${evolvePortalBaseUrl}/cs/myEvolve`,
    target: null,
    internal: false,
    items: [...MY_ACCOUNT_MENU]
  },
  {
    id: 'mnuCatalog',
    name: 'Catalog',
    href: `${evolvePortalBaseUrl}/cs/store?role=faculty`,
    target: null,
    items: [
      {
        id: 'mnuRedeemAccessCode',
        name: 'Redeem access code',
        href: `${evolvePortalBaseUrl}/cs/store?role=faculty&redeem=1#redeem`,
        target: null,
      },
      {
        id: 'mnuBrowseByDiscipline',
        name: 'Discipline list',
        href: `${evolvePortalBaseUrl}/cs/products`,
        target: null,
      },
      {
        id: 'mnuSubmitClassRoster',
        name: 'Submit class roster',
        href: `${evolvePortalBaseUrl}/cs/courseRoster/createRoster`,
        target: '_blank',
      }
    ]
  },
  {
    ...GET_SUPPORT_MENU
  },
  {
    ...LEARN_MORE_MENU
  }
];

export const FOOTER_SOCIAL_MENU_ITEMS = {
  default: [
    {
      id: 'mnuLinkedIn',
      name: 'Linkedin',
      href: 'https://www.linkedin.com/showcase/elsevier-education',
      iconName: 'linkedin',
      iconDesc: 'Linked In',
      target: '_blank'
    },
    {
      id: 'mnuTwitter',
      name: 'X',
      href: 'https://twitter.com/ELS_Educator',
      iconName: 'X',
      iconDesc: 'Twitter',
      target: '_blank'
    }
  ],
  studentPaymentPage: [
    {
      id: 'mnuFacebook',
      name: 'Facebook',
      href: 'https://www.facebook.com/ElsevierStudentLife',
      iconName: 'Facebook',
      iconDesc: 'Facebook',
      target: '_blank'
    },
    {
      id: 'mnuTwitter',
      name: 'X',
      href: 'https://twitter.com/elseviersa',
      iconName: 'X',
      iconDesc: 'Twitter',
      target: '_blank'
    },
    {
      id: 'mnuInstagram',
      name: 'Instagram',
      href: 'https://www.instagram.com/elsevierstudentlife/',
      iconName: 'Instagram',
      iconDesc: 'Instagram',
      target: '_blank'
    },
    {
      id: 'mnuYouTube',
      name: 'YouTube',
      href: 'https://www.youtube.com/user/ElsevierStudentLife',
      iconName: 'YouTube',
      iconDesc: 'YouTube',
      target: '_blank'
    }
  ]
};
