import { ELSIcon } from '@els/els-component-form-field-react';
import CreditRequestAttachmentComponent from 'components/common/credit-request-attachment/CreditRequestAttachment.component';
import { COUNTRY } from 'constants/app.constant';
import { CreditRequestFields } from 'constants/creditRequest.constant';
import { EvolveAddressModel } from 'models/invoice.model';
import { getUsStateName } from 'utilities/app/app.utility';
import { renderHtmlText } from 'utilities/render-fields';
import { CreditRequestDetailModel } from '../../../../models/credit-request.models';
import { ELSButton } from '../../../common/els';

interface CreditRequestConfirmationProps {
  creditRequest: CreditRequestDetailModel;
  onSubmit: (data: CreditRequestDetailModel) => void;
  onCancel: () => void;
}

const renderAddress = (address: EvolveAddressModel, field: string) => (
  <div className="c-tsp-credit-request-confirmation__address u-els-margin-top-1x1o2">
    <div className="c-tsp-credit-request-confirmation__requester-details-item">
      <p className="c-tsp-credit-request-confirmation__requester-details-item--label">
        {field === CreditRequestFields.incorrectBillingAddress ? 'Billing address:' : 'Shipping address:'}
      </p>
      <p className="c-tsp-credit-request-confirmation__requester-details-item--value">{address.address1}</p>
    </div>
    <div className="c-tsp-credit-request-confirmation__requester-details-item">
      <p className="c-tsp-credit-request-confirmation__requester-details-item--label">
        Apt, suite, building:
      </p>
      <p className="c-tsp-credit-request-confirmation__requester-details-item--value">{address.address2 ? address.address2 : '-'}</p>
    </div>
    <div className="c-tsp-credit-request-confirmation__requester-details-item">
      <p className="c-tsp-credit-request-confirmation__requester-details-item--label">
        Country:
      </p>
      <p className="c-tsp-credit-request-confirmation__requester-details-item--value">{address.country}</p>
    </div>
    <div className="c-tsp-credit-request-confirmation__requester-details-item">
      <p className="c-tsp-credit-request-confirmation__requester-details-item--label">
        {address && address.country === COUNTRY.CA ? 'Province:' : 'State:'}
      </p>
      <p className="c-tsp-credit-request-confirmation__requester-details-item--value">{getUsStateName(address.state)}</p>
    </div>
    <div className="c-tsp-credit-request-confirmation__requester-details-item">
      <p className="c-tsp-credit-request-confirmation__requester-details-item--label">
        City:
      </p>
      <p className="c-tsp-credit-request-confirmation__requester-details-item--value">{address.city}</p>
    </div>
    <div className="c-tsp-credit-request-confirmation__requester-details-item">
      <p className="c-tsp-credit-request-confirmation__requester-details-item--label">
        Postal code:
      </p>
      <p className="c-tsp-credit-request-confirmation__requester-details-item--value">{address.postalCode}</p>
    </div>
  </div>
);

const CreditRequestConfirmation = ({ creditRequest, onSubmit, onCancel }: CreditRequestConfirmationProps) => (
  <div className="c-tsp-confirm-modal c-tsp-credit-request-confirmation c- o-els-flex-layout">
    <div className="o-els-flex-layout__item">
      <ELSIcon
        name="confirmation-outline-circle"
        size="1x1o2"
        color="warn"
        customClass="u-els-margin-top-1o4 u-els-margin-right" />
    </div>
    <div className="c-tsp-confirm-modal__content c-tsp-credit-request-confirmation__content o-els-flex-layout__item o-els-flex-layout__item--grow">
      <h3 className="u-els-font-family-bold">Confirm Credit Request?</h3>
      <p>Please verify that the information below is correct. Then click “Confirm” to submit your credit request.</p>

      <p className="u-els-margin-top-2x">Requester&apos;s Details</p>
      <div className="c-els-divider c-els-divider--1x" />
      <div className="c-tsp-credit-request-confirmation__requester-details">
        <div className="c-tsp-credit-request-confirmation__requester-details-item">
          <p className="c-tsp-credit-request-confirmation__requester-details-item--label">Invoice Number:</p>
          <p className="c-tsp-credit-request-confirmation__requester-details-item--value">{creditRequest.invoiceNumber}</p>
        </div>
        <div className="c-tsp-credit-request-confirmation__requester-details-item">
          <p className="c-tsp-credit-request-confirmation__requester-details-item--label">Institution:</p>
          <p className="c-tsp-credit-request-confirmation__requester-details-item--value">{creditRequest.institutionName}</p>
        </div>
        <div className="c-tsp-credit-request-confirmation__requester-details-item">
          <p className="c-tsp-credit-request-confirmation__requester-details-item--label">Requester’s Name:</p>
          <p className="c-tsp-credit-request-confirmation__requester-details-item--value">{creditRequest.requesterFullName}</p>
        </div>
        <div className="c-tsp-credit-request-confirmation__requester-details-item">
          <p className="c-tsp-credit-request-confirmation__requester-details-item--label">Requester’s Title:</p>
          <p className="c-tsp-credit-request-confirmation__requester-details-item--value">{creditRequest.requesterTitle}</p>
        </div>
        <div className="c-tsp-credit-request-confirmation__requester-details-item">
          <p className="c-tsp-credit-request-confirmation__requester-details-item--label">Requester’s Email:</p>
          <p className="c-tsp-credit-request-confirmation__requester-details-item--value">{creditRequest.requesterEmail}</p>
        </div>
        <div className="c-tsp-credit-request-confirmation__requester-details-item">
          <p className="c-tsp-credit-request-confirmation__requester-details-item--label">Requester’s Phone:</p>
          <p className="c-tsp-credit-request-confirmation__requester-details-item--value">{creditRequest.requesterPhone}</p>
        </div>
      </div>
      <div className="c-els-divider c-els-divider--1x" />
      <p className="u-els-margin-top-1x1o2 u-els-margin-top-1o4">Credit Request Details</p>
      <div className="c-els-divider c-els-divider--1x" />
      <div className="c-tsp-credit-request-confirmation__requester-details">
        <div className="c-tsp-credit-request-confirmation__requester-details-item">
          <div className="c-tsp-credit-request-confirmation__requester-details-item--label">Reason(s) for Request:</div>
          <div className="c-tsp-credit-request-confirmation__requester-details-item--value">
            {creditRequest.incorrectShippingAddress && <p>Incorrect “Ship To” Address</p>}
            {creditRequest.incorrectBillingAddress && <p>Incorrect “Bill To” Address</p>}
            {creditRequest.incorrectTax && <p>Incorrect Tax</p>}
            {creditRequest.incorrectPricing && <p>Incorrect Pricing</p>}
            {creditRequest.incorrectContactInfo && <p>Incorrect Contact Information</p>}
            {creditRequest.other && <p>Other</p>}
          </div>
        </div>
        {
          creditRequest.incorrectShippingAddress && renderAddress(creditRequest.incorrectShippingAddress as EvolveAddressModel, CreditRequestFields.incorrectShippingAddress)
        }
        {
          creditRequest.incorrectBillingAddress && renderAddress(creditRequest.incorrectBillingAddress as EvolveAddressModel, CreditRequestFields.incorrectBillingAddress)
        }
        {creditRequest.comment && (
          <div className="c-tsp-credit-request-confirmation__requester-details-item u-els-margin-top-1x1o2">
            <p className="c-tsp-credit-request-confirmation__requester-details-item--label">Provided Details:</p>
            <div className="c-tsp-credit-request-confirmation__requester-details-item--value">
              <p>“{renderHtmlText(creditRequest.comment)}”</p>
              {creditRequest.attachments && creditRequest.attachments.map(attachment => <CreditRequestAttachmentComponent key={attachment.path} attachment={attachment} />)}
            </div>
          </div>
        )}
      </div>
      <div className="c-els-divider c-els-divider--1x" />
      <div className="u-els-margin-top">
        <ELSButton
          id="btnSubmit"
          type="warn"
          className="u-els-margin-top-1o2 u-els-margin-right"
          onClick={onSubmit}>
          Confirm
        </ELSButton>
        <ELSButton
          id="btnCancel"
          type="secondary"
          className="u-els-margin-top-1o2"
          onClick={onCancel}>
          Cancel
        </ELSButton>
      </div>
    </div>
  </div>
);

export default CreditRequestConfirmation;
