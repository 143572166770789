import { EvolveAddressModel } from './invoice.model';

export interface EvolveProductSearchOptions {
  query?: string;
  startIndex: number;
  size?: number;
  sortBy?: string;
}

export interface EvolveProductPromotionModel {
  type: string;
  percentageOff: number;
  discountPrice: number;
  discountPriceCAD: number;
  isValid: boolean;
  couponCode: string;
}

export interface AssociatedProductModel {
  isbn: string;
  title: string;
}

export interface EvolvePlatformModel {
  platformId: string;
  platformType: string;
  resourceId: string;
  courseFormatFlag: string;
  name: string;
  platformCategory?: string;
  hideIcon: boolean;
  formatConfig: string[];
  type: string;
  updateBy?: string;
  updateDate?: string;
  value: string | number;
  [key: string]: number | string | boolean | string[];
}

type MarketingLinkModel = {
  url: string;
  title: string;
  target: string;
  type: string;
  rank: number | string;
}

export interface EvolveProductButton {
  type: string;
  label_primary: string;
  label_secondary: string;
}

export enum PublishedStatus {
  NotYetPublished = 'NYP'
}

export interface EvolveProductModel {
  resourceId: string;
  pubStatusId?: number;
  type?: string;
  name: string;
  isbn10?: string;
  authorsHtml?: string;
  shortAuthorsHtml?: string;
  pubYear?: string | number;
  copyYear: string | number;
  listPrice: number;
  listPriceCAD?: number;
  productType?: string;
  productTypeName: string;
  category?: string;
  marketingSites?: string[];
  editionText?: string;
  thumbnailUrl?: string;
  promotion?: EvolveProductPromotionModel;
  inventoryQuantity?: number | string;
  pubStatus?: string;
  quantity?: number;

  // for product detail
  numberOfPages?: number;
  imprint?: string;
  productImgUrl?: string;
  productThumbnailImgUrl?: string;
  edition?: number | string;
  price?: number;
  originalPrice?: number;
  originalPriceCAD?: number;
  availableDate?: string;
  tableOfContent?: string;
  newToThisEdition?: string;
  copyKeyFeatures?: string;
  collectionTags?: string;
  description?: string;
  studentDescription?: string;
  facultyDescription?: string;
  platformList?: Partial<EvolvePlatformModel>[];
  relatedProductIds?: string[];
  additionalLinks?: MarketingLinkModel[];
  sherpathCollection?: AssociatedProductModel[];
  automaticBundleMemberProductIds?: string[];
  salesdemo?: {
    libraries?: any;
  };
  marketingURL?: string;
  hostedLMS?: string;
  hostedPlatform?: string;
  adImageUrl?: string;
  eflag?: boolean;
  allowCompRequest?: boolean;
  buttonList?: EvolveProductButton[];
  adoptAsStudent?: boolean;
  archived: boolean;
  nextEditionIsbn?: string;
  fulfilled?: boolean;
  term?: number;
}

export interface EvolveLMSConfigModel {
  lmsDropDownPlatformList: EvolvePlatformModel[],
  evolveContent: EvolvePlatformModel[],
  loExtEvolveContent: EvolvePlatformModel[],
  globalEvolveContent: EvolvePlatformModel[],
  noLmsUniqueContent: EvolvePlatformModel[]
}

export interface ShadowHealthFormModel {
  courseStartDate: Date | null;
  courseEndDate: Date | null;
  enrollmentStartDate: Date | null;
  affiliatedNursingProgram: string;
}

export interface EvolveInstitutionModel {
  country: string;
  institutionId: number;
  city: string;
  name: string;
  state: string;
}

export interface EvolveUserAddressModel extends EvolveAddressModel {
  addressType: string;
  attentionName?: string;
}

export interface EvolveUserModel {
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone?: string,
  roles: string[],
  systemRole: string,
  alternateEmail: string;
  userInstitution: EvolveInstitutionModel;
  optInMarketing: boolean;
  phone: string;
  shipPhone?: string;
  programType: string;
  studentId?: number,
  addresses: EvolveUserAddressModel[],
  workExtension?: string;
}

export interface ProductTypeCategoriesModel {
  category: string;
  description: string;
  eFlag: boolean;
  name: string;
  type: string;
}
