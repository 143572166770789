import React from 'react';
import { NavigationSectionType } from '../../../models/navigation.models';
import TopNavigationItem from '../navigation-items/navigation-item/TopNavigationItem.component';

export interface TopNavigationSectionProps {
  section: NavigationSectionType;
}

const TopNavigationSection = ({ section }: TopNavigationSectionProps) => (
  <div className="o-els-flex-layout u-els-nowrap o-els-flex-layout--middle u-els-display-none@tablet c-tsp-nav-section--top">
    <div className="o-els-flex-layout__item">
      {section.content}
    </div>
    <ul className="o-els-flex-layout__item c-els-nav-group">
      {section.items.map(item => <TopNavigationItem key={item.key} item={item} />)}
    </ul>
  </div>
);

export default TopNavigationSection;
