import { cloneDeep, isNil, omitBy } from 'lodash';
import { Attachment } from 'models/credit-request.models';
import { PagingOptions, ListingResponse } from '../../models/api.model';
import { CohortModel } from '../../models/opportunity.model';
import { tspServiceApi } from '../../helpers/RestClient.helper';
import { buildEvolvePagingOptions } from '../../helpers/api.helpers';
import { transformToTspCohortWorkflowStatuses } from '../../utilities/app/app.utility';

import {
  updateCohortApi,
  createInstallment as createInstallmentApi
} from '../../utilities/api/cohorts/cohortApi.util';
import { VALIDATE_RESPONSE_ERROR_TYPE } from '../../constants/app.constant';
import { EvolveProductModel } from '../../models/evolve.models';
import { EvolveInstallmentModel, EvolveInstllmentAmountHistoryModel } from '../../models/installment.model';
import { ProductApprovalItem } from '../../models/product-approval.model';
import { TspCohortDetailModel, CohortCommentModel } from '../../models/cohort.models';
import { getPayloadAttachment } from '../invoice-credit-rebill/invoiceCreditRebill.service';

export const fetchCohortsByOpportunityId = (
  OpportunityID, pagingOptions: PagingOptions,
  includeCohortWorkflowStatuses?: boolean,
  includeArchivedCohorts?: boolean
): Promise<ListingResponse> =>
  tspServiceApi.post(`/opportunities/${OpportunityID}/cohorts`, {
    data: {
      ...buildEvolvePagingOptions(pagingOptions),
      includeCohortWorkflowStatuses,
      includeArchivedCohorts
    }
  });

export const fetchInstallmentsByCohortId = (cohortId: string | number, pageOptions: PagingOptions, full = true) =>
  tspServiceApi.post(`/invoicing/installments/cohort/${cohortId}`, {
    data: {
      cohortId,
      ...buildEvolvePagingOptions(pageOptions),
      full
    }
  }) as Promise<{ installments: EvolveInstallmentModel[], totalCount: number }>;

export const fetchCommentsByCohortId = (opportunityId: string | number, cohortId: string | number, pageOptions: PagingOptions) =>
  tspServiceApi.get(`/opportunities/${opportunityId}/cohorts/${cohortId}/comments`, {
    params: buildEvolvePagingOptions(pageOptions)
  }) as Promise<{
    startIndex: number,
    size: number,
    totalCount: number,
    comments: CohortCommentModel[],
  }>;

export const fetchProductsByCohortId = async (payload: { cloneCohortId: string | number, dataTable: any }) => (
  tspServiceApi.post(`/productSuite/${payload.cloneCohortId}`, { data: payload.dataTable }) as Promise<{
    items: EvolveProductModel[];
    totalCount: number;
  }>
);
export const clonedProducts = async (payload) =>
  tspServiceApi.post('/productSuite/cloneProducts', { data: payload }) as Promise<{ totalCount: number }>;

export const validateClonedProducts = async (payload) =>
  tspServiceApi.post('/productSuite/cloneProducts/validate', { data: payload });

export const fetchApprovalsByCohortId = async (cohortId: string | number, activityId = null) => {
  let data = {};
  if (activityId) {
    data = { activityId };
  }
  try {
    return await tspServiceApi.get(`/productSuite/${cohortId}/approvalHistory`, { data }) as Promise<{ productApprovalHistory: ProductApprovalItem[] }>;
  } catch (err) {
    if (err.response.status === 404) {
      return {
        productApprovalHistory: []
      };
    }
    throw err;
  }
};

export const sendForApproval = async (cohortId: string | number, productApprovalUserName: string) =>
  tspServiceApi.post(`/productSuite/${cohortId}/sendForApproval`, {
    data: {
      cohortId,
      productApprovalUserName
    }
  });

export const markProductSuiteAsApproved = (cohortId: string | number, notes: string, internalApproval = true) =>
  tspServiceApi.post(`/productSuite/${cohortId}/markAsApproved`, {
    data: {
      cohortId,
      notes,
      internalApproval
    }
  });

export const approveProduct = (cohortId: string | number, notes: string, activityId: string) =>
  tspServiceApi.post(`/approval/productSuite/${cohortId}/approve`, {
    data: {
      cohortId,
      notes,
      activityId
    }
  }, VALIDATE_RESPONSE_ERROR_TYPE.REVIEW_APPROVAL);

export const rejectedProduct = (cohortId: string | number, notes: string, activityId: string) =>
  tspServiceApi.post(`/approval/productSuite/${cohortId}/reject`, {
    data: {
      cohortId,
      notes,
      activityId
    }
  }, VALIDATE_RESPONSE_ERROR_TYPE.REVIEW_APPROVAL);

export const updateCohort = (cohort: CohortModel) => updateCohortApi(cohort);

export const updateCohortDetail = async (payload) => {
  const body = {
    ...payload,
  };
  if (payload.workflowStatuses) {
    body.workflowStatuses = transformToTspCohortWorkflowStatuses(payload.workflowStatuses);
  }
  return await tspServiceApi.put(`/opportunities/${payload.opportunityId}/cohorts/${payload.cohortId}`, {
    data: body
  }) as Promise<{}>;
};

export const updateCohortName = async (payloadData) =>
  tspServiceApi.put(`/opportunities/${payloadData.opportunityId}/cohorts/${payloadData.cohortId}`, {
    data: payloadData
  }) as Promise<{}>;

export const updateCohortStartDateAndGraduationDate = async (payload) => tspServiceApi.put(`/opportunities/${payload.opportunityId}/cohorts/${payload.cohortId}`, {
  data: payload
});

export const addComment = (opportunityId: string | number, cohortId: string | number, comment: string) =>
  tspServiceApi.post(`/opportunities/${opportunityId}/cohorts/${cohortId}/comments`, {
    data: { comment }
  }) as Promise<{ tspCohortCommentId: number }>;

export const createInstallment = installment => createInstallmentApi(installment);

export const fetchTspCohortById = (opportunityId: string, cohortId: string | number) =>
  (tspServiceApi.get(`/opportunities/${opportunityId}/cohorts/${cohortId}`)) as Promise<TspCohortDetailModel>;

export const createHESICohort = (cohortId: string | number, data) => tspServiceApi.post(`/cohorts/${cohortId}/hesiCohort`, { data });

export const updateHESICohort = (cohortId: string | number, data) => tspServiceApi.put(`/cohorts/${cohortId}/hesiCohort/${data.hesiCohortId}`, { data });

export const reviewHESICohortDetails = (data) => tspServiceApi.post(`/cohorts/${data.cohortId}/hesiContract/review`, { data });

export const createNGContract = (data) => tspServiceApi.post(`/cohorts/${data.cohortId}/hesiContract`, { data });

export const updateNGContract = (data) => tspServiceApi.put(`/cohorts/${data.cohortId}/hesiContract`, { data });

export const cancelHESICohort = (cohortId: string | number, hesiCohortId: string | number) => tspServiceApi.delete(`/cohorts/${cohortId}/hesiCohort/${hesiCohortId}`);

export const fetchNgContractById = (cohortId: string | number, hesiContractId: string | number) =>
  tspServiceApi.get(`/cohorts/${cohortId}/hesiContract/${hesiContractId}`) as Promise<TspCohortDetailModel>;

export const cancelNGContract = (cohortId: string | number, hesiContractId: number) => tspServiceApi.delete(`/cohorts/${cohortId}/hesiContract/${hesiContractId}`);

interface UpdateInstallmentAmountsRequest {
  installmentAmounts: {
    installmentSysIs: string | number;
    institutionCostPerStudent: number;
    costPerStudent: number;
  }[],
  comment: string;
  attachments?: Attachment[];
}
export const updateInstallmentAmounts = async (cohortId: string | number, payload: UpdateInstallmentAmountsRequest) => {
  const data = cloneDeep(omitBy(payload, isNil));
  const attachments = data.attachments || [];
  const allPromise = attachments.map((attachment, index) => getPayloadAttachment(data, attachment, index));
  await Promise.all(allPromise);

  return tspServiceApi.put(`/cohorts/${cohortId}/installmentAmounts`, { data });
};

export const fetchInstallmentAmountsByID = (cohortId: string | number) =>
  (tspServiceApi.get(`/cohorts/${cohortId}/installmentAmountHistory`)) as Promise<EvolveInstllmentAmountHistoryModel>;

export const archiveCohort = (opportunityId: string | number, cohortId: string | number) => tspServiceApi.delete(`/opportunities/${opportunityId}/cohorts/${cohortId}`);
