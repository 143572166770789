import { getHostUrl, getProperty } from 'config/app.config';
import { EvolveUserAddressModel } from 'models/evolve.models';
import { StudentPaymentDashboardHistoriesModel, StudentPaymentDashboardModel, StudentPaymentModel } from 'models/student-payments.models';
import { generatePath } from 'react-router';
import { transformToCohortPackage } from 'utilities/app/app.utility';
import { ListingResponse, PagingOptions } from 'models/api.model';
import { buildEvolvePagingOptions } from 'helpers/api.helpers';
import { tspServiceApi } from '../../helpers/RestClient.helper';
import { VALIDATE_RESPONSE_ERROR_TYPE } from '../../constants/app.constant';

export const validateStudentPaymentExpired = (installmentId: string) =>
  tspServiceApi.get(`/studentPayment/${installmentId}/expired`,
    { ignoreToken: true }, VALIDATE_RESPONSE_ERROR_TYPE.STUDENT_PAYMENT) as Promise<{ isStudentPaymentExpired: boolean }>;

type CreatePaymentContextAPIResponse = Promise<{ eCaptureURL: string }>;
export const createPaymentContext = (tspStudentPaymentOrderId: number, installmentId: string) => {
  const hostUrl = getHostUrl();
  const paymentUrlObject = getProperty('payment');
  const paymentData = {
    tspStudentPaymentOrderId,
    redirectURL: hostUrl + generatePath(paymentUrlObject.redirectURL, { installmentId }),
    cancelURL: hostUrl + generatePath(paymentUrlObject.cancelURL, { installmentId })
  };

  return tspServiceApi.post('/ecapture/payments', { data: paymentData }) as CreatePaymentContextAPIResponse;
};

export const getPaymentDetails = (tspStudentPaymentOrderId: number, contextId: string) =>
  tspServiceApi.get(`/ecapture/payments/${tspStudentPaymentOrderId}/${contextId}`);

export const getTax = (tspStudentPaymentOrderId: number) =>
  tspServiceApi.get(`/order/tax/${tspStudentPaymentOrderId}`, {}, VALIDATE_RESPONSE_ERROR_TYPE.STUDENT_PAYMENT);

export const createTaxAudit = (tspStudentPaymentOrderId: number) =>
  tspServiceApi.post(`/order/tax/audit/${tspStudentPaymentOrderId}`, { data: {} }, VALIDATE_RESPONSE_ERROR_TYPE.STUDENT_PAYMENT);

export const commitTaxAudit = (tspStudentPatmentOrderId: number) =>
  tspServiceApi.post(`/order/tax/commit/${tspStudentPatmentOrderId}`, { data: {} }, VALIDATE_RESPONSE_ERROR_TYPE.STUDENT_PAYMENT);

export const abortTaxAudit = (tspStudentPaymentOrderId: number) =>
  tspServiceApi.post(`/order/tax/abort/${tspStudentPaymentOrderId}`, { data: {} }, VALIDATE_RESPONSE_ERROR_TYPE.STUDENT_PAYMENT);

export const updateTspOrder = (address: EvolveUserAddressModel, orderId: string) =>
  tspServiceApi.put(`/studentPayment/${orderId}`, {
    data: {
      tspStudentPaymentOrderId: orderId,
      billAddress: {
        ...address
      }
    }
  });

export const getTspCohortForStudentPayment = async (installmentId: string): Promise<StudentPaymentModel> => {
  const response: any = await tspServiceApi.get(`/studentPayment/${installmentId}`, {}, VALIDATE_RESPONSE_ERROR_TYPE.STUDENT_PAYMENT);
  return Promise.resolve({
    OpportunityID: response.opportunityId,
    OpportunityName: response.opportunityName,
    AccountName: response.accountName,
    CohortID: response.cohortId,
    CohortName: response.cohortName,
    StartDate: response.startDate,
    EndDate: response.endDate,
    Package: transformToCohortPackage(response.package),
    Program: response.program,
    InstallmentName: response.installmentName,
    InstallmentNumber: response.installmentNumber,
    StudentPaymentStatus: response.studentPaymentStatus,
    TspStudentPaymentOrder: response.tspStudentPaymentOrder
  });
};

export const getStudentPaymentDashboard = (opportunityId: string | number, pagingOption: PagingOptions & { filteredCohorts?: string[]; enrolled?: boolean }) => {
  const filteredCohorts = pagingOption.filteredCohorts ? pagingOption.filteredCohorts.join(',') : undefined;
  return tspServiceApi.post(`/studentPaymentHistory/opportunity/${opportunityId}`, {
    data: {
      ...buildEvolvePagingOptions(pagingOption),
      filteredCohorts,
      enrolled: pagingOption.enrolled
    }
  }) as Promise<ListingResponse & { enrollments: StudentPaymentDashboardModel[] }>;
};

export const getStudentPaymentDashboardHistory = (opportunityId: string | number, userName: string) =>
  tspServiceApi.get(`/studentPaymentHistory/opportunity/${opportunityId}/student/${userName}`) as Promise<StudentPaymentDashboardHistoriesModel>;
