import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MAX_NO_OF_INVOICES_FOR_SINGLE_INSTALLMENT_DEFAULT } from 'constants/invoice.constant';
import { AppConstant } from '../../constants/app.constant';

export const REDUCER_ID = AppConstant.redux.APP_STATE;

export const initialState = {
  loadingActions: 0,
  asyncLoadingActions: {},
  breadcrumbs: [],
  notifications: [],
  accessRoles: [],
  isTspEnabled: false,
  message: null,
  inflightCohortView: false,
  enableHesiIntegrationPhase1: false,
  enableStudentGroups: false,
  maxInvoicesNumber: MAX_NO_OF_INVOICES_FOR_SINGLE_INSTALLMENT_DEFAULT
};
const appSlice = createSlice({
  name: 'app',
  reducers: {
    restoreState(state, action) {
      return {
        ...action.payload,
        breadcrumbs: [],
        loadingActions: 0
      };
    },
    startAsyncLoading(state, action) {
      return {
        ...state,
        asyncLoadingActions: {
          ...state.asyncLoadingActions,
          [action.payload]: true
        }
      };
    },
    endAsyncLoading(state, action) {
      return {
        ...state,
        asyncLoadingActions: {
          ...state.asyncLoadingActions,
          [action.payload]: false
        }
      };
    },
    startLoading(state) {
      return {
        ...state,
        loadingActions: state.loadingActions + 1
      };
    },
    endLoading(state) {
      return {
        ...state,
        loadingActions: Math.max(state.loadingActions - 1, 0)
      };
    },
    setBreadcrumbs(state, action) {
      return {
        ...state,
        breadcrumbs: action.payload
      };
    },
    setNotifications(state, action) {
      return {
        ...state,
        ...action.payload
      };
    },
    setAppConfig(state, action) {
      return {
        ...state,
        accessRoles: action.payload.accessRoles,
        isTspEnabled: action.payload.isTspEnabled,
        enableHesiIntegrationPhase1: action.payload.enableHesiIntegrationPhase1,
        enableStudentGroups: action.payload.enableStudentGroups,
        maxInvoicesNumber: action.payload.maxInvoicesNumber
      };
    },
    setMessage(state, action: PayloadAction<{ success: boolean, text: string }>) {
      return {
        ...state,
        message: action.payload
      };
    },
    resetMessage(state) {
      return {
        ...state,
        message: null
      };
    },
    setInflightCohortView(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        inflightCohortView: action.payload
      };
    },
    restoreAppState(state, action:PayloadAction<any>) {
      return {
        ...state,
        inflightCohortView: action.payload.inflightCohortView
      };
    }
  },
  initialState
});

export const appReducer = appSlice.reducer;
export const appActions = appSlice.actions;
