import React from 'react';
import elsevierLogo from 'assets/icons/ElsevierLogo.svg';

const FooterEvolveLogo = () => (
  <div className="c-tsp-footer__logo">
    <a href="https://www.elsevier.com/" aria-label="Elsevier homepage" className="es-js-tooltip" rel="noopener noreferrer nofollow" target="_blank">
      <img alt="Elsevier" src={elsevierLogo} />
    </a>
  </div>
);

export default FooterEvolveLogo;
