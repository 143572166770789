import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import BaseLink from '../base-link/BaseLink.component';
import { NavigationItemType } from '../../../../models/navigation.models';

export interface NavigationLinkProps {
  item: NavigationItemType;
  className?: string;
  activeClassName?: string;
}
const NavigationLink = ({ item, className, activeClassName }: NavigationLinkProps) => (
  <NavLink to={item.path}
           className={cx('u-els-debuttonize c-tsp-nav-item c-tsp-nav-item--link', className)}
           activeClassName={activeClassName || 'c-tsp-nav-item--active'}>
    <BaseLink item={item} />
  </NavLink>
);

export default NavigationLink;
