import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import withUseLoading from 'utilities/with-loading/withUseLoading';
import { getMessageBasedOnErrorType } from '../../../utilities/error/error.utility';
import { Attachment } from '../../../models/credit-request.models';
import { invoiceCreditRebillServices } from '../../../services';
import { AcceptFileType } from '../../../constants/app.constant';
import { ELSButtonWithIcon } from '../els';

interface CreditRequestAttachmentProps {
  attachment: Attachment;
}

/**
 * This component to handle render link and download PDF file
 */
const CreditRequestAttachment = ({ attachment }: CreditRequestAttachmentProps) => {
  const [path, setPath] = useState(attachment.path);
  const [errorMessage, setErrorMessage] = useState<string>();
  const linkRef = useRef<HTMLAnchorElement>();
  const dispatch = useDispatch();
  const handleDownloadFile = withUseLoading(async () => {
    if (attachment.path) {
      linkRef.current.click();
      return;
    }
    try {
      const response = await invoiceCreditRebillServices.fetchAttachmentFile(attachment.filePath);
      const blob = new Blob([response.data], { type: AcceptFileType.PDF });
      const url = URL.createObjectURL(blob);
      linkRef.current.href = url;
      linkRef.current.click();
      setPath(url);
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    } catch (error) {
      setErrorMessage(getMessageBasedOnErrorType(error));
    }
  });

  return (
    <>
      <div className="c-tsp-credit-request-attachment">
        <ELSButtonWithIcon
          className="c-tsp-credit-request-attachment__btn-download c-els-link__text--blue"
          type="link"
          sprite="Paperclip"
          linkIconSize="xs"
          onClick={() => dispatch(handleDownloadFile)}>
          {attachment.fileName}
        </ELSButtonWithIcon>
        <a
          ref={linkRef}
          href={path}
          target="_blank"
          rel="noopener noreferrer"
          className="c-tsp-credit-request-attachment__link-download"
          download={attachment.fileName}>
          {attachment.fileName}
        </a>
      </div>
      {errorMessage && <p className="u-els-color-warn">{errorMessage}</p>}
    </>
  );
};

export default CreditRequestAttachment;
