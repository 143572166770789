import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { isEqual } from 'lodash';

/**
 * This is a workaround while waiting the official release.
 * Helmet uses deep-equal and sometimes crashes on circular objects.
 * See: https://github.com/nfl/react-helmet/issues/373
 * The fix is in 6.x beta. Wait for official release
 */
Helmet.prototype.shouldComponentUpdate = function shouldComponentUpdate(nextProps) {
  return !isEqual(this.props, nextProps);
};

export interface SEOData {
  title?: string;
}

const withHTMLHeadSEO = (data: SEOData = {}) => BaseComponent => {
  class SEOComponent extends PureComponent {
    render() {
      const title = data && data.title ? `${data.title} | CMS` : 'Cohort Management System';
      return (
        <>
          <Helmet title={title}>
            <html lang="en" />
          </Helmet>
          {BaseComponent && <BaseComponent {...this.props} />}
        </>
      );
    }
  }

  return SEOComponent;
};

export default withHTMLHeadSEO;
