import { sortBy } from 'lodash';
import { FilterFacetItem } from '../../../models/facet-filter.model';
import { toSlug } from '../../app/app.utility';

export const normalizeCategories = (productTypeCategories) => {
  if (!Array.isArray(productTypeCategories) || productTypeCategories.length === 0) {
    return {};
  }
  const data = {};
  const { length } = productTypeCategories;
  for (let i = 0; i < length; i += 1) {
    data[productTypeCategories[i].type] = productTypeCategories[i];
  }
  return data;
};

export const coreceFormatFacets = (availableFormat) => {
  const formatOptions = [];
  const names = [];
  const { length } = availableFormat;
  let totalOfFormats = 0;
  for (let i = 0; i < length; i += 1) {
    const format = availableFormat[i];
    const index = names.indexOf(format.name);
    if (index === -1) {
      formatOptions.push({
        value: format.type,
        name: format.name,
        total: format.total
      });
      names.push(format.name);
      totalOfFormats += format.total;
    } else {
      formatOptions[index].total += format.total;
      formatOptions[index].value = [formatOptions[index].value, format.type].join(',');
    }
  }
  formatOptions.unshift({ name: 'All Format', value: 0, total: totalOfFormats });
  return {
    id: 'availableFormat',
    title: 'Filter by Format',
    options: formatOptions
  };
};

export const coreceProductTypes = (productTypes, productTypeCategories) => {
  if (!productTypes) {
    return [];
  }
  const normalizedCategories = productTypeCategories || normalizeCategories(productTypeCategories);
  const facetObj = {};
  const { length } = productTypes;
  for (let i = 0; i < length; i += 1) {
    const { category } = normalizedCategories[productTypes[i].type];
    const typeItem = { name: productTypes[i].name, value: productTypes[i].type, total: productTypes[i].total };
    if (!facetObj[category]) {
      facetObj[category] = {
        id: toSlug(category),
        groupName: 'productType',
        title: category,
        accordion: true,
        showTotal: true,
        options: [typeItem]
      };
    } else {
      facetObj[category].options.push(typeItem);
    }
  }
  const facets = [];
  Object.keys(facetObj).forEach(key => {
    const facetItem = facetObj[key];
    facetItem.options = sortBy(facetItem.options, 'name');
    facets.push(facetItem);
  });
  return facets;
};

export const buildFilterFacets = (allFacets, productTypeCategories) => {
  const productTypeFilters = coreceProductTypes(allFacets.productType, productTypeCategories);
  const facets: FilterFacetItem[] = [
    {
      id: 'sortBy',
      type: 'dropdown',
      title: 'Sort By',
      options: [
        {
          name: 'Relevance',
          value: 'relevancy'
        },
        {
          name: 'Title',
          value: 'name'
        },
        {
          name: 'Product Type',
          value: 'productTypeName'
        },
        {
          name: 'Copyright Year',
          value: 'copyYear'
        },
        {
          name: 'Author',
          value: 'authorSort'
        },
        {
          name: 'Price',
          value: 'listPrice'
        }
      ]
    },
    {
      id: 'selectAll',
      title: 'Filter by Product Type',
      options: [{
        name: 'All Products',
        value: 'all'
      }]
    }
  ].concat(productTypeFilters);
  facets.push(coreceFormatFacets(allFacets.availableFormat));
  return facets;
};
