export const MenuConstants = {
  keyCodes: {
    space: 32,
    enter: 13,
    tab: 9,
    up: 38,
    down: 40,
    esc: 27,
    home: 36,
    end: 35
  },
  align: {
    top: 'top',
    right: 'right',
    left: 'left',
    bottom: 'bottom'
  },
  position: {
    BOTTOM_LEFT: 'bottom-left',
    BOTTOM_RIGHT: 'bottom-right'
  }
};
