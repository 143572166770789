import React from 'react';
import cx from 'classnames';
import { NavigationItemType } from '../../../../models/navigation.models';
import { NavItemCategory } from '../../../../constants/navigation.constant';
import NavigationLink from '../navigation-link/NavigationLink.component';
import NavigationText from '../navigation-text/NavigationText.component';
import NavigationButton from '../navigation-button/NavigationButton.component';

const navClasses = {
  [NavItemCategory.SECONDARY]: 'c-els-nav-group__item--small'
};

interface NavigationItemProps {
  item: NavigationItemType;
}

const NavigationItem = ({ item }: NavigationItemProps) => {
  let content;
  if (item.disabled) {
    content = <NavigationText item={item} />;
  } else if (item.onAction) {
    content = <NavigationButton item={item} />;
  } else {
    content = <NavigationLink item={item} />;
  }

  return (
    <li className={cx('o-els-flex-layout__item c-els-nav-group__item', navClasses[item.category])}>
      {content}
    </li>
  );
};

export default NavigationItem;
