/* eslint-disable */
import moment from 'moment';
import { CohortModel } from '../models/opportunity.model';

export const cohorts: CohortModel[] = [
  {
    CohortID: '41',
    CohortName: 'Spring 2020 Cohort A',
    CohortTrack: 'ACV',
    Program: 'BSN',
    Status: ['Product Selections'],
    StartDate: '2020-01-15',
    EndDate: moment().add(1, 'y').toISOString(),
    CohortCount: 25,
    CurrentCohortCount: 25,
    Package: {
      PackageID: '1',
      PackageName: 'Elsevier 360',
      CostPerStudent: 5,
      TotalCost: '$50',
      Addons: [
        {
          AddonID: '1',
          AddonName: 'Shadow Health Module - Health Assessment',
          ProductName: 'Shadow Health Module - Health Assessment'
        },
        {
          AddonID: '2',
          AddonName: 'Shadow Health Module - Health Assessment',
          ProductName: 'Shadow Health Module - Health Assessment'
        }, {
          AddonID: '3',
          AddonName: 'Simulation Learning Systerm for RN with Virtual Reality (2-year access)',
          ProductName: 'Simulation Learning Systerm for RN with Virtual Reality (2-year access)'
        }
      ]
    },
    WorkflowStatuses: {
      CohortID: '41',
      ProductSelections: false,
      DigitalMappingConsultation: true,
      CohortSetup: true,
      EducationAndTraining: false,
      StudentSetup: false,
      CustomerSuccess: true,
      HESITesting: true,
      CreatedBy: 'Test',
      CreateDate: '09-09-2021'
    },
    Installments: [
      {
        CohortID: '41',
        InstallmentID: 'installmentA#01',
        InstallmentName: 'Spring 2020 Cohort A Installment 01',
        CostPerStudent: 433.33,
        Status: '',
        InvoiceDate: '08-26-2021',
        TotalCost: 10
      },
      {
        CohortID: '41',
        InstallmentID: 'installmentA#02',
        InstallmentName: 'Spring 2020 Cohort A Installment 02',
        CostPerStudent: 433.33,
        Status: '',
        InvoiceDate: '08-20-2021',
        TotalCost: 10
      }
    ],
    NumberOfInstallments: 5,
    CancelledHesiCohorts: []
  },
  {
    CohortID: '42',
    CohortName: 'Spring 2020 Cohort B',
    Program: 'BSN',
    Status: ['Product Selections', 'Education and Training'],
    StartDate: '2021-02-15',
    EndDate: '2023-08-15',
    CohortCount: 25,
    CurrentCohortCount: 25,
    Package: {
      PackageID: '2',
      PackageName: 'Pick 2',
      CostPerStudent: 5,
      TotalCost: '$100'
    },
    Installments: [
      {
        CohortID: '42',
        InstallmentID: 'installmentB#01',
        InstallmentName: 'Spring 2020 Cohort B Installment 01',
        CostPerStudent: 433.33,
        Status: '',
        InvoiceDate: '08-20-2021',
        TotalCost: 10
      },
      {
        CohortID: '42',
        InstallmentID: 'installmentB#02',
        InstallmentName: 'Spring 2020 Cohort B Installment 02',
        CostPerStudent: 433.33,
        Status: '',
        InvoiceDate: '08-20-2021',
        TotalCost: 10
      }
    ]
  },
  {
    CohortID: '43',
    CohortName: 'Spring 2020 Cohort C',
    Program: 'ADN',
    Status: ['Product Selections', 'Digital Mapping Consultation'],
    StartDate: '2022-01-15',
    EndDate: '2024-09-15',
    CohortCount: 30,
    CurrentCohortCount: 30,
    Package: {
      PackageID: '3',
      PackageName: 'Elsevier 360',
      CostPerStudent: 10,
      TotalCost: '$300',
      Addons: [
        {
          AddonID: '1',
          AddonName: 'Shadow Health Module - Health Assessment',
          ProductName: 'Shadow Health Module - Health Assessment'
        },
        {
          AddonID: '2',
          AddonName: 'Shadow Health Module - Health Assessment',
          ProductName: 'Shadow Health Module - Health Assessment'
        },
        {
          AddonID: '3',
          AddonName: 'Shadow Health Module - Health Assessment',
          ProductName: 'Shadow Health Module - Health Assessment'
        }
      ]
    },
    WorkflowStatuses: {
      CohortID: '43',
      ProductSelections: true,
      DigitalMappingConsultation: true,
      CohortSetup: true,
      EducationAndTraining: false,
      StudentSetup: false,
      CustomerSuccess: false,
      HESITesting: false,
      CreatedBy: 'Test',
      CreateDate: '09-09-2021'
    },
    Installments: [
      {
        CohortID: '43',
        InstallmentID: 'installmentC#01',
        InstallmentName: 'Spring 2020 Cohort C Installment 01',
        CostPerStudent: 433.33,
        Status: '',
        InvoiceDate: '08-20-2021',
        TotalCost: 10
      }
    ]
  },
  {
    CohortID: '44',
    CohortName: 'Spring 2020 Cohort D',
    Program: 'ADN',
    Status: ['Product Selections', 'Education and Training', 'Student Set Up'],
    StartDate: '2020-01-15',
    EndDate: '2022-10-15',
    CohortCount: 30,
    CurrentCohortCount: 30,
    Package: {
      PackageID: '4',
      PackageName: 'Elsevier 360',
      CostPerStudent: 15,
      TotalCost: '$300',
      Addons: [
        {
          AddonID: '1',
          AddonName: 'Shadow Health Module - Health Assessment',
          ProductName: 'Shadow Health Module - Health Assessment'
        },
        {
          AddonID: '2',
          AddonName: 'Shadow Health Module - Health Assessment',
          ProductName: 'Shadow Health Module - Health Assessment'
        },
        {
          AddonID: '3',
          AddonName: 'Shadow Health Module - Health Assessment',
          ProductName: 'Shadow Health Module - Health Assessment'
        },
        {
          AddonID: '4',
          AddonName: 'Shadow Health Module - Health Assessment',
          ProductName: 'Shadow Health Module - Health Assessment'
        }
      ]
    },
    WorkflowStatuses: {
      CohortID: '44',
      ProductSelections: false,
      DigitalMappingConsultation: true,
      CohortSetup: true,
      EducationAndTraining: false,
      StudentSetup: false,
      CustomerSuccess: false,
      HESITesting: false,
      CreatedBy: 'Test',
      CreateDate: '09-09-2021'
    },
    Installments: [
      {
        CohortID: '44',
        InstallmentID: 'installmentD#01',
        InstallmentName: 'Spring 2020 Cohort D Installment 01',
        CostPerStudent: 433.33,
        Status: '',
        InvoiceDate: '08-20-2021',
        TotalCost: 10
      }
    ]
  },
  {
    CohortID: '45',
    CohortName: 'Spring 2020 Cohort E',
    Program: 'BSN',
    Status: ['Digital Mapping Consultation', 'Cohort Set Up', 'Education and Training'],
    StartDate: '2021-01-15',
    EndDate: '2023-11-15',
    CohortCount: 25,
    CurrentCohortCount: 25,
    Package: {
      PackageID: '5',
      PackageName: 'Elsevier 360',
      CostPerStudent: 5,
      TotalCost: '$300'
    },
    WorkflowStatuses: {
      CohortID: '45',
      ProductSelections: false,
      DigitalMappingConsultation: true,
      CohortSetup: true,
      EducationAndTraining: false,
      StudentSetup: true,
      CustomerSuccess: false,
      HESITesting: true,
      CreatedBy: 'Test',
      CreateDate: '09-09-2021'
    },
    Installments: [
      {
        CohortID: '45',
        InstallmentID: 'installmentE#01',
        InstallmentName: 'Spring 2020 Cohort E Installment 01',
        CostPerStudent: 433.33,
        Status: '',
        InvoiceDate: '08-20-2021',
        TotalCost: 10
      }
    ]
  },
  {
    CohortID: '46',
    CohortName: 'Spring 2020 Cohort F',
    Program: 'ADN',
    Status: ['Digital Mapping Consultation', 'Cohort Set Up'],
    StartDate: '2022-01-15',
    EndDate: '2024-12-15',
    CohortCount: 35,
    CurrentCohortCount: 35,
    Package: {
      PackageID: '6',
      PackageName: 'Elsevier 360',
      CostPerStudent: 10,
      TotalCost: '$500'
    },
    WorkflowStatuses: {
      CohortID: '46',
      ProductSelections: true,
      DigitalMappingConsultation: false,
      CohortSetup: false,
      EducationAndTraining: false,
      StudentSetup: false,
      CustomerSuccess: false,
      HESITesting: true,
      CreatedBy: 'Test',
      CreateDate: '09-09-2021'
    },
    Installments: [
      {
        CohortID: '46',
        InstallmentID: 'installmenFt#01',
        InstallmentName: 'Spring 2020 Cohort F Installment 01',
        CostPerStudent: 433.33,
        Status: '',
        InvoiceDate: '08-20-2021',
        TotalCost: 10
      }
    ]
  }
];
