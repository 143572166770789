import * as yup from 'yup';
import { COUNTRY } from 'constants/app.constant';
import { EvolveAddressModel } from 'models/invoice.model';
import { validateObject } from 'utilities/app/app.utility';
import {
  CreditRequestDetailModel,
  CreditRequestReasonsEditorModel
} from '../../models/credit-request.models';

export const validateEmail = (value, { message = 'Please enter valid email address.', callback = null } = {}) => () => {
  const schema = yup.object().shape({ email: yup.string().email() });
  const result = {
    id: 'email',
    value,
    content: message,
    isValid: schema.isValidSync({ email: value })
  };
  if (typeof callback === 'function') callback(result);
  return result;
};

export const validateCohortCounts = (installments) => {
  const cohortCount = (count) => count.quantity > 0;
  return installments.every(cohortCount);
};

export const validateState = () => yup.string()
  .transform(v => (v ? v.trim() : v))
  .when('$country', (country, schema) => {
    if (country === COUNTRY.CA) {
      return schema.required('Billing province is required.');
    }
    return schema.required('Billing state is required.');
  });

export const validatePostalCode = () => yup.string()
  .transform(v => (v ? v.trim() : v))
  .required('Billing postal code is required.')
  .when('$country', (country, schema) => {
    if (country === COUNTRY.CA) {
      return schema.matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/,
        'Billing postal code is not correct. Please use one of the following formats: K1A 0B1 or K1A0B1.');
    }
    return schema.matches(/^\d{5}(-\d{4})?$/, 'Billing postal code is not correct. Please use one of the following formats: 12345 or 12345-6789.');
  });

export const validateBillingAddress = (requiredFields: string[], address?: EvolveAddressModel, context?) => {
  if (!address) return null;
  const addressSchema = {
    address1: yup.string()
      .transform(v => (v ? v.trim() : v))
      .max(40, 'Billing address should not be greater than 40 characters.')
      .required('Billing address is required.'),
    address2: yup.string()
      .transform(v => (v ? v.trim() : v))
      .max(30, 'Billing apt, suite, building should not be greater than 30 characters.'),
    country: yup.string()
      .transform(v => (v ? v.trim() : v))
      .required('Billing country is required.'),
    city: yup.string()
      .transform(v => (v ? v.trim() : v))
      .max(40, 'Billing city should not be greater than 40 characters.')
      .required('Billing city is required.'),
    state: validateState(),
    postalCode: validatePostalCode()
  };
  return validateObject(address, addressSchema, requiredFields, context);
};

export const getSchemaCreditRequestAddress = (isShippingAddress?: boolean) => {
  const fieldStreet = isShippingAddress ? 'Shipping' : 'Billing';
  return {
    address1: yup.string()
      .nullable()
      .transform((v) => (v ? v.trim() : v))
      .required(`${fieldStreet} address is required.`)
      .max(40, `${fieldStreet} address should not be greater than 40 characters.`),
    address2: yup.string()
      .nullable()
      .transform((v) => (v ? v.trim() : v))
      .max(30, `${fieldStreet} apt, suite, building should not be greater than 30 characters.`),
    city: yup.string()
      .nullable()
      .transform((v) => (v ? v.trim() : v))
      .required(`${fieldStreet} city is required.`)
      .max(40, `${fieldStreet} city should not be greater than 40 characters.`),
    state: yup.string()
      .nullable()
      .transform((v) => (v ? v.trim() : v))
      .required(`${fieldStreet} state is required.`)
      .max(2, `${fieldStreet} state should not be greater than 2 characters.`),
    country: yup.string()
      .nullable()
      .transform((v) => (v ? v.trim() : v))
      .required(`${fieldStreet} country is required.`),
    postalCode: yup.string()
      .nullable()
      .required(`${fieldStreet} postal code is required.`)
      .matches(/^\d{5}(-\d{4})?$/, `${fieldStreet} postal code is not correct. Please use one of the following formats: 12345 or 12345-6789.`)
  };
};

export const getSchemaInvoiceAddress = (addressType?: string) => {
  const fieldStreet = addressType === 'shippingAddress' ? 'Shipping' : 'Billing';
  return {
    address1: yup.string()
      .nullable()
      .transform((v) => (v ? v.trim() : v))
      .required(`${fieldStreet} address is required.`)
      .max(40, `${fieldStreet} address should not be greater than 40 characters.`),
    address2: yup.string()
      .nullable()
      .transform((v) => (v ? v.trim() : v))
      .max(30, `${fieldStreet} apt, suite, building should not be greater than 30 characters.`),
    city: yup.string()
      .nullable()
      .transform((v) => (v ? v.trim() : v))
      .required(`${fieldStreet} city is required.`)
      .max(40, `${fieldStreet} city should not be greater than 40 characters.`),
    state: yup.string()
      .nullable()
      .transform((v) => (v ? v.trim() : v))
      .required(`${fieldStreet} state is required.`),
    postalCode: yup.string()
      .nullable()
      .required(`${fieldStreet} postal code is required.`)
      .matches(/^\d{5}(-\d{4})?$/, `${fieldStreet} postal code is not correct. Please use one of the following formats: 12345 or 12345-6789.`)
  };
};

export const validateCreditRequestAddress = (address: EvolveAddressModel, requiredFields: string[], isShippingAddress?: boolean) =>
  validateObject(address, getSchemaCreditRequestAddress(isShippingAddress), requiredFields);

export const validateCreditRequest = (requiredFields: string[], requestForm?: CreditRequestDetailModel, context?) => {
  if (!requestForm) return null;
  const creditSchema = {
    institutionName: yup.string()
      .transform(v => (v ? v.trim() : v))
      .required('Institution is required.')
      .max(128, 'Institution should not be greater than 128 characters.'),
    requesterFullName: yup.string()
      .transform(v => (v ? v.trim() : v))
      .required('Requester\'s Full Name is required.')
      .max(128, 'Requester\'s Full Name should not be greater than 128 characters.'),
    requesterTitle: yup.string()
      .transform(v => (v ? v.trim() : v))
      .required('Requester\'s Title is required.')
      .max(128, 'Requester\'s Title should not be greater than 128 characters.'),
    requesterEmail: yup.string()
      .transform(v => (v ? v.trim() : v))
      .required('Requester\'s Email is required.')
      .email('Please enter a valid email address.'),
    requesterPhone: yup.string()
      .transform(v => (v ? v.trim() : v))
      .required('Requester\'s Phone Number is required.')
      .max(128, 'Requester\'s Phone Number should not be greater than 128 characters.'),
    incorrectBillingAddress: yup.object(getSchemaCreditRequestAddress(false)),
    incorrectShippingAddress: yup.object(getSchemaCreditRequestAddress(true)),
    comment: yup.string()
      .transform(v => (v ? v.trim() : v))
      .required('Reason details are required.')
  };

  return validateObject(requestForm, creditSchema, requiredFields, context);
};

export const validateEditCreditRequest = (requiredFields: string[], requestForm?: CreditRequestReasonsEditorModel, context?) => {
  if (!requestForm) return null;
  const creditSchema = {
    comment: yup.string()
      .transform(v => (v ? v.trim() : v))
      .required('Comment is required.')
  };

  return validateObject(requestForm, creditSchema, requiredFields, context);
};
