import React from 'react';
import { ELSIcon, ELSTextBox } from '@els/els-component-form-field-react';
import { ELSTooltip } from '@els/els-component-tooltip-react';
import CreditRequestContact from '../../CreditRequestContact.component';
import { CreditRequestStatusModel, InternalCreditRequestModel } from '../../../../models/credit-request.models';
import { getClassColorForCreditRequestStatus } from '../../../../utilities/credit-request/credit-request.utility';

export interface CreditRequestTrackDetailsProps extends InternalCreditRequestModel {
  creditRequestNumber: string;
  creditRequestStatus: CreditRequestStatusModel;
}

const CreditRequestTrackDetails = ({ isInternalCreditRequest, creditRequestNumber, creditRequestStatus }: CreditRequestTrackDetailsProps) => (
  <>
    <h4 className="u-els-margin-top">Below are the details of your credit request.</h4>
    <div className="u-els-margin-top-2x">
      <ELSTextBox
        placeholder="Credit Request Number (XXXXXXXXXXXXX)"
        name="creditRequestNumber"
        id="creditRequestNumber"
        isDisabled
        value={creditRequestNumber}
        iconRight="confirmation"
      >
        <div className="credit-request-number-field-text u-els-margin-bottom-1o4">
          <span>Credit Request Number*</span>
        </div>
        <ELSIcon name="confirmation-solid-circle" size="1x" color="confirm" customClass="c-tsp-credit-request__icon-confirmation" />
      </ELSTextBox>
    </div>
    <h3 className="u-els-margin-top-2x u-els-font-family-bold u-tsp-display-inline-flex">
      <span>Credit Request Status:&nbsp;</span>
      <span className={getClassColorForCreditRequestStatus(creditRequestStatus.id)}>{creditRequestStatus.name}</span>
      <ELSTooltip
        wrapperClassName="c-tsp-credit-request-track-details__tooltip"
        showWhenHover
        content={(
          <>
            <p className="u-els-font-family-bold">Credit Request Statuses:</p>
            <ul className="c-tsp-credit-request-track-details__tooltip-status">
              <li className="c-tsp-credit-request-track-details__tooltip-status-item">Unprocessed</li>
              <li className="c-tsp-credit-request-track-details__tooltip-status-item">Under Review</li>
              <li className="c-tsp-credit-request-track-details__tooltip-status-item">Credit Process Underway</li>
              <li className="c-tsp-credit-request-track-details__tooltip-status-item">Processed</li>
              <li className="c-tsp-credit-request-track-details__tooltip-status-item">Denied</li>
            </ul>
          </>
        )}
      >
        <ELSIcon name="information-solid-circle" color="secondary" prefix="gizmo" size="1x1o4" customClass="c-tsp-status-header__icon o-els-layout--middle" />
      </ELSTooltip>
    </h3>
    {!isInternalCreditRequest && <CreditRequestContact />}
  </>
);

export default CreditRequestTrackDetails;
