import React from 'react';
import SideDrawerModal from './side-drawer-modal/SideDrawerModal.component';
import { NavigationConfig } from '../../../models/navigation.models';
import SideNav from '../side-nav/SideNav.component';

export interface SideDrawerProps {
  navConfig: NavigationConfig;
}
const SideDrawer = ({ navConfig }: SideDrawerProps) => (
  <div className="o-els-flex-layout
                  o-els-flex-layout--right
                  u-els-display-none
                  u-els-display-block@tablet">
    <div className="o-els-flex-layout__item">
      <SideDrawerModal modalId="sideDrawerModal">
        <div className="c-tsp-side-drawer">
          <SideNav navConfig={navConfig} />
        </div>
      </SideDrawerModal>
    </div>
  </div>
);

const SideDrawerWrapper = SideDrawer;

export default SideDrawerWrapper;
