import { ELSDropDown, ELSTextBox } from '@els/els-component-form-field-react';
import cx from 'classnames';
import { get, isEmpty } from 'lodash';
import { COUNTRY } from 'constants/app.constant';
import useCountries from 'hooks/use-countries/useCountries.hook';
import useStates from 'hooks/use-states/useStates.hook';
import { EvolveAddressModel } from 'models/invoice.model';
import { useState, useEffect } from 'react';
import { validateCreditRequestAddress } from 'utilities/validators/validators.utility';
import { CreditRequestFields } from 'constants/creditRequest.constant';
import { FormattedHTMLMessage } from 'react-intl';

interface CreditRequestAddressProps {
  address: EvolveAddressModel;
  fieldName: CreditRequestFields;
  errorMessage?: { [x: string]: string };
  updateAddress: (address: EvolveAddressModel) => void;
}

const defaultAddress: EvolveAddressModel = {
  address1: '',
  address2: '',
  city: '',
  country: '',
  postalCode: '',
  state: ''
};

const getDataValue = (field: string, object?: EvolveAddressModel) => {
  if (!object || !object[field]) {
    return '';
  }
  return object[field];
};

const useProcessValidateAndGetErrorMessage = (address: EvolveAddressModel, fieldName: CreditRequestFields, errorMessage?: { [x: string]: string }) => {
  const [editedFields, setEditedFields] = useState([]);
  const [internalError, setInternalError] = useState<{ [x: string]: string }>();

  const handleValidateAddress = () => {
    const result = validateCreditRequestAddress(address, editedFields, fieldName === CreditRequestFields.incorrectShippingAddress);
    setInternalError(result);
  };

  useEffect(() => {
    if (editedFields.length > 0) {
      handleValidateAddress();
    }
  }, [address]);

  const addEditedFields = (field: string) => {
    setEditedFields(prevData => {
      if (prevData.includes(field)) {
        return prevData;
      }
      return [...prevData, field];
    });
  };

  const getErrorMessage = (field: string): string => {
    if (errorMessage && get(errorMessage, `${fieldName}.${field}`)) {
      return get(errorMessage, `${fieldName}.${field}`);
    }
    return internalError && get(internalError, field);
  };

  return {
    addEditedFields,
    getErrorMessage
  };
};

const CreditRequestAddress = (props: CreditRequestAddressProps) => {
  const { countries } = useCountries();
  const { states, updateStates } = useStates();
  const [address, setAddress] = useState(props.address || defaultAddress);
  const { addEditedFields, getErrorMessage } = useProcessValidateAndGetErrorMessage(address, props.fieldName, props.errorMessage);

  useEffect(() => {
    updateStates(address && address.country);
  }, [address && address.country]);

  const renderItemClasses = (defaultClass?: string, errorField?: string) =>
    cx(`c-tsp-credit-request__field u-els-min-width-1o2 u-els-max-width-1o2 u-els-max-width-1o1@mobile u-els-min-width-1o1@mobile ${defaultClass || ''}`,
      { 'c-els-field--error': errorField && getErrorMessage(errorField) });

  const handleAddressChanged = (event: never, value: string, { name }: { name: string }): void => {
    addEditedFields(name);
    setAddress((prevData) => {
      const updateData = { [name]: value };
      if (name === 'country') {
        updateData.state = '';
      }
      const newValue = { ...prevData, ...updateData };
      props.updateAddress(newValue);
      return newValue;
    });
  };

  const renderErrorMessage = (field: string) => {
    const error = getErrorMessage(field);
    return error && (
      <div className="c-els-field__message-list">
        <span className="c-els-field__message c-els-field__message--error c-tsp-student-payment-error-message">{error}</span>
      </div>
    );
  };

  return (
    <div className="u-els-width">
      <div className="u-els-padding-top"><FormattedHTMLMessage id="PROVIDE_CORRECT_ADDRESS" /></div>
      <div className="c-tsp-credit-request__address">
        <div className={renderItemClasses('u-els-padding-right u-els-margin-top u-els-padding-right-none@mobile ', 'address1')}>
          <ELSTextBox
            name="address1"
            id="txtAddress1"
            autoComplete="off"
            value={getDataValue('address1', address)}
            changeHandler={handleAddressChanged}>
            <span> {props.fieldName === CreditRequestFields.incorrectBillingAddress ? 'Billing address*' : 'Shipping address*'}</span>
          </ELSTextBox>
          {renderErrorMessage('address1')}
        </div>
        <div className={renderItemClasses('u-els-margin-top u-els-margin-top@mobile')}>
          <ELSTextBox
            name="address2"
            id="txtAddress2"
            autoComplete="off"
            value={getDataValue('address2', address)}
            changeHandler={handleAddressChanged}>
            <span>Apt, suite, building (optional)</span>
          </ELSTextBox>
          {renderErrorMessage('address2')}
        </div>
      </div>
      <div className="c-tsp-credit-request__address">
        <div className={renderItemClasses('u-els-padding-right u-els-padding-right-none@mobile ', 'country')}>
          <ELSDropDown
            id="ddlCountry"
            name="country"
            options={countries}
            value={getDataValue('country', address)}
            changeHandler={handleAddressChanged}>
            <span>Country*</span>
          </ELSDropDown>
          {renderErrorMessage('country')}
        </div>
        <div className={renderItemClasses('u-els-margin-top@mobile ', 'state')}>
          <ELSDropDown
            id="ddlState"
            name="state"
            isDisabled={isEmpty(address.country)}
            options={states}
            value={getDataValue('state', address)}
            changeHandler={handleAddressChanged}>
            <span> {address && address.country === COUNTRY.CA ? 'Province*' : 'State*'}</span>
          </ELSDropDown>
          {renderErrorMessage('state')}
        </div>
      </div>
      <div className="c-tsp-credit-request__address">
        <div className={renderItemClasses('u-els-padding-right u-els-padding-right-none@mobile ', 'city')}>
          <ELSTextBox
            name="city"
            id="txtCity"
            autoComplete="off"
            value={getDataValue('city', address)}
            changeHandler={handleAddressChanged}>
            <span>City*</span>
          </ELSTextBox>
          {renderErrorMessage('city')}
        </div>
        <div className={renderItemClasses('u-els-margin-top@mobile ', 'postalCode')}>
          <ELSTextBox
            name="postalCode"
            id="txtPostalCode"
            autoComplete="off"
            value={getDataValue('postalCode', address)}
            changeHandler={handleAddressChanged}>
            <span>Postal code*</span>
          </ELSTextBox>
          {renderErrorMessage('postalCode')}
        </div>
      </div>
    </div>
  );
};

export default CreditRequestAddress;
