import { pull, omitBy, isNil } from 'lodash';
import { CohortProductModel } from 'models/cohort.models';
import { buildSubComboCourseProduct } from 'utilities/product-suite/product-suite.utility';
import withUseLoading from 'utilities/with-loading/withUseLoading';
import { locationAsyncActions } from 'redux/location';
import { RoutePath } from 'constants/app.constant';
import { FilterPagingOptions } from '../../models/api.model';
import { cohortServices, productSuiteServices, evolveServices, opportunitiesServices } from '../../services';
import { cohortActions } from './cohort.reducer';
import { opportunityActions } from '../opportunity';
import { transformToCohortPackage, transformToCohortWorkflowStatuses } from '../../utilities/app/app.utility';
import { prepareOppObject } from '../opportunity/opportunity.actions';

export const fetchTspCohortById = (opportunityId: string, cohorId: number | string) =>
  withUseLoading(async (dispatch) => {
    try {
      const response = await cohortServices.fetchTspCohortById(opportunityId, cohorId);
      dispatch(cohortActions.fetchCohortSuccess({
        CohortID: response.cohortId,
        CohortName: response.cohortName,
        CohortCount: response.cohortCount,
        Program: response.program,
        CohortTrack: response.cohortTrack,
        HesiCohortId: response.hesiCohortId,
        HesiCaseNumber: response.hesiCaseNumber,
        HesiQuoteNumber: response.hesiQuoteNumber,
        SalesforceUrl: response.salesforceUrl,
        StartDate: response.startDate,
        EndDate: response.endDate,
        IsInFlightCohort: response.inFlightCohort,
        Package: transformToCohortPackage(response.package),
        CurrentCohortCount: response.currentCohortCount,
        CohortCountApprover: response.cohortCountApprover,
        NumberOfInstallments: response.numberOfInstallments,
        HesiCohortName: response.hesiCohortName,
        HesiCohortStartDate: response.hesiCohortStartDate,
        HesiCohortEndDate: response.hesiCohortEndDate,
        HesiProgramId: response.hesiProgramId,
        HesiProgramName: response.hesiProgramName,
        HesiProgramType: response.hesiProgramType,
        HesiOrganizationId: response.hesiOrganizationId,
        HesiOrganizationName: response.hesiOrganizationName,
        HesiNGOrderId: response.hesiNGOrderId,
        HesiLegacyOrderId: response.hesiLegacyOrderId,
        HesiOrderingFaculty: response.hesiOrderingFaculty,
        ContractStartDate: response.contractStartDate,
        ContractEndDate: response.contractEndDate,
        HesiProductId: response.hesiProductId,
        HesiProductName: response.hesiProductName,
        HesiContractNumber: response.hesiContractNumber,
        HesiContractStatus: response.hesiContractStatus,
        WorkflowStatuses: transformToCohortWorkflowStatuses(response.workflowStatuses),
        ContractAudit: response.contractAudit,
        CancelledHesiCohorts: response.cancelledHesiCohorts,
        CancelledHesiContracts: response.cancelledHesiContracts,
        HesiCohortStatus: response.hesiCohortStatus,
        TspHesiContractId: response.tspHesiContractId,
        CostPerStudent: response.costPerStudent
      }));
    } catch (e) {
      if (e.response && e.response.status === 404) {
        dispatch(locationAsyncActions.redirect(RoutePath.error.pageNotFound));
      }
      dispatch(cohortActions.fetchCohortFailed(e));
    }
  });

export const fetchTspOpportunityAndCohortById = (opportunityId, cohortId) =>
  withUseLoading(async (dispatch) => {
    dispatch(cohortActions.resetCohort());
    try {
      const tspOpportunity = await opportunitiesServices.fetchTspOpportunityById(opportunityId);
      const opportunity = prepareOppObject(tspOpportunity, {});
      dispatch(opportunityActions.fetchOpportunitySuccess({ ...opportunity, ...omitBy(opportunity, isNil) }));
      dispatch(fetchTspCohortById(opportunityId, cohortId));
    } catch (e) {
      if (e.response && e.response.status === 404) {
        dispatch(locationAsyncActions.redirect(RoutePath.error.pageNotFound));
      }
      dispatch(cohortActions.fetchCohortFailed(e));
    }
  });

export const fetchProductSuiteByCohort = (cohortId: string | number, sessionId: string, filterOptions: FilterPagingOptions = null, includeProductDetail = false) =>
  withUseLoading(async (dispatch) => {
    try {
      const [ProductSuite] = await Promise.all([
        productSuiteServices.fetchProductSuite(cohortId, sessionId, filterOptions, includeProductDetail),
        evolveServices.fetchEvolveProductPlatforms()
      ]);
      dispatch(cohortActions.fetchProductSuiteSuccess(ProductSuite));
    } catch (e) {
      dispatch(cohortActions.fetchProductSuiteError(e));
    }
  });

export const createProductSuite = (opportunityId: string | number, cohortId: string | number, products) =>
  withUseLoading(async (dispatch) => {
    try {
      await productSuiteServices.createProductSuite(opportunityId, cohortId, products);
      dispatch(cohortActions.emptySelectedProducts());
    } catch (e) {
      dispatch(cohortActions.fetchProductSuiteError(e));
    }
  });

export const addProductsToProductSuite = (cohortId: string | number, products) =>
  withUseLoading(async (dispatch) => {
    try {
      await productSuiteServices.addProductsToProductSuite(cohortId, products);
      dispatch(cohortActions.emptySelectedProducts());
    } catch (e) {
      dispatch(cohortActions.fetchProductSuiteError(e));
    }
  });

export const updateProductSuite = (cohortId: string | number, products) =>
  withUseLoading(async (dispatch) => {
    try {
      await productSuiteServices.updateProductSuite(cohortId, products);
      dispatch(cohortActions.updateProductFromProductSuite({ products }));
    } catch (e) {
      dispatch(cohortActions.fetchProductSuiteError(e));
    }
  });

export const fetchChildrenProduct = (productsId: string[], comboProduct: CohortProductModel, sessionId: string, fetchChildrenProductFailed: any,
  cohortId: string | number, pagingOptions: any) =>
  withUseLoading(async (dispatch) => {
    const productList = [];
    const results = await evolveServices.fetchProductsByIds(productsId, sessionId);
    const validResults = results.filter(result => !(result instanceof Error));
    validResults.forEach(validItem => {
      const childProduct = buildSubComboCourseProduct(validItem, comboProduct);
      if (cohortId) {
        productList.push(childProduct);
      } else {
        dispatch(cohortActions.addSelectedProducts({ products: childProduct }));
      }
    });
    if (cohortId) {
      await productSuiteServices.updateProductSuite(cohortId, productList);
      dispatch(fetchProductSuiteByCohort(cohortId, sessionId, pagingOptions, true));
    }
    const isbns = [...productsId];
    validResults.forEach(result => {
      if (isbns.includes(result.resourceId)) {
        pull(isbns, result.resourceId);
      }
    });
    if (fetchChildrenProductFailed && isbns && isbns.length > 0) {
      const title = 'Add product';
      const message = (`Products [${isbns}] are not available. Please visit the 'Help' link to contact Evolve Support for further assistance.`);
      fetchChildrenProductFailed(title, message);
    }
  });
