import { omitBy, isNil } from 'lodash';
import { transformEvolveAddressToOppAddress } from 'utilities/app/app.utility';
import withUseLoading from 'utilities/with-loading/withUseLoading';
import { locationAsyncActions } from 'redux/location';
import { RoutePath } from 'constants/app.constant';
import { opportunitySelectors } from '.';
import { opportunitiesServices } from '../../services';
import { opportunityActions } from './opportunity.reducer';

export const prepareOppObject = (tspOpportunity, opportunity) => ({
  BillingAddress: tspOpportunity.billingAddress ? transformEvolveAddressToOppAddress(tspOpportunity.billingAddress) : opportunity.BillingAddress,
  ShippingAddress: tspOpportunity.shippingAddress ? transformEvolveAddressToOppAddress(tspOpportunity.shippingAddress) : opportunity.ShippingAddress,
  Approver: tspOpportunity.cohortCountApprover,
  PONumber: tspOpportunity.poNumber,
  TaxExemptFlag: tspOpportunity.taxExemptFlag,
  TaxRegistrationNumber: tspOpportunity.taxRegistrationNumber,
  OpportunityID: tspOpportunity.opportunityId,
  OpportunityIDNumber: tspOpportunity.opportunityIdNumber,
  OpportunityName: tspOpportunity.opportunityName,
  AccountName: tspOpportunity.accountName,
  CohortProgram: tspOpportunity.cohortProgram,
  Contract: tspOpportunity.contract &&
    (tspOpportunity.contract.contractId || tspOpportunity.contract.contractStatus || tspOpportunity.contract.contractAgreement) && {
    ContractID: tspOpportunity.contract.contractId,
    ContractStatus: tspOpportunity.contract.contractStatus,
    ContractAgreement: tspOpportunity.contract.contractAgreement,
  },
  ContactName: tspOpportunity.contactName,
  ContactEmail: tspOpportunity.contactEmail,
  KeyDecisionMakers: tspOpportunity.keyDecisionMakers,
  PORequired: tspOpportunity.poRequired,
  HasInFlightCohorts: tspOpportunity.hasInFlightCohorts,
  HasRegularCohorts: tspOpportunity.hasRegularCohorts,
  LastCohortEndDate: tspOpportunity.lastCohortEndDate,
  ECRID: tspOpportunity.ecrId,
  OpportunityOwner: tspOpportunity.opportunityOwner
});

export const fetchTspOpportunityById = (opportunityId: string) => withUseLoading(async (dispatch, getState) => {
  const opportunity = opportunitySelectors.getOpportunity(getState()) || {};
  try {
    const tspOpportunity = await opportunitiesServices.fetchTspOpportunityById(opportunityId);
    const updatedOpportunity = prepareOppObject(tspOpportunity, opportunity);
    dispatch(opportunityActions.fetchOpportunitySuccess({ ...opportunity, ...omitBy(updatedOpportunity, isNil) }));
  } catch (error) {
    if (error.response && error.response.status === 404) {
      dispatch(locationAsyncActions.redirect(RoutePath.error.pageNotFound));
    }
    dispatch(opportunityActions.fetchOpportunityFailed(error));
  }
});
