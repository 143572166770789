import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppConstant } from '../../constants/app.constant';

export const REDUCER_ID = AppConstant.redux.PRODUCT_SEARCH;

const initialState = {
  currentPage: 1,
  resultsPerPage: 10,
  query: '',
  facets: [],
  sortBy: 'relevancy',
  selectedFormats: [],
  selectedProductTypes: {},
  selectAll: ['all']
};

export const productSearchSlice = createSlice({
  name: 'productSearch',
  initialState,
  reducers: {
    resetProductSearch() {
      return {
        ...initialState
      };
    },
    updateProductSearch(state, action: PayloadAction<object>) {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const productSearchActions = productSearchSlice.actions;
export const productSearchReducer = productSearchSlice.reducer;
