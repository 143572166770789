/* eslint-disable max-len */
import React, { useState } from 'react';
import { compose } from 'recompose';
import { pick, get } from 'lodash';
import * as yup from 'yup';
import withHTMLHeadSEO from 'hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import { useDispatch } from 'react-redux';
import { CREDIT_REQUEST_NUMBER_VALIDATION_SCHEMA } from 'constants/creditRequest.constant';
import withUseLoading from 'utilities/with-loading/withUseLoading';
import { parseErrorObject } from '../../../../utilities/app/app.utility';
import { getMessageBasedOnErrorType } from '../../../../utilities/error/error.utility';
import { invoiceCreditRebillServices } from '../../../../services';
import CreditRequestTrackDetails from './CreditRequestTrackDetails.component';
import CreditRequestTrackForm from './CreditRequestTrackForm.component';
import { InternalCreditRequestModel } from '../../../../models/credit-request.models';

const CreditRequestTrack = ({ isInternalCreditRequest }: InternalCreditRequestModel) => {
  const [creditRequestNumber, setCreditRequestNumber] = useState('');
  const [creditRequestStatus, setCreditRequestStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const checkInvoiceCreditRequestStatus = withUseLoading(async () => {
    try {
      const res = await invoiceCreditRebillServices.checkInvoiceCreditRequestStatus(creditRequestNumber.trim());
      setCreditRequestStatus(res);
    } catch (error) {
      setErrorMessage(getMessageBasedOnErrorType(error));
    }
  });

  const handleCreditRequestNumberChanged = evt => {
    setCreditRequestNumber(evt.target.value);
  };

  const validatedCreditRequestNumber = (val) => {
    const validatedFields = pick(CREDIT_REQUEST_NUMBER_VALIDATION_SCHEMA, ['creditRequestNumber']);
    if (Object.keys(validatedFields).length === 0) {
      return null;
    }
    const newShape = Object.entries(validatedFields)
      .reverse()
      .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {});
    const schema = yup.object().shape(newShape);
    try {
      schema.validateSync({ creditRequestNumber: val });
    } catch (yupErr) {
      return parseErrorObject(yupErr);
    }
    return null;
  };

  const handleCreditRequestNumberSubmit = async () => {
    const errors = validatedCreditRequestNumber(creditRequestNumber.trim());
    if (errors) {
      setErrorMessage(get(errors, 'creditRequestNumber'));
      return;
    }

    dispatch(checkInvoiceCreditRequestStatus);
  };

  const handleCreditRequestNumberKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      await handleCreditRequestNumberSubmit();
    }
  };

  return (
    <div>
      <h2>Track Program Solutions Credit Request</h2>
      {creditRequestStatus ? (
        <CreditRequestTrackDetails isInternalCreditRequest={isInternalCreditRequest} creditRequestNumber={creditRequestNumber} creditRequestStatus={creditRequestStatus} />
      ) : (
        <CreditRequestTrackForm
          isInternalCreditRequest={isInternalCreditRequest}
          creditRequestNumber={creditRequestNumber}
          errorMessage={errorMessage}
          handleCreditRequestNumberChanged={handleCreditRequestNumberChanged}
          handleCreditRequestNumberKeyDown={handleCreditRequestNumberKeyDown}
          handleSubmit={handleCreditRequestNumberSubmit}
        />
      )}
    </div>
  );
};

const enhancers = [withHTMLHeadSEO({ title: 'Track Credit Request' })];

export default compose(...enhancers)(CreditRequestTrack);
