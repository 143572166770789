import React, { Component } from 'react';
import FooterEvolveLogo from './logos/FooterEvolveLogo.component';
import FooterRelxLogo from './logos/FooterRelxLogo.component';
import FooterContent from './content/FooterContent.component';
import FooterCookieConsent from './cookie-consent/FooterCookieConsent.component';

class Footer extends Component {
  render() {
    return (
      <footer className="c-tsp-footer" id="footer" role="contentinfo">
        <div className="c-tsp-footer__container">
          <FooterEvolveLogo />
          <FooterContent>
            <FooterCookieConsent />
          </FooterContent>
          <FooterRelxLogo />
        </div>
      </footer>
    );
  }
}

export default Footer;
