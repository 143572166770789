/* eslint-disable sonarjs/no-duplicate-string */
import { InstitutionInvoiceModel, EvolveAddressModel } from '../../models/invoice.model';
import { EvolveInstallmentModel } from '../../models/installment.model';

const ADDRESS: EvolveAddressModel = {
  address1: '1234 West St',
  city: 'Townville',
  address2: 'Suite 2A',
  state: 'PA',
  postalCode: '12345',
  country: 'USA'
};

export const INSTALLMENTS: EvolveInstallmentModel[] = [
  {
    installmentNumber: 1,
    installmentId: 'installment_001',
    installmentName: 'Installment 001',
    costPerStudent: 433.33,
    cohortId: '41',
    cohortName: 'Cohort #041',
    cohortProgram: 'ADN',
    invoiceDate: '2021-5-1',
    quantity: 25,
    netAmount: '433.33'
  },
  {
    installmentNumber: 2,
    installmentId: 'installment_002',
    installmentName: 'Installment 002',
    costPerStudent: 433.33,
    cohortId: '42',
    cohortName: 'Cohort #042',
    cohortProgram: 'ADN',
    invoiceDate: '2021-12-31',
    quantity: 10,
    netAmount: '433.33'
  },
  {
    installmentNumber: 3,
    installmentId: 'installment_003',
    installmentName: 'Installment 003',
    costPerStudent: 433.33,
    cohortId: '44',
    cohortName: 'Cohort #044',
    cohortProgram: 'ADN',
    invoiceDate: '2022-8-15',
    quantity: 10,
    netAmount: '433.33'
  },
  {
    installmentNumber: 4,
    installmentId: 'installment_004',
    installmentName: 'Installment 004',
    costPerStudent: 433.33,
    cohortId: '44',
    cohortName: 'Cohort #044',
    cohortProgram: 'ADN',
    invoiceDate: '2022-8-15',
    quantity: 25,
    netAmount: '433.33'
  }
];

export const invoices: InstitutionInvoiceModel[] = [
  {
    opportunityId: 'opportunity#id',
    installment: INSTALLMENTS[0],
    invoices: [{
      tspInvoiceId: '1',
      poNumber: 'pointegrate',
      billingAddress: ADDRESS,
      shippingAddress: ADDRESS,
      taxExemptFlag: false,
      invoiceStatus: {
        id: 1,
        name: 'Invoice Pending',
        desc: 'Invoice Pending',
        activityDate: '10/12/2021'
      },
    }]
  },
  {
    opportunityId: 'opportunity#id',
    installment: INSTALLMENTS[1],
    invoices: [{
      tspInvoiceId: '2',
      invoiceBomId: 'HGF1234567894',
      invoiceBomRefNumber: 'HGF1234567894',
      poNumber: '05XXXXXXXXXXX',
      taxExemptFlag: false,
      invoiceStatus: {
        id: 2,
        name: 'Cohort Counts Rejected',
        desc: 'Cohort Counts Rejected',
        activityDate: '10/20/2021'
      },
      billingAddress: ADDRESS,
      shippingAddress: ADDRESS,
    }]
  },
  {
    opportunityId: 'opportunity#id',
    installment: INSTALLMENTS[2],
    invoices: [{
      tspInvoiceId: '3',
      invoiceBomId: 'DEF2345678901',
      invoiceBomRefNumber: 'DEF2345678901',
      poNumber: '05XXXXXXXXXX',
      taxExemptFlag: false,
      invoiceStatus: {
        id: 3,
        name: 'Approved',
        desc: 'Approved',
        activityDate: '10/10/2021'
      },
      billingAddress: ADDRESS,
      shippingAddress: ADDRESS,
    }]
  },
  {
    opportunityId: 'opportunity#id',
    installment: INSTALLMENTS[3],
    invoices: [{
      tspInvoiceId: '4',
      invoiceBomId: 'JGF1234567890',
      invoiceBomRefNumber: 'JGF1234567890',
      poNumber: '05XXXXXXXXXX',
      taxExemptFlag: false,
      invoiceStatus: {
        id: 4,
        name: 'Invoice Past Due',
        desc: 'Invoice Past Due',
        activityDate: '10/10/2021'
      },
      billingAddress: ADDRESS,
      shippingAddress: ADDRESS,
    }]
  },
  {
    opportunityId: 'opportunity#id',
    installment: INSTALLMENTS[3],
    invoices: [{
      tspInvoiceId: '5',
      invoiceBomId: 'XXX1234567890',
      invoiceBomRefNumber: 'XXX1234567890',
      poNumber: '05XXXXXXXXXXX',
      taxExemptFlag: false,
      invoiceStatus: {
        id: 4,
        name: 'Opened',
        desc: 'Opened',
        activityDate: '10/9/2021'
      },
      billingAddress: ADDRESS,
      shippingAddress: ADDRESS,
    }]
  },
  {
    opportunityId: 'opportunity#id',
    installment: INSTALLMENTS[2],
    invoices: [{
      tspInvoiceId: '6',
      invoiceBomId: 'LKM2345678901',
      invoiceBomRefNumber: 'LKM2345678901',
      poNumber: '05XXXXXXXXXXX',
      oracleStatus: {
        name: 'Invoice Failed',
        desc: 'Invoice Failed',
        id: 2,
        activityDate: '10/1/2021'
      },
      taxExemptFlag: false,
      invoiceStatus: {
        id: 1,
        name: 'Invoice Payload Sent',
        desc: 'Invoice Payload Sent',
        activityDate: '10/1/2021'
      },
      billingAddress: ADDRESS,
      shippingAddress: ADDRESS,
    }]
  },
  {
    opportunityId: 'opportunity#id',
    installment: INSTALLMENTS[1],
    invoices: [{
      tspInvoiceId: '7',
      invoiceBomId: 'FGH2345678901',
      invoiceBomRefNumber: 'FGH2345678901',
      poNumber: '05XXXXXXXXXXX',
      taxExemptFlag: false,
      invoiceStatus: {
        id: 5,
        name: 'Closed',
        desc: 'Closed',
        activityDate: '10/20/2021'
      },
      billingAddress: ADDRESS,
      shippingAddress: ADDRESS,
    }]
  },
  {
    opportunityId: 'opportunity#id',
    installment: INSTALLMENTS[2],
    invoices: [{
      tspInvoiceId: '8',
      invoiceBomId: 'SDF1234567890',
      invoiceBomRefNumber: 'SDF1234567890',
      poNumber: '05XXXXXXXXXXX',
      taxExemptFlag: false,
      invoiceStatus: {
        id: 6,
        name: 'Sent',
        desc: 'Sent',
        activityDate: '10/15/2021'
      },
      billingAddress: ADDRESS,
      shippingAddress: ADDRESS,
    }]
  }
];

export const invoiceDetail: InstitutionInvoiceModel = {
  opportunityId: 'opportunity#id',
  installment: {
    installmentNumber: 1,
    installmentId: 'installment_001',
    cohortId: 'cohort_001',
    cohortName: 'cohort_001',
    cohortProgram: 'BSN',
    quantity: 25,
    costPerStudent: 43.33
  },
  invoices: [{
    tspInvoiceId: '8',
    invoiceBomId: 'SDF1234567890',
    invoiceBomRefNumber: 'SDF1234567890',
    poNumber: '05XXXXXXXXXXX',
    invoiceStatus: {
      id: 6,
      name: 'Cohort Count Approval Request Sent',
      desc: 'Cohort Count Approval Request Sent',
      activityDate: '10/15/2021'
    },
    taxExemptFlag: false,
    billingAddress: ADDRESS,
    shippingAddress: ADDRESS,
  }]
};
