/* eslint-disable max-len */
import React from 'react';
import CreditRequestContact from '../../CreditRequestContact.component';

type CreditRequestSuccessProps = {
  creditRequestNumber: string;
};

const CreditRequestSuccess = (props: CreditRequestSuccessProps) => {
  const { creditRequestNumber } = props;
  return (
    <div className="submitted-credit-request-screen">
      <h2>Program Solutions Credit Request Submitted</h2>
      <h4 className="u-els-margin-top">
        Your Program Solutions credit request has been submitted. The credit request number has been provided below for your records and a confirmation has been sent to the email you’ve provided.
      </h4>
      <h3 className="u-els-margin-top"><strong>Credit Request Number: {creditRequestNumber}</strong></h3>
      <h4 className="u-els-margin-top">
        Please allow up to 10 business days for the credit to be processed. You may check the status of your credit request at any time by entering the Credit Request Number on the Track Program Solutions Credit Request page.
      </h4>
      <CreditRequestContact />
    </div>
  );
};

export default CreditRequestSuccess;
