import React from 'react';
import cx from 'classnames';
import { ELSTextBox } from '@els/els-component-form-field-react';
import CreditRequestContact from '../../CreditRequestContact.component';
import { ELSButton } from '../../../common/els';
import { InternalCreditRequestModel } from '../../../../models/credit-request.models';

export interface CreditRequestTrackFormProps extends InternalCreditRequestModel {
  creditRequestNumber: string;
  errorMessage: string;
  handleCreditRequestNumberChanged: Function;
  handleCreditRequestNumberKeyDown: Function;
  handleSubmit: Function;
}

const CreditRequestTrackForm = ({
  isInternalCreditRequest,
  creditRequestNumber,
  errorMessage,
  handleCreditRequestNumberChanged,
  handleCreditRequestNumberKeyDown,
  handleSubmit
}: CreditRequestTrackFormProps) => (
  <>
    <h4 className="u-els-margin-top">To track a program solutions credit request you will first need to enter a valid credit request number.</h4>
    {!isInternalCreditRequest && <CreditRequestContact />}
    <div className={cx('u-els-margin-top-2x', { 'c-els-field--error': errorMessage })}>
      <ELSTextBox
        placeholder="Credit Request Number (XXXXXXXXXXXXX)"
        name="creditRequestNumber"
        id="creditRequestNumber"
        value={creditRequestNumber}
        changeHandler={handleCreditRequestNumberChanged}
        keyDownHandler={handleCreditRequestNumberKeyDown}
      >
        <div className="u-els-margin-bottom-1o4">Credit Request Number*</div>
      </ELSTextBox>
      {errorMessage && (
        <div className="c-els-field__message-list">
          <span className="c-els-field__message c-els-field__message--error">{errorMessage}</span>
        </div>
      )}
    </div>
    <div>
      <ELSButton className="u-els-margin-right-1o2 u-els-margin-top-2x c-tsp-landing-page__rounded" type="primary" disabled={!creditRequestNumber} onClick={handleSubmit}>
        Submit
      </ELSButton>
    </div>
  </>
);

export default CreditRequestTrackForm;
