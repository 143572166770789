import React from 'react';
import relxLogo from 'assets/icons/RelxLogo.svg';

const FooterRelxLogo = () => (
  <div className="c-tsp-footer__relx">
    <a aria-label="RELX ™ homepage" className="es-js-tooltip" href="https://www.relx.com/" rel="noopener noreferrer nofollow" target="_blank">
      <img alt="RELX ™" src={relxLogo} />
    </a>
  </div>
);

export default FooterRelxLogo;
