import { gatewayServiceApi } from 'helpers/RestClient.helper';
import { buildEvolvePagingOptions } from 'helpers/api.helpers';
import { PagingOptions } from 'models/api.model';
import {
  BillingQueueModel,
  BillingQueueRequestParam,
  InstallmentCommentModel
} from '../../models/billing-queue.models';

export const fetchBillingQueue = (pagingOptions: PagingOptions, requestParam: BillingQueueRequestParam) => {
  const data = {
    ...buildEvolvePagingOptions(pagingOptions),
    ...requestParam
  };
  return gatewayServiceApi.post('/api/tsp/billingDashboard/installments/search', { data }) as Promise<BillingQueueModel>;
};

export const fetchCommentsByInstallmentId = (installmentId: string | number) =>
  gatewayServiceApi.get(`/api/tsp/billingDashboard/installments/${installmentId}/comments`) as Promise<{ totalCount: number, comments: InstallmentCommentModel[] }>;

export const addComment = (installmentId: string | number, comment: string) =>
  gatewayServiceApi.post(`/api/tsp/billingDashboard/installments/${installmentId}/comments`, {
    data: {
      installmentSysId: installmentId,
      comment
    }
  }) as Promise<InstallmentCommentModel>;
