import React from 'react';
import { isNil, isEmpty, isNull } from 'lodash';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import Status from 'components/common/status/Status.component';
import {
  convertCSTTimeZone,
  formatDate,
  isDateOnly,
  nl2br
} from 'utilities/app/app.utility';
import { DATE_FORMAT_MDYYYY, DATE_TIME_FORMAT_MDYYYYHMMA, RoutePath } from 'constants/app.constant';
import { RosterModel } from 'models/roster.models';
import { StudentPaymentStatusType } from 'constants/student-payments.constant';

export const renderDate = (date: string | Date, format: string = DATE_FORMAT_MDYYYY) => {
  if (isNil(date) || isEmpty(date)) {
    return '';
  }

  if (isDateOnly(date)) {
    return formatDate(date, format);
  }
  return convertCSTTimeZone(date, format);
};
export const renderDateTime = (date: string | Date, format: string = DATE_TIME_FORMAT_MDYYYYHMMA) => convertCSTTimeZone(date, format);

export const renderCurrency = (row, column) => (
  // eslint-disable-next-line react/style-prop-object
  <FormattedNumber value={row[column.field] ? row[column.field] : 0} style="currency" currency="USD" />
);

export const renderCurrencyValue = (value: number | string) => {
  if (isNull(value)) return '-';
  const style = { value: 'currency' };
  return (<FormattedNumber value={value} style={style.value} currency="USD" />);
};

export const renderStatus = (row, column) => row[column.field] && <Status status={row[column.field]} />;

export const renderFullNameRoster = (roster: RosterModel) => `${roster.lastName} ${roster.firstName}`;
export const renderFullNameRosterWithComma = (roster: RosterModel) => `${roster.lastName}, ${roster.firstName}`;

export const renderNoResultsFound = (message: string | JSX.Element) => <h4>{message}</h4>;

interface RenderApprovedCohortCountProps {
  approvedCohortCount: number;
  opportunityId: string | number;
  installmentId: string;
  cohortId: string;
  activityId: number;
  previousPage: string;
}

// eslint-disable-next-line max-len
export const renderApprovedCohortCountColumn = ({ approvedCohortCount, opportunityId, installmentId, cohortId, activityId, previousPage }: RenderApprovedCohortCountProps) => {
  if (approvedCohortCount) {
    const path = `${generatePath(
      RoutePath.cohort.reviewInstallmentCohortCountApproval, { opportunityId, cohortId, installmentId }
    )}?activityId=${activityId}&previousPage=${previousPage}`;
    return (
      <div key={`approved-cohort-count-${installmentId}-${cohortId}`}>
        <Link
          to={path}>
          {approvedCohortCount}
        </Link>
      </div>
    );
  }
  return <div key={`approved-cohort-count-${installmentId}-${cohortId}`}>-</div>;
};

export const renderPaymentDetails = (row, coloumn) => {
  switch (row[coloumn.field] && row[coloumn.field].id) {
    case StudentPaymentStatusType.PAID:
      return (
        <>
          <p>{row[coloumn.field].paymentDate && renderDate(row[coloumn.field].paymentDate)}</p>
          <p><strong className="u-els-color-confirm-on-dark">PAID</strong>{row[coloumn.field].paymentId && ` (${row[coloumn.field].paymentId})`}</p>
        </>
      );
    case StudentPaymentStatusType.PAYMENT_OVERDUE:
      return <strong className="u-els-color-warn">PAYMENT OVERDUE</strong>;
    case StudentPaymentStatusType.NO_REQUIRED:
      return <strong className="u-els-color-confirm-on-dark">NOT REQUIRED</strong>;
    case StudentPaymentStatusType.AWAITING_PAYMENT:
      return <span>AWAITING PAYMENT</span>;
    case StudentPaymentStatusType.REFUNDED:
      return <strong className="u-els-color-warn">REFUNDED</strong>;
    default:
      return (row[coloumn.field] && row[coloumn.field].id) || '-';
  }
};

export const renderUniqueId = (orderItemId: number) => orderItemId && `(Unique ID - ${Math.abs(orderItemId)}${orderItemId < 0 ? ' - New' : ''})`;

export const renderHtmlText = (text: string) => <span dangerouslySetInnerHTML={{ __html: nl2br(text) }} />;
