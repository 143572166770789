import React, { Component, ErrorInfo } from 'react';
import { Redirect } from 'react-router-dom';
import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import { errorUtils } from 'utilities/error/error.utility';
import { ELSIcon } from '@els/els-component-form-field-react';
import { EvolveLinks } from '../../constants/app.constant';

interface ErrorBoundaryState {
  error: Error;
  errorInfo: ErrorInfo;
}

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  state = { error: null, errorInfo: null };
  errorRef: React.RefObject<any>;
  constructor(props) {
    super(props);
    this.errorRef = React.createRef();
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo
    });
    errorUtils.logError({ err: error, previousState: errorInfo, previousStateParams: null });
  }

  handleSelectCopyText = () => {
    const range = document.createRange();
    range.selectNodeContents(this.errorRef.current);
    const self = window.getSelection();
    self.removeAllRanges();
    self.addRange(range);
  };

  handleCopyClipboard = () => {
    this.handleSelectCopyText();
    return navigator.clipboard.writeText(this.state.errorInfo.componentStack);
  };

  render() {
    if (this.state.error) {
      if (this.state.error.name === 'InvalidTokenError') {
        return <Redirect to={`/${ELSCommonUIConstants.security.States.NotAuthorized}`} />;
      }
      return (
        <div className="c-tsp-error-page o-els-flex-layout u-els-margin-1x1o2">
          <div className="o-els-flex-layout__item u-els-margin-left-1x u-els-margin-right-1x">
            <ELSIcon name="information-outline-circle" color="warn" size="2x" prefix="gizmo" />
          </div>
          <div className="o-els-flex-layout__item o-els-flex-layout__item--grow u-els-padding-right-1x@tablet">
            <h1 className="c-tsp-error-page__header u-els-margin-bottom-1x1o2 u-tsp-font-size-2x">
              An unexpected issue has occurred. Please contact
              <a href={EvolveLinks.EVOLVE_SUPPORT_LINK} target="_blank" rel="noopener noreferrer"> Evolve Support</a>
              or call 1-800-222-9570 if you continue to receive this message.
            </h1>
            <div className="o-els-flex-layout__item">
              {
                this.state.error && this.state.error.toString() &&
                <p className="u-els-font-family-bold u-els-margin-bottom-1x1o2 u-tsp-font-size-1x1o4">{this.state.error.toString()}</p>
              }
              <div>
                <p className="u-els-font-family-bold u-els-color-n5 u-tsp-font-size-1x1o4">{'Debug Information '}
                  <button
                    type="button"
                    className="c-tsp-error-page__actions--copy u-els-debuttonize"
                    onClick={this.handleCopyClipboard.bind(this)}
                  >
                    Copy to clipboard
                  </button>
                </p>
                <pre ref={this.errorRef} className="c-tsp-error-page__message--content">{this.state.errorInfo.componentStack}</pre>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
