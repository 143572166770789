import React from 'react';
import ELSMenu from '../../common/menu/MenuComponent';
import { NavigationSectionType } from '../../../models/navigation.models';
import NavigationLink from '../navigation-items/navigation-link/NavigationLink.component';
import NavigationButton from '../navigation-items/navigation-button/NavigationButton.component';

export interface DropdownNavSectionProps {
  section: NavigationSectionType;
}

const DropdownNavSection = ({ section }: DropdownNavSectionProps) => {
  const dropdownMenuItems = section.items.map(item => ({
    key: item.key,
    content: item.onAction ?
      <NavigationButton key={item.key} item={item} /> :
      <NavigationLink key={item.key} item={item} />
  }));
  return (
    <div className="u-els-display-none@tablet c-tsp-nav-section--dropdown">
      <ul className="c-els-nav-group">
        <li className="c-els-nav-group__item o-els-layout--bottom u-els-margin-none">
          <ELSMenu
            menuItems={dropdownMenuItems}
            buttonType="tertiary"
            buttonClassName="u-els-padding-1o2
                             u-els-padding-left-1x
                             u-els-padding-right-1x
                             c-tsp-nav-section--dropdown__toggle-button"
            icon={section.iconName}
            iconPrefix={section.iconPrefix}>
            <div className="c-tsp-nav-section--dropdown__toggle-button__text">{section.iconLabel}</div>
          </ELSMenu>
        </li>
      </ul>
    </div>
  );
};

export default DropdownNavSection;
