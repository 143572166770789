import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ELSIcon } from '@els/els-component-form-field-react';
import { appSelectors } from '../../../redux/app';

const GlobalMessage = () => {
  const isLoading = useSelector(appSelectors.isLoading);
  const message = useSelector(appSelectors.getMessage);
  const messageRef = useRef(null);
  const [isOverlapMessage, setOverlapMessage] = useState(isLoading);

  useEffect(() => {
    if (message && messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (message) {
      setOverlapMessage(isLoading);
    }
  }, [message]);

  useEffect(() => {
    if (!isLoading) {
      setOverlapMessage(isLoading);
    }
  }, [isLoading]);

  return (
    <div
      ref={messageRef}
      className={classNames('c-tsp-layout__message', {
        'c-tsp-layout__message--show': message,
        'c-tsp-layout__message--success': message && message.success,
        'c-tsp-layout__message--failed': message && !message.success,
        'c-tsp-layout__message--overlap': isOverlapMessage
      })}
    >
      {message && message.success && <ELSIcon size="1x" name="checkmark" />}
      <span className="u-els-font-family-bold u-els-margin-left c-tsp-layout__message-info" dangerouslySetInnerHTML={{ __html: (message && message.text) || '' }} />
    </div>
  );
};

export default GlobalMessage;
