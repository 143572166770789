import { useState } from 'react';
import cx from 'classnames';
import { ELSIcon, ELSTextBox } from '@els/els-component-form-field-react';
import { ELSTooltip } from '@els/els-component-tooltip-react';
import invoiceImage from '../../../../assets/images/invoice-image.png';
import { ELSButton } from '../../../common/els';
import { InternalCreditRequestModel } from '../../../../models/credit-request.models';
import CreditRequestDescription from '../../CreditRequestDescription.component';

interface ValidateInvoiceNumberProp extends InternalCreditRequestModel{
  invoiceNumber: string;
  errorMessage: string;
  submitHandler: (invoiceNumber: string) => void;
}

const ValidateInvoiceNumber = (props: ValidateInvoiceNumberProp) => {
  const [invoiceNumber, setInvoiceNumber] = useState(props.invoiceNumber);

  const handleInvoiceNumberChanged = (evt) => {
    setInvoiceNumber(evt.target.value);
  };

  const renderSampleImageTooltip = () => (
    <ELSTooltip
      wrapperClassName="tsp-validate-invoice-number__invoice-status-tooltip"
      showWhenHover
      wrapperMaxWidth={800}
      content={(
        <>
          <div>
            <img className="image-class"
              src={invoiceImage}
              alt="Invoice Sample" />
          </div>
        </>
      )}>
      <ELSIcon name="information-solid-circle" prefix="gizmo" color="secondary" size="1x1o4" customClass="c-tsp-status-header__icon o-els-layout--middle" />
    </ELSTooltip>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    props.submitHandler(invoiceNumber);
  };

  return (
    <form onSubmit={handleSubmit} className="tsp-validate-invoice-number">
      <h2>Submit Program Solutions Credit Request</h2>
      <CreditRequestDescription isInternalCreditRequest={props.isInternalCreditRequest} />
      <div className={cx('u-els-margin-top', { 'c-els-field--error': props.errorMessage })}>
        <ELSTextBox
          placeholder="Enter Invoice Number (XXXXXXXXXXXXX)"
          name="invoiceNumber"
          value={invoiceNumber}
          changeHandler={handleInvoiceNumberChanged}>
          <div className="tsp-validate-invoice-number__invoice-number u-els-margin-bottom-1o4">
            <span>Invoice Number*</span>
            <span>{renderSampleImageTooltip()}</span>
          </div>
        </ELSTextBox>
        {props.errorMessage && (
          <div className="c-els-field__message-list">
            <span className="c-els-field__message c-els-field__message--error">{props.errorMessage}</span>
          </div>
        )}
      </div>
      <ELSButton
        className="u-els-margin-right-1o2 u-els-margin-top-2x tsp-validate-invoice-number__submit"
        type="primary"
        onClick={handleSubmit}
        disabled={!invoiceNumber}>
        Submit
      </ELSButton>
    </form>
  );
};

export default ValidateInvoiceNumber;
