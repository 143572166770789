import { appActions } from '../../redux/app';

const withUseLoading = (asyncAction: (dispatch, getState?) => Promise<unknown> | void) => async (dispatch, getState) => {
  try {
    dispatch(appActions.startLoading());
    return await asyncAction(dispatch, getState);
  } finally {
    dispatch(appActions.endLoading());
  }
};

export default withUseLoading;
