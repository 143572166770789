/* eslint sonarjs/no-duplicate-string: 0 */
import { ELSCommonConfig } from '@els/els-ui-common-react';

export const ServerConstants = {
  DataSource: ELSCommonConfig.appProfile, // Replace with the preferred source from below.

  common: {
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true,
    },
    featureFlags: {
      fetchOpportunitiesFromApi: true,
      fetchWorkflowStatusesAPI: false,
      authenticationDisabled: false
    },
    evolveHelpUrl: 'https://service.elsevier.com/app/overview/evolve/',
    elsevierTechnicalSupportURL: 'https://service.elsevier.com/app/overview/evolve/',
    elsevierContactUsURL: '/education/contact-us/?isbn=',
    cookieDomain: 'elsevier.com',
    gatewayBaseURL: 'https://qa-apigateway-eols.elsevier.com',
    fedAccessCookieName: 'ev.fa-c-login',
    evolvePortalBaseUrl: 'https://evolvetest.elsevier.com',
    sessionId: 'EVAE2_CERTF',
    recaptchaPublicKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    payment: {
      redirectURL: '/tsp/my-dashboard/student-payment/:installmentId/payment',
      cancelURL: '/tsp/my-dashboard/student-payment/:installmentId/cancel'
    },
    pendoApiKey: 'd34c653d-5fc1-4c86-75cb-1b929c938da5'
  },
  local: {
    gatewayBaseURL: 'https://dev-apigateway-eols.elsevier.com',
    fedAccessCookieName: 'ev.fa-d-login',
    evolvePortalBaseUrl: 'https://evolvelocal.elsevier.com',
    fedAccessLogoutUrl: 'https://evolvedev.elsevier.com/fa/logout?appRedirectURL=https://evolvelocal.elsevier.com/cs/logout',
    sessionId: 'EVAE2_DEV',
    productPlatformImages: '//evolvedev-images.s3.amazonaws.com/uploaded/images/custom/platforms/',
    searchCatalogImages: {
      dynamicCampaignImage: '//evolvedev-images.s3.amazonaws.com/education/img/ad/rp/top/',
      dynamicCampaignImageUrl: '//evolvedev.elsevier.com/education/ad/adspace.html?campCode='
    },
    productAdImages: {
      dynamicLeftAdImage: '//evolvedev-images.s3.amazonaws.com/education/img/ad/pp/left/',
      dynamicRightAdImage: '//evolvedev-images.s3.amazonaws.com/education/img/ad/pp/right/',
      dynamicAdImageUrl: '//evolve.elsevier.com/education/ad/adspace.html?campCode='
    }
  },
  dev: {
    gatewayBaseURL: 'https://dev-apigateway-eols.elsevier.com',
    fedAccessCookieName: 'ev.fa-d-login',
    evolvePortalBaseUrl: 'https://evolvedev.elsevier.com',
    sessionId: 'EVAE2_DEV',
    productPlatformImages: '//evolvedev-images.s3.amazonaws.com/uploaded/images/custom/platforms/',
    searchCatalogImages: {
      dynamicCampaignImage: '//evolvedev-images.s3.amazonaws.com/education/img/ad/rp/top/',
      dynamicCampaignImageUrl: '/education/ad/adspace.html?campCode='
    },
    productAdImages: {
      dynamicLeftAdImage: '//evolvedev-images.s3.amazonaws.com/education/img/ad/pp/left/',
      dynamicRightAdImage: '//evolvedev-images.s3.amazonaws.com/education/img/ad/pp/right/',
      dynamicAdImageUrl: '//evolve.elsevier.com/education/ad/adspace.html?campCode='
    }
  },
  qa: {},
  test1: {},
  test2: {},
  test3: {},
  test4: {},
  test5: {},
  test6: {
    productPlatformImages: '//evolvecert-images.s3.amazonaws.com/uploaded/images/custom/platforms/'
  },
  stage: {
    gatewayBaseURL: 'https://evolvestage.elsevier.com/eolsapi',
    fedAccessCookieName: 'ev.fa-s-login',
    evolvePortalBaseUrl: 'https://evolvestage.elsevier.com',
    productPlatformImages: '//evolvecert-images.s3.amazonaws.com/uploaded/images/custom/platforms/',
    searchCatalogImages: {
      dynamicCampaignImage: '//evolvecert-images.s3.amazonaws.com/education/img/ad/rp/top/',
      dynamicCampaignImageUrl: '//evolvestage.elsevier.com/education/ad/adspace.html?campCode='
    },
    productAdImages: {
      dynamicLeftAdImage: '//evolvecert-images.s3.amazonaws.com/education/img/ad/pp/left/',
      dynamicRightAdImage: '//evolvecert-images.s3.amazonaws.com/education/img/ad/pp/right/',
      dynamicAdImageUrl: '//evolve.elsevier.com/education/ad/adspace.html?campCode='
    },
    recaptchaPublicKey: '6LdlxbsUAAAAALmj-aM2zwn6HBcBTVP2LRpuX6FC'
  },
  prod: {
    gatewayBaseURL: 'https://eolsapi.elsevier.com',
    fedAccessCookieName: 'ev.fa-p-login',
    evolvePortalBaseUrl: 'https://evolve.elsevier.com',
    sessionId: 'EVAE2_PROD',
    productPlatformImages: '//evolveprod-images.s3.amazonaws.com/uploaded/images/custom/platforms/',
    searchCatalogImages: {
      dynamicCampaignImage: '//evolveprod-images.s3.amazonaws.com/education/img/ad/rp/top/',
      dynamicCampaignImageUrl: '//evolve.elsevier.com/education/ad/adspace.html?campCode='
    },
    productAdImages: {
      dynamicLeftAdImage: '//evolveprod-images.s3.amazonaws.com/education/img/ad/pp/left/',
      dynamicRightAdImage: '//evolveprod-images.s3.amazonaws.com/education/img/ad/pp/right/',
      dynamicAdImageUrl: '//evolve.elsevier.com/education/ad/adspace.html?campCode='
    },
    recaptchaPublicKey: '6LfH7aQUAAAAALbzhKMJOEKG0SPLomCDQ1e6o57A'
  }
};
