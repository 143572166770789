import React from 'react';
import { STATUS_MAPPING } from '../../../constants/opportunities.constant';

export const getStatusClassMapping = status => {
  const statusOption = STATUS_MAPPING.find(item => status && item.value.toLowerCase() === status.toLowerCase());
  return (
    statusOption || null
  );
};

const Status = (props: { status: string }) => {
  const status = getStatusClassMapping(props.status);
  const renderStatus = () => (
    <>
      <div className={`c-tsp-status__icon u-els-background-color-${status.type}`} />
      <span>{status.name}</span>
    </>
  );

  return (
    <div className="c-tsp-status">
      {
        status ? renderStatus() : <span>{props.status}</span>
      }
    </div>
  );
};
export default Status;
