import React, { useEffect } from 'react';
import { Redirect, Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ALCRoutePath } from 'modules/hesi/constants/hesi.constants';
import { securitySelectors } from 'redux/security';
import { createHESIRoutes } from '../../utilities/route/route.utility';
import { ALCRoutes } from '../../routes/hesi.routes';
import { fetchHESIProgramList, fetchProductMetadata } from '../../redux/order-exams/order-exams.actions';

const hesiALCRoutes = createHESIRoutes(ALCRoutes.getRoutes());

const OrderExamHomePage = () => {
  const isLoggedIn = useSelector(securitySelectors.isLoggedIn);
  const dispatch = useDispatch();

  const initData = () => {
    dispatch(fetchProductMetadata());
    dispatch(fetchHESIProgramList());
  };

  useEffect(() => {
    if (isLoggedIn) {
      initData();
    }
  }, [isLoggedIn]);

  return (
    <Switch>
      {hesiALCRoutes}
      <Redirect exact from={ALCRoutePath.orderExams.home} to={ALCRoutePath.orderExams.programTypes} />
      <Redirect to={ALCRoutePath.error.pageNotFound} />
    </Switch>
  );
};

export default OrderExamHomePage;
