import React, { ReactNode } from 'react';
import HESIHeader from '../header/HESIHeader.component';
import HESIFooter from '../footer/HESIFooter.component';
import GlobalMessage from '../../../../components/common/global-message/GlobalMessage.component';

interface LayoutHESIALCProps {
  children: ReactNode;
}
const HESILayout = (props: LayoutHESIALCProps) => (
  <>
    <div className="c-tsp-layout__header">
      <HESIHeader />
    </div>
    <GlobalMessage />
    <div className="o-els-flex-layout o-els-flex-layout--column@tablet o-els-flex-layout--left">
      <div className="o-els-flex-layout__item o-els-flex-layout__item--grow">
        <main id="main" role="main">
          {props.children}
        </main>
        <HESIFooter />
      </div>
    </div>
  </>
);

export default HESILayout;
