import * as yup from 'yup';
import { InvoiceStatuses } from './invoice.constant';
import { isBeforeDate, isValidedDateByFormat } from '../utilities/app/app.utility';
import { DATE_FORMAT_MDYYYY } from './app.constant';

export const MAX_LENGTH_CREDIT_REQUEST_COMMENT = 255;

export const billingQueueText = 'Institution Invoice Queue';

export const EmptyBillingDashboardDataMessage = 'No Installments were found within the selected date range.';
export const BillingQueueSearchDateRangeText = 'Search by invoice date range';
export const BillingQueueFilterStatusText = 'Filter by Institution Payment Status:';

export const BillingQueueDateRangeFields = {
  fromDate: 'fromDate',
  toDate: 'toDate'
};

export const BillingDashboardQueueTableColumns = {
  accountName: { field: 'accountName', header: 'Account Name' },
  initialCohortCount: { field: 'initialCohortCount', header: 'Initial Cohort Count' },
  currentCohortCount: { field: 'currentCohortCount', header: 'Current Cohort Count' },
  requiredTasks: { field: 'requiredTasks', header: 'Required Tasks' },
  invoiceDate: { field: 'invoiceDate', header: 'Invoice Date' },
  installmentId: { field: 'installmentId', header: 'InstallmentId' },
  cohortAndinstallmentDetail: { field: 'installmentNumber', header: 'Cohort & Installment Details' },
  institutionPaymentStatus: { field: 'invoiceStatus', header: 'Institution Payment Status' },
  opportunityOwner: { field: 'opportunityOwner', header: 'Opportunity Owner' }
};

export const BillingQueueRequiredTasks = {
  hesiQuoteNumberAdded: 'hesiQuoteNumberAdded',
  taxExemptionStatusChosen: 'taxExemptionStatusChosen',
  hesiCaseNumberProvided: 'hesiCaseNumberProvided',
  cohortCountApproved: 'cohortCountApproved',
  termsSelectedForProducts: 'termsSelectedForProducts',
};

export const TaxExemptStatusOptions = [
  {
    name: 'Select Tax Exempt Status',
    value: ''
  },
  {
    name: 'Yes',
    value: 'Y'
  },
  {
    name: 'No',
    value: 'N'
  }
];

export const InstitutionPaymentStatuses = [
  InvoiceStatuses.UnprocessedInstallment,
  InvoiceStatuses.InvoicePending,
  InvoiceStatuses.InvoiceBillingApproved,
  InvoiceStatuses.InvoicePayloadSent,
  InvoiceStatuses.InvoiceDrafted,
  InvoiceStatuses.InvoiceCreated,
  InvoiceStatuses.InvoiceSent,
  InvoiceStatuses.InvoiceClosed,
  InvoiceStatuses.InvoiceFailed,
  InvoiceStatuses.InvoiceCreditPayloadSent,
  InvoiceStatuses.InvoiceCreditMemoCreated,
  InvoiceStatuses.InvoiceCreditMemoSent,
  InvoiceStatuses.InvoiceCreditMemoFailed,
  InvoiceStatuses.InvoiceIgnore
];

export const BillingDashboardFilterOptions = InstitutionPaymentStatuses.map(item => ({ ...item, value: `${item.id}` }));

const invalidDateFormat = 'Invalid date format. Please enter the date in the format "MM/DD/YYYY".';
const invalidDateRange = 'Invalid date range. "To Date" must be greater than or equal to "From Date".';

export const BILLING_QUEUE_VALIDATION_SCHEMA = {
  fromDate: yup
    .date()
    .nullable()
    .transform((value, originalValue) => {
      if (!originalValue || originalValue === '') {
        return null;
      }
      return isValidedDateByFormat(originalValue, DATE_FORMAT_MDYYYY) ? value : false;
    })
    .typeError(invalidDateFormat),
  toDate: yup
    .date()
    .nullable()
    .transform((value, originalValue) => {
      if (!originalValue || originalValue === '') {
        return null;
      }
      return isValidedDateByFormat(originalValue, DATE_FORMAT_MDYYYY) ? value : false;
    })
    .typeError(invalidDateFormat)
    .test({
      name: 'isFromDateNotBeforeTodate',
      test(value) {
        const { fromDate } = this.options.context;
        if (fromDate && !isBeforeDate(value, fromDate)) {
          return true;
        }
        return false;
      },
      message: invalidDateRange,
      exclusive: true
    })
};
