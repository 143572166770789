import { StatusOptionModel } from '../models/opportunity.model';

export const statuses: StatusOptionModel[] = [
  {
    value: '',
    name: 'All Statuses',
    type: 'default'
  },
  {
    value: 'new',
    name: 'New',
    type: 'default'
  },
  {
    value: 'in-progress',
    name: 'Setup in-progress',
    type: 'subtile'
  },
  {
    value: 'Active',
    name: 'Active',
    type: 'primary'
  },
  {
    value: 'expired',
    name: 'Expired',
    type: 'warn'
  },
  {
    value: 'pending',
    name: 'Pending',
    type: 'subtile'
  }
];
