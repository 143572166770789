import React, { Component } from 'react';
import cx from 'classnames';
import { ELSButton, ELSButtonWithIcon } from 'components/common/els';

const TABLET_MAX_WIDTH = 900;

interface SideDrawerModalProps {
  modalId: string;
}

interface SideDrawerModalState {
  openNav: boolean;
}

export class SideDrawerModal extends Component<SideDrawerModalProps, SideDrawerModalState> {
  state = { openNav: false };
  listenerResize = null;

  constructor(props) {
    super(props);
    this.listenerResize = window.addEventListener('resize', this.handleListenerResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.listenerResize);
  }

  handleListenerResize = () => {
    if (window.innerWidth > TABLET_MAX_WIDTH && !this.state.openNav) {
      this.closeSide();
    }
  }

  openSide = () => {
    this.setState({ openNav: true });
  }

  closeSide = () => {
    this.setState({ openNav: false });
  }

  render() {
    const { children } = this.props;

    return (
      <>
        <ELSButtonWithIcon
          onClick={this.openSide}
          sprite="Menu"
          id="nav-open-button"
          className="c-els-menu__button c-els-button c-els-button--icon c-els-button--icon-only c-els-button--blank"
          a11y={{
            name: 'Open navigation',
          }}
        >
          <span />
        </ELSButtonWithIcon>
        <nav
          id="navigation"
          className={cx('c-tsp-header-nav', { 'c-tsp-header-nav--open': this.state.openNav })}
          aria-label="Primary site navigation"
        >
          <div className="c-tsp-header-nav__content">
            <ELSButtonWithIcon
              type={ELSButton.Types.LINK}
              onClick={this.closeSide}
              sprite="Close"
              id="nav-close-button"
              className="u-els-float-right u-els-debuttonize u-els-margin-left u-els-margin-bottom"
              a11y={{
                name: 'Close navigation',
              }}
            >
              <span />
            </ELSButtonWithIcon>
            <div className="c-els-header-modal__menu-list">
              {children}
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default SideDrawerModal;
