import { ELSRestClientHelper, ELSTokenHelper } from '@els/els-ui-common-react';
import { merge } from 'lodash';
import { withApiErrorLoggingOnly } from '../utilities/api/api.utility';
import { getProperty, getTspServiceBaseUrl } from '../config/app.config';
import { ServerConstants } from '../constants/server.constant';
import { VALIDATE_RESPONSE_ERROR_TYPE } from '../constants/app.constant';

export class RestClientHelper {
  private readonly getBaseUrl: Function;

  constructor(getBaseUrl) {
    this.getBaseUrl = getBaseUrl;
  }

  private getDefaultOptions = () => ({
    headers: {
      Authorization: `Bearer ${ELSTokenHelper.getToken()}`,
      accept: 'application/json, text/plain, */*'
    }
  });

  private buildUrlWithQueryParams = (baseUrl, options: { params?: {}}) => {
    let url = baseUrl;
    if (options.params) {
      url += url.indexOf('?') > 0 ? '' : '?';
      const searchParams = new URLSearchParams();
      Object.entries(options.params).forEach(([key, value]) => {
        searchParams.append(key, `${value}`);
      });
      url += searchParams.toString();
    }
    return url;
  }

  get = (url: string, options = {}, validateErrorType = VALIDATE_RESPONSE_ERROR_TYPE.NORMAL) => withApiErrorLoggingOnly(() => {
    const finalUrl = this.buildUrlWithQueryParams(`${this.getBaseUrl()}${url}`, options);
    return ELSRestClientHelper.get(finalUrl, merge(this.getDefaultOptions(), options));
  }, validateErrorType);
  post = (url: string, options = {}, validateErrorType = VALIDATE_RESPONSE_ERROR_TYPE.NORMAL) => withApiErrorLoggingOnly(() =>
    ELSRestClientHelper.post(`${this.getBaseUrl()}${url}`, merge(this.getDefaultOptions(), options)), validateErrorType);
  put = (url: string, options = {}, validateErrorType = VALIDATE_RESPONSE_ERROR_TYPE.NORMAL) => withApiErrorLoggingOnly(() => ELSRestClientHelper.put(`${this.getBaseUrl()}${url}`,
    merge(this.getDefaultOptions(), options)), validateErrorType);
  patch = (url: string, options = {}) => withApiErrorLoggingOnly(() => ELSRestClientHelper.patch(`${this.getBaseUrl()}${url}`,
    merge(this.getDefaultOptions(), options)));
  delete = (url: string, options = {}) => withApiErrorLoggingOnly(() => ELSRestClientHelper.delete(`${this.getBaseUrl()}${url}`,
    merge(this.getDefaultOptions(), options)));
}

const gatewayServiceUrl = () => `${getProperty('gatewayBaseURL')}`;
const evolvePortalUrl = () => `${getProperty('evolvePortalBaseUrl')}`;
const tspServiceUrl = () => `${getTspServiceBaseUrl()}`;
const cookieServiceUrl = () => {
  if (ServerConstants.DataSource === 'local') {
    return getProperty('gatewayBaseURL');
  }
  return `${getProperty('evolvePortalBaseUrl')}/eolsapi`;
};

export const gatewayServiceApi = new RestClientHelper(gatewayServiceUrl);
export const evolveServiceApi = new RestClientHelper(evolvePortalUrl);
export const tspServiceApi = new RestClientHelper(tspServiceUrl);
export const evolveGatewayServiceApi = new RestClientHelper(cookieServiceUrl);
