import { some, isEmpty } from 'lodash';
import { appSelectors } from 'redux/app';
import { REDUCER_ID } from './security.reducer';
import { UserRole } from '../../constants/app.constant';
import { getFeatureFlag } from '../../config/app.config';

export const getErrors = globalState => globalState[REDUCER_ID].errors;
export const getToken = globalState => globalState[REDUCER_ID].token;
export const isLoggedIn = globalState => {
  if (getFeatureFlag('authenticationDisabled')) {
    return true;
  }
  return globalState[REDUCER_ID].loggedIn;
};
export const getEvolveUser = globalState => globalState[REDUCER_ID].evolveUser;
export const getSessionId = globalState => globalState[REDUCER_ID].sessionId;
export const getRoleId = globalState => {
  if (getFeatureFlag('authenticationDisabled')) {
    return UserRole.EducationConsultant;
  }
  if (!globalState[REDUCER_ID].evolveUser) {
    return null;
  }
  const { role, roles } = globalState[REDUCER_ID].evolveUser;
  if (role) {
    return role;
  }
  return (roles && roles.indexOf(UserRole.Faculty) >= 0) ? UserRole.Faculty : UserRole.Student;
};
export const getEolsExternalUserId = globalState => globalState[REDUCER_ID].eolsExternalUserId;
export const getRoles = globalState => {
  if (!globalState[REDUCER_ID].evolveUser) {
    return [];
  }
  return globalState[REDUCER_ID].evolveUser.roles || [];
};

export const getRole = globalState => {
  if (!globalState[REDUCER_ID].evolveUser) {
    return UserRole.Faculty;
  }
  return globalState[REDUCER_ID].evolveUser.role || UserRole.Faculty;
};

export const isAuthorized = (allowRoles = []) => globalState => {
  if (getFeatureFlag('authenticationDisabled')) {
    return true;
  }
  const isTspEnabled = appSelectors.isTspEnabled(globalState);
  if (!isTspEnabled) {
    return false;
  }
  const accessRoles = appSelectors.getAllowAccessRoles(globalState);
  const allowAccessRoles = isEmpty(allowRoles) ? accessRoles : allowRoles;
  const userRoles = getRoles(globalState);
  if (isEmpty(allowAccessRoles) || isEmpty(userRoles)) {
    return false;
  }
  return some(userRoles, role => allowAccessRoles.includes(role));
};

export const isOnlyRoles = (roles = [UserRole.TspReadOnlyUser]) => globalState => {
  const accessRoles = appSelectors.getAllowAccessRoles(globalState);
  const userRoles = getRoles(globalState);
  const otherAccessRoles = userRoles.filter(role => accessRoles.includes(role) && !roles.includes(role));
  if (isEmpty(otherAccessRoles) && isAuthorized(roles)(globalState) && isAuthorized()(globalState)) {
    return true;
  }
  return false;
};

export const isAccessControlRoles = (roles: string [], isOnly: boolean) => globalState => {
  if (isOnly) {
    return isOnlyRoles(roles)(globalState);
  }
  return isAuthorized(roles)(globalState);
};
