import { ReactNode, useRef } from 'react';
import { ELSTableCell } from '@els/els-component-data-table-react';
import { ELSIcon } from '@els/els-component-form-field-react';
import classNames from 'classnames';
import { isDescendantNode } from '../../../utilities/app/app.utility';
import { KeyCode } from '../../../constants/app.constant';

interface TableHeadCellProps {
  align?: string;
  headerCss?: string;
  cssModifier?: string;
  isSortable?: boolean;
  isSortActive?: boolean;
  isSortDesc?: boolean;
  sortIcon?: string;
  iconSize?: string;
  children?: ReactNode;
  expandContent?: Function;
  inlineStyle?: { [key: string]: number | string };
  onSort?: (evt: Event) => void;
}

const TableHeadCell = (props: TableHeadCellProps) => {
  const expandElement = useRef();

  const handleClick = evt => {
    if (props.isSortable && props.onSort && expandElement.current && !isDescendantNode(expandElement.current, evt.target)) {
      props.onSort(evt);
    }
  };

  const handleKeyDown = evt => {
    if (evt.keyCode === KeyCode.ENTER) {
      handleClick(evt);
    }
  };

  const getCellClass = () =>
    classNames({
      'c-els-table__cell--sortable': props.isSortable,
      'c-els-table__cell--sort-active': props.isSortActive
    });
  const getSortIconClass = () =>
    classNames('c-els-table__sort-icon', {
      'c-els-table__sort-icon--desc': props.isSortDesc
    });
  const getSortIcon = () => (props.isSortDesc ? 'arrow-down' : 'arrow-up');

  const getSortButtonTextClass = () =>
    classNames('c-els-table__sortable-button-text', {
      'u-els-margin-right-3o4': !props.isSortActive
    });

  return (
    <ELSTableCell
      align={props.align}
      isHeader
      headerCss={props.headerCss}
      cssModifier={getCellClass()}
      inlineStyle={props.inlineStyle}
    >
      {!props.isSortable && (
        <>
          {props.children}
          <div className={`${props.expandContent ? 'u-els-display-inline-block' : 'u-els-display-none'}`}>
            {props.expandContent && props.expandContent()}
          </div>
        </>
      )}
      {props.isSortable && (
        <div role="button" tabIndex={0} className="c-els-table__sortable-button" onKeyDown={handleKeyDown} onClick={handleClick}>
          <span className={getSortButtonTextClass()}>{props.children} </span>
          {props.isSortActive && (
            <span className={getSortIconClass()}>
              <ELSIcon name={getSortIcon()} prefix="gizmo" size={props.iconSize} />
            </span>
          )}
          <div ref={expandElement} className={`${props.expandContent ? 'u-els-display-inline-block' : 'u-els-display-none'}`}>
            {props.expandContent && props.expandContent()}
          </div>
        </div>
      )}
    </ELSTableCell>
  );
};

TableHeadCell.defaultProps = {
  iconSize: '3o4',
  isSortable: false,
  isSortActive: false,
  isSortDesc: false,
  sortIcon: 'arrow-right',
  align: 'left'
};

export default TableHeadCell;
