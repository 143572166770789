import React, { useState } from 'react';
import { compose } from 'recompose';
import { pick, get } from 'lodash';
import * as yup from 'yup';
import ValidateInvoiceNumber from 'components/submit-credit-request/submit-credit-request-steps/validate-invoice-number/ValidateInvoiceNumber.component';
import CreditRequestForm from 'components/submit-credit-request/submit-credit-request-steps/credit-request-form/CreditRequestForm.component';
import CreditRequestSuccessComponent from 'components/submit-credit-request/submit-credit-request-steps/credit-request-success/CreditRequestSuccess.component';
import withUseLoading from 'utilities/with-loading/withUseLoading';
import { invoiceCreditRebillServices } from 'services';
import { useDispatch } from 'react-redux';
import withHTMLHeadSEO from 'hocs/with-html-head-seo/withHTMLHeadSEO.hoc';
import { parseErrorObject } from 'utilities/app/app.utility';
import { INVOICE_NUMBER_VALIDATION_SCHEMA } from 'constants/creditRequest.constant';
import { getMessageBasedOnErrorType } from '../../../../utilities/error/error.utility';
import { InternalCreditRequestModel } from '../../../../models/credit-request.models';

enum SubmitCreditRequestSteps {
  ValidateInvoiceNumberStep = 1,
  SubmitCreditRequestStep = 2,
  CreditRequestSuccessStep = 3
}

const CreditRequestSubmission = ({ isInternalCreditRequest }: InternalCreditRequestModel) => {
  const [currentStep, setCurrentStep] = useState<SubmitCreditRequestSteps>(SubmitCreditRequestSteps.ValidateInvoiceNumberStep);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [creditRequestNumber, setCreditRequestNumber] = useState('');

  const dispatch = useDispatch();

  const handleValidateInvoiceNumber = (value: string) => withUseLoading(async () => {
    try {
      await invoiceCreditRebillServices.validateInvoiceNumber(value);
      setInvoiceNumber(value);
      setCurrentStep(SubmitCreditRequestSteps.SubmitCreditRequestStep);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(getMessageBasedOnErrorType(error));
    }
  });

  const doValidatedInvoiceNumber = (val) => {
    const validatedFields = pick(INVOICE_NUMBER_VALIDATION_SCHEMA, ['invoiceNumber']);
    if (Object.keys(validatedFields).length === 0) {
      return null;
    }
    const newShape = Object.entries(validatedFields)
      .reverse()
      .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {});
    const schema = yup.object().shape(newShape);
    try {
      schema.validateSync({ invoiceNumber: val });
    } catch (yupErr) {
      return parseErrorObject(yupErr);
    }
    return null;
  };

  const validateInvoiceNumber = (value: string) => {
    const errors = doValidatedInvoiceNumber(value.trim());
    if (errors) {
      setErrorMessage(get(errors, 'invoiceNumber'));
      return;
    }

    dispatch(handleValidateInvoiceNumber(value.trim()));
  };

  const submitCreditRequest = (value: string) => {
    setCreditRequestNumber(value);
    setCurrentStep(SubmitCreditRequestSteps.CreditRequestSuccessStep);
  };

  return (
    <>
      {currentStep === SubmitCreditRequestSteps.ValidateInvoiceNumberStep && (
        <ValidateInvoiceNumber
          isInternalCreditRequest={isInternalCreditRequest}
          invoiceNumber={invoiceNumber}
          errorMessage={errorMessage}
          submitHandler={validateInvoiceNumber} />
      )}
      {currentStep === SubmitCreditRequestSteps.SubmitCreditRequestStep && (
        <CreditRequestForm
          isInternalCreditRequest={isInternalCreditRequest}
          invoiceNumber={invoiceNumber}
          handleBack={() => setCurrentStep(SubmitCreditRequestSteps.ValidateInvoiceNumberStep)}
          handleSubmit={submitCreditRequest} />
      )}
      {currentStep === SubmitCreditRequestSteps.CreditRequestSuccessStep && <CreditRequestSuccessComponent creditRequestNumber={creditRequestNumber} />}
    </>
  );
};

const enhancers = [
  withHTMLHeadSEO({ title: 'Submit Credit Request' })
];

export default compose(...enhancers)(CreditRequestSubmission);
