import React from 'react';
import cx from 'classnames';
import { NavigationItemType } from '../../../../models/navigation.models';
import TopBaseLink from '../base-link/TopBaseLink.component';

export interface TopNavigationButtonProps {
  item: NavigationItemType;
}
const TopNavigationButtonWithIcon = ({ item }: TopNavigationButtonProps) => (
  <button
    onClick={() => {
      item.onAction(item);
    }}
    type="button"
    className="c-els-nav-group__link
               u-els-display-block
               u-els-padding-1o2
               u-els-padding-left-1x
               c-tsp-nav-item-top
               c-tsp-nav-item-top--button
               u-els-padding-right-1x">
    <TopBaseLink item={item} />
  </button>
);

const TopNavigationButtonWithoutIcon = ({ item }: TopNavigationButtonProps) => (
  <button
    onClick={() => {
      item.onAction(item);
    }}
    type="button"
    className={cx(`c-els-nav-group__link
               c-tsp-nav-item-top
               c-tsp-nav-item-top--link`, item.className)}>
    <TopBaseLink item={item} />
  </button>
);

const TopNavigationButton = ({ item }: TopNavigationButtonProps) => (item.iconName ?
  <TopNavigationButtonWithIcon item={item} /> :
  <TopNavigationButtonWithoutIcon item={item} />);

export default TopNavigationButton;
