import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import FooterEvolveLogo from '../../../../components/footer/logos/FooterEvolveLogo.component';
import { getHESIBaseURL } from '../../configs/hesi.config';

const hesiBaseURL = getHESIBaseURL();

export const HESI_FOOTER_MENU_ITEMS = [
  {
    id: 'hesiTermsAndConditions',
    text: 'Terms and Conditions',
    href: 'https://www.elsevier.com/legal/elsevier-website-terms-and-conditions',
    target: '_blank'
  },
  {
    id: 'hesiPrivacyPolicy',
    text: 'Privacy Policy',
    href: `${hesiBaseURL}/#/privacyPolicy`,
    target: '_blank'
  },
  {
    id: 'hesiNDA',
    text: 'Exam Terms of Use',
    href: `${hesiBaseURL}/#/nda`,
    target: '_blank'
  },
  {
    id: 'hesiLegalDisclaimer',
    text: 'Exam Security Policy',
    href: `${hesiBaseURL}/#/legalDisclaimer`,
    target: '_blank'
  }
];

const renderFooterMenu = menu => {
  const menuItems = menu.map(item => (
    <div className="c-tsp-footer__link" key={item.id}>
      <a className="es-link" href={item.href} id={item.id} target={item.target} rel="noopener noreferrer">
        {item.text}
      </a>
    </div>
  ));

  return <>{menuItems}</>;
};

const HESIFooter = () => (
  <footer className="c-tsp-footer" id="footer" role="contentinfo">
    <div className="c-tsp-footer__container">
      <FooterEvolveLogo />
      <div className="c-tsp-footer__content">
        <div className="c-tsp-footer__link u-els-width-1o1">
          <FormattedHTMLMessage id="FOOTER_COPYRIGHT" values={{ year: new Date().getFullYear() }} />
        </div>
        {renderFooterMenu(HESI_FOOTER_MENU_ITEMS)}
        <div className="c-tsp-footer__link u-els-width-1o1">Cookies are used by this site.</div>
      </div>
    </div>
  </footer>
);

export default HESIFooter;
