import { generatePath } from 'react-router';
import { NavigationItemType, NavigationSectionType } from '../models/navigation.models';
import { RoutePath, UserRole } from './app.constant';
import * as actionHandlers from '../components/navigation/navigation-actions/NavigationActions.component';
import AlertsNavSection from '../components/navigation/navigation-sections/AlertsNavSection.component';
import DropdownNavSection from '../components/navigation/navigation-sections/DropdownNavSection.component';
import SignInNavSection from '../components/navigation/navigation-sections/SignInNavSection.component';
import { creditRequestQueueText } from './creditRequest.constant';
import { billingQueueText } from './billingQueue.constant';

export enum NavItemCategory {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

export const baseNavigationItems: {[key: string]: NavigationItemType} = {
  myDashboard: {
    category: NavItemCategory.PRIMARY,
    iconName: 'syllabus',
    iconPrefix: 'hmds',
    key: 'myDashboard',
    path: RoutePath.myDashboard.home,
    text: 'TBD'
  },
  reportBug: {
    category: NavItemCategory.SECONDARY,
    key: 'reportBug',
    path: '/report-bug',
    text: 'Report a Bug'
  },
  reportContentError: {
    category: NavItemCategory.SECONDARY,
    disabled: false,
    key: 'reportContentError',
    path: '/report-content-error',
    text: 'Report a Content Error'
  },
  feedback: {
    category: NavItemCategory.SECONDARY,
    disabled: false,
    key: 'feedback',
    path: '/feedback',
    text: 'Submit Feedback'
  },
  logout: {
    category: NavItemCategory.SECONDARY,
    disabled: false,
    key: 'logout',
    path: RoutePath.security.logout,
    text: 'Logout'
  },
  login: {
    category: NavItemCategory.SECONDARY,
    disabled: false,
    key: 'login',
    path: RoutePath.security.login,
    text: 'Sign in',
    className: 'c-tsp-nav-item-top--login'
  },
  accountSettings: {
    category: NavItemCategory.SECONDARY,
    disabled: false,
    key: 'accountSettings',
    onAction: actionHandlers.accountSettingsActionHandler,
    text: 'Account Settings'
  },
  opportunitiesWithPilotCohorts: {
    category: NavItemCategory.SECONDARY,
    disabled: false,
    key: 'opportunitiesWithPilotCohorts',
    path: generatePath(RoutePath.myDashboard.inFlightCohortDashboard, { ifc: true }),
    text: 'Opportunities with Pilot Cohorts'
  },
  myEvolve: {
    category: NavItemCategory.PRIMARY,
    key: 'myEvolve',
    text: 'My Evolve',
    onAction: actionHandlers.myEvolveActionHandler,
  },
  catalog: {
    category: NavItemCategory.PRIMARY,
    key: 'catalog',
    text: 'Catalog',
    onAction: actionHandlers.catalogActionHandler,
  },
  help: {
    category: NavItemCategory.PRIMARY,
    key: 'help',
    text: 'Help',
    onAction: actionHandlers.helpActionHandler,
  },
  orderHistory: {
    category: NavItemCategory.SECONDARY,
    key: 'orderHistory',
    onAction: actionHandlers.orderHistoryActionHandler,
    text: 'Order History'
  },
  submitSupportEmail: {
    category: NavItemCategory.SECONDARY,
    key: 'submitSupportEmail',
    onAction: actionHandlers.submitSupportEmailActionHandler,
    text: 'Submit Support Email'
  },
  viewSupportHistory: {
    category: NavItemCategory.SECONDARY,
    key: 'viewSupportHistory',
    onAction: actionHandlers.viewSupportHistoryActionHandler,
    text: 'View Support History'
  },
  creditRequestQueue: {
    category: NavItemCategory.SECONDARY,
    key: 'creditRequestQueue',
    path: RoutePath.myDashboard.creditRequestQueue,
    text: creditRequestQueueText,
    roles: [UserRole.TspSuperUser, UserRole.TspBillingSpecialist]
  },
  billingDashboardQueue: {
    category: NavItemCategory.SECONDARY,
    key: 'billingQueue',
    path: RoutePath.myDashboard.billingDashboardQueue,
    text: billingQueueText,
    roles: [UserRole.TspSuperUser, UserRole.TspBillingSpecialist]
  },
};

export const anonymousNavSections: NavigationSectionType[] = [
  {
    key: 'signIn',
    content: 'Already Enrolled?',
    items: [
      baseNavigationItems.login
    ],
    navSectionComponent: SignInNavSection
  }
];

export const authorizedNavSections: NavigationSectionType[] = [
  {
    key: 'myEvolve',
    items: [
      baseNavigationItems.myEvolve,
      baseNavigationItems.catalog,
      baseNavigationItems.help
    ]
  },
  {
    key: 'alerts',
    iconLabel: 'Alerts',
    iconName: 'alarm',
    iconPrefix: 'gizmo',
    items: [],
    navSectionComponent: AlertsNavSection
  },
  {
    key: 'account',
    iconLabel: 'Account',
    iconName: 'person',
    iconPrefix: 'gizmo',
    items: [
      baseNavigationItems.creditRequestQueue,
      baseNavigationItems.billingDashboardQueue,
      baseNavigationItems.opportunitiesWithPilotCohorts,
      baseNavigationItems.accountSettings,
      baseNavigationItems.logout
    ],
    navSectionComponent: DropdownNavSection
  }
];

export const unauthorizedNavSections: NavigationSectionType[] = [
  {
    key: 'myEvolve',
    items: [
      baseNavigationItems.myEvolve,
      baseNavigationItems.catalog,
      baseNavigationItems.help
    ]
  },
  {
    key: 'alerts',
    iconLabel: 'Alerts',
    iconName: 'alarm',
    iconPrefix: 'gizmo',
    items: [],
    navSectionComponent: AlertsNavSection
  },
  {
    key: 'account',
    iconLabel: 'Account',
    iconName: 'person',
    iconPrefix: 'gizmo',
    items: [
      baseNavigationItems.accountSettings,
      baseNavigationItems.logout
    ],
    navSectionComponent: DropdownNavSection
  }
];
