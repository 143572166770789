import { COUNTRY } from 'constants/app.constant';
import { useState } from 'react';
import { getStateByCountry } from 'utilities/app/app.utility';

const useStates = () => {
  const [states, setStates] = useState([]);

  const updateStates = (country = COUNTRY.US) => {
    setStates([
      {
        value: '',
        name: country === COUNTRY.CA ? 'Province' : 'State',
      },
      ...getStateByCountry(country),
    ]);
  };

  return { states, updateStates };
};

export default useStates;
