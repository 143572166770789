/* eslint react/no-danger:0 */
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { ELSFlex, ELSFlexItem } from '@els/els-ui-common-react';
import { Icon } from '@els/els-react--icon';
import { getFooterMenu, getCurrentUrl, getContactRole } from '../../utilities/footer';
import { FOOTER_SOCIAL_MENU_ITEMS, FooterMenuItemType } from '../../constants/footer.constants';
import { securitySelectors } from '../../redux/security';
import { getContactUrl } from '../../services/contacts/contacts.service';

const mapStateToProps = state => ({
  evolveUser: securitySelectors.getEvolveUser(state),
  isLoggedIn: securitySelectors.isLoggedIn(state),
  roleId: securitySelectors.getRoleId(state)
});

type FooterMenuItemsProps = {
  evolveUser: any,
  roleId: string,
  isLoggedIn: boolean
};

const FooterMenuItems = (props: FooterMenuItemsProps) => {
  const location = useLocation();
  const handleSupportEmail = target => {
    const data = (props.isLoggedIn && props.evolveUser) ? {
      eolsUserId: props.evolveUser.userId,
      contextUrl: getCurrentUrl(location),
      appIdType: 'EVOLVE',
      ticketType: 'CONTACT_US',
      userRole: getContactRole(props.roleId),
      evolveUserName: props.evolveUser.userName,
      evolveInstitution: null
    } : null;

    getContactUrl(data).then((res) => {
      window.open(res, target || '');
    });
  };

  const handleMenuItemClicked = (evt, type, target) => {
    evt.preventDefault();
    if (type === FooterMenuItemType.SUPPORT_EMAIL) {
      handleSupportEmail(target);
    }
  };
  const renderMenuItem = item => {
    if (item.type) {
      return (
        <HashLink to="#"
                  id={item.id}
                  onClick={(e) => handleMenuItemClicked(e, item.type, item.target)}
                  role="button">
          {item.name}
        </HashLink>
      );
    }
    if (item.internal) {
      return (
        <NavLink to={item.href} target={item.target} id={item.id}>
          {item.name}
        </NavLink>
      );
    }
    return (
      <a href={item.href} target={item.target} id={item.id}>
        {item.name}
      </a>
    );
  };
  const renderFoooterMenus = menuItems => menuItems.map(menu => {
    const renderMenuItems = items => items.map(item => (
      <li key={item.id} className="u-els-padding-bottom-1o8 u-els-padding-right-1o2">
        {renderMenuItem(item)}
      </li>
    ));
    const itemsByRole = (typeof menu.items === 'function') ? menu.items(props.roleId) : menu.items;
    return (
      <ELSFlexItem key={menu.id}
        className="c-tsp-footer-menu__links__column u-els-width-1o4 u-els-width-1o1@mobile">
        <h4 className="u-els-padding-bottom-1o4">
          <a href={menu.href} target={menu.target}>
            {menu.name}
          </a>
        </h4>
        <ul>
          {renderMenuItems(itemsByRole)}
        </ul>
      </ELSFlexItem>
    );
  });

  const renderSocialMenus = menuItems => menuItems.map(menu =>
    // eslint-disable-next-line global-require, import/no-dynamic-require
    (
      <li key={menu.id}>
        <a href={menu.href} target={menu.target} title={menu.name}>
          <Icon className="o-els-icon-svg"
                id={menu.name}
                sprite={menu.name}
                size="ml"
                isVisible
                a11y={{ name: menu.iconName, description: menu.iconDesc }}
          />
        </a>
      </li>
    ));
  return (
    <div className="c-tsp-footer-menu" role="region">
      <ELSFlex className="c-tsp-footer-menu__links">
        {renderFoooterMenus(getFooterMenu(props.roleId, props.isLoggedIn))}
      </ELSFlex>
      <div className="c-tsp-footer-menu__socials">
        <ul>
          {!getCurrentUrl(location).includes('/student-payment/') ?
            renderSocialMenus(FOOTER_SOCIAL_MENU_ITEMS.default) :
            renderSocialMenus(FOOTER_SOCIAL_MENU_ITEMS.studentPaymentPage)}
        </ul>
      </div>
    </div>
  );
};
export default connect(mapStateToProps)(FooterMenuItems);
