import { ELSRouterHelper } from '@els/els-ui-common-react';
import withHESIAuthorize from '../../hocs/with-hesi-authorize/withHESIAuthorize.hoc';

export const createHESIRoutes = routes =>
  routes.map(route => {
    const { roles } = route;
    const routeWithAuthorize = {
      ...route
    };

    if (!roles || roles.length > 0) {
      routeWithAuthorize.component = withHESIAuthorize(route.component);
    }

    return ELSRouterHelper.createRoute(routeWithAuthorize);
  });
