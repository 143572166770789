import { PagingOptions } from '../models/api.model';

export const buildHESIParams = (params: PagingOptions) => ({
  SortBy: params.sortColumn,
  SortOrder: params.sortDirection.toUpperCase(),
  StartIndex: ((params.currentPage - 1) * params.resultsPerPage) + 1,
  Size: params.resultsPerPage,
  Query: params.query
});

export const buildEvolvePagingOptions = (params: PagingOptions) => ({
  sortBy: params.sortColumn,
  sortOrder: params.sortDirection.toLowerCase(),
  startIndex: ((params.currentPage - 1) * params.resultsPerPage) + 1,
  size: params.resultsPerPage
});

export const buildPagingOptions = (params: PagingOptions, camelCase = false) => ((camelCase) ? buildEvolvePagingOptions(params) : buildHESIParams(params));
