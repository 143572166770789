import * as yup from 'yup';
import moment from 'moment';
import { isSameOrBeforeDate, isValidedDate } from '../utilities/app/app.utility';

const MAX_LENGTH_HESI_COHORT_NAME = 250;

export const OPTION_FACETS = {
  title: 'Options',
  groups: {
    actions: {
      title: 'Actions',
      items: [
        {
          id: 'uploadDocument',
          title: 'Upload Document',
          iconName: 'add-document',
          iconPrefix: 'gizmo'
        },
        {
          id: 'addNote',
          title: 'Add Note',
          iconName: 'writing',
          iconPrefix: 'gizmo'
        }
      ]
    },
    explore: {
      title: 'Explore',
      items: [
        {
          id: 'viewNotes',
          title: 'View Notes (3)',
          iconName: 'eye',
          iconPrefix: 'gizmo'
        },
        {
          id: 'viewDocuments',
          title: 'View Documents (4)',
          iconName: 'document-check'
        },
        {
          id: 'viewCustomerTickets',
          title: 'View Customer Service Tickets (1)',
          iconName: 'alert-unsupported-browser',
          iconPrefix: 'hmds'
        }
      ]
    }
  }
};
export const InstallmentCohortCountStatus = [
  {
    key: 'cohort-count-approval-request-sent',
    value: 'Cohort Count Approval Request Sent'
  },
  {
    key: 'cohort-count-approved',
    value: 'Cohort Count Approved'
  },
  {
    key: 'cohort-count-rejected',
    value: 'Cohort Count Rejected'
  }
];

export enum PurchasingType {
  INSTITUTIONAL = 'Institutional',
  STUDENT_PAY_ECOMMERCE = 'Student Pay-eCommerce',
  INSTITUTIONAL_VIA_A_BOOKSTORE = 'Institutional via a Bookstore',
  HYBRID_INSTITUTIONAL_STUDENT_PAY_ECOMMERCE = 'Hybrid - Institutional + Student Pay-eCommerce',
  HYBRID_INSTITUTIONAL_AND_INSTITUTIONAL_BOOKSTORE = 'Hybrid - Institutional + Institutional Bookstore'
}

export enum CohortTabsIndex {
  ManageProductTab = 0,
  ProductApprovalTab = 1,
  StudentsTab = 2,
  InstallmentsTab = 3,
  StudentPaymentsTab = 4,
  CohortCommentsTab = 5
}

export const CohortInstallmentTab = 'cohortInstallmentsTab';

export const SelectedCohortTab = 'selectedTab';

export const HESICohortFields = {
  hesiCaseNumber: 'hesiCaseNumber',
  hesiQuoteNumber: 'hesiQuoteNumber',
  salesforceUrl: 'salesforceUrl',
  hesiCohortName: 'hesiCohortName',
  hesiProgramId: 'hesiProgramId',
  hesiCohortStartDate: 'hesiCohortStartDate',
  hesiCohortEndDate: 'hesiCohortEndDate',
};

export const HESI_COHORT_INFO_SCHEMA = {
  [HESICohortFields.hesiCaseNumber]: yup.string().nullable(true)
    .transform((v) => (v.trim() === '' ? null : v.trim()))
    .max(128, 'HESI Case Number should not be greater than 128 characters'),
  [HESICohortFields.hesiQuoteNumber]: yup.string().nullable(true)
    .transform((v) => (v.trim() === '' ? null : v.trim()))
    .max(15, 'HESI Quote Number should not be greater than 15 characters'),
  [HESICohortFields.salesforceUrl]: yup.string().nullable(true)
    .transform((v) => (v.trim() === '' ? null : v.trim()))
    .max(1024, 'Salesforce URL should not be greater than 1024 characters')
};

export const DEFAULT_PAYLOAD_MODEL = 'Institutional';

export const InFlightCohortViewMessage = 'Please note that when viewing pilot cohorts, billing and revenue allocation is not supported and has been disabled.';

export const HESI_COHORT_NAME = 'HESI Cohort Name:';

export const HESICohortStatus = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
};

export const HESINGContractStatus = {
  HESI_NG_CONTRACT_CREATION_SUBMITTED: { id: 45, name: 'HESI NG Contract Creation Submitted' },
  HESI_NG_CONTRACT_CREATED: { id: 46, name: 'HESI NG Contract Created' },
  HESI_NG_CONTRACT_CREATION_FAILED: { id: 47, name: 'HESI NG Contract Creation Failed' },
  HESI_NG_CONTRACT_UPDATE_SUBMITTED: { id: 48, name: 'HESI NG Contract Update Submitted' },
  HESI_NG_CONTRACT_UPDATED: { id: 49, name: 'HESI NG Contract Updated' },
  HESI_NG_CONTRACT_UPDATE_FAILED: { id: 50, name: 'HESI NG Contract Update Failed' },
  HESI_NG_CONTRACT_CANCELLATION_SUBMITTED: { id: 51, name: 'HESI NG Contract Cancellation Submitted' },
  HESI_NG_CONTRACT_CANCELLED: { id: 52, name: 'HESI NG Contract Cancelled' },
  HESI_NG_CONTRACT_CANCELLATION_FAILED: { id: 53, name: 'HESI NG Contract Cancellation Failed' }
};

export const NGContractFormFields = {
  contractStartDate: 'contractStartDate',
  contractEndDate: 'contractEndDate'
};

const invalidDateFormatText = 'Invalid date format. Please enter the date in the format "MM/DD/YYYY".';

const transformContractDate = (value, originalValue: string | Date) => {
  if (originalValue === '') {
    return null;
  }
  return isValidedDate(originalValue) ? value : false;
};

export const NG_CONTRACT_VALIDATION_SCHEMA = {
  [NGContractFormFields.contractStartDate]: yup
    .date()
    .nullable()
    .transform(transformContractDate)
    .required('The contract start date is required.')
    .typeError(invalidDateFormatText)
    .test({
      name: 'isNotPastDate',
      test(value) {
        const toDayDate = moment().startOf('day').toDate();
        const { isUpdateContract } = this.options.context;
        if (isUpdateContract) {
          return true;
        }

        return isSameOrBeforeDate(toDayDate, value);
      },
      message: 'The contract start date must be today’s date or a future date.',
      exclusive: true
    }),
  [NGContractFormFields.contractEndDate]: yup
    .date()
    .nullable()
    .transform(transformContractDate)
    .required('The contract end date is required.')
    .typeError(invalidDateFormatText)
    .test({
      name: 'isNotPasTDate',
      test(value) {
        const { isUpdateContract } = this.options.context;
        if (isUpdateContract) {
          return true;
        }

        const toDayDate = moment().startOf('day').toDate();
        return isSameOrBeforeDate(toDayDate, value);
      },
      message: 'The contract end date must be today’s date or a future date.',
      exclusive: true
    })
    .test({
      name: 'isGreaterThanContractStartDate',
      test(value) {
        const { contractStartDate } = this.options.context;
        if (!contractStartDate || !isSameOrBeforeDate(value, contractStartDate)) {
          return true;
        }
        return false;
      },
      message: 'The contract end date must be after the contract start date',
      exclusive: true
    })
};

export const HESI_COHORT_VALIDATION_SCHEMA = {
  [HESICohortFields.hesiCohortName]: yup
    .string()
    .nullable(true)
    .transform(v => (v.trim() === '' ? null : v.trim()))
    .max(MAX_LENGTH_HESI_COHORT_NAME, `HESI Cohort Name should not be greater than ${MAX_LENGTH_HESI_COHORT_NAME} characters`),
  [HESICohortFields.hesiCohortStartDate]: yup
    .date()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required('The cohort start date is required.')
    .typeError(invalidDateFormatText),
  [HESICohortFields.hesiCohortEndDate]: yup
    .date()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required('The cohort grad date is required.')
    .typeError(invalidDateFormatText)
    .test({
      name: 'isGreaterThanCohortStartDate',
      test(value) {
        const hesiCohortStartDate = this.parent[HESICohortFields.hesiCohortStartDate];
        if (!isSameOrBeforeDate(value, hesiCohortStartDate)) {
          return true;
        }
        return false;
      },
      message: 'The cohort grad date must be after the cohort start date.',
      exclusive: true
    })
};

export const MAX_LENGTH_STUDENT_GROUP_NAME_ERROR_MESSAGE = 'Group Name should not be greater than 20 characters.';
export const MAX_LENGTH_STUDENT_GROUP_NAME = 20;
export const STUDENT_GROUP_ERROR_MSG = 'Student Group Name is required.';

export const STUDENT_GROUP_SCHEMA = {
  groupName: yup.string()
    .trim(STUDENT_GROUP_ERROR_MSG)
    .required(STUDENT_GROUP_ERROR_MSG)
    .max(MAX_LENGTH_STUDENT_GROUP_NAME, MAX_LENGTH_STUDENT_GROUP_NAME_ERROR_MESSAGE)
};

const COHORT_NAME_ERROR_MSG = 'The cohort name is required.';
const MAX_LENGTH_COHORT_NAME = 128;
const MAX_LENGTH_COHORT_NAME_ERROR_MESSAGE = 'Cohort name must not exceed 128 characters';

export const UPDATE_COHORT_NAME_VALIDATION_SCHEMA = {
  editCohortName: yup
    .string()
    .trim(COHORT_NAME_ERROR_MSG)
    .required(COHORT_NAME_ERROR_MSG)
    .max(MAX_LENGTH_COHORT_NAME, MAX_LENGTH_COHORT_NAME_ERROR_MESSAGE)
};
