import { HESIServerConstants } from '../constants/hesi-server.constant';

export const getHESIProperty = (property = null) => {
  if (!property) {
    return '';
  }
  return HESIServerConstants[HESIServerConstants.DataSource][property] || HESIServerConstants.common[property] || HESIServerConstants[property] || '';
};

export const getHESIBaseURL = () => getHESIProperty('hesiBaseURL');
