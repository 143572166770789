import React from 'react';
import { NavLink } from 'react-router-dom';
import { RoutePath } from 'constants/app.constant';
import nonSolusLogo from 'assets/icons/NonSolusLogo.svg';
import eolveLogo from 'assets/icons/EvolveLogo.svg';

interface LogoProps {
  className?: string
}
const Logo = (props: LogoProps) => (
  <div className={`c-tsp-logo ${props.className || ''}`}>
    <NavLink to={RoutePath.home}
       className="c-els-header__logo-link
                  o-els-flex-layout
                  o-els-flex-layout--middle"
       aria-label="Elsevier Evolve">
      <img src={nonSolusLogo} alt="Elsevier Non Solus logo" className="u-els-margin-right-3o4" width="58" height="58" />
      <img src={eolveLogo} alt="Evolve logo" className="u-els-display-none@mobile" />
    </NavLink>
  </div>
);

export default Logo;
