import withExtendProps from 'hocs/with-extend-props/withExtendProps.hoc';
import { SubmitCreditRequestTabs } from 'pages/submit-track-credit-request/SubmitAndTrackCreditRequest.page';
import { lazy } from 'react';
import { Analytics, RoutePath, UserRole } from '../../constants/app.constant';

const getRoutes = () => [
  {
    path: RoutePath.home,
    component: lazy(() => import('pages/landing/Landing.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.home,
        pageName: 'home'
      }
    }
  },
  {
    path: RoutePath.submitCreditRequest,
    component: withExtendProps({
      activeTab: SubmitCreditRequestTabs.SubmitCreditRequest
    })(lazy(() => import('pages/submit-track-credit-request/SubmitAndTrackCreditRequest.page'))),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.submitCreditRequest,
        pageName: 'submit-track-credit-request:submit'
      }
    }
  },
  {
    path: RoutePath.trackCreditRequest,
    component: withExtendProps({
      activeTab: SubmitCreditRequestTabs.TrackCreditRequest
    })(lazy(() => import('pages/submit-track-credit-request/SubmitAndTrackCreditRequest.page'))),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.trackCreditRequest,
        pageName: 'submit-track-credit-request:track'
      }
    }
  },
  {
    path: RoutePath.cohort.productDetail,
    component: lazy(() => import('pages/cohort/product-detail/ProductDetail.page')),
    data: {
      analytics: {
        pageType: Analytics.pageType.productDetail,
        pageName: 'product:detail'
      }
    }
  },
  {
    path: RoutePath.cohort.productReview,
    component: lazy(() => import('pages/cohort/review-selections/ReviewProductSelections.page')),
    data: {
      analytics: {
        pageType: Analytics.pageType.productReview,
        pageName: 'product:review-product-selection'
      }
    }
  },
  {
    path: RoutePath.cohort.reviewProductSelections,
    component: lazy(() => import('pages/cohort/review-product-selections/ReviewProductSelections.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.reviewProductSelections,
        pageName: 'review-approval:product'
      }
    }
  },
  {
    path: RoutePath.cohort.facultyRosterSubmission,
    component: lazy(() => import('pages/cohort/faculty-roster-submission/FacultyRosterSubmission.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.facultyRosterSubmission,
        pageName: 'roster-submission:faculty'
      }
    }
  },
  {
    path: RoutePath.cohort.studentRosterSubmission,
    component: lazy(() => import('pages/cohort/student-roster-submission/StudentRosterSubmission.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.studentRosterSubmission,
        pageName: 'roster-submission:student'
      }
    }
  },
  {
    path: RoutePath.cohort.productSearch,
    component: lazy(() => import('pages/cohort/product-search/ProductSearch.page')),
    data: {
      analytics: {
        pageType: Analytics.pageType.productSearch,
        pageName: 'search:search-results'
      }
    }
  },
  {
    path: RoutePath.myDashboard.cohortDetail,
    component: lazy(() => import('pages/my-dashboard/cohort-detail/CohortDetail.page')),
    data: {
      analytics: {
        pageType: Analytics.pageType.cohortDetail,
        pageName: 'cohort:cohort-detail'
      }
    }
  },
  {
    path: RoutePath.myDashboard.opportunityDetail,
    component: lazy(() => import('pages/my-dashboard/opportunity-detail/OpportunityDetail.page')),
    data: {
      analytics: {
        pageType: Analytics.pageType.opportunityDetail,
        pageName: 'opportunity:opportunity-detail'
      }
    }
  },
  {
    path: RoutePath.myDashboard.creditRequestQueue,
    component: lazy(() => import('pages/my-dashboard/credit-request-queue/CreditRequestQueue.page')),
    roles: [UserRole.TspSuperUser, UserRole.TspBillingSpecialist],
    data: {
      analytics: {
        pageType: Analytics.pageType.creditRequestQueue,
        pageName: 'credit-request-queue:list'
      }
    }
  },
  {
    path: RoutePath.myDashboard.billingDashboardQueue,
    component: lazy(() => import('pages/my-dashboard/billing-queue/BillingQueue.page')),
    roles: [UserRole.TspSuperUser, UserRole.TspBillingSpecialist],
    data: {
      analytics: {
        pageType: Analytics.pageType.billingDashboardQueue,
        pageName: 'billing-dashboard-queue:list'
      }
    }
  },
  {
    path: RoutePath.cohort.reviewInstallmentCohortCountApproval,
    component: lazy(() => import('pages/cohort/review-installment-cohort-counts-approval/ReviewInstallmentCohortCountsApproval.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.reviewInstallmentCohortCountApproval,
        pageName: 'review-approval:cohort-count'
      }
    }
  },
  {
    path: RoutePath.cohort.studentPayment,
    component: lazy(() => import('pages/cohort/student-payment/StudentPayment.page')),
    roles: [],
    data: {
      analytics: {
        pageType: Analytics.pageType.studentPayment,
        pageName: 'student-payment'
      }
    }
  },
  {
    path: RoutePath.myDashboard.home,
    component: lazy(() => import('pages/my-dashboard/MyDashboardHome.page')),
    data: {
      analytics: {
        pageType: Analytics.pageType.myDashboard,
        pageName: 'my-dashboard'
      }
    }
  },
  {
    path: RoutePath.myDashboard.inFlightCohortDashboard,
    component: lazy(() => import('pages/my-dashboard/MyDashboardHome.page')),
    data: {
      analytics: {
        pageType: Analytics.pageType.myDashboard,
        pageName: 'my-dashboard:pilot-cohort'
      }
    }
  }
];

export const AppRoutes = {
  getRoutes
};
