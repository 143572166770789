import * as yup from 'yup';
import { isBeforeDate, isSameOrBeforeDate, isValidedDateByFormat } from '../utilities/app/app.utility';
import { DATE_FORMAT_MDYYYY } from './app.constant';

export const InstallmentStatuses = {
  CohortCountApprovalRequestSent: { id: 2, name: 'Cohort Count Approval Request Sent' },
  ReminderCohortCountApprovalRequestSent: { id: 13, name: 'Reminder: Cohort Count Approval Reminder Request Sent' },
  CohortCountsApproved: { id: 3, name: 'Cohort Counts Approved' },
  CohortCountsRejected: { id: 4, name: 'Cohort Counts Rejected' },
  CohortCountsUndone: { id: 25, name: 'Cohort Count Undone' }
};

export enum ReviewInstallmentCohortCountPreviousPage {
  UnprocessInstallmentsTab = 'accountInstallmentsTab',
  CohortInstallmentsTab = 'cohortInstallmentsTab'
}

export enum INSTALLMENT_ERROR_TYPES {
  INVALID_COHORT_COUNTS = 'invalid_cohort_counts'
}

export const INSTALLMENT_INVOICE_DATE_SHEMA = {
  invoiceDate: yup
    .date()
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue === '') {
        return null;
      }
      return isValidedDateByFormat(originalValue, DATE_FORMAT_MDYYYY) ? value : false;
    })
    .required('Invoice Date is required.')
    .typeError('Invalid date format. Date must be in MM/DD/YYYY format.')
    .test({
      name: 'isFutureDate',
      test(value) {
        const { currentDate } = this.options.context;
        if (!isSameOrBeforeDate(value, currentDate)) {
          return true;
        }
        return false;
      },
      message: 'Invoice Date must be a date in the future.',
      exclusive: true
    })
    .test({
      name: 'isGreaterThanAddDropDate',
      test(value) {
        const { addDropDate } = this.options.context;
        if (!isSameOrBeforeDate(value, addDropDate)) {
          return true;
        }
        return false;
      },
      message: 'Invoice date cannot be equal to or less than the Add/Drop Date.',
      exclusive: true
    })
    .test({
      name: 'isLessThanGraduationDate',
      test(value) {
        const { endDate } = this.options.context;
        if (isBeforeDate(value, endDate)) {
          return true;
        }
        return false;
      },
      message: 'Invoice Date cannot be equal to or greater than Cohort’s Graduation Date.',
      exclusive: true
    })
};

export const INSTALLMENT_STATUS_DEFAULT = 'Unprocessed Installment';

export const MAX_LENGTH_INSTALLMENT_COMMENT = 2048;
