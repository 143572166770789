import moment from 'moment';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { pick, intersection, uniq, findIndex, uniqBy, orderBy } from 'lodash';
import {
  billingCountries,
  canadaProvinces,
  COUNTRY,
  DATE_FORMAT,
  DATE_FORMAT_MDYYYY,
  DATE_FORMAT_MMDDYYYY,
  LABEL_TIME_UTC,
  usStates
} from 'constants/app.constant';
import { EvolveAddressModel, InvoiceModel, InvoiceStatusModel } from 'models/invoice.model';
import { CohortModel, CohortPackage, HESIContractStatusModel, OpportunityAddressModel, PackageAddon } from 'models/opportunity.model';
import { CohortInstallmentModel, CohortWorkflowStatusModel, TspCohortPackage, TspCohortWorkflowStatusModel } from 'models/cohort.models';
import { EvolveInstallmentModel } from 'models/installment.model';
import { CreditRequestStatusModel } from 'models/credit-request.models';
import { INVOICE_MODE, InvoiceStatuses } from 'constants/invoice.constant';
import { getInstallmentInstitutionAmount, isInstallmentRebilledNotYet } from 'utilities/installment/installment.utility';
import { HESICohortStatus, PurchasingType } from '../../constants/cohort.constant';
import { CreditRequestStatuses } from '../../constants/creditRequest.constant';
import { getProperty } from '../../config/app.config';
import { RosterModel } from '../../models/roster.models';
import { parseISODate, changeDateToISODate } from './date.utility';

const isIsoDate = (date: string | Date): boolean => moment(date, moment.ISO_8601).isValid();

export const formatDate = (str: string | Date, format = DATE_FORMAT_MDYYYY) => moment.utc(str).format(format);

export const formatDateWithoutTime = (str: string | Date, format = DATE_FORMAT_MDYYYY) => {
  const utcDate = isIsoDate(str) ? moment(str).format(format) : str;
  return moment.utc(utcDate).format(format);
};

export const formatDateToISOString = (str: string | Date) => {
  const dateStr = moment(str).format(DATE_FORMAT_MDYYYY);
  return new Date(`${dateStr} ${LABEL_TIME_UTC}`).toISOString();
};

export const convertCSTTimeZone = (str: string | Date, format = DATE_FORMAT_MDYYYY) => {
  const dateStr = new Date(str).toLocaleString('en-US', {
    timeZone: 'America/Chicago'
  });
  return (moment(dateStr).format(format));
};

export const convertDateWithZeroHour = (value?: string | Date) => {
  const date = value ? new Date(value) : new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

export const parseDate = (str: string) => {
  const valid = moment(str, DATE_FORMAT, true).isValid();
  return valid ? moment(str, DATE_FORMAT, true).toDate() : undefined;
};

export const toSinglePlural = (numberValue: number, singleText: string, pluralText: string) => (numberValue === 1 ? singleText : pluralText);

/**
 * age > 2 years -> Age: X years
 * age > 1 year and <= 2 years -> Age: X months
 * age > 1 month and <= 1 year -> Age: X months Y days
 * age <= 1 month -> Age: X days
 * @param dateString MM-DD-YYYY string
 * @param dateToCompare date to compare, moment object, default is current time
 */
export const dateToAge = (dateString: string, dateToCompare = moment()) => {
  const valid = moment(dateString, DATE_FORMAT, true).isValid();
  if (!valid) {
    return '';
  }
  const date = moment(dateString, DATE_FORMAT, true);
  date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  dateToCompare.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });

  const years = date.diff(dateToCompare, 'years') * -1;
  if (years > 2) {
    return `Age: ${years} years`;
  }
  if (years === 2) {
    const newDateToCompare = dateToCompare.clone().subtract(years, 'year');
    const daysDiff = date.diff(newDateToCompare, 'days') * -1;
    if (daysDiff > 0) {
      return `Age: ${years} years`;
    }
  }

  const months = date.diff(dateToCompare, 'months') * -1;
  if (months > 12) {
    return `Age: ${months} months`;
  }
  if (months === 12) {
    const newDateToCompare = dateToCompare.clone().subtract(12, 'month');
    const days = date.diff(newDateToCompare, 'days') * -1;
    if (days > 0) {
      return `Age: ${months} months`;
    }
  }

  if (months > 1) {
    const newDateToCompare = dateToCompare.clone().subtract(months, 'month');
    const days = date.diff(newDateToCompare, 'days') * -1;
    return days > 0 ? `Age: ${months} months ${days} ${toSinglePlural(days, 'day', 'days')}` : `Age: ${months} months`;
  }
  if (months === 1) {
    const newDateToCompare = dateToCompare.clone().subtract(months, 'month');
    const days = date.diff(newDateToCompare, 'days') * -1;
    if (days > 0) {
      return `Age: ${months} month ${days} ${toSinglePlural(days, 'day', 'days')}`;
    }
  }

  const diffDays = date.diff(dateToCompare, 'days') * -1;
  return `Age: ${diffDays} ${toSinglePlural(diffDays, 'day', 'days')}`;
};

export const buildDropdownModelWithBlank = (objList: string[]) => {
  const blankOption = { name: '', value: '' };
  if (!objList) {
    return [blankOption];
  }
  return [blankOption].concat(objList.map(item => ({ name: item, value: item })));
};

export const getWindowMode = () => {
  const { innerWidth } = window;
  const mode = { mobile: false, tablet: false, desktop: false, wide: false };
  if (innerWidth < 600) {
    return { ...mode, mobile: true };
  }
  if (innerWidth >= 600 && innerWidth < 900) {
    return { ...mode, tablet: true };
  }
  if (innerWidth >= 900 && innerWidth < 1200) {
    return { ...mode, desktop: true };
  }
  return { ...mode, wide: true };
};

export const getTopPosition = () => window.scrollY || document.documentElement.scrollTop;

export const devModeEnabled = (): boolean => {
  try {
    const url = new URL(window.location.href);
    const devModeParam = url.searchParams.get('devMode');
    const devModeValue = localStorage.getItem('devMode');
    return devModeParam === 'true' || devModeValue === 'true';
  } catch (err) {
    return false;
  }
};

export const toSlug = (s: string): string => ((!s) ? '' : s.toLowerCase().replace(/(\s+)/, ''));
export const randomStr = () => uuidv4().substring(0, 6);
export const isSameOrAfterDate = (source: Date | string, target: Date | string) => moment(source).isSameOrAfter(target, 'day');

export const isSameOrBeforeDate = (source: Date | string, target: Date | string) => moment(source).isSameOrBefore(target, 'day');

export const isBeforeDate = (source: Date | string, target: Date | string) => moment(source).isBefore(target, 'day');

export const isAfterDate = (source: Date | string, target: Date | string) => moment(source).isAfter(target, 'day');

export const isSameDate = (sourceDate: Date |string, targetDate: string) => sourceDate && targetDate && moment(sourceDate).isSame(targetDate, 'day');

// value is valid by format MM-DD-YYYY, M/D/YYYY
export const isValidedDate = (value: Date | string) => moment(value, [DATE_FORMAT_MMDDYYYY, DATE_FORMAT_MDYYYY], true).isValid();
export const isValidedDateByFormat = (value: Date | string, format: string) => moment(value, [format], true).isValid();

export const isDateOnly = (value: string | Date): boolean => {
  const date = moment.utc(value);
  if (!date.isValid()) {
    return false;
  }
  if (date.hours() === 0 && date.minutes() === 0 && date.seconds() === 0 && date.milliseconds() === 0) {
    return true;
  }

  return false;
};

const getInstallmentsFromCohort = (cohort: CohortModel) => {
  if (!cohort.Installments) {
    return [];
  }
  return cohort.Installments.map(installment => ({ ...installment, CohortName: cohort.CohortName }));
};

export const convertInstallmentsFromCohorts = (cohorts: CohortModel[]): CohortInstallmentModel[] =>
  cohorts.reduce((array, cohort) => [...array, ...getInstallmentsFromCohort(cohort)], []);

// zipcode is valid by format 55555 or 55555-5555
export const isValidZipcode = (value: string) => /^\d{5}(-\d{4})?$/.test(value);

export const getUsStateName = (value: string): string => {
  const state = usStates.find(s => s.value === value);
  return state ? state.name : '';
};

export const isNumber = (value: string): boolean => /^[0-9\b]+$/.test(value);

export const isEmail = (value: string): boolean => {
  const schema = yup.string().email();
  try {
    schema.validateSync(value);
    return true;
  } catch (ex) {
    return false;
  }
};

export const isDescendantNode = (parent, child) => {
  let node = child.parentNode;
  while (node !== null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

export const transformOppAddressToEvolveAddress = (address: OpportunityAddressModel): EvolveAddressModel => ({
  address1: address.Street,
  address2: address.AptSuite,
  city: address.City,
  state: address.State,
  country: address.Country,
  postalCode: address.PostalCode
});

export const transformEvolveAddressToOppAddress = (address: EvolveAddressModel): OpportunityAddressModel => ({
  Street: address.address1,
  AptSuite: address.address2,
  City: address.city,
  State: address.state,
  Country: address.country,
  PostalCode: address.postalCode
});

export const isEvolveAddressChange = (target: EvolveAddressModel, source: EvolveAddressModel) =>
  target && source && (
    target.address1 !== source.address1 ||
    target.address2 !== source.address2 ||
    target.city !== source.city ||
    target.state !== source.state ||
    target.postalCode !== source.postalCode ||
    target.country !== source.country
  );

export const isInvoicePendingStatus = (invoiceStatus: InvoiceStatusModel) => invoiceStatus && invoiceStatus.id === InvoiceStatuses.InvoicePending.id;

export const isInvoiceEditable = (invoiceStatus: InvoiceStatusModel) => isInvoicePendingStatus(invoiceStatus);

export const ACTION_FIELD = 'actions';

export const isSingleInvoice = (invoice) => invoice.invoices && invoice.invoices.length === 1;

export const maxInvoicesNumberAllowCreate = (installment, maxInvoicesNumberConfig) => {
  if (+getInstallmentInstitutionAmount(installment) === 0) {
    return 1;
  }
  if (isInstallmentRebilledNotYet(installment) && installment.activeTspInvoiceIds && installment.activeTspInvoiceIds.length > 0) {
    return maxInvoicesNumberConfig - installment.activeTspInvoiceIds.length;
  }
  return maxInvoicesNumberConfig;
};

export const parseRosters = (text) => (text.split('\n').reduce((data, user) => {
  const parts = user.split(',');
  if (parts.length === 3) {
    data.push({
      lastName: parts[0].trim(),
      firstName: parts[1].trim(),
      email: parts[2].trim(),
    });
  }
  return data;
}, []));

export const parseEvolveInstallment = (installment: CohortInstallmentModel): EvolveInstallmentModel => ({
  installmentNumber: installment.InstallmentNumber,
  cohortId: installment.CohortID,
  installmentId: installment.InstallmentID,
  costPerStudent: installment.CostPerStudent,
  invoiceDate: installment.InvoiceDate
});

export const isInvoicePastDueDate = (invoiceDate: string, cmsStatus: InvoiceStatusModel) => {
  const now = changeDateToISODate(new Date());
  now.setHours(0, 0, 0, 0);
  const invoiceDateISO = parseISODate(invoiceDate);
  const pastDueDate = moment(invoiceDateISO).add(1, 'days').toDate();
  pastDueDate.setHours(0, 0, 0, 0);
  return pastDueDate <= now &&
    cmsStatus.id !== InvoiceStatuses.InvoicePayloadSent.id &&
    cmsStatus.id !== InvoiceStatuses.InvoiceCreditPayloadSent.id &&
    cmsStatus.id !== InvoiceStatuses.InvoiceIgnore.id;
};

export const parseErrorObject = (yupError) => {
  const errors = {};
  if (yupError.errors.length === 0 && yupError.inner.length === 0) {
    return errors;
  }
  if (yupError.inner.length === 0) {
    errors[yupError.params.path] = yupError.message;
    return errors;
  }
  const fieldNames = [];
  const { length } = yupError.inner;
  for (let i = 0; i < length; i += 1) {
    const errorItem = yupError.inner[i];
    if (!fieldNames.includes(errorItem.path)) {
      errors[errorItem.path] = errorItem.message;
      fieldNames.push(errorItem.path);
    }
  }
  return errors;
};

export const validateObject = (data, dataSchema, requireFields, context?) => {
  const validatedFields = pick(dataSchema, requireFields);
  const newShape = Object.entries(validatedFields)
    .reverse()
    .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {});
  const schema = yup.object().shape(newShape);
  try {
    schema.validateSync(data, { context, abortEarly: false });
  } catch (yupErr) {
    return parseErrorObject(yupErr);
  }
  return null;
};

export const nl2br = (str: string) => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  return (`${str}`).replace(/([^>\r\n]?)(\r\n|\n\r|\n)/g, '$1<br />$2');
};

export const trimNewLines = (str: string) => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  return (`${str}`).replace(/(\r\n|\n\r|\n)+$/, '').trim();
};

export const getErrorMessage = (res) => {
  const errors = res.response && res.response.data ? res.response.data.errors : res.errors;
  return (Array.isArray(errors) && errors.length > 0) ?
    (errors[0].message || errors[0].type) :
    (res.response && res.response.data && res.response.data.message) || res.message;
};

export const isMissingFieldsError = (res) => {
  const errors = res.response && res.response.data ? res.response.data.errors : res.errors;
  return Array.isArray(errors) && errors.length > 0 && errors[0].type === 'missing_fields';
};

export const isCohortRosterLocked = (res) => {
  const errors = res.response && res.response.data ? res.response.data.errors : res.errors;
  return Array.isArray(errors) && errors.length > 0 && errors[0].type === 'cohort_roster_locked';
};

export const isInvalidFieldError = (error) => error.type && error.type === 'invalid_field';

export const getMissingFieldsError = (res) => {
  const errors = res.response && res.response.data ? res.response.data.errors : res.errors;
  return (Array.isArray(errors) && errors.length > 0 && errors[0].detail && errors[0].detail.fields) ?
    errors[0].detail.fields : [];
};

export const generateInvalidErrorMessage = (error, fieldNameObj) => {
  const fieldNames = Object.keys(fieldNameObj);
  if (isInvalidFieldError(error)) {
    const { message } = error;
    const field = intersection(fieldNames, message.split(' '));
    return field.length > 0 ? message.replace(field[0], fieldNameObj[field[0]]) : message;
  }
  return error.message;
};

export const generateInvalidErrorMessages = (res, fieldNameObj: { [x: string]: string }): string[] => {
  let errorMessages = [];
  let errors = res;
  if (!Array.isArray(res)) {
    errors = res.response && res.response.data ? res.response.data.errors : res.errors;
  }
  if (!Array.isArray(errors) && errors.length === 0) {
    return errorMessages;
  }

  errorMessages = errors.map(error => generateInvalidErrorMessage(error, fieldNameObj));
  return uniq(errorMessages);
};

export const isErrorByErrorType = (res, type) => {
  const errors = res.response && res.response.data ? res.response.data.errors : res.errors;
  return Array.isArray(errors) && errors.length > 0 && errors[0].type === type;
};

export const mappingFieldsToLabel = (fields: string[]) => {
  const labels = [];
  fields.forEach(field => {
    switch (field) {
      case 'term':
        labels.push('Term (semester)');
        break;
      case 'courseSetupDate':
        labels.push('Course Setup Date');
        break;
      case 'studentEnrollmentDate':
        labels.push('Student Enrollment Date');
        break;
      case 'schoolCourseName':
        labels.push('School Course Name');
        break;
      case 'leadFaculty':
        labels.push('Lead Faculty');
        break;
      case 'courseFormat':
      case 'courseFormatName':
      case 'platformId':
      case 'platformName':
        if (!labels.includes('Hosting Details')) {
          labels.push('Hosting Details');
        }
        break;
      default:
        labels.push(field);
        break;
    }
  });
  return labels;
};

const transformToCohortPackageAddons = (addons: any): PackageAddon[] => addons && addons.map(addon => ({
  AddonID: addon.addonId,
  AddonName: addon.addonName,
  ProductName: addon.productName
}));

export const transformToCohortPackage = (evolvePackage: TspCohortPackage): CohortPackage => evolvePackage && ({
  PackageID: evolvePackage.packageId,
  CostPerStudent: evolvePackage.costPerStudent,
  PackageName: evolvePackage.packageName,
  PSPPurchasingType: evolvePackage.pspPurchasingType,
  TotalCost: evolvePackage.totalCost,
  Addons: evolvePackage.addons ? transformToCohortPackageAddons(evolvePackage.addons) : []
});

export const transformToCohortWorkflowStatuses = (evolveCohortWorkflowStatus: TspCohortWorkflowStatusModel): CohortWorkflowStatusModel =>
  evolveCohortWorkflowStatus && ({
    CohortID: evolveCohortWorkflowStatus.cohortId,
    ProductSelections: evolveCohortWorkflowStatus.productSelections,
    DigitalMappingConsultation: evolveCohortWorkflowStatus.digitalMappingConsultation,
    CohortSetup: evolveCohortWorkflowStatus.cohortSetup,
    EducationAndTraining: evolveCohortWorkflowStatus.educationAndTraining,
    StudentSetup: evolveCohortWorkflowStatus.studentSetup,
    CustomerSuccess: evolveCohortWorkflowStatus.customerSuccess,
    HESITesting: evolveCohortWorkflowStatus.hesiTesting
  });

export const transformToTspCohortWorkflowStatuses = (evolveCohortWorkflowStatus: CohortWorkflowStatusModel) =>
  evolveCohortWorkflowStatus && ({
    cohortId: evolveCohortWorkflowStatus.CohortID,
    productSelections: !!evolveCohortWorkflowStatus.ProductSelections,
    digitalMappingConsultation: !!evolveCohortWorkflowStatus.DigitalMappingConsultation,
    cohortSetup: !!evolveCohortWorkflowStatus.CohortSetup,
    educationAndTraining: !!evolveCohortWorkflowStatus.EducationAndTraining,
    studentSetup: !!evolveCohortWorkflowStatus.StudentSetup,
    customerSuccess: !!evolveCohortWorkflowStatus.CustomerSuccess,
    hesiTesting: !!evolveCohortWorkflowStatus.HESITesting
  });

export const transformToCohortArray = (evolveCohorts: any): CohortModel[] =>
  evolveCohorts && evolveCohorts.map(evolveCohort => ({
    CohortName: evolveCohort.cohortName,
    CohortID: evolveCohort.cohortId,
    CohortCount: evolveCohort.cohortCount,
    Program: evolveCohort.program,
    NumberOfInstallments: evolveCohort.numberOfInstallments,
    Status: evolveCohort.status,
    StartDate: evolveCohort.startDate,
    EndDate: evolveCohort.endDate,
    IsInFlightCohort: evolveCohort.isInFlightCohort,
    Package: transformToCohortPackage(evolveCohort.package),
    WorkflowStatuses: transformToCohortWorkflowStatuses(evolveCohort.workflowStatuses),
    CurrentInstallmentNumber: evolveCohort.currentInstallmentNumber,
    CurrentCohortCount: evolveCohort.currentCohortCount
  }));

export const isCohortStudentPayment = (pkg: CohortPackage) => {
  const studentPaymentPurchasingTypes = [PurchasingType.HYBRID_INSTITUTIONAL_STUDENT_PAY_ECOMMERCE, PurchasingType.STUDENT_PAY_ECOMMERCE];
  return pkg && studentPaymentPurchasingTypes.includes(pkg.PSPPurchasingType);
};

export const isCohortStudentPaymentByPurchasingType = (pspPurchasingType: PurchasingType) => {
  const studentPaymentPurchasingTypes = [PurchasingType.HYBRID_INSTITUTIONAL_STUDENT_PAY_ECOMMERCE, PurchasingType.STUDENT_PAY_ECOMMERCE];
  return pspPurchasingType && studentPaymentPurchasingTypes.includes(pspPurchasingType);
};

export const isInstallmentAmountValueSelected = (purchasingType) => {
  const institutionalPurchasingTypes = [PurchasingType.INSTITUTIONAL_VIA_A_BOOKSTORE, PurchasingType.INSTITUTIONAL,
    PurchasingType.HYBRID_INSTITUTIONAL_AND_INSTITUTIONAL_BOOKSTORE];
  return !purchasingType || institutionalPurchasingTypes.includes(purchasingType);
};

export const getInstallmentAmount = ({ pspPurchasingType, installmentAmountPerStudent, institutionCostPerStudent }: EvolveInstallmentModel) =>
  (isInstallmentAmountValueSelected(pspPurchasingType) ?
    installmentAmountPerStudent :
    institutionCostPerStudent);

export const isSumOfPendingInvoicesEqualInstallmentAmount = (invoices: InvoiceModel[]) => {
  const { installment } = invoices[0];
  const sumOfPendingInvoices = invoices.reduce((sum, invoice) => (isInvoicePendingStatus(invoice.invoiceStatus) ? +(sum + +invoice.costPerStudent).toFixed(2) : sum), 0);
  return +getInstallmentAmount(installment) === sumOfPendingInvoices;
};

export const getStateByCountry = (country: string = COUNTRY.US) => {
  if (country === COUNTRY.CA) {
    return canadaProvinces;
  }
  return usStates;
};

export const getBillingCountries = () => billingCountries;

export const isLocalProfile = () => {
  const localProfiles = ['local', 'local_qa'];
  const index = findIndex(localProfiles, (o) => o === getProperty('DataSource'));
  return index !== -1;
};

export const getTypeError = (res) => {
  const errors = res.response && res.response.data ? res.response.data.errors : res.errors;
  return (Array.isArray(errors) && errors.length > 0 && errors[0].type) ?
    errors[0].type : null;
};

export const getDetailError = (res) => {
  const errors = res.response && res.response.data ? res.response.data.errors : res.errors;
  return (Array.isArray(errors) && errors.length > 0 && errors[0].detail) ?
    errors[0].detail : {};
};

export const isValidCreditRequestNumber = (value: string) => /^PSCR-(\d+)-(\d+)$/.test(value);

export const convertToBase64 = (file): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = error => reject(error);
});

export const blobToJson = (blob: Blob) => {
  const url = URL.createObjectURL(blob);
  const xmlRequest = new XMLHttpRequest();
  xmlRequest.open('GET', url, false);
  xmlRequest.send();
  URL.revokeObjectURL(url);
  return JSON.parse(xmlRequest.responseText);
};

let prevLocalStorage = {};
const TSP_WATCH_LOCAL_STORAGE_FIELDS = ['elsevier._euid', 'elsevier.loggedIn', 'elsevier.evolveUser', 'security.elsevier.jwt'];
export const isLocalStorageChange = (newStorageData: Storage) => {
  const isDataChange = !!Object.keys(newStorageData).find(key => TSP_WATCH_LOCAL_STORAGE_FIELDS.includes(key) &&
    prevLocalStorage[key] !== newStorageData[key]);
  if (isDataChange) {
    prevLocalStorage = JSON.parse(JSON.stringify(newStorageData));
  }
  return isDataChange;
};

export const isCreditRequestEditable = (creditRequestStatus?: CreditRequestStatusModel) => {
  const allowStatuses = [
    CreditRequestStatuses.unprocessed.id,
    CreditRequestStatuses.underReview.id,
    CreditRequestStatuses.denied.id
  ];
  return creditRequestStatus && allowStatuses.includes(creditRequestStatus.id);
};

export const getLatestStatusOfInstallment = (installment) => {
  if (!installment.oracleStatus || !installment.oracleStatus.id) {
    return installment.invoiceStatus;
  }

  if (isSameOrAfterDate(installment.oracleStatus.activityDate, installment.invoiceStatus.activityDate)) {
    return installment.oracleStatus;
  }

  return installment.invoiceStatus;
};

export const isCohortGraduated = (cohort: CohortModel) => !isSameOrBeforeDate(new Date(), cohort.EndDate);

export const isHESICohortCreated = (cohort: CohortModel) => !!cohort.HesiCohortId;

export const isHESICohortCancelled = (cohort: CohortModel) => cohort.HesiCohortStatus === HESICohortStatus.CANCELED;

export const isNGContractCreated = (cohort: CohortModel) => !!cohort.HesiNGOrderId;

export const filterUsersByPermission = (enrollments, permissionKey) => enrollments.filter(e => e.permissions && e.permissions[permissionKey]);

export const getUsersByPermission = (enrollments, permissionKey) => {
  const enrollmentsPermission = filterUsersByPermission(enrollments, permissionKey);
  return enrollmentsPermission ? enrollmentsPermission.map(f => f.userName) : [];
};

export const filterUsersBySelectedUsers = (users, selectedUsers, field) => users.filter(user => selectedUsers.includes(user[field]));

export const formatRosterName = (roster: RosterModel) => `${roster.lastName}, ${roster.firstName}, ${roster.userName}, ${roster.email}`;

export const customRosterOptions = rosters => rosters.map(roster => ({ ...roster, name: formatRosterName(roster), value: roster.userName }));

export const generateNGContractNumber = (hesiCohortId) => `PS-${hesiCohortId}-${Date.now()}`;

export const isAllowedHesiContractStatus = (allowStatusIds: number[], hesiContractStatus?: HESIContractStatusModel) =>
  hesiContractStatus && allowStatusIds.includes(hesiContractStatus.id);

export const getHesiOpsErrorMessage = (hesiContractStatus: HESIContractStatusModel) =>
  hesiContractStatus.activityDetails &&
  ((hesiContractStatus.activityDetails.details &&
  hesiContractStatus.activityDetails.details.detailedErrorMessage) || (
    hesiContractStatus.activityDetails.hesiResponse &&
    hesiContractStatus.activityDetails.hesiResponse.details &&
    hesiContractStatus.activityDetails.hesiResponse.details.detailedErrorMessage
  ));

export const orderEnrollemntsByLastAndFirstName = (enrollments) => (orderBy(uniqBy(enrollments, 'userName'),
  [(student) => student.lastName.toLowerCase(), (student) => student.firstName.toLowerCase()], ['asc']));

export const customGroupOptions = groups => groups.map(group => ({ name: group.groupName, value: `${group.tspGroupId}` }));

export const formatAmountValue = amount => parseFloat(amount).toFixed(2);

export const recognizeInvoiceMode = action => {
  let isView = false;
  let isEdit = false;
  let isCreate = false;
  let initInvoicePopupType = 0;
  switch (action) {
    case INVOICE_MODE.CREATE:
      isCreate = true;
      break;
    case INVOICE_MODE.VIEW:
      initInvoicePopupType = 2;
      isView = true;
      break;
    case INVOICE_MODE.EDIT:
      initInvoicePopupType = 1;
      isEdit = true;
      break;
    default:
      break;
  }
  return { isCreate, isEdit, isView, initInvoicePopupType };
};

const buildInvoiceModel = (invoice) => ({
  invoiceDate: invoice.invoiceDate,
  poNumber: invoice.poNumber ? invoice.poNumber.trim() : invoice.poNumber,
  billingAddress: invoice.billingAddress,
  shippingAddress: invoice.shippingAddress,
  billingContactName: invoice.billingContactName.trim(),
  billingContactEmail: invoice.billingContactEmail,
  taxExemptFlag: !!invoice.taxExemptFlag,
  taxRegistrationNumber: invoice.taxRegistrationNumber ? invoice.taxRegistrationNumber.trim() : invoice.taxRegistrationNumber,
  legalLanguage: invoice.legalLanguage,
  comment: invoice.comment,
  costPerStudent: invoice.costPerStudent
});

export const buildInvoicePayload = (invoices, mode) => {
  if (!Array.isArray(invoices)) {
    return [];
  }
  return invoices.map(invoiceIns => {
    const invoiceModel: InvoiceModel = buildInvoiceModel(invoiceIns);
    if (INVOICE_MODE.EDIT === mode) {
      invoiceModel.tspInvoiceId = invoiceIns.tspInvoiceId;
    }
    return invoiceModel;
  });
};

export const isResourceCompleted = (endDate?: string | Date) => endDate && isAfterDate(new Date(), endDate);

export const getOppTaxExemptFlagByInvoice = (oppTaxExemptFlag: boolean | null, updatedInvTaxExemptFlag: boolean, isTaxExemptFlagUpdated: boolean) => {
  if (!isTaxExemptFlagUpdated) {
    return oppTaxExemptFlag;
  }

  if (!!oppTaxExemptFlag && !updatedInvTaxExemptFlag) {
    return null;
  }

  if (updatedInvTaxExemptFlag) {
    return updatedInvTaxExemptFlag;
  }

  return oppTaxExemptFlag;
};

export const buildStudentSelfEnrollmentLink = (studentUniqueUrlGuid) => `${getProperty('evolvePortalBaseUrl')}/tsp/my-dashboard/student-roster-submission/${studentUniqueUrlGuid}`;
